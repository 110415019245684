const firstEl = { top: -8, right: 14 };
const secondEl = { top: -8, right: 7 };
const thirdEl = { top: -8, right: 0 };
const fourthEl = { top: -15, right: 14 };
const fifthEl = { top: -15, right: 7 };
const sixthEl = { top: -15, right: 0 };

export const LegendaItemPosition = {
  1: [firstEl],
  2: [firstEl, secondEl],
  3: [firstEl, secondEl, thirdEl],
  4: [firstEl, secondEl, thirdEl, fourthEl],
  5: [firstEl, secondEl, thirdEl, fourthEl, fifthEl],
  6: [firstEl, secondEl, thirdEl, fourthEl, fifthEl, sixthEl],
};

import { openDialogBuilding } from "../../../redux/reducers/DialogBuilding-reducer";
import { setPageType } from "../../../redux/reducers/DialogGeneral";

export const handleDialogEditBuilding = ({ type, row:rowInfo }, dispatch, event = null) => {
  if(event) event.stopPropagation();
  dispatch(openDialogBuilding({ type, rowInfo }));
};

export const handleDialogToEnableDisableBuilding = ({type, dialogPageType: dialogPageType, row: rowInfo}, dispatch, event = null) => {
  if(event) event.stopPropagation(); 
  dispatch(setPageType(dialogPageType));
  dispatch(openDialogBuilding({ type, rowInfo }));
};
import { Typography } from "@mui/material";
import Logo from "../../assests/logo.png";
import { useTranslation } from "react-i18next";

export default function NotFound() {
    const { t } = useTranslation(); 
    return(
    <div style={{textAlign: 'center', marginTop:'50px'}}>
    <img src={Logo} width={350}/>
    <Typography variant='h4' mt={2} >{t("PageNotFound")}</Typography>
    <Typography mt={2}>{t("SorryPageDoesNotExist")}</Typography>
    </div>
    )
}
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next"; // Importa useTranslation dal pacchetto react-i18next
import { dialogPageTypes } from "../../const/dialogPageTypes";
import { Grid, Divider, Typography } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import TableToolbar from "../UI/TableToolbar/TableToolbar";
import { CDCard, CDCardContent, CDCardHeader } from "../../const/CDCardStyles";
import CheckValidSortModel from "../../functions/CheckValidSortModel";
import { PostazioniHeader, PostazioniHeaderNoQrCodeDinamico } from "../../const/DatatableData";
import { getPostazioniDataTableByIdStanza } from "../../const/urlAction";
import { useSelector, useDispatch } from "react-redux";
import PostazioniDialog from "../Dialog/PostazioniDialog";
import handlerError from "../../functions/handlerError";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import Notification from "../UI/Notification/Notification";
import { useParams } from "react-router-dom";
import PlanimetriaGestione from "../Planimetria/PlanimetriaGestione/PlanimetriaGestione";
import { removeDataGridHeader } from "../../functions/handlerDataGrid";
import usePathTextDetail from "../../Hooks/usePathTextDetail";
import { openEmptyDialog, setPageType } from "../../redux/reducers/DialogGeneral";
import useCheckDisabilitazioniSuPostazioni from "../../Hooks/useCheckDisabilitazioniSuPostazioni";
import { TitleDatable } from "../TitleDatable/TitleDataTable";
import useHeaderDataGridI18n from "../../Hooks/I18n/useHeaderDataGridI18n";
import MobileViewContainer from "../UI/mobileView/mobileViewContainer";
import MobileViewList from "../UI/mobileView/list/MobileViewList";
import ButtonBoxPostazioni from "./ButtonBoxPostazioni";
import { rowStructuresEnum } from "../UI/mobileView/enum/rowStructuresEnum";
import { handleDialogToEditPostazioni } from "./operations/handleDialogToEditPostazioni";
import { useRef } from "react";
import handlePaginationAndRowCleanupMobileView from "../../functions/mobileView/handlePaginationAndRowCleanupMobileView";
import useViewportAdaptedHeight from "../../Hooks/Adaptive/useViewportAdaptedHeight";
import convertTipoStanzaToKeysI18n from "../../functions/DataTable/convertTipoStanzaToNameDataTable";

const typePage = "Postazioni";
const urlBase = process.env.REACT_APP_URL;
const rowPage = 10;

const PostazioniDatatable = () => {

  const { t } = useTranslation(); // Inizializza la funzione di traduzione
  const dispatch = useDispatch();
  const params = useParams();
  const qrcodeDinamico = useSelector(
    (state) => state.configurations.configurazioniGlobali.qrCodeDinamico
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const viewState = useSelector((state) => state.viewState);
  const reloading = useSelector((state) => state.dataTablePostazioni);
  const [searchFilter, setSearchFilter] = useState("");
  const lastSearchFilter = useRef(); //determina se deve resettare le row
  const lastPageIndex = useRef(); //determina se deve resettare le row
  const [searchStatus, setSearchStatus] = useState(false);
  const [dettaglioStanza, setDettaglioStanza] = useState({});
  const [idTipologiaStanza, setIdTipologiaStanza] = useState();
  const [page, setPage] = useState(0);
  const CDCardContentRef = useRef(null);
  const [pageSize, setPageSize] = useState(rowPage);
  const [rows, setRows] = useState([]);
  const header = useHeaderDataGridI18n(
      qrcodeDinamico
      ? PostazioniHeader.headers
      : PostazioniHeaderNoQrCodeDinamico.headers
  );
  const [rowsCount, setRowsCount] = useState(rows.length);
  const [loading, setLoading] = useState(false);
  const [isMapActive, setIsMapActive] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: PostazioniHeader.initialFieldName,
      sort: PostazioniHeader.initialFieldOrder,
    },
  ]);
  const StyledDataGrid = removeDataGridHeader(DataGrid, viewState);
  const path = usePathTextDetail();
  const isDisabilitazioniOnEntity = useCheckDisabilitazioniSuPostazioni();
  const [keysI18n, setKeysI18n] = useState({});
  const handleSearchFilter = (searchText) => {
    setSearchFilter(searchText);
    setSearchStatus(!searchStatus);
    setPage(0)
    setRows([])
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const handleResizeWindow = () => {
    if (window.innerWidth > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    const { sortField, sortOrder } = CheckValidSortModel(
      sortModel,
      PostazioniHeader.initialFieldName
    );
    if (!isMapActive) {
      (async () => {
        let isMounted = true;
        setLoading(true);
        const { idStanza } = params;
        const { action, method } = getPostazioniDataTableByIdStanza;
        let url = urlBase + action;

        const requestBody = {
          pageIndex: page,
          pageSize: pageSize,
          sortColumnName: sortField,
          sortDirection: sortOrder,
          applyPaginationAndSort: true,
          searchFilter: searchFilter,
          IdStanza: idStanza,
        };

        const cleanRow = handlePaginationAndRowCleanupMobileView(lastSearchFilter.current,lastPageIndex.current,searchFilter,page,pageSize,requestBody)

        try {
          const response = await axios({
            method: method,
            url,
            data: requestBody,
          });

          if (isMounted) {
            setDettaglioStanza(response.data.result.dettaglio);
            setIdTipologiaStanza(response.data.result.dettaglio.idTipologiaStanza);
            const newRows = response.data.result.result;
            const newRowsCount = response.data.result.totalFiltered;
            cleanRow || !isMobile && !viewState  ? setRows(newRows) : setRows(oldRows => [...oldRows, ...newRows]);
            setRowsCount(newRowsCount);
            lastSearchFilter.current = requestBody.searchFilter;
            lastPageIndex.current = requestBody.pageIndex;
            setLoading(false);
          }

          if (!response.data.ok) {
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          }
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
          setLoading(false);
        }

        return () => {
          isMounted = false;
        };
      })();
    }
  }, [
    page,
    pageSize,
    sortModel,
    searchStatus,
    searchFilter,
    reloading,
    isMapActive,
  ]);

  //gestisce il cambio di View su dispositivo desktop tra desktop e mobile
  useEffect(() => {
    if(page != 0 || pageSize != rowPage) setRows([])
    setPage(0)
    setPageSize(rowPage)
  },[viewState])

  function changeViewToMap() {
    setIsMapActive(!isMapActive);
    return !isMapActive;
  }

  const adaptedHeight = useViewportAdaptedHeight(".adaptHeight", 19 , CDCardContentRef, !loading, [viewState, isMobile]);

  useEffect(()=>{
    if (idTipologiaStanza && idTipologiaStanza !== 0) {
      setKeysI18n(convertTipoStanzaToKeysI18n(idTipologiaStanza));
    }
  },[idTipologiaStanza])
  
  return (
    <>
      {isMobile ? (
        keysI18n && (<>
        <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding adaptHeight"
                title={
                  <>
                    <TitleDatable
                      denominazione={dettaglioStanza.denominazione}
                      nameDataTable={t(keysI18n.titleDataTable)}
                      pathDataTable={path}
                      mostraTipologia={true}
                    />
                  </>
                }
              />
              <Divider />
              <CDCardContent className="CDCardContent-Mobile small-padding-mobilerow" style={{height: adaptedHeight}} ref={CDCardContentRef}>
              <Grid container item>
            <MobileViewContainer>
              <TableToolbar
                typePage={typePage}
                enableAdd={true}
                enableSearch={true}
                enableExport={true}
                map={false}
                isSalaRiunione={dettaglioStanza.isSalaRiunione}
                showResetDisable={isDisabilitazioniOnEntity}
                changeViewToMap={changeViewToMap}
                handleAdd={() => {
                  dispatch(setPageType(dialogPageTypes.postazioni));
                  dispatch(openEmptyDialog("add"));
                }}
                handleEdit={() => {
                  dispatch(setPageType(dialogPageTypes.postazioni));
                  dispatch(openEmptyDialog("add"));
                }}
                initialSearchText={searchFilter}
                startSearch={handleSearchFilter}
              />
            </MobileViewContainer>
            <MobileViewList rows={rows} BoxButton={ButtonBoxPostazioni} typeStructure={rowStructuresEnum.PostazioneRowStructures} handleDialogToEdit={handleDialogToEditPostazioni}
                                setPage={setPage}
                                areMoreRowsAvailable={(page + 1) * pageSize < rowsCount}
            />
            </Grid>
            </CDCardContent>
            </Grid>
          </Grid>
        </>)
      ) :  !isMobile && !viewState ? (
        (keysI18n && <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding"
                title={
                  <>
                    <TitleDatable
                      denominazione={dettaglioStanza.denominazione}
                      nameDataTable={t(keysI18n.titleDataTable)}
                      pathDataTable={path}
                      mostraTipologia={true}
                    />
                  </>
                }
              />
              <Divider />
              <CDCardContent>
                <Grid container item>
                  {isMapActive ? (
                    <>
                      <TableToolbar
                        typePage={t("planimetriaGestione")}
                        map={true}
                        mobile={false}
                        changeViewToMap={changeViewToMap}
                      />
                      <PlanimetriaGestione dettaglio={dettaglioStanza} />
                    </>
                  ) : (
                    <StyledDataGrid
                      rows={rows}
                      rowCount={rowsCount}
                      getRowId={(row) => `${row.id}-${row.posto}`}
                      columns={header}
                      page={page}
                      pageSize={pageSize}
                      onPageChange={(newPage) => setPage(newPage)}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      onSortModelChange={(newSortModel) =>
                        setSortModel(newSortModel)
                      }
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      disableSelectionOnClick
                      sortModel={sortModel}
                      sortingMode="server"
                      sortingOrder={["asc", "desc"]}
                      paginationMode="server"
                      loading={loading}
                      sx={{ border: 0 }}
                      disableColumnMenu
                      disableColumnFilter
                      disableColumnSelector
                      autoHeight
                      autoWidth
                      components={{
                        Toolbar: () => (
                          <TableToolbar
                            typePage={typePage}
                            enableAdd={true}
                            enableSearch={true}
                            enableExport={true}
                            map={true}
                            isSalaRiunione={dettaglioStanza.isSalaRiunione}
                            showResetDisable={isDisabilitazioniOnEntity}
                            changeViewToMap={changeViewToMap}
                            handleAdd={() => {
                              dispatch(setPageType(dialogPageTypes.postazioni));
                              dispatch(openEmptyDialog("add"));
                            }}
                            handleEdit={() => {
                              dispatch(setPageType(dialogPageTypes.postazioni));
                              dispatch(openEmptyDialog("add"));
                            }}
                            initialSearchText={searchFilter}
                            startSearch={handleSearchFilter}
                          />
                        ),
                        NoRowsOverlay: () => (
                          <GridOverlay>
                            <Typography>{t("NoRowsOverlay")}</Typography>
                          </GridOverlay>
                        ),
                        NoResultsOverlay: () => (
                          <GridOverlay>
                            <Typography>{t("NoRowsOverlay")}</Typography>
                          </GridOverlay>
                        ),
                      }}
                    />
                  )}
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
        </>)
      ) : (
        <>
        <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding adaptHeight"
                title={
                  <>
                    <TitleDatable
                      denominazione={dettaglioStanza.denominazione}
                      nameDataTable={t(keysI18n.titleDataTable)}
                      pathDataTable={path}
                      mostraTipologia={true}
                    />
                  </>
                }
              />
              <Divider />
               <div  className="adaptHeight" style={{ backgroundColor: "white" }}>
                <div style={{ margin: "20px" }}>
                <TableToolbar
                typePage={typePage}
                enableAdd={true}
                enableSearch={true}
                enableExport={true}
                map={false}
                isSalaRiunione={dettaglioStanza.isSalaRiunione}
                showResetDisable={isDisabilitazioniOnEntity}
                changeViewToMap={changeViewToMap}
                handleAdd={() => {
                  dispatch(setPageType(dialogPageTypes.postazioni));
                  dispatch(openEmptyDialog("add"));
                }}
                handleEdit={() => {
                  dispatch(setPageType(dialogPageTypes.postazioni));
                  dispatch(openEmptyDialog("add"));
                }}
                initialSearchText={searchFilter}
                startSearch={handleSearchFilter}
              />
              </div>
              </div>
              <CDCardContent className="CDCardContent-Mobile " style={{height: adaptedHeight}} ref={CDCardContentRef}>
              <Grid container item>
            <MobileViewList rows={rows} BoxButton={ButtonBoxPostazioni} typeStructure={rowStructuresEnum.PostazioneRowStructures} handleDialogToEdit={handleDialogToEditPostazioni}
                                setPage={setPage}
                                areMoreRowsAvailable={(page + 1) * pageSize < rowsCount}
            />
            </Grid>
            </CDCardContent>
            </Grid>
          </Grid>
        </>
      )}
      <Notification />
      <PostazioniDialog />
    </>
  );
};

export default PostazioniDatatable;

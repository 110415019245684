import * as yup from "yup";

/* ## Building ## */

export const validationSchemaBuildingEdit = yup.object({
  Denominazione: yup
    .string()
    .required("Campo Obbligatorio."),
  Sigla: yup
    .string()
    .required("Campo Obbligatorio."),
  UtentiResponsabiliPrenotazioni: yup.array()
    .of(
      yup.object().shape({
        idUtente: yup.string().required("Campo Obbligatorio."),
        nominativo: yup.string().required("Campo Obbligatorio.")
      })
        .required("Campo Obbligatorio.")
    ),
  OrariAperturaEdificio: yup.array()
    .of(
      yup.object().shape({
        idGiornoSettimana: yup.string().required('Il giorno è obbligatorio'),
        orarioApertura: yup.string().test('validazioneOrario', 'L\'orario di apertura deve essere precedente all\'orario di chiusura.', function (value, context) {
          const orarioChiusura = context.parent.orarioChiusura;
          if (value && orarioChiusura) {
            return new Date(`2000-01-01T${value}`) < new Date(`2000-01-01T${orarioChiusura}`);
          }
          return true;
        }),
        orarioChiusura: yup.string().test('validazioneOrario', 'L\'orario di chiusura deve essere successivo all\'orario di apertura.', function (value, context) {
          const orarioApertura = context.parent.orarioApertura;
          if (value && orarioApertura) {
            return new Date(`2000-01-01T${value}`) > new Date(`2000-01-01T${orarioApertura}`);
          }
          return true;
        }),
        apertura: yup.boolean().required('Il campo apertura è obbligatorio'),
      })
    )
    .min(7, 'Indicare orario di apertura per tutti i giorni della settimana.'),
});

export const validationSchemaBuildingAdd = yup.object({
  Denominazione: yup
    .string()
    .required("Campo Obbligatorio."),
  Sigla: yup
    .string()
    .required("Campo Obbligatorio."),
  UtentiResponsabiliPrenotazioni: yup.array()
    .of(
      yup.object().shape({
        idUtente: yup.string().required("Campo Obbligatorio."),
        nominativo: yup.string().required("Campo Obbligatorio.")
      })
        .required("Campo Obbligatorio.")
    ),
  OrariAperturaEdificio: yup.array()
    .of(
      yup.object().shape({
        idGiornoSettimana: yup.string().required('Il giorno è obbligatorio'),
        orarioApertura: yup.string().test('validazioneOrario', 'L\'orario di apertura deve essere precedente all\'orario di chiusura.', function (value, context) {
          const orarioChiusura = context.parent.orarioChiusura;
          if (value && orarioChiusura) {
            return new Date(`2000-01-01T${value}`) < new Date(`2000-01-01T${orarioChiusura}`);
          }
          return true;
        }),
        orarioChiusura: yup.string().test('validazioneOrario', 'L\'orario di chiusura deve essere successivo all\'orario di apertura.', function (value, context) {
          const orarioApertura = context.parent.orarioApertura;
          if (value && orarioApertura) {
            return new Date(`2000-01-01T${value}`) > new Date(`2000-01-01T${orarioApertura}`);
          }
          return true;
        }),
        apertura: yup.boolean().required('Il campo apertura è obbligatorio'),
      })
    )
    .min(7, 'Indicare orario di apertura per tutti i giorni della settimana.'),
});
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Paper, Grid } from "@mui/material";
import "../mobileViewList.css";
import { useTranslation } from "react-i18next";
import LayerDescender from "../general/LayerDescender";
import { useDispatch } from "react-redux";
import { openDialog, setPageType } from "../../../../redux/reducers/DialogGeneral";
import { multiDialogAction } from "../../../../const/Dialog/MultiDialogAction";
import { dialogPageTypes } from "../../../../const/dialogPageTypes";
import { useEffect, useRef, useState } from "react";
import useAdaptiveEllipsis from "../../../../Hooks/Adaptive/useAdaptiveEllipsis";

export default function DataTableRowStructures({ row, BoxButton, handleDialogToEdit = () => null }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridItemRef = useRef(null);
  const [paragraphWidth, setParagraphWidth] = useState(null);

  useAdaptiveEllipsis(gridItemRef, setParagraphWidth, -5);

  return (
    <>
      <Paper className="row-mobileView box-shadow-light " onClick={() => { return row.isSalaRiunione ? handleDialogToEdit({ type: multiDialogAction.edit, row }, dispatch): null; }}>
        <Grid container>
          <Grid container item xs={11}>
            <Grid item xs={2} sm={1} style={{ maxWidth: "55px" }} >
              <div className="booking-card-left-section bk-icon-mob flex-center-center">
                <Grid container item justifyContent="center" xs={2} sm={2} md={1}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        background: "#3e6bb4",
                        width: "27px",
                        height: "27px",
                        borderRadius: "26px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "11px",
                          color: "white",
                          fontStyle: "regular",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        {row.sigla.toUpperCase()}
                      </div>
                    </div>
                  </div>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={9} sx={{ paddingTop: "5px" }} 
            onClick={() => handleDialogToEdit({ type: multiDialogAction.edit, row }, dispatch)}
            >
              <Grid
                item
                xs={12}
              >
                <Grid
                  item
                  xs={10}
                  sm={10}
                  md={11}
                  className="mobileRow-center-text"
                  ref={gridItemRef}
                >
                  <p className="font-segoeui firstRow-mobileView textOverflowEllipsis" style={{ width: paragraphWidth }} >
                    {row.denominazione}
                  </p>
                  {row.idSfondoPlanimetria !== undefined && (
                    <p className="font-segoeui secondRow-HeaderMobile">
                      {t('floorPlan')}: {row.idSfondoPlanimetria !== null ? t('yes') : t('no')}
                    </p>
                  )}
                  {row.utentiResponsabiliPrenotazioni && (
                    <p className="font-segoeui secondRow-mobileView textOverflowEllipsis" style={{ width: paragraphWidth }} >
                      {row.utentiResponsabiliPrenotazioni
                        .map((x) => x.nominativo)
                        .join(", ")}
                    </p>
                  )}
                </Grid>
              </Grid>
              {BoxButton && (
                <Grid
                  item
                  xs={12}
                >
                  <BoxButton row={row} />
                </Grid>
              )}
            </Grid>
          </Grid>
          {!row.isSalaRiunione && <Grid container item xs={1} className="flex-center-center">
            <Grid item xs={12} className="flex-center-center height-tot">
              <LayerDescender icon={faAngleRight} id={row.id} />
            </Grid>
          </Grid>}
        </Grid>
      </Paper>
    </>
  );
}

import { Autocomplete, Button, Checkbox, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import { closeDialogUser } from "../../../redux/reducers/dialogUser-reducer";
import { useDispatch } from "react-redux";
import { useEffect, useState, Fragment, useLayoutEffect } from "react";
import {
  deleteAccount,
  resetPassword,
  getUsersFiltered,
} from "../../../const/urlAction";
import axios from "axios";
import { reloadingDataTableUsers } from "../../../redux/reducers/dataTableUsers-reducer";
import { useFormik } from "formik";
import {
  dialogButtonConfirm,
  changeStatusUserTitleDialog,
  changeStatusUserTextDialog,
} from "../../../const/DialogData";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import handlerError from "../../../functions/handlerError";
import {
  validationSchemaUserReset,
} from "../../../const/formik/User/validationSchemaUser";
import {
  initialValuesUserAdd,
  initialValuesUserDelete,
  initialValuesUserEdit,
  initialValuesUserReset,
} from "../../../const/formik/User/initialValuesUser";
import DialogAddUser from "./componentUserDialog/viewUserDialog/DialogAddUser";
import DialogEditUser from "./componentUserDialog/viewUserDialog/DialogEditUser";
import { sincronizzazione } from "../../UsersDatatable/customButton/SynchronizeButton";
import DialogSincronizzazioneUser from "./componentUserDialog/viewUserDialog/DialogSincronizzazioneUser"
import { hideLoader, showLoader } from "../../../redux/reducers/appLoader-reducer";
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../../Hooks/useMobileDetection";

const urlBase = process.env.REACT_APP_URL;

const UserDialog = () => {
  const [stateUsers, setStateUsers] = useState([]);
  const [lastTabsSelected, setLastTabsSelected] = useState(0);
  const [isRenderingDialog, setIsRenderingDialog] = useState(false);
  const [changedRow, setchangedRow] = useState();
  const dialogState = useSelector((state) => state.dialogUser);
  const authConfiguration = useSelector((state) => state.configuration);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const backButtonState = useSelector((state) => state.dialogBackButton);
  const userState = useSelector((state) => state.userDate);
  const dispatch = useDispatch();
  const isMobile = useMobileDetection(950);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { rowInfo, dialogType, dialogPageType } = dialogState;
  const [switchStatePassword, setSwitchStatePassword] = useState(true);
  const { t } = useTranslation(); 
  let validationSchema;

  const appLoadingState = useSelector((state) => state.appLoader.loading);

  // if (dialogType === "edit") {
  //   validationSchema = validationSchemaUserEdit;
  // } else if (dialogType === "add") {
  //   validationSchema = switchStatePassword
  //     ? validationSchemaUserAddToAuthDatabase
  //     : validationSchemaUserAddToAuthActiveDirectory;
  // } else
  if (dialogType === "reset") {
    validationSchema = validationSchemaUserReset;
  }

  let initialValues = {};

  const {
    id,
    firstName: FirstName,
    lastName: LastName,
    username: Username,
    PhoneNumber,
    roles,
    users,
    dataDel,
    hasPassword,
  } = rowInfo;

  if (dialogType === "edit") {
    initialValues = initialValuesUserEdit(rowInfo);
  } else if (dialogType === "add") {
    initialValues = initialValuesUserAdd(rowInfo);
  } else if (dialogType === "reset") {
    initialValues = initialValuesUserReset(rowInfo);
  } else if (dialogType === "delete") {
    initialValues = initialValuesUserDelete(rowInfo);
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true, // rinizializza il valore che gli viene passato con rowInfo
    onSubmit: (values) => {
      if (dialogType === "edit") {
        setchangedRow({
          id: values.id,
          FirstName: values.FirstName,
          LastName: values.LastName,
          Username: values.Username,
          PhoneNumber: values.PhoneNumber,
          roles: selectedRoles,
          users: selectedUsers,
        });
      } else if (dialogType === "add") {
        setchangedRow({
          FirstName: values.FirstName,
          LastName: values.LastName,
          Username: values.Username,
          // Email: values.Email,
          PhoneNumber: values.PhoneNumber,
          // Password: values.Password,
          Password: null,
          // ConfirmPassword: values.ConfirmPassword,
          ConfirmPassword: null,
          roles: selectedRoles,
          users: selectedUsers,
        });
      }
    },
  });

  //Gestisce il cambio di Datatable con la Dialog Aperta, la chiude e il dialogPageType impedisce di far partire la chiamata
  useEffect(() => {
    dispatch(closeDialogUser());
  }, [window.onhashchange]);

  /* useEffect(() => {
    let isMounted = true;
    (async () => {
      const { action, method } = getUsersFiltered;
      let url = urlBase + action;
      try {
        const response = await axios({
          method: method,
          url,
        });
        if (isMounted) {
          setStateUsers(response.data.result);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [dialogState.isOpen]); */

  useEffect(() => {
    if (changedRow != null) {
      (async () => {
        let formData, action, method, url;

        if (dialogType === "reset") {
          formData = { ...changedRow };
          action = resetPassword.action;
          method = resetPassword.method;
          url = urlBase + action;
        }
        dispatch(showLoader())
        try {
          const response = await axios({
            method: method,
            url,
            data: formData,
          });

          if (response.data.ok) {
            formik.resetForm(initialValues);
            dispatch(reloadingDataTableUsers());
            dispatch(closeDialogUser());
          }
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: response.data.ok ? "success" : "error",
            })
          );
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
          if (error?.response?.data?.errors != undefined) {
            let keys = Object.keys(error?.response?.data?.errors);
            keys.map((key) => {
              formik.setFieldError(key, error?.response?.data?.errors[key]);
            });
          }
        }
        dispatch(hideLoader())
      })();
    }
  }, [changedRow]);

  const deleteRestore = (rowInfo) => {
    if (dialogState.dialogType === "delete") {
      (async () => {
        setIsRenderingDialog(true);

        const { id } = rowInfo;
        const { action, method } = deleteAccount;
        let url = urlBase + action;
        dispatch(showLoader())
        try {
          const response = await axios({
            method: method,
            url,
            data: Username,
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.data.ok) {
            dispatch(reloadingDataTableUsers());
            setIsRenderingDialog(false);
          }

          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        } catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(
              notificationOpen({
                message: `${error.response.data.message}`,
                status: "error",
              })
            );
          } else {
            dispatch(
              notificationOpen({
                message: "Operazione fallita: Server non raggiungibile.",
                status: "error",
              })
            );
          }
        }
        dispatch(hideLoader())
        setIsRenderingDialog(false);
      })();

      dispatch(closeDialogUser());
    }
  };

  const resetPsw = (rowInfo) => {
    if (dialogState.dialogType === "reset") {
      (async () => {
        setIsRenderingDialog(true);

        const { id } = rowInfo;
        const { action, method } = resetPassword;
        let url = urlBase + action;
        dispatch(showLoader())
        try {
          const response = await axios({
            method: method,
            url,
            data: id,
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.data.ok) {
            formik.resetForm(initialValues);
            dispatch(reloadingDataTableUsers());
            setIsRenderingDialog(false);
          }

          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        } catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(
              notificationOpen({
                message: `${error.response.data.message}`,
                status: "error",
              })
            );
          } else {
            dispatch(
              notificationOpen({
                message: "Operazione fallita: Server non raggiungibile.",
                status: "error",
              })
            );
          }
        }
        setIsRenderingDialog(false);
        dispatch(hideLoader())
      })();

      dispatch(closeDialogUser());
    }
  };

  useEffect(() => {
    setSelectedRoles([]);
  }, [dialogState.isOpen]);

  useEffect(() => {
    dispatch(closeDialogUser());
  }, [backButtonState]);

  return (
    <>
      {dialogState.dialogType === "delete" && (
        <>
          <Dialog
            open={dialogState.isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"sm"}
          >
            <DialogTitle>{changeStatusUserTitleDialog(dataDel, t)}</DialogTitle>
            <DialogContent className="border-Content-dialog">
              <DialogContentText id="alert-dialog-description">
                {changeStatusUserTextDialog(dataDel, t)} {t("user_v1")}
                <b>
                  {FirstName} {LastName} ({Username})
                </b>
                ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => dispatch(closeDialogUser())}>
                {t(dialogButtonConfirm.Cancel)}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteRestore(rowInfo)}
                disabled={appLoadingState}
              >
                {t(dialogButtonConfirm.Confirm)}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {dialogState.dialogType === "reset" && (
        <>
          <Dialog
            open={dialogState.isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            fullScreen = {isMobile}
            maxWidth={"sm"}
          >
            <DialogTitle>{t("res_psw" )}</DialogTitle>
            <DialogContent className="border-Content-dialog">
              <DialogContentText>
              {t("text_1")}
              </DialogContentText>
              <br />
              <DialogContentText>
                {t("text_2")}
                <b>
                  {" " + FirstName} {LastName} ({Username})
                </b>
                ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => dispatch(closeDialogUser())}>
                {t(dialogButtonConfirm.Cancel)}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => resetPsw(rowInfo)}
                disabled={appLoadingState}
              >
                {t(dialogButtonConfirm.Confirm)}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {dialogState.dialogType === "add" && (
        <DialogAddUser
          isOpen={dialogState.isOpen}
          lastTabsSelected={lastTabsSelected}
          setLastTabsSelected={setLastTabsSelected}
        />
      )}
      {dialogState.dialogType === "edit" && !isRenderingDialog && (
        <DialogEditUser
          isOpen={dialogState.isOpen}
          rowInfo={rowInfo}
          setLastTabsSelected={setLastTabsSelected}
        />
      )}
      {dialogState.dialogType === sincronizzazione && !isRenderingDialog && (
        <DialogSincronizzazioneUser
        isOpen={dialogState.isOpen}
        />
      )}
    </>
  );
};

export default UserDialog;

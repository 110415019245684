import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useBackPathFromUrl from "../../Hooks/useBackfromUrlPath";

export function BackButton() {
  const navigate = useNavigate();
  const path = useBackPathFromUrl();

  return (
    <>
      {path != "" && (
        <Button
          sx={{
            borderRadius: "500px",
            backgroundColor: "#a0b3d36b",
            padding: "0",
            minHeight: "35px",
            minWidth: "35px",
          }}
          onClick={() => navigate(path)}
        >
          <FontAwesomeIcon icon={faArrowLeft} style={{ margin: 0 }} />
        </Button>
      )}
    </>
  );
}

import { Button, Grid, Paper} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { disponibilitaBackButton } from "../../../../functions/backButton/disponibilitaBackButton";
import useHandlerBackButton from "../../../../functions/Utils/handlerBackButtonAndroid";
import PathViewDisponibilitaSlice from "./PathViewDisponibilitaSlice";
import UndoIcon from '@mui/icons-material/Undo';

export default function PathViewDisponibilita({ el, returnNewPath, returnLenghtArrElementPath, activeBackStepPath = false, handlerStateDialogMappaDisponibilita = () => null }) {
  const [memoryPath, setMemoryPath] = useState([]);
  const [pathGoToElement, setPathGoToElement] = useState(null);
  const [isBackAction, setIsBackAction] = useState(false); // serve a gestire l'azione di Back nel path.
  
  
  

  //gestisce il memoryPath,
  useEffect(() => {
    if (el) {
      if (isBackAction) memoryPath.pop();
      setIsBackAction(false);
      setMemoryPath((prev) => {
        return [...prev, el];
      });
    }
  }, [el]);

  useEffect(() => {
    if (pathGoToElement != null) {
      let newPath = memoryPath.slice(0, pathGoToElement + 1);
      setMemoryPath(newPath);
      returnNewPath(newPath[pathGoToElement]);
      setPathGoToElement(null);
      setIsBackAction(true);
      
    }
  }, [pathGoToElement]);

  function backStepPath() {
    setPathGoToElement(memoryPath.length - 2);
 }

  useEffect(() => {
    backStepPath();
  },[activeBackStepPath])

  useHandlerBackButton({},() => {disponibilitaBackButton(memoryPath,setPathGoToElement,handlerStateDialogMappaDisponibilita )})


  useEffect(() => {
    returnLenghtArrElementPath(memoryPath.length)
  },[memoryPath.length])

  return (
    <>
      <Paper
       className="rowDisponibilitaCardTitle"
       sx={{display:memoryPath.length < 1 ? "none" : ""}}
      >
        <Grid container>
          <Grid container item xs={10}>
            {memoryPath.map((element, index) => {
              if(index < 3) { // limitazione fino alla zona
                return (
                  <PathViewDisponibilitaSlice
                    key={index}
                    el={{ index, element }}
                    setPathGoToElement={setPathGoToElement}
                  />
                );
              } 
            })}
            
          </Grid> 
          <Grid item xs={2} sx={{ float: "right" }}>
            <Button
              sx={{ float: "right" }}
              onClick={() => {
                backStepPath();
              }}
            >
             <UndoIcon />
            </Button>
          </Grid>
         
        </Grid>
      <Grid>
      
      </Grid>
      </Paper>
    </>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  DataOra,
  EdificioZona,
  Stanza,
  getFramesStepper,
  getOriginalFrames,
} from "../../../const/Stepper/constStepper";
import { useDispatch, useSelector } from "react-redux";
import {
  procediPrenotazione,
  resetPrenotazioneWithDefaultConfiguration,
} from "../../../redux/reducers/prenotazioneStepper-reducer";
import { closeDialog } from "../../../redux/reducers/DialogGeneral";
import {
  CheckStepperPrenotazioneGiorni,
  CheckStepperPrenotazioneEdificioZona,
  CheckStepperIsPrenotazionePerAltri,
  CheckStepperIsPrenotazioneOspitiOnly,
} from "../../../functions/checkStepperPrenotazione";
import HandleFrameStepper from "../../../functions/handleFrameStepper";

import { BuildingHeader } from "../../../const/DatatableData";
import { addPrenotazioni } from "../../../const/urlAction";
import moment from "moment";
import axios from "axios";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { loadprenotazioniTableState } from "../../../redux/reducers/prenotazioneTable-reducer";
import handlerError from "../../../functions/handlerError";
import { lastActionOnStepperBack, resetStatePrenotazioneStepper } from "../../../redux/reducers/infoPrenotazione-reducer";
import ConvSecToMin from "../../../functions/ConvSecToMin";
import { useCheckPrenotazioneUserExist } from "../../../Hooks/useCheckPrenotazioneUserIsExist";
import useMobileDetection from "../../../Hooks/useMobileDetection";
import { useEffect } from "react";
import associaInfoToPlaceholderStepper from "../../../functions/associaInfoToPlaceHolderStepper";
import { useNavigate } from "react-router-dom";
import handlerBackButton, {
  logicBackButtonStepper,
} from "../../../functions/Utils/handlerBackButtonAndroid";
import { actionOnStepper } from "../../../const/Stepper/actionOnStepper";
import stepperBackButton from "../../../functions/backButton/StepperBackButton";
import LabelPrenotazionePerUtente from "./stepperComponent/LabelPrenotazionePerUtente";
import { Fragment } from "react";
import IconLabelStep from "./stepperComponent/iconLabelStep";
import {
  hideLoader,
  showLoader,
} from "../../../redux/reducers/appLoader-reducer";
import { resetUserInMultiUser } from "../../../redux/reducers/MultiUserInStepper-reducer";
import { saveDataForRiepilogo } from "../../../redux/reducers/RiepilogoDialogAfterPrenotazione-reducer";
import normalizzaNominativoOspite from "../../../functions/Ospite/normalizzaNominativoOspite";
import { useTranslation } from "react-i18next";

const FramesNoNextButton = [Stanza, EdificioZona];
const urlBase = process.env.REACT_APP_URL;

export default function CustomStepper({handleStepPadding}) {
  const stateDati = useSelector((state) => state.prenotazioneStepper);
  const stateInfoStepper = useSelector((state) => state.infoPrenotazione);

  const configuration = useSelector(
    (state) => state.configurations.configurazioniPrenotazione
  );
  const { t } = useTranslation();
  const frames = getFramesStepper(t);
  const originalFrames = getOriginalFrames(t);
  const [stepNoAutoSkipped, setStepNoAutoSkipped] = React.useState([]); //gestice quali pagine NON sono state auto-skippate e come deve lavorare il tasto di back.
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [
    activeCheckPrenotazioneUserExist,
    setActiveCheckPrenotazioneUserExist,
  ] = React.useState(false);
  const [tastoNextDisabled, setTastoNextDisabled] = React.useState(false);

  const dispatch = useDispatch();

  const appLoadingState = useSelector((state) => state.appLoader.loading);


  const PlaceHolderComponentFrames = frames[activeStep]?.body;

  //i dati recuperati dal contesto(redux)
  const {
    prenotazione,
    ripetizioneGiorni,
    dataInizio,
    dataGiorno,
    dataFine,
    ripetizioneDataFine,
    utentePrenotazione,
    postazioneId,
    partecipantiRiunione,
    invioNotificaInvito,
    titoloRiunione,
    dataGiornoFromPrenotazioniComponent,
  } = stateDati;

  //controlla se quello step è opzionale, lo confronta a 1 perchè 1 è l'unico skippabile
  const isStepOptional = (step) => {
    return step === -1;
  };
  //controlla se quello step( passato per index) è presente tra quelli skippati
  // gli passa tutti gli index, una volta in più quello appena passato. (se non lo ripassa non è presente)
  // viene usato per fare un controllo, se ritorna vero, setta una proprietà a false

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  useEffect(()=>{
    handleStepPadding(activeStep);
  },[activeStep])

  // convalida l'accesso allo step successivo
  React.useEffect(() => {
    let isDisabled = false;
    switch (true) {
      case activeStep === 0:
        isDisabled =
          CheckStepperPrenotazioneGiorni(stateDati, configuration) ||
          CheckStepperIsPrenotazionePerAltri(stateDati)
            ? true
            : false;
        break;
      case activeStep === 1:
        isDisabled = CheckStepperPrenotazioneEdificioZona(stateInfoStepper);
        break;
    }

    setTastoNextDisabled(isDisabled);
  }, [stateDati, activeStep]);

  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      //resetta le pagine skippate in precedenza
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 0) {
      //setIsLoading(true)
    }
    setActiveCheckPrenotazioneUserExist(!activeCheckPrenotazioneUserExist);
    setActiveStep((prevActiveStep) =>{
      return (prevActiveStep + 1)
    }
    ); //aggiorna ActiveStep passando il precedente stato + 1
    setSkipped(newSkipped);
  };

  const eseguiPrenotazione = () => {
    dispatch(procediPrenotazione(true));
  };

  const handleBack = () => {
    HandleFrameStepper(dispatch, activeStep);
    stepNoAutoSkipped.pop();
    setActiveStep(() => {
      let backOf = 1;
      return stepNoAutoSkipped[stepNoAutoSkipped.length - backOf];
    });
    dispatch(lastActionOnStepperBack());
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("Non puoi saltare una fase che non è opzionale.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    setSkipped((prevSkipped) => {
      //aggiunge lo step Skipped
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    dispatch(
      resetPrenotazioneWithDefaultConfiguration(configuration, dispatch)
    );
    dispatch(resetStatePrenotazioneStepper());
    dispatch(resetUserInMultiUser());
  };
  
  

  //Procede allo step successivo quando seleziono un valore
  React.useEffect(() => {
    if (
      (stateInfoStepper.idStanza !== 0  && stateInfoStepper.lastActionOnStepper == 0 ) ||
      (stateInfoStepper.idZona !== 0 &&
        stateInfoStepper.idZona !== undefined  &&
        stateInfoStepper.lastActionOnStepper == 0 )
    ) {
      handleNext();
    }
  }, [
    stateInfoStepper.idStanza,
    stateInfoStepper.idZona,
    stateInfoStepper.idEdificio,
  ]);

  /*### tolto dopo l'inserimento della tipologica stanze ###*/
  // let resultPrenotazioneUserExist = useCheckPrenotazioneUserExist(
  //   activeCheckPrenotazioneUserExist,
  //   activeStep
  // );
  // React.useEffect(() => {
  //   if (resultPrenotazioneUserExist.isPrenotazioneExist) setActiveStep(0);
  // }, [resultPrenotazioneUserExist]);

  //Invio prenotazione
  React.useEffect(() => {
    (async () => {
      if (prenotazione) {
        let arrPrenotazioni = [];
        const { action, method } = addPrenotazioni;
        let url = urlBase + action;

        //gestisce la prenotazione singola per se stesso(quindi l'utente non viene indicato)
        if (
          utentePrenotazione.length === 0 &&
          (postazioneId != null || undefined)
        ) {
          arrPrenotazioni.push({ Utente: null, IdPosto: postazioneId });
        } else {
          arrPrenotazioni = utentePrenotazione;
        }

        const requestBody = {
          prenotazioni: arrPrenotazioni.map((x) => {
            return {
              ...x,
              nominativo: x.nominativo
                ? normalizzaNominativoOspite(x.nominativo)
                : null,
            };
          }),
          disabilitazione: false,
          dataPrenotazione: moment(dataGiorno).format(),
          oraInizioPrenotazione: dataInizio,
          oraFinePrenotazione: dataFine,
          dataFineRipetizioni: moment(ripetizioneDataFine).format(),
          giorniRipetizione: ripetizioneGiorni,
          partecipantiRiunione: partecipantiRiunione,
          invioNotificaInvito: invioNotificaInvito,
          titoloRiunione: titoloRiunione,
        };

        if (arrPrenotazioni) {
          dispatch(showLoader());
          try {
            const response = await axios({
              method: method,
              url,
              data: requestBody,
            });
            dispatch(hideLoader());
            if (response.data.ok) {
              handleReset();
              dispatch(closeDialog());
              dispatch(
                notificationOpen({
                  message: response.data.message,
                  status: "success",
                })
              );
              dispatch(saveDataForRiepilogo(response.data.result));
            } else {
              dispatch(
                notificationOpen({
                  message: response.data.message,
                  status: "error",
                })
              );
            }
          } catch (error) {
            console.error(error);
            dispatch(hideLoader());
            const notificationError = handlerError(error);
            dispatch(notificationOpen(notificationError));
            if (notificationError.status === "warning") {
              dispatch(closeDialog());
              handleReset();
            }
          }
          dispatch(procediPrenotazione(false));
          dispatch(loadprenotazioniTableState());
        }
        dispatch(hideLoader());
      }
    })();
  }, [stateDati.prenotazione]);

  let isMobile = useMobileDetection();

  handlerBackButton(
    { activeStep: activeStep, handleBack: handleBack },
    stepperBackButton,
    dispatch
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        sx={{ overflow: "hidden", paddingLeft: activeStep === 1 ? '24px' : '' }}
        className={isMobile ? "stepperNoFlexBox adaptHeightStepperPrenotazione" : " adaptHeightStepperPrenotazione"}
        activeStep={activeStep}
      >
        {frames.map((step, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            //gestisce se lo step è stato completato o meno
            stepProps.completed = false; //aggiunge il valore all'attributo
          }

          //serve a riportare la label all'etichetta originale
          if (step.label !== originalFrames[step.id].label) {
            document.getElementById("alert-dialog-title").innerHTML =
              t("Booking");
            step.label = originalFrames[step.id].label;
          }

          if (step.id < activeStep || activeStep === 3) {
            step.label = associaInfoToPlaceholderStepper(
              t,
              step,
              stateDati,
              stateInfoStepper
            ); //associa le informazioni al placeholder della stepper
          }
          return (
            <Fragment key={index}>
              <Step key={step.label} {...stepProps} expanded>
                {" "}
                {/* stepProps sovrascrive le proprietà di default */}
                <StepLabel
                  icon={<IconLabelStep activeStep={activeStep} index={index} />}
                  {...labelProps}
                >
                  {step.label}
                </StepLabel>
                <br></br>
              </Step>
            </Fragment>
          );
        })}
      </Stepper>
      {utentePrenotazione.length >= 1 && activeStep != 0 && (
        <LabelPrenotazionePerUtente
          utentiDaMostrare={utentePrenotazione}
          activeStep={activeStep}
        />
      )}
      {activeStep === frames.length ? ( // Render condizionale in base allo step
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}></Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <PlaceHolderComponentFrames
            setLoading={appLoadingState}
            setStepNoAutoSkipped={setStepNoAutoSkipped}
            framesIndex={activeStep}
            disabled={tastoNextDisabled}
          />
          <Box
            sx={{ display: "flex", 
            flexDirection: "row", 
            pt: isMobile ? 1 : 2, 
            pb: isMobile ? 1 : 0, 
            padding: !isMobile && activeStep !== 1 ? "10px 0px" : "10px 12px", 
            marginTop: activeStep === 1 ? "0px!important" : "" 
          }}
            className= {isMobile ? "border-Action-dialog adaptHeightStepperPrenotazione fixedButtonsContainer" : "border-Action-dialog adaptHeightStepperPrenotazione"}
          >
            <Button
              color="inherit"
              onClick={() => {
                handleReset();
                dispatch(closeDialog());
              }}
              sx={{ mr: 1 }}

            >
              {t("close")}
            </Button>

            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: isMobile && (activeStep === 1 || activeStep === 2) ? 3 : 1 }}
              
            >
              {t("back")}
            </Button>
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                {t("skip")}
              </Button>
            )}
            {activeStep === frames.length - 1 ? (
              <Button
                onClick={() => {
                  eseguiPrenotazione();
                }}
                variant="contained"
                disabled={stateDati.statoTastoInvio}
                sx={{ mr: isMobile ? 3 : 0 }}
              >
                {t("reserve")}
              </Button>
            ) : (
              <>
                {!FramesNoNextButton.includes(activeStep) && (
                  <Button disabled={tastoNextDisabled} onClick={handleNext} sx={{ mr: isMobile ? 3 : 0 }}>
                    {t("next")}
                  </Button>
                )}
              </>
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}

import { Divider, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FixedSizeList } from "react-window";
import { getPlannerData } from "../../../const/urlAction";
import { v4 as uuidv4 } from "uuid";
import {
  hideLoader,
  showLoader,
} from "../../../redux/reducers/appLoader-reducer";
import MemoRowPlanner from "../RowPlanner/RowPlanner";

let firstRender = true;
let scrollTimeout = null;
export const itemSizeReactWindow = 110;
const SCROLL_DEBOUNCE_TIME = 1000
const TOLLERANCE_FORCE_TO_SCROLL_BOTTOM = 3

function UserTablePlanner({ year, month, order, heightComponent = 150 }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [datiUtente, setDatiUtente] = useState({
    rowPlanner: [],
    holidays: [],
  });

  const isScrollingRef = useRef(false);
  const listRef = useRef(false);
  const lastScrollTime = useRef(null);
  const CountScrollToBottom = useRef(0);

  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      let data = new Date(year, month, 1, 0, 0, 0, 0);
      let formatedDate = moment(data).format("DD/MM/YYYY");
      try {
        let urlBase = process.env.REACT_APP_URL;
        let result = await axios({
          method: getPlannerData.method,
          url:
            urlBase +
            getPlannerData.action +
            "?data=" +
            formatedDate +
            "&orderBy=" +
            order,
        });
        setDatiUtente(result.data.result);
      } catch (error) {
        console.error(error);
      }
      dispatch(hideLoader());
    }
    )();
    return () => {
      firstRender = true
    }
  }, [year, month, order]);

  function handleScroll(event) {
    if(firstRender) {
      firstRender = false;
      return;
    } 
    isScrollingRef.current = true;
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }
    scrollTimeout = setTimeout(() => {
      handleScrollEnd(event);
    }, 150);
  }

  function handleScrollEnd(event) {
      isScrollingRef.current = false;
      setIsLoading(!isLoading);

      EndScrollHandler(event)
  }

  function EndScrollHandler({ scrollOffset }) {
    const isBottom = scrollOffset >= ((datiUtente.rowPlanner.length) * (itemSizeReactWindow) - heightComponent);
    if (isBottom) {
      const newPos = scrollOffset - 2;
      const currentTime = new Date().getTime();
      if (
        lastScrollTime &&
        currentTime - lastScrollTime.current <= SCROLL_DEBOUNCE_TIME
      ) {
        if(CountScrollToBottom.current >= TOLLERANCE_FORCE_TO_SCROLL_BOTTOM) {
          listRef.current.scrollTo({ top: newPos });
          CountScrollToBottom.current = 0
        }
        CountScrollToBottom.current = CountScrollToBottom.current + 1
      }
      lastScrollTime.current = currentTime;
    }
  }
  
  const renderRow = useCallback(
    ({ index, style, data }) => {
      const { datiUtente, year, month, holidays } = data;
      return (
        <MemoRowPlanner
          key={index}
          datiUtenteSingolo={datiUtente[index]}
          anno={year}
          mese={month}
          holidays={holidays}
          style={style}
          isScrolling={isScrollingRef.current}
        />
      );
    },
    [isScrollingRef, datiUtente]
  );

  return (
    <>
      {datiUtente?.rowPlanner?.length > 0 && (
        <FixedSizeList
          ref={listRef}
          className="List"
          overscanCount={10}
          height={heightComponent}
          itemCount={datiUtente.rowPlanner.length}
          itemSize={itemSizeReactWindow}
          width="100%"
          onScroll={(e) => handleScroll(e)}
          itemData={{
            datiUtente: datiUtente.rowPlanner,
            year,
            month,
            holidays: datiUtente.holidays,
          }}
        >
          {renderRow}
        </FixedSizeList>
      )}
    </>
  );
}

const MemoUserTablePlanner = React.memo(UserTablePlanner);

export default MemoUserTablePlanner;

import React from "react";
import { Grid, Typography } from "@mui/material";
import "../../index.css";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import AvatarImage from "../Avatar/AvatarImage";
import { useEffect } from "react";
import { getInfoAccount } from "../../const/urlAction";
import axios from "axios";
import { saveUser } from "../../redux/reducers/user-reducer";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import handlerError from '../../functions/handlerError';

const urlBase = process.env.REACT_APP_URL;

export default function CardInfoUtente({ returnCardInfoDialogState }) {
  const state = useSelector((state) => state.userDate);
  const [isOpenDialogDettaglioUtente, setIsOpenDialogDettaglioUtente] = useState(false);
  const {
    email,
    fullName,
    roles,
  } = state;
  const [infoUser, setInfoUser] = useState(useSelector((state) => state.userDate));
  const dispatch = useDispatch();

  function returnDialogState() {
    returnCardInfoDialogState()
  }

  useEffect(() => {
    (async () => {
      const { action, method } = getInfoAccount;
      let url = urlBase + action;
      try {
        const response = await axios({
          method: method,
          url
        });

        let result = response.data.result;

        let updatedUser = {
          ...infoUser,
          email: result.email,
          fullName: result.fullName,
          phoneNumber: result.phoneNumber,
          roles: result.roles,
          responsabileEdificio: result.responsabileEdificio,
          utenteResponsabileUtenti: result.utenteResponsabileUtenti
        };
        dispatch(saveUser(updatedUser));

      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
  }, [])

  return (
    <>
      <Grid container className="cardInfoUtente" onClick={() => { returnDialogState() }} >
        <Grid container item sx={{ paddingBottom: "5px" }} className="cardInfoUtente-Click">
          <Grid item lg={12} md={12} xs={12} className="card-icon">
            <AvatarImage size={30} name={fullName} isInputDisabled={true} isCentered={true} isUserLoggedImage={true} />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className="card-text-Primary textCard-margin"
          >
            <Typography className="fontCard-Principal">{fullName}</Typography>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className="card-text-Primary textCard-margin"
          >
            <Typography className="fontCard-Principal">{email}</Typography>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className="card-text-Primary textCard-margin"
          >
            <Typography className="fontCard-Principal">
              {roles.join(", ")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

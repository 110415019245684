import React, { Fragment } from "react";
import { Grid, Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BoxAutoCompletePills from "../../../UI/BoxAutoCompletePills/BoxAutoCompletePills";
import { useEffect } from "react";
import { useState } from "react";

export default function PostazioneDialogContent({
  formik,
  users = [],
  selectedUsers,
  handleChangeUserSelect,
  handleInputChangeUserSelect,
  dotazioni,
  selectedDotazioni,
  handleChangeDotazioneSelect,
}) {
  const { t } = useTranslation();
  const qrcodeDinamico = useSelector(
    (state) => state.configurations.configurazioniGlobali.qrCodeDinamico
  );

  return (
    <>
      {qrcodeDinamico && <Grid item xs={12} sm={12}>
        <TextField
          margin="dense"
          id="nominativoDevice"
          label={t("deviceName")}
          type="text"
          fullWidth
          InputProps={{}}
          variant="standard"
          value={formik.values.nominativoDevice}
          onChange={formik.handleChange("nominativoDevice")}
          error={
            formik.touched.PonominativoDevicesto &&
            Boolean(formik.errors.nominativoDevice)
          }
          helperText={
            formik.touched.nominativoDevice && formik.errors.nominativoDevice
          }
        />
      </Grid>}
      <Grid item xs={12} sm={12}>
        <Autocomplete
          multiple
          id="utentiPostazione"
          size="small"
          options={users}
          value={selectedUsers}
          disableCloseOnSelect
          limitTags={1}
          isOptionEqualToValue={(option, value) =>
            option.idUtente === value.idUtente
          }
          getOptionLabel={(option) => option.nominativo || ""}
          onChange={handleChangeUserSelect}
          onInputChange={handleInputChangeUserSelect}
          renderOption={(props, option, { selected }) => (
            <li key={option?.idUtente} {...props}>
              {option.nominativo}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Workstation_reserved_for")}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>{params.InputProps.endAdornment}</Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <BoxAutoCompletePills items={dotazioni} activeItems={selectedDotazioni} onActiveItemsChange={handleChangeDotazioneSelect}/>
      </Grid>
    </>
  );
}

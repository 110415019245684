import { Button, Divider, Grid } from "@mui/material";
import { dialogButtonQrCode } from "../../../../../const/DialogData";
import CreateLogoPostazioneScannedQrCode from "../../../../../functions/createLogoPostazioneScannedQrCode";
import "../../../../../index.css";
import TitleScannedQrCode from "../GeneralComponentQrCode/titleScannedQrCode";
import IconResum from "./ComponentPostazioneScannedQrCode/iconResume";
import { useEffect, useState } from "react";
import usePrenotazioneQrCode from "../../../../../Hooks/usePrenotazioneQrCode";
import { recuperaOraEMinutiDaDefault } from "../../../../../functions/recuperaOraEMinutiDaStringa";
import recuperaMaxTimeQrCodePrenotazione from "../../../../../functions/recuperaMaxTimeQrCodePrenotazione";
import { DISPONIBILE } from "../../../../../const/Planimetria/StatoPostazione";
import GeneralCustomDateTimePicker from "../../../../UI/TimePicker/GeneralCustomDateTimePicker";
import BodyComponentPostazioneOccupata from "./ComponentPostazioneScannedQrCode/BodyComponentPostazioneOccupata";
import BodyComponentPostazioneCheckin from "./ComponentPostazioneScannedQrCode/BodyComponentPostazioneCheckin";
import { useSelector } from "react-redux";
import { useRef } from "react";
import useAdaptiveCssFromRef from "../../../../../Hooks/Adaptive/useAdaptiveCssFromRef";
import adaptiveCssFromRef from "../../../../../Hooks/Adaptive/useAdaptiveCssFromRef";
import { STATOPRENOTAZIONE } from "../../../../../const/Dialog/StatoPrenotazione/StatoPrenotazione";
import BodyComponentPostazioneCheckOut from "./ComponentPostazioneScannedQrCode/BodyComponentPostazioneCheckOut";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export default function PostazioneScannedQrCode({
  postazioneScanned,
  returnBackComponentView,
  closeGeneralDialog,
  returnComponentState,
}) {
  const [checkInButton, setcheckInButton] = useState(0);
  const [adaptiveHeightComponent, setAdaptiveHeightComponent] = useState(0);
  const buttonsBox = useRef(undefined);
  const { t } = useTranslation();
  const {
    postazione,
    statoAlMomentoDelloScan,
    prenotazioneSuggerita,
    postazioneOccupata,
    hasPrenotazioniAttiveNelGiorno,
    prenotazioneCheckIn,
    prenotazioniDelGiorno
  } = postazioneScanned;

  const { dataFinePrenotazione, id } = prenotazioneSuggerita;
  const dataFineDefault = useSelector(
    (state) => state.prenotazioneStepper.dataFine
  ); //recupera l'orario di default dalla configurazione della stepper

  const defaultFinePrenotazione = recuperaOraEMinutiDaDefault(dataFineDefault);

  //Gestisce quale data passare come suggerimento all'utente durante la prenotazione.
  let dataFinePrenotazioneRecuperata =
    dataFinePrenotazione === null
      ? defaultFinePrenotazione
      : dataFinePrenotazione;

  //Gestisce il limite massimo di estensione della prenotazione.
  const maxTime = recuperaMaxTimeQrCodePrenotazione(
    dataFinePrenotazione,
    dataFinePrenotazioneRecuperata
  );

  const [valueDataFine, setValueDataFine] = useState(
    dataFinePrenotazione || defaultFinePrenotazione
  );
  const [prenota, setPrenota] = useState(0);

  let iconStatoPostazione = CreateLogoPostazioneScannedQrCode(
    prenotazioneCheckIn?.checkInTypology ?? 0,
    statoAlMomentoDelloScan,
    postazione
  );

  usePrenotazioneQrCode(
    prenota,
    valueDataFine,
    id,
    closeGeneralDialog,
    returnBackComponentView
  );

  function closeAll() {
    returnBackComponentView(false);
    closeGeneralDialog(false);
  }
  useEffect(() => {
    setAdaptiveHeightComponent(adaptiveCssFromRef(buttonsBox));
  }, [buttonsBox]);

  return (
    <>
      <div style={{ minHeight: `${adaptiveHeightComponent}px` }}>
        <Grid container >
          <Grid item className="flex-bottom-left">
            <Button onClick={() => returnBackComponentView(false)}>
              <FontAwesomeIcon icon={faArrowLeft} className="pathIcon" />
            </Button>
          </Grid>
          <Grid item>
            <TitleScannedQrCode title="Quick_booking" />
          </Grid>
        </Grid>
        <Grid my={3}>
          <IconResum informazioniPostazione={postazione} />
        </Grid>
        <Divider sx={{ width: '90%', marginLeft: '5%' }}></Divider>
        <Grid mt={5} container className="flex-center-center">
          {iconStatoPostazione}
        </Grid>
        {!prenotazioneCheckIn ? (
          <BodyComponentPostazioneOccupata
            statoAlMomentoDelloScan={statoAlMomentoDelloScan}
            postazioneOccupata={postazioneOccupata}
            setValueDataFine={setValueDataFine}
            dataFinePrenotazione={dataFinePrenotazione}
            valueDataFine={valueDataFine}
            hasPrenotazioniAttiveNelGiorno={hasPrenotazioniAttiveNelGiorno}
            prenotazioniDelGiorno={prenotazioniDelGiorno}
          />
        ) : prenotazioneCheckIn.checkInTypology === STATOPRENOTAZIONE.CHECKIN ? (
          <><BodyComponentPostazioneCheckOut
            prenotazioneCheckIn={prenotazioneCheckIn}
            checkInDependency={checkInButton}
            returnComponentState={returnComponentState}
          /></>
        ) : (
          <BodyComponentPostazioneCheckin
            prenotazioneCheckIn={prenotazioneCheckIn}
            checkInDependency={checkInButton}
            returnComponentState={returnComponentState}
          />
        )}
        <Grid container sx={{
          padding: "8px",
          position: (adaptiveHeightComponent < 800 && hasPrenotazioniAttiveNelGiorno ) ? '' :
            adaptiveHeightComponent > 750 ? 'absolute' : '',
          bottom: (adaptiveHeightComponent < 800 && hasPrenotazioniAttiveNelGiorno ) ? '' :
            adaptiveHeightComponent > 750 ? '0px!important' : ''
        }}
          ref={buttonsBox}>
          <Grid item sm={6} xs={6} md={12}>
            <Button onClick={() => closeAll()}>
              {t(dialogButtonQrCode.Annulla)}
            </Button>
          </Grid>
          {statoAlMomentoDelloScan == STATOPRENOTAZIONE.DISPONIBILE && (
            <Grid item sm={6} xs={6} md={12} sx={{ float: "right" }}>
              <Button
                sx={{ float: "right" }}
                variant="contained"
                onClick={() => setPrenota((old) => old + 1)}
              >
                {t(dialogButtonQrCode.Prenota)}
              </Button>
            </Grid>
          )}
          {prenotazioneCheckIn && (
            <Grid item sm={6} xs={6} md={12} sx={{ float: "right" }}>
              <Button
                sx={{ float: "right" }}
                variant="contained"
                onClick={() => setcheckInButton((old) => old + 1)}
              >
                {t(dialogButtonQrCode.Conferma)}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

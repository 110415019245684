import { AutocompleteBoxHome } from "../../components/Dashboard/Stepper/stepperComponent/AutocompleteBoxHome";
import { DataPickerBoxHome } from "../../components/Dashboard/Stepper/stepperComponent/DataPickerBoxHome";
import PostazionePlanimetriaBoxHome from "../../components/Dashboard/Stepper/stepperComponent/PostazionePlanimetriaBoxHome";
import { StanzaPlanimetriaBoxHome } from "../../components/Dashboard/Stepper/stepperComponent/StanzaPlanimetriaBoxHome";


export const DataOra = 0
export const EdificioZona = 1
export const Stanza = 2
export const Postazione = 3
  
export function getFramesStepper(t) {
  return [
    {
      label: t("date_booking"),
      body: DataPickerBoxHome,
      id: DataOra
    },
    {
      label: t("bulding_zone"),
      body: AutocompleteBoxHome  ,
      id: EdificioZona
    },
    {
      label: t("stanza_v3"),
      body: StanzaPlanimetriaBoxHome ,
      id: Stanza
    },
    {
      label: t("element"),
      body: PostazionePlanimetriaBoxHome  ,
      id: Postazione
    },
  ];
}

  //serve per poter tornare alla label originale nella Stepper
  export function getOriginalFrames(t) {
    return [
      {
        label: t("date_booking"),
        body: DataPickerBoxHome,
        id: DataOra
      },
      {
        label: t("bulding_zone"),
        body: AutocompleteBoxHome  ,
        id: EdificioZona
      },
      {
        label: t("stanza_v3"),
        body: StanzaPlanimetriaBoxHome ,
        id: Stanza
      },
      {
        label: t("element"),
        body: PostazionePlanimetriaBoxHome  ,
        id: Postazione
      },
    ];
  }

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";

//serve per cambiare il modo in cui si presenta un component, simulando un finto tasto di back
export function BackButtonChangeView({changeView = () => null}) {

  return (
    <>
      {changeView && (
        <Button
          sx={{
            borderRadius: "500px",
            backgroundColor: "#a0b3d36b",
            padding: "0",
            minHeight: "35px",
            minWidth: "35px",
          }}
          onClick={() => {changeView()}}
        >
          <FontAwesomeIcon icon={faArrowLeft} style={{ margin: 0 }} />
        </Button>
      )}
    </>
  );
}

import { useEffect } from "react";
import { useState } from "react";

export function ApplyScalePlanimetria(
  initialZoomValue,
  scale,
  initDimensioneOne
) {

  return scale * initDimensioneOne - initDimensioneOne ;
}

export function IncrementaDimensioneValoreAlloZoom(value, delta, scaleZoom) {
  if(scaleZoom > 1) return (Math.abs(value) * scaleZoom) + delta
  let result = value > 0 ? value + (delta * 2) : 0
  return result > 0 ? result : 0
}


export function IncrementaDimensioneImageAlloZoom(margin,delta) {
  if (typeof val !== 'string') return margin
  let valore = parseInt(margin.replace("px", "", 10))
  valore = valore + margin 
  return valore + (delta * 2)
}
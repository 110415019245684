import { Typography } from "@mui/material";
import Logo from "../../assests/logo.png";
import { useTranslation } from "react-i18next";

export default function Forbidden() {
    const { t } = useTranslation(); 
    return(
    <div style={{textAlign: 'center', marginTop:'50px'}}>
    <img src={Logo} width={350}/>
    <Typography variant='h4' mt={2} >{t("AccessDenied")}</Typography>
    <Typography mt={3}>{t("NoPermissionToAccessResource")}</Typography>
    <a href="/">{t("GoBackToHome")}</a>
    </div>
    )
}
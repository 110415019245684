import {
  DatePicker,
  DesktopDatePicker,
  DesktopTimePicker,
  MobileDatePicker,
  MobileDateTimePicker,
  DateTimePicker,
  DesktopDateTimePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import React, { useState } from "react";
import TimePickerCustom from "../../TimePickerCustom/TimePickerCustom";
import StaticTimePickerCustom from "../../TimePickerCustom/StaticTimePickerCustom";

export default function GeneralCustomDateTimePicker({
  TypeTimePicker = 1,
  label = "",
  valueStart,
  minutesStep=5,
  minutesStepEnabled=true,
  placeholder= "hh/mm",
  handleChange = () => null,
  showToolbar = false,
  minDate = null,
  maxDate = null,
  disabled = false,
}) {
  const [open, setOpen] = useState(false);
  let Component = importTypePicker(TypeTimePicker);

  function close() {
    setOpen(false)
  }

  const conditionalProps = minutesStepEnabled ? {minutesStep} : {};

  return (
    <Component
      {...conditionalProps} 
      label={label}
      value={valueStart ? new Date(valueStart) : null}
      open={open}
      onOpen={() => { disabled ? setOpen(false) : setOpen(true) }}
      onClose={() => close()}
      minDate={minDate}
      maxDate={maxDate}
      onChange={disabled ? () => null : handleChange}
      cancelText={React.createElement("span", null, "indietro")}
      showToolbar={showToolbar}
      disabled={disabled}
      slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
      fullWidth
    />
  );
}

function importTypePicker(type) {
  switch (true) {
    case type === 1:
      return TimePickerCustom;
    case type === 2:
      return MobileTimePicker;
    case type === 3:
      return DesktopTimePicker;
    case type === 4:
      return DatePicker;
    case type === 5:
      return DesktopDatePicker;
    case type === 6:
      return MobileDatePicker;
    case type === 7:
      return DateTimePicker;
    case type === 8:
      return MobileDateTimePicker;
    case type === 9:
      return MobileDateTimePicker;
    case type === 10:
      return DesktopDateTimePicker;
    case type === 11:
      return StaticTimePickerCustom;
    default:
      console.error("CustomTimePicker - Type picker missed ");
      break;
  }
}

import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { Fragment } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { suggerimentoPrenotazione } from "../../../const/urlAction";
import { CheckStepperPrenotazioneGiorni } from "../../../functions/checkStepperPrenotazione";
import "../../../index.css";
import { loadprenotazioniTableState } from "../../../redux/reducers/prenotazioneTable-reducer";
import DialogConfermaPRenotazioneSuggerimento from "./DialogConfermaPrenotazioneSuggerimento";
import { ElementSuggerimentoPrenotazione } from "./ElementSuggerimentoPrenotazione";

const urlBase = process.env.REACT_APP_URL;

export default function PannelloSuggerimentiPrenotazione({ label = "", showSuggerimenti = true, isRepeatBookingConfiguredCorrectly = false }) {
  const [elements, setElements] = useState([]);
  const dispatch = useDispatch();
  const configurations = useSelector((state) => state.configurations);
  const stateDialog = useSelector((state) => state.dialogGeneral.isOpen);
  const [elementsSelected, setElementsSelected] = useState(null);
  const stateInfoPrenotazione = useSelector(
    (state) => state.prenotazioneStepper
  );
  const { t } = useTranslation(); 

  const { dataGiorno, dataInizio, dataFine, utentePrenotazione, dataGiornoFromPrenotazioniComponent } =
    stateInfoPrenotazione;

  let isValidDate = !CheckStepperPrenotazioneGiorni(
    stateInfoPrenotazione,
    configurations
  );

  useEffect(() => {
    let isMounted = true;
    if (isRepeatBookingConfiguredCorrectly && isValidDate && stateDialog && showSuggerimenti && dataGiorno) {
      (async () => {
        let body = {
          IdUtente:
            utentePrenotazione.length === 0
              ? null
              : utentePrenotazione[0].utente,
          OraInizioPrenotazione: dataInizio,
          OraFinePrenotazione: dataFine,
          dataPrenotazione: moment(dataGiorno ?? dataGiornoFromPrenotazioniComponent).format("YYYY-MM-DDTHH:mm:ss"),
        };
        const { action, method } = suggerimentoPrenotazione;
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data: body,
          });
          if (response.data.ok && isMounted) {
            setElements(response.data.result);
          }
        } catch (error) {
          console.error(error);
        }
      })();
    } else {
      setElements([])
    }
  },[utentePrenotazione,dataFine,dataInizio,dataGiorno, isRepeatBookingConfiguredCorrectly]);

  useEffect(() => {
    const isPast = new Date(dataGiorno).setHours(...dataInizio.split(':')) < new Date();
    const correctHours = dataInizio < dataFine
    if(!showSuggerimenti || (isPast && correctHours) ) setElements([])
  },[showSuggerimenti,stateInfoPrenotazione])

  return (
    <>
        <Fragment>
          <label>
            <Typography className="label-SuggerimentoPrenotazione labelAutoCompletePills">
              {label}
            </Typography>
          </label>
          <Grid container className="boxElementi-SuggerimentoPrenotazione">
          {elements.length <= 0 && 
          <Typography style={{opacity: "50%"}}>{t("booking_guest_suggestions")}</Typography>
          }
            {elements.map((el, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={6}
                  lesssm={6}
                  sm={4}
                  lessmd={4}
                  medium={2}
                  md={2}
                  lesslg={2}
                  lg={2}
                  className="boxElementi-SuggerimentoPrenotazione-column"
                >
                  <ElementSuggerimentoPrenotazione
                    key={index}
                    elementoDaMostrare={el}
                    handlerElementSelected={setElementsSelected}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Fragment>
      {elementsSelected && (
        <DialogConfermaPRenotazioneSuggerimento
          elementsSelected={elementsSelected}
          infoPrenotazione={stateInfoPrenotazione}
        />
      )}
    </>
  );
}

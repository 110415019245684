import { useSelector } from "react-redux";
import DialogViewAddUserLocalDatabase from "../../../../../components/Dialog/UserDialog/componentUserDialog/viewUserDialog/AddUserView/DialogViewAddUserLocalDatabase";
import DialogViewAddUserActiveDirectory from "../../../../../components/Dialog/UserDialog/componentUserDialog/viewUserDialog/AddUserView/DialogViewAddUserActiveDirectory";
import { labelArrayDialogUserAddEdit } from "../../../../RadioButton/RadioButtonListRadio";

export function ListTabsDialogoUserAdd({isCommitted, setIsCommitted}) {
  const authConfiguration = useSelector((state) => state.configurations.configurazioniAutenticazione);
  let arrayTabs = []

  if(authConfiguration.enableDatabaseAuth) arrayTabs.push({ componentTab: <DialogViewAddUserActiveDirectory isCommitted={isCommitted} setIsCommitted={setIsCommitted} />, label: labelArrayDialogUserAddEdit[0]})
  if(authConfiguration.enableActiveDirectoryAuth) arrayTabs.push({ componentTab: <DialogViewAddUserLocalDatabase isCommitted={isCommitted} setIsCommitted={setIsCommitted} />, label: labelArrayDialogUserAddEdit[1] })
    
  return arrayTabs;
  }
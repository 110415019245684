import {faDesktop, faSquareParking, faUtensils } from "@fortawesome/free-solid-svg-icons";

export const iconMappingTipologiaStanza = {
    1:faDesktop,
    3:faSquareParking,
    4:faUtensils,
  };
  
  export const labelMappingTipologiaStanza = {
    1: "workstation",
    2: "prefixRowDataTable_cafeteria_seating",
    3: "prefixRowDataTable_parking_spot"
  };
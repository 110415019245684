import { Chip, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetUserInMultiUser,
  selectUserInMultiUser,
} from "../../../../../redux/reducers/MultiUserInStepper-reducer";
import { reloadPlanimetriaPrenotazione } from "../../../../../redux/reducers/planimetria-reducer";
import {
  deleteOspitiFromPlanimetriaStepper,
  resetPostazionePrenotata,
} from "../../../../../redux/reducers/prenotazioneStepper-reducer";
import AvatarImage from "../../../../Avatar/AvatarImage";
import "../../Stepper.style.css";

const idElementChip = "chip";


// i controlli vengono fatti su identificativo, nominativo e se è un ospite, in quanto l'identificativo per l'ospite diventa quello della persona associata. 
export default function PannelloConElementiCliccabili({ utenti = [] }) {
  const [isLoading, setIsloading] = useState(false);
  const userSelectedInStepper = useSelector(
    (state) => state.multiUserHandlerInStepper
  );
  const utentiConPostazione = useSelector(
    (state) => state.prenotazioneStepper.utentePrenotazione
  );
  const dispatch = useDispatch();
  const refBoxPills = useRef();


  function handleClick(event, utente) {
    if (event.target) {
      let elementsSelected = document.querySelectorAll(
        ".elementSelectedInStepper"
      );
      if (elementsSelected) {
        elementsSelected.forEach((element) => {
          element.classList.remove("elementSelectedInStepper");
        });
      }
    }
    if (event.target) {
      if (event?.target?.id === idElementChip) {
        event.target.classList.add("elementSelectedInStepper");
      } else {
        event.target.parentNode.classList.add("elementSelectedInStepper");
      }
      dispatch(selectUserInMultiUser(utente));
    }
  }

  function handleDelete(utente) {
    if (
      utentiConPostazione.filter(
        (x) => x.utente === utente.utente
          && x.nominativo === utente.nominativo
          && x.posto !== null
      ).length > 0
    ) {
      dispatch(resetPostazionePrenotata(utente));
      dispatch(reloadPlanimetriaPrenotazione());
      if (userSelectedInStepper.utente === utente.utente
        && utente.nominativo === userSelectedInStepper.nominativo
        && utente.ospite === userSelectedInStepper.ospite)
        dispatch(selectUserInMultiUser({ ...utente, posto: null, index: null }));
    } else {
      dispatch(deleteOspitiFromPlanimetriaStepper({
        nominativo: utente.nominativo,
        ospite: utente.ospite,
        utente: { ...utente, idUtente: utente.utente }, //normalizza il dato
      }))
      if (userSelectedInStepper.utente === utente.utente
        && utente.nominativo === userSelectedInStepper.nominativo
        && utente.ospite === userSelectedInStepper.ospite) {
        setIsloading((old) => !old);
      }
    }
  }

  //auto-seleziona il primo elemento
  useEffect(() => {
    dispatch(resetUserInMultiUser());
    let elementToSelect = utentiConPostazione.find((x) => !x.idPosto); //seleziona il primo elemento senza una postazione associata
    if (elementToSelect) dispatch(selectUserInMultiUser(elementToSelect)); //lo seleziona

    return () => {
      dispatch(reloadPlanimetriaPrenotazione());
    };
  }, [isLoading]);

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Box
          className={`boxElementi-Planimetria boxElementi-Planimetria-overflow ${refBoxPills?.current?.scrollWidth <=
            refBoxPills?.current?.clientWidth && "flex-center-center"
            }`}
          ref={refBoxPills}
          sx={{ width: "100%" }}
        >
          {utenti.map((x, index) => {
            return (
              <Chip
                id={idElementChip}
                key={index}
                label={x.nominativo}
                avatar={<AvatarImage styleContainer={{ marginLeft: 5 }} size={22} bgColor={'#ad3a3a'} ImmagineAvatar={x.immagineAvatar ? x.immagineAvatar : null}
                  isUserLoggedImage={false} isCentered={false} isInputDisabled={true} name={x.nominativo}></AvatarImage>}
                className={
                  x.utente === userSelectedInStepper.utente
                    && x.nominativo === userSelectedInStepper.nominativo
                    && x.ospite === userSelectedInStepper.ospite
                    ? "elementSelectedInStepper"
                    : x.posto !== null
                      ? "elementAssociatedInStepper"
                      : "elementCommonInStepper"
                }
                onClick={(event) => {
                  handleClick(event, x);
                }}
                onDelete={() => {
                  handleDelete(x);
                }}
              />
            );
          })}
        </Box>
      </Grid>
    </>
  );
}

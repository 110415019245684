import { Grid, Typography } from "@mui/material";
import React from "react";
import { gestioneTitleDescrizioneStatusSubMenu } from "../../../functions/gestioneTitleDescrizioneStatusSubMenu";
import ShowSubMenuInfo from "../InfoSubMenu/ShowSubMenuInfo/ShowSubMenuInfo";

export default function DescrizioneStatusSubMenu({ stato, prenotazioni }) {
  //const title = gestioneTitleDescrizioneStatusSubMenu(stato);

  return (
    <>
      <Grid
        container
      >
          <ShowSubMenuInfo stato={stato} info = {{prenotazioni}} />
      </Grid>
    </>
  );
}

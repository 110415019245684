import React from "react";
import {
    iconPostazioneUfficio,
  iconStanzaMensa,
  iconStanzaParcheggio,
  iconStanzaRiunione,
} from "../../../const/Planimetria/IconPlanimetria";
export default function handlerIconSuggerimenti(idTipologiaStanza) {
  let icon;
  let styleIcon;
  switch (idTipologiaStanza) {
    case 1:
      icon = iconPostazioneUfficio;
      styleIcon = "sm-Icon-ElementSuggerimentoPrenotazione-desktop"
      break;
    case 2:
      icon = iconStanzaRiunione;
      styleIcon = "sm-Icon-ElementSuggerimentoPrenotazione-meetingRoom"
      break;
    case 3:
      icon = iconStanzaParcheggio;
      styleIcon = "sm-Icon-ElementSuggerimentoPrenotazione-park"
      break;
    case 4:
      icon = iconStanzaMensa;
      styleIcon = "sm-Icon-ElementSuggerimentoPrenotazione-cafeteria"
      break;
    default:
  }

  const element = React.cloneElement(icon,{
    className: styleIcon
  })

  return element;
}
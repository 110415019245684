
import React from 'react';
import { makeStyles } from '@mui/styles';
import { TimePicker } from "@mui/x-date-pickers";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect } from 'react';

  
export default function TimePickerCustom(props) {
    const { t } = useTranslation();

      return (
        <TimePicker
          {...props}
          localeText={{toolbarTitle:t('select_time')}}
        />
      );
    };
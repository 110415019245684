import { useLocation, useNavigate } from "react-router-dom";
import { Container, Typography as Typo } from "@mui/material";
import NotFound from "../components/error/notFound";
import Forbidden from "../components/error/forbidden";
import Unauthorized from "../components/error/unauthorized";
import { useSelector } from "react-redux";
import { PagesAvailable } from "../const/pages/PagesAvailable";


const ErrorPage = () => {
  const location = useLocation();
  let available = PagesAvailable.includes(location.pathname)
  const state = useSelector((state) => state.userDate);
  return (
    <>
      <Container>
        {!available 
          ? <NotFound />
          : state.token !== undefined 
                ? <Forbidden /> 
                : <Unauthorized />
        }
      </Container>
    </>
  );
};

export default ErrorPage;

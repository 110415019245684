import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  dialogAddBuilding,
  dialogButtonGeneral,
} from "../../../../../const/DialogData";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Fragment, useRef } from "react";
import { useFormik } from "formik";
import { initialValuesBuildingAdd } from "../../../../../const/formik/Building/initialValuesBuilding";
import { useEffect } from "react";
import { validationSchemaBuildingAdd } from "../../../../../const/formik/Building/validationSchemaBuilding";
import { useState } from "react";
import { addBuilding } from "../../../../../const/urlAction";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { notificationOpen } from "../../../../../redux/reducers/notification-reducer";
import { reloadingDataTableBuildings } from "../../../../../redux/reducers/dataTableBuildings-reducer";
import {
  hideLoader,
  showLoader,
} from "../../../../../redux/reducers/appLoader-reducer";
import AddressField from "./addressForm/AddressField"
import AutocompleteAddressField from "./addressForm/AutocompleteAddressField";
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../../../../Hooks/useMobileDetection";
import handleErrorResponse from "../../../../../functions/handlerError";
import { TimePicker } from "@mui/x-date-pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";

const urlBase = process.env.REACT_APP_URL;

export default function DialogViewBuildingAdd({
  isOpen = false,
  returnIsOpen,
  handlerBlur,
  users
}) {
  const [changedRow, setchangedRow] = useState();
  const dispatch = useDispatch();
  const initialValues = initialValuesBuildingAdd();
  const { t } = useTranslation();
  const isMobile = useMobileDetection(950);
  const apiKey = useSelector((state) => state.configurations.configurazioniGlobali.googlePlacesActive);
  const appLoadingState = useSelector((state) => state.appLoader.loading);
  const configurations = useSelector((state) => state.configurations.configurazioniGlobali);
  const [selectedUsers, setSelectedUsers] = useState();
  const fileInputRef = useRef(null);

  const giorniSettimana = {
    1: t("Monday"),
    2: t("Tuesday"),
    3: t("Wednesday"),
    4: t("Thursday"),
    5: t("Friday"),
    6: t("Saturday"),
    7: t("Sunday")
  };

  const handleChangeUserSelect = (event, value) => {
    setSelectedUsers(value);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaBuildingAdd,
    enableReinitialize: true,
    onSubmit: (values) => {
      setchangedRow({
        Denominazione: values.Denominazione,
        Sigla: values.Sigla,
        UtentiResponsabiliPrenotazioni: selectedUsers,
        Indirizzo: values.Indirizzo,
        Coordinate: values.Coordinate,
        FotoEdificio: values.file,
        OrariAperturaEdificio: values.OrariAperturaEdificio
      });
    },
  });


  const closeDialog = (initialValues) => {
    formik.resetForm(initialValues);
    returnIsOpen(false);
  };

  useEffect(() => {
    if (changedRow != null && formik.isValid) {
      (async () => {
        let formData, action, method, url;

        if (selectedUsers === null || selectedUsers === undefined) {
          formData = { ...changedRow, UtentiResponsabiliPrenotazioni: null };
        } else {
          formData = {
            ...changedRow,
            UtentiResponsabiliPrenotazioni: selectedUsers,
          };
        }

        action = addBuilding.action;
        method = addBuilding.method;

        url = urlBase + action;
        dispatch(showLoader());
        try {
          const response = await axios({
            method: method,
            url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.data.ok) {
            formik.resetForm(initialValues);
            dispatch(reloadingDataTableBuildings());
            closeDialog();
          }
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        } catch (error) {
          console.error(error);

          const response = handleErrorResponse(error);
          if (response) {
            dispatch(notificationOpen({ message: response.message, status: response.status }));
          }

          if (error?.response?.data?.errors != undefined) {
            let keys = Object.keys(error?.response?.data?.errors);
            keys.map((key) => {
              formik.setFieldError(key, error?.response?.data?.errors[key]);
            });
          }

        }
        dispatch(hideLoader());
      })();
    }
  }, [changedRow]);

  function handleChangeFile(event) {

    fileInputRef.current.click();

    if (event?.currentTarget?.files[0]) {
      let filename = event?.currentTarget?.files[0]?.name;
      let filenameExtension = filename.substring(filename.lastIndexOf("."), filename.length);
      if (event?.currentTarget?.files[0]?.type?.startsWith("image")) {
        formik.setFieldValue("file", event.currentTarget.files[0]);
      } else if (configurations.enableImportCadFiles && (filenameExtension.includes("dwg") || filenameExtension.includes("dxf"))) {
        formik.setFieldValue("file", event.currentTarget.files[0]);
      } else {
        formik.setFieldError(
          "file",
          configurations.enableImportCadFiles ? t("planimetry_file_cad_format_error") : t("planimetry_file_format_error")
        );
      }
    }
  }
  
  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        fullScreen={isMobile}
        maxWidth={"md"}
      >
        <DialogTitle>{t(dialogAddBuilding.DialogTitle)}</DialogTitle>
        <DialogContent className="border-Content-dialog">
          <FormControl fullWidth>
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item xs={12} sm={2}>
                <TextField
                  margin="dense"
                  inputProps={{
                    maxLength: 3,
                  }}
                  id="Sigla"
                  label={t("abbreviation")}
                  type="text"
                  fullWidth
                  variant="standard"
                  // value={formik?.values?.Sigla}
                  onChange={formik.handleChange("Sigla")}
                  error={formik.errors.Sigla && (formik.submitCount > 0)}
                  helperText={(formik.errors.Sigla && (formik.submitCount > 0)) ? formik.errors.Sigla : null}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  margin="dense"
                  id="Denominazione"
                  label={t("denomination")}
                  type="text"
                  fullWidth
                  variant="standard"
                  // value={formik?.values?.Denominazione}
                  onChange={formik.handleChange("Denominazione")}
                  error={formik.errors.Denominazione && (formik.submitCount > 0)}
                  helperText={(formik.errors.Denominazione && (formik.submitCount > 0)) ? formik.errors.Denominazione : null}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  multiple
                  id="UtentiResponsabiliPrenotazioni"
                  size="small"
                  options={users} //tutti ma sotto forma di json selectedUsers idUtente Nominativo
                  value={selectedUsers}
                  disableCloseOnSelect
                  limitTags={1}
                  isOptionEqualToValue={(option, value) =>
                    option.idUtente === value.idUtente
                  }
                  getOptionLabel={(option) => option.nominativo || ""}
                  onChange={handleChangeUserSelect}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.nominativo}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Building_Managers")}
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>{params.InputProps.endAdornment}</Fragment>
                        ),
                      }}
                      error={
                        Boolean(formik.errors.UtentiResponsabiliPrenotazioni && (selectedUsers === undefined || selectedUsers === null))
                      }
                      helperText={Boolean(formik.errors.UtentiResponsabiliPrenotazioni && (selectedUsers === undefined || selectedUsers === null))}
                    />
                  )}
                />
              </Grid>

              {apiKey ?
                <AutocompleteAddressField formik={formik} />
                :
                <AddressField formik={formik} />
              }
              <Grid
                sx={{ margin: "8px 0 8px 0" }}
                item
                container
                xs={12}
                sm={12}
              >
                <Grid item xs={12} sm={12}>
                  <FormLabel component="legend">
                    <b> {t("Building_picture")}</b>
                  </FormLabel>
                </Grid>
                <Grid item xs={1} sm={1}>
                  <IconButton
                    color="primary"
                    aria-label={t("upload_picture")}
                    component="span"
                  >
                    <label htmlFor="file" className="file-label">
                      <FontAwesomeIcon icon={faImage} size="2x" cursor={'pointer'} />
                    </label>
                  </IconButton>
                  <input
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    margin="dense"
                    id="file"
                    type="file"
                    onChange={(event) => handleChangeFile(event)}
                  />
                </Grid>
                <Grid item xs={10} sm={10}>
                  <Typography sx={{ position: 'relative', top: '1.2rem' }}>{formik.values.file?.name}</Typography>
                </Grid>
              </Grid>
              <Grid
                sx={{ margin: "0px 0 2px 0" }}
                item
                xs={12}
                sm={12}
              >
                {formik.values.OrariAperturaEdificio.length > 0 && <FormLabel component="legend">
                  <b>{t("Opening_hours_Management")}</b>
                </FormLabel>}
              </Grid>
              {formik.values.OrariAperturaEdificio.map((orarioApertura, index) => (
                <>
                  <Grid item xs={3} sm={2}>
                    <Typography sx={{ position: 'relative', top: '1rem' }}><b>{giorniSettimana[formik.values.OrariAperturaEdificio[index].idGiornoSettimana]}</b></Typography>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          name={`OrariAperturaEdificio[${index}].apertura`}
                          checked={formik.values.OrariAperturaEdificio[index].apertura}
                          onChange={formik.handleChange}
                          sx={{ position: 'relative', top: '0.6rem' }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={3} sm={4}>
                    <TimePicker
                      label={t("Opening")}
                      name={`OrariAperturaEdificio[${index}].orarioApertura`}
                      value={formik.values.OrariAperturaEdificio[index].orarioApertura
                        ? new Date(`2000-01-01T${formik.values.OrariAperturaEdificio[index].orarioApertura}`)
                        : new Date()}
                      onChange={(date) => {
                        const formattedTime = date
                          ? date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })
                          : '';
                        formik.setFieldValue(`OrariAperturaEdificio[${index}].orarioApertura`, formattedTime);
                      }}
                      ampm={false}
                      slotProps={{
                        textField: { variant: 'standard', 
                        fullWidth: true, 
                        error: formik.touched.OrariAperturaEdificio?.[index]?.orarioApertura && Boolean(formik.errors.OrariAperturaEdificio?.[index]?.orarioApertura),
                        helperText: formik.touched.OrariAperturaEdificio?.[index]?.orarioApertura && formik.errors.OrariAperturaEdificio?.[index]?.orarioApertura
                      }
                      }}
                      disabled={!formik.values.OrariAperturaEdificio[index].apertura}
                    />
                  </Grid>
                  <Grid item xs={3} sm={4}>
                    <TimePicker
                      label={t("Closure")}
                      name={`OrariAperturaEdificio[${index}].orarioChiusura`}
                      value={formik.values.OrariAperturaEdificio[index].orarioChiusura
                        ? new Date(`2000-01-01T${formik.values.OrariAperturaEdificio[index].orarioChiusura}`)
                        : new Date()}
                      onChange={(date) => {
                        const formattedTime = date
                          ? date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })
                          : '';
                        formik.setFieldValue(`OrariAperturaEdificio[${index}].orarioChiusura`, formattedTime);
                      }}
                      ampm={false}
                      slotProps={{
                        textField: { variant: 'standard', 
                        fullWidth: true, 
                        error: formik.touched.OrariAperturaEdificio?.[index]?.orarioChiusura && Boolean(formik.errors.OrariAperturaEdificio?.[index]?.orarioChiusura),
                        helperText: formik.touched.OrariAperturaEdificio?.[index]?.orarioChiusura && formik.errors.OrariAperturaEdificio?.[index]?.orarioChiusura
                      }
                      }}
                      disabled={!formik.values.OrariAperturaEdificio[index].apertura}
                    />
                  </Grid>
                </>
              ))}
              <br></br>
              <br></br>
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog(initialValues)}>
            {t(dialogButtonGeneral.Cancel)}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={appLoadingState}
            onClick={() => formik.handleSubmit()}
          >
            {t(dialogButtonGeneral.Confirm)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, Grid, Paper, Typography } from "@mui/material";
import "../../../index.css";
import {
  coloreDisponibile,
  coloreOccupato,
} from "../../../const/general/colorApp";
import { convalidaOtpRichiestaCredenzialiButtonText } from "../../../const/DialogData";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  showLoader,
} from "../../../redux/reducers/appLoader-reducer";
import { useTranslation } from "react-i18next";

const grandezzaLogo = "150px";

export default function ResponseConvalidaRichiestaCredenziali({
  outCome = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (outCome === null) {
      dispatch(showLoader());
    }
  }, [outCome]);

  return (
    <>
      <Grid container className="flex-no-center" sx={{ backgroundColor:"#f5f5f5", height:"100vh", paddingTop:"10%"}} >
        <Paper sx={{ height:"450px"}}>
          {outCome !== null && (
            <>
            <Grid container rowSpacing={5} sx={{paddingTop:"50px"}}>
              <Grid item xs={12} className="flex-center-center">
                {outCome ? (
                  <CheckCircleIcon
                    style={{
                      fontSize: grandezzaLogo,
                      color: coloreDisponibile,
                    }}
                  />
                ) : (
                  <CancelIcon
                    style={{ fontSize: grandezzaLogo, color: coloreOccupato }}
                  />
                )}
              </Grid>
              <Grid item xs={12} className="flex-center-center">
                {outCome ? (
                  <Typography sx={{textAlign:"center", fontSize:"18px"}}>
                    {t("admin_response_soon")}
                  </Typography>
                ) : (
                  <Typography sx={{textAlign:"center", fontSize:"18px"}}>
                     {t("request_validation_failed")}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} className="flex-center-center">
                <Button variant="contained" onClick={() => navigate("/")}>
                {t(convalidaOtpRichiestaCredenzialiButtonText.Indietro)}
                </Button>
              </Grid>
          </Grid>
            </>
          )}
          </Paper>
      </Grid>
    </>
  );
}

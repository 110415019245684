import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import { DatapickerBoxDisabilita } from "./DatapickerBoxDisabilita";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/reducers/DialogGeneral";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { urlDisabilitaEntita } from "../../../functions/UrlParams";
import ButtonBoxRiattivaTuttePostazioni from "./ButtonBoxRiattivaTuttePostazioni";
import { typoStyle } from "../../../const/Dialog/Disabilita/dialogDisabilitaText";
import { reloadingDataTableUsers } from "../../../redux/reducers/dataTableUsers-reducer";
import { checkRangeDateDisabilitazione } from "../../../functions/checkRangeDate";
import { hideLoader, showLoader } from "../../../redux/reducers/appLoader-reducer";
import { useTranslation } from "react-i18next"; // Importa useTranslation
import useMobileDetection from "../../../Hooks/useMobileDetection";
import { textDisableWorkstations, textEnableWorkstations } from "../../../const/Dialog/Disabilita/dialogDisabilitaText";

const initialValues = {};
const validationSchema = yup.object({});
const urlBase = process.env.REACT_APP_URL;

export function BoxDialogDisabilita({ isOpen = false, closeForm, dialogType}) {

  const { t } = useTranslation(); // Inizializza useTranslation
  const dispatch = useDispatch();
  const textArea = useRef(undefined);
  const [changedRow, setchangedRow] = useState(null);
  const isMobile = useMobileDetection(950);
  const [valueDatapicker, setValueDatapicker] = useState({
    DataOraInizio: undefined,
    DataOraFine: undefined,
  });
  const { dialogPageType, rowInfo } = useSelector((state) => state.dialogGeneral);
  const { entita, action, method } = urlDisabilitaEntita(dialogPageType, rowInfo.id);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setchangedRow({
        IdEntita: entita,
        DataOraInizioPrenotazione: valueDatapicker.DataOraInizio,
        DataOraFinePrenotazione: valueDatapicker.DataOraFine,
        Note: textArea.current.value.trim() || null,
      });
    },
  });

  useEffect(() => {
    if (changedRow != null) {
      (async () => {
        dispatch(showLoader());
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data: changedRow,
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.data.ok) {
            formik.resetForm(initialValues);
            dispatch(reloadingDataTableUsers());
            dispatch(closeDialog());
          }
          dispatch(hideLoader());
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        } catch (error) {
          console.error(error);
          dispatch(hideLoader());
          dispatch(
            notificationOpen({
              message: error.response.data.message,
              status: "error",
            })
          );
        }
      })();
    }
  }, [changedRow]);

  function disableButton() {}

  function returnValueInizio(value) {
    setValueDatapicker({ ...valueDatapicker, DataOraInizio: value });
  }

  function returnValueFine(value) {
    setValueDatapicker({ ...valueDatapicker, DataOraFine: value });
  }

  let isButtonDisabled = checkRangeDateDisabilitazione(
    valueDatapicker.DataOraInizio,
    valueDatapicker.DataOraFine
  );

  useEffect(() => {
    setValueDatapicker({
      DataOraInizio: undefined,
      DataOraFine: undefined,
    });
  }, [isOpen]);

  return (
    <React.Fragment>
      {dialogType === "disableRangeData" ? (
        <>
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            fullScreen={isMobile}
            maxWidth={"sm"}
          >
            <DialogTitle>{t("detail_disabling")}</DialogTitle>
            <DialogContent className="border-Content-dialog">
              <FormControl fullWidth>
                <Grid item sx={{ marginBottom: "5px" }}>
                  <div>
                    <Typography sx={typoStyle}>
                      {textDisableWorkstations(t, rowInfo.denominazione)}
                    </Typography>
                  </div>
                </Grid>
                <DatapickerBoxDisabilita
                  disableButton={disableButton}
                  valueInizio={returnValueInizio}
                  valueFine={returnValueFine}
                />
                <Grid item sx={{ marginTop: "18px" }}>
                  <label>
                    <Typography className="labelAutoCompletePills" sx={{ marginBottom: "2px" }}>
                      {t("annotation")}
                    </Typography>
                  </label>
                  <TextareaAutosize
                    ref={textArea}
                    minRows={6}
                    maxRows={6}
                    placeholder={t("note_placeholder")}
                    style={{ width: "99%" }}
                  />
                </Grid>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeForm()}>{t("close")}</Button>
              <Button
                type="submit"
                variant="contained"
                disabled={isButtonDisabled}
                onClick={() => formik.handleSubmit()}
              >
                {t("confirm")}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <>
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"sm"}
          >
            <DialogTitle>{t("detail_enabling")}</DialogTitle>
            <DialogContent className="border-Content-dialog">
              <DialogContentText>
                <FormControl fullWidth>{textEnableWorkstations(t, rowInfo.denominazione)}</FormControl>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeForm()}>{t("cancel")}</Button>
              <ButtonBoxRiattivaTuttePostazioni buttonText={t("confirm")} />
            </DialogActions>
          </Dialog>
        </>
      )}
    </React.Fragment>
  );
}

import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Divider, Grid, Typography } from "@mui/material";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import { CDCard, CDCardContent, CDCardHeader } from "../../const/CDCardStyles";
import { TitleDatable } from "../TitleDatable/TitleDataTable";
import { getUsers, GetPrenotazioniByUtenteAndDataPrenotazione } from "../../const/urlAction";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import { showLoader, hideLoader } from "../../redux/reducers/appLoader-reducer";
import handlerError from "../../functions/handlerError";
import Notification from "../UI/Notification/Notification";
import MobileViewContainer from "../UI/mobileView/mobileViewContainer";
import GeneralCustomDateTimePicker from "../UI/TimePicker/GeneralCustomDateTimePicker";
import { AutocompleteCustom } from "../UI/AutoComplete/AutoComplete";
import ListaPrenotazioniCollega from "./ListaPrenotazioniCollega/ListaPrenotazioniCollega";
import useViewportAdaptedHeight from "../../Hooks/Adaptive/useViewportAdaptedHeight";
import handlerConfiguration from "../../functions/general/handlerConfiguration";
import { enumViewModePrenotazioni } from "../../const/prenotazioni/enumViewMode";

const urlBase = process.env.REACT_APP_URL;
const now = new Date(Date.now());

export default function TrovaCollegaComponent() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const CDCardContentRef = useRef(null);
    const configurations = useSelector((state) => state.configurations);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const [loading, setLoading] = useState(false);
    const [usersRows, setUsersRows] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [dataPrenotazione, setDataPrenotazione] = useState(null);
    const [prenotazioni, setPrenotazioni] = useState([]);
    const { maxGiorniRipetizione } = handlerConfiguration(configurations);
    const adaptedHeight = useViewportAdaptedHeight(".adaptHeight", 19, CDCardContentRef, !loading, [isMobile]);
    const maxday = new Date(
        new Date(Date.now()).setDate(now.getDate() + maxGiorniRipetizione)
    );

    const handleChangeData = (newValue) => {
        if (newValue !== null) {
            setDataPrenotazione(newValue);            
        } else {
            setDataPrenotazione("null");
        }
    };

    const handleResizeWindow = () => {
        if (window.innerWidth > 600) {
            setIsMobile(false);
        } else {
            setIsMobile(true);
        }
    };

    const handleValueUser = (selectedValue) => {
        setSelectedUser(selectedValue);
    };

    const findColleague = () => {
        (async () => {
            setLoading(true);
            dispatch(showLoader());

            const { action, method } = GetPrenotazioniByUtenteAndDataPrenotazione;
            let url = urlBase + action;
            let requestBody = {
                idUtente: selectedUser.idUtente,
                dataInizioPrenotazione: dataPrenotazione
            };

            try {
                const response = await axios({
                    method: method,
                    url,
                    data: requestBody
                });
                setPrenotazioni(response.data.result);

                if (!response.data.ok) {
                    dispatch(
                        notificationOpen({
                            message: response.data.message,
                            status: "success"
                        })
                    );
                }
                setLoading(false);
                dispatch(hideLoader());
            } catch (error) {
                console.error(error);
                const notificationError = handlerError(error);
                dispatch(notificationOpen(notificationError));
                setLoading(false);
                dispatch(hideLoader());
            }
        })();
    }

    useEffect(() => {
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            window.removeEventListener("resize", handleResizeWindow);
        };
    });

    useEffect(() => {
        let isMounted = true;
        setDataPrenotazione(now);

        (async () => {
            const { action, method } = getUsers;
            let url = urlBase + action;

            try {
                const response = await axios({
                    method: method,
                    url
                });
                if (isMounted) {
                    setUsersRows(response.data.result);
                }
            } catch (error) {
                console.error(error);
                const notificationError = handlerError(error);
                dispatch(notificationOpen(notificationError));
            }
        })();
        return () => {
            isMounted = false;
        };
    }, []);


    return (
        <>
            {isMobile ? (
                <>
                    <Grid container variant="No-Padding">
                        <Grid item component={CDCard} xs={12} sm={12} >
                            <CDCardHeader
                                className="CDCardHeader-Padding adaptHeight"
                                title={
                                    <>
                                        <TitleDatable nameDataTable={t("find_colleague")} />
                                    </>
                                }
                            />
                            <Divider />
                            <CDCardContent className="CDCardContent-Mobile" style={{ height: adaptedHeight }} ref={CDCardContentRef}>
                                <Grid container item>
                                    <MobileViewContainer >
                                        <Grid container item rowSpacing={2}>
                                            <Grid item xs={12} sm={3}>
                                                <GeneralCustomDateTimePicker
                                                    TypeTimePicker={5}
                                                    label={t("Booking_date")}
                                                    valueStart={dataPrenotazione}
                                                    handleChange={handleChangeData}
                                                    minDate={now}
                                                    maxDate={maxday}
                                                    placeholder={t("Booking_date_placeholder")}                                                    
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <AutocompleteCustom
                                                    label={t("colleague_name")}
                                                    field={["nominativo"]}
                                                    checkField={"idUtente"}
                                                    fullWidth={false}
                                                    keyId={"idUtente"}
                                                    openOnFocus={true}
                                                    optionsAutoComplete={usersRows}
                                                    handleValueReturned={handleValueUser}
                                                    startValueAutoComplete={selectedUser}
                                                    clean={true}
                                                    cleanAlways={false}
                                                    multiple={false}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3} className="flexEnd" sx={{ mt: 2 }}>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        findColleague();
                                                    }}
                                                    disabled={!dataPrenotazione || !selectedUser}
                                                >
                                                    {t("search")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </MobileViewContainer>
                                    {prenotazioni.length === 0 ? (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            className="no-result-card"
                                            mt={2}
                                        >
                                            <Grid item xs={12}>
                                                <EventBusyRoundedIcon className="no-result-icon" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className="no-result-text">
                                                    {t("no_reservation")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ) :
                                        <Grid container item flexDirection={"column"}
                                            xs={12}
                                            sm={12}
                                            lessmd={12}
                                            md={12}
                                            lg={8}
                                            xl={8}
                                            sx={{ height: "fit-content" }}>
                                            <ListaPrenotazioniCollega
                                                isOpenAdvancedSearch={false}
                                                viewOnlyMine={false}
                                                prenotazioni={prenotazioni}
                                                viewMode={enumViewModePrenotazioni.Month}
                                                className="box-shadow-light"
                                            />
                                        </Grid>}
                                </Grid>
                            </CDCardContent>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid container>
                        <Grid item component={CDCard} xs={12} sm={12} md={12}>
                            <CDCardHeader
                                className="CDCardHeader-Padding"
                                title={
                                    <>
                                        <TitleDatable nameDataTable={t("find_colleague")} />
                                    </>
                                }
                            />
                            <Divider />
                            <CDCardContent
                                className="no-padding-MuiCardContent-root"
                                style={{ width: "100%" }}
                            >
                                <Grid container direction="row" justifyContent="center" >
                                    <Grid
                                        container //contenitore sezione sx 
                                        item
                                        direction="row"
                                        className="with-right-divider leftContainer"
                                        xs={12}
                                        sm={12}
                                        lessmd={12}
                                        md={12}
                                        lg={4}
                                        xl={4}
                                    >
                                        <Grid
                                            container //contenitore form ricerca
                                            item
                                            justifyContent="center"
                                            rowSpacing={3}
                                            xs={12}
                                            sm={12}
                                            lessmd={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            sx={{ maxHeight: "150px" }}
                                        >
                                            <Grid item xs={12} sm={12} md={12}>
                                                <GeneralCustomDateTimePicker
                                                    TypeTimePicker={5}
                                                    label={t("Booking_date")}
                                                    valueStart={dataPrenotazione}
                                                    handleChange={handleChangeData}
                                                    minutesStepEnabled={false}
                                                    minDate={now}
                                                    maxDate={maxday}
                                                    placeholder={t("Booking_date_placeholder")}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <AutocompleteCustom
                                                    label={t("colleague_name")}
                                                    field={["nominativo"]}
                                                    checkField={"idUtente"}
                                                    fullWidth={false}
                                                    keyId={"idUtente"}
                                                    openOnFocus={true}
                                                    optionsAutoComplete={usersRows}
                                                    handleValueReturned={handleValueUser}
                                                    startValueAutoComplete={selectedUser}
                                                    clean={true}
                                                    cleanAlways={false}
                                                    multiple={false}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} className="flexEnd">
                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        findColleague();
                                                    }}
                                                    disabled={!dataPrenotazione || !selectedUser}
                                                >
                                                    {t("search")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {prenotazioni.length === 0 ? (
                                        <Grid
                                            container
                                            item
                                            xs={9}
                                            sm={9}
                                            md={9}
                                            lg={8}
                                            xl={8}
                                            className="no-result-card"
                                        >
                                            <Grid item xs={12}>
                                                <EventBusyRoundedIcon className="no-result-icon" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className="no-result-text">
                                                    {t("no_reservation")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid
                                            container
                                            item
                                            flexDirection={"column"}
                                            xs={12}
                                            sm={12}
                                            lessmd={12}
                                            md={12}
                                            lg={8}
                                            xl={8}
                                            sx={{ height: "fit-content", padding: "0 12px", marginTop: "12px" }}
                                        >
                                            <ListaPrenotazioniCollega
                                                isOpenAdvancedSearch={false}
                                                viewOnlyMine={false}
                                                prenotazioni={prenotazioni}
                                                viewMode={enumViewModePrenotazioni.Month}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </CDCardContent>
                        </Grid>
                    </Grid>
                </>
            )}
            <Notification />
        </>
    );
}
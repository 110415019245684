import { Chip, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export default function LegendaComponent({ infoMap }) {
  const refBoxPills = useRef();
  const { t } = useTranslation(); 

  return (
    <>
    <div className="amenities-box">
      <Box
        className={`boxElementi-legendMap  ${
          refBoxPills?.current?.scrollWidth <=
            refBoxPills?.current?.clientWidth && "flex-center-center"
        }`}
        ref={refBoxPills}
        sx={{ width: "100%"}}
      >
        <Grid container>
          <Grid item xs={12}>
            {infoMap.map((x, index) => {
              return (
                <Chip
                  className="chip-square"
                  key={index}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <div
                        className="amenities-infoitem rounded"
                        style={{
                          backgroundColor: x.coloreUI,
                        }}
                      ></div>
                      {x.descrizione}
                    </Box>
                  }
                />
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </div>
    </>
  );
}

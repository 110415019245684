import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginForm from "../../components/Login/LoginForm";
import "../../components/Login/login.css";
import MessageNoProvider from "../../components/UI/MessageNoProvider/MessageNoProvider";




const Login = () => {
  const authConfiguration = useSelector(
    (state) => state.configurations.configurazioniAutenticazione
  );


  const userDate = localStorage.getItem("deskbooking");
  const objUserDate = JSON.parse(userDate);


  if (objUserDate != null && Object.entries(objUserDate).length !== 0) {
    window.location.href = "/prenotazioni";
    return null; // Per evitare di renderizzare il resto del componente
  }


  return (
    <>
      {authConfiguration && Object.keys(authConfiguration).length > 0 && (
        <>
          {authConfiguration?.enableActiveDirectoryAuth ||
          authConfiguration?.enableDatabaseAuth ? (
            <LoginForm />
          ) : (
            <MessageNoProvider />
          )}
        </>
      )}
    </>
  );
};




export default Login;

export default function LegendaElement({el, positionR, positionT}) {

    return(<div
      className="amenities rounded"
        style={{
          top: positionT,
          right: positionR,
          backgroundColor: el?.coloreUI,
        }}
      ></div>)
}
import { Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCity, faDesktop, faDoorOpen, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { iconMappingTipologiaStanza } from '../../../../../../const/mapping/TipologiaStanza/tipologiaStanzaMapping';

export default function IconResum({ informazioniPostazione = {} }) {
  const { edificio, zona, stanza, posto, isSalaRiunione, idTipologiaStanza } = informazioniPostazione;
  return (
    <>
      <Grid container className="flex-center-center">
        {/* <Grid item  className="flex-center-center icon-margin">
          <FontAwesomeIcon icon={faCity} className="pathIcon"/> {edificio}
        </Grid>
        <Grid item  className="flex-center-center icon-margin">
          <FontAwesomeIcon icon={faLocationDot} className="pathIcon"/>{zona}
        </Grid> */}
        <Grid item xs={12} sx={{ height: '3vh', marginBottom:'1rem!important' }} className="flex-center-center icon-margin">
          {/* <FontAwesomeIcon icon={faDoorOpen} style={{ marginRight: 4, height: '100%' }} className="pathIcon" /> */}<Typography mx={1} sx={{ fontSize: '28px' }}>{stanza}</Typography>
        </Grid>
        {!isSalaRiunione && <Grid xs={12} sx={{ height: '3vh' }} item className="flex-center-center icon-margin">
          <FontAwesomeIcon icon={iconMappingTipologiaStanza[idTipologiaStanza]} style={{ marginRight: 4, height: '100%' }} className="pathIcon" />  <Typography ml={1} sx={{ fontSize: '20px' }}>{posto}</Typography>
        </Grid>}
      </Grid>
    </>
  );
}

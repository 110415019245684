import React from "react";
import { Typography, Grid, Paper, Avatar } from "@mui/material";
import style from "../riepilogoDialog.module.css";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import GroupsIcon from "@mui/icons-material/Groups";
import moment from "moment/moment";
import { faCheck, faDesktop, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoPostazione from "./InfoPostazione";
import AvatarImage from "../../Avatar/AvatarImage";

export function BodyRiepilogoDialog({ date }) {
  //raggruppa per utente le prenotazioni
  
  var result = date.reduce(function (r, a) {
    var key = a.idUtente + a.fullnameUtente; // Creare una chiave univoca combinando idUtente e fullnameUtente, in modo da gestire anche gli ospiti
    r[key] = r[key] || [];
    r[key].push(a);
    return r;
  }, Object.create(null));

  //recupera la lista degli utenti
  var utenti = Object.keys(result);

  //inserisce due array(dataOraInizio,dataOraFine) con tutte le date che bisogna mostrare,all'interno della prima prenotazione.
  var utentimod = utenti.map((utente) => {
    let dateUtenteInizio = [];
    let dateUtenteFine = [];
    let esitoPrenotazione = [];
    result[utente].map((prenotazione) => {
      dateUtenteInizio.push(prenotazione["dataOraInizio"]);
      dateUtenteFine.push(prenotazione["dataOraFine"]);
      esitoPrenotazione.push(prenotazione["prenotazioneEffettuata"]);
    });
    return {
      ...result[utente][0],
      dateUtenteInizio,
      dateUtenteFine,
      esitoPrenotazione,
    };
  });

  return utentimod.map((utente) => {
    const {
      idUtente,
      infoPostazione,
      tipologiaStanza,
      idPostazione,
      denominazioneEdificio,
      denominazioneStanza,
      denominazioneZona,
      fullnameUtente,
      dateUtenteInizio,
      dateUtenteFine,
      esitoPrenotazione,
      avatarImage,
      numerazionePostazione,
      isOspite
    } = utente;

    return (
<Paper key={idUtente} className={`${style["paper-DialogRiepilogo"]} ${style["box-shadow-light-paper-Riepilogo"]}`}>
  <Grid container item xs={12} spacing={2}>
    <Grid item xs={12} className={style["no-padding-top"]}>
      <Typography className={style["field-DialogRiepilogo"]}>
        <div className={`${style["flex-center"]} ${style["line-spacing"]}`}>
        <AvatarImage
                          ImmagineAvatar={avatarImage}
                          isOspite={isOspite}
                          name={fullnameUtente}
                          isInputDisabled={true}
                        />
          <div className="padding-left-fullnameUtente">{fullnameUtente}</div>
        </div>
      </Typography>
    </Grid>
    <Grid item xs={12} className={style["no-padding-top"]}>
      <InfoPostazione numerazionePostazione={numerazionePostazione} infoPostazione={infoPostazione} tipologiaStanza={tipologiaStanza} denominazioneZona={denominazioneZona} denominazioneEdificio={denominazioneEdificio} denominazioneStanza={denominazioneStanza}/>
    </Grid>
    {dateUtenteInizio.map((date, index) => (
      <Grid container item xs={12} key={index} className={style["no-padding-top"]}>
        <Grid item xs={12} className={style["no-padding-top"]}>
          <Typography className={style["field-DialogRiepilogo"]}>
            <div className={`${style["flex-center"]} ${style["line-spacing"]}`}>
              <div style={{minWidth:"20px"}}>
              {esitoPrenotazione[index] ? (
                <FontAwesomeIcon icon={faCheck} className={`${style["icon-success"]} ${style["icon-spacing-right"]}`} />
              ) : (
                <FontAwesomeIcon icon={faXmark} className={`${style["icon-error"]} ${style["icon-spacing-right"]}`} />
              )}
              </div>
              <span>
                {moment(dateUtenteInizio[index]).format("DD/MM/YYYY")}{" "}
                {moment(dateUtenteInizio[index]).format("HH:mm")} -{" "}
                {moment(dateUtenteFine[index]).format("HH:mm")}
              </span>
            </div>
          </Typography>
        </Grid>
      </Grid>
    ))}
  </Grid>
</Paper>
    );
  });
}

import { dialogPageTypes } from "../../../const/dialogPageTypes";
import { openDialog, setPageType } from "../../../redux/reducers/DialogGeneral";

export const handleDialogToEditZone = ({type:dialogType, row:rowInfo}, dispatch, event = null) => {
  if(event) event.stopPropagation();
  dispatch(setPageType(dialogPageTypes.zone))
  dispatch(openDialog({dialogType, rowInfo}))
};

export const handleDialogToEnableDisableZone = ({dialogPageType: dialogPageType, dialogType: dialogType, rowInfo: rowInfo}, dispatch, event = null) => {
  if(event) event.stopPropagation();  
  dispatch(setPageType(dialogPageType));
  dispatch(openDialog({ dialogType, rowInfo }));
};
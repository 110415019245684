import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import EditIcon from "@mui/icons-material/Edit";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import {
  openDialogUser,
  setPageType,
} from "../../redux/reducers/dialogUser-reducer";
import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { policies as permessi } from "../../const/policies";
import LockResetIcon from "@mui/icons-material/LockReset";
import { dialogPageTypes } from "../../const/dialogPageTypes";
import { faBan, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../Hooks/useMobileDetection";
import { Typography } from "@mui/material";
import { handleDialogToEditUser } from "./operations/handleDialogToEditUser";

const ButtonBoxUsers = ({ row: rowInfo }) => {
  const state = useSelector((state) => state.userDate);
  const { policies } = state;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMobileDetection(600);

  return (
    <>
      {!isMobile &&
        policies.indexOf(permessi.utentiInserimentoModifica) != -1 && (
          <GridActionsCellItem
            icon={
              <Tooltip title={t("edit")}>
                <FontAwesomeIcon
                  icon={faPen}
                  style={{ fontSize: 25 }}
                  className="pathIcon"
                />
              </Tooltip>
            }
            label={t("edit")}
            onClick={(event) => handleDialogToEditUser({ dialogType: "edit", rowInfo }, dispatch, event)}
          />
        )}

      {policies.indexOf(permessi.utentiCancellazione) != -1 && (
        <GridActionsCellItem
        sx={{ '& .MuiTouchRipple-root': { display: 'none' }}}
        className={isMobile ? "button-ButtonBox-Mobile" : ""}
          icon={
            <Tooltip
              title={!rowInfo.dataDel ? t("disable_Action") : t("enable")}
            >
              {!rowInfo.dataDel ? (
                !isMobile ? (
                  <FontAwesomeIcon
                    icon={faBan}
                    style={{ fontSize: 25 }}
                    className="pathIcon"
                  />
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faBan}
                      size="xs"
                      style={{ color: "#636161", marginRight: "3px" }}
                    />{" "}
                    <Typography sx={{ fontSize: 10, color: "#636161" }}>
                      {t("disable_Action")}
                    </Typography>
                  </>
                )
              ) : !isMobile ? (
                <LoopOutlinedIcon sx={{ fontSize: 30 }} color="primary" />
              ) : (
                <>
                  <LoopOutlinedIcon
                    size="xs"
                    style={{ color: "#636161", marginRight: "3px" }}
                  />{" "}
                  <Typography sx={{ fontSize: 10, color: "#636161" }}>
                    {t("enable")}
                  </Typography>
                </>
              )}
            </Tooltip>
          }
          label={t("Cancel")}
          onClick={(event) => handleDialogToEditUser({ dialogType: "delete", rowInfo }, dispatch, event)}
        />
      )}
      {policies.indexOf(permessi.utentiResetPassword) != -1 &&
      rowInfo.hasPassword ? (
        <GridActionsCellItem
        sx={{ '& .MuiTouchRipple-root': { display: 'none' }}}
        className={isMobile ? "button-ButtonBox-Mobile" : ""}
          icon={
            <Tooltip title={t("res_psw_v2")}>
              {!isMobile ? (
                <LockResetIcon sx={{ fontSize: 30 }} color="primary" />
              ) : (
                <>
                  <LockResetIcon
                    style={{ color: "#636161", marginRight: "3px", fontSize:20 }}
                  />
                  <Typography sx={{ fontSize: 10, color: "#636161" }}>
                    {t("res_psw_v3")}
                  </Typography>
                </>
              )}
            </Tooltip>
          }
          label={t("reset")}
          onClick={(event) => handleDialogToEditUser({ dialogType: "reset", rowInfo }, dispatch, event)}
        />
      ) : (
        <GridActionsCellItem
        sx={{ visibility: "hidden", '& .MuiTouchRipple-root': { display: 'none' }}}
        className={isMobile ? "button-ButtonBox-Mobile" : ""}
        icon={
          <Tooltip title={t("res_psw_v2")}>
            {!isMobile ? (
              <LockResetIcon sx={{ fontSize: 30 }} color="primary" />
            ) : (
              <>
                <LockResetIcon
                  style={{ color: "#636161", marginRight: "3px", fontSize:20 }}
                />
                <Typography sx={{ fontSize: 10, color: "#636161" }}>
                  {t("res_psw_v3")}
                </Typography>
              </>
            )}
          </Tooltip>
        }
          label={t("reset")}
        />
      )}
    </>
  );
};

export default ButtonBoxUsers;

import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { loginUtente } from "../../const/urlAction";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import Notification from "../../components/UI/Notification/Notification";
import handlerError from "../../functions/handlerError";
import { Navigate } from "react-router-dom";
import Logo from "../../assests/logo.png";
import "../../components/Login/login.css";
import handlerConfiguration from "../../functions/general/handlerConfiguration";
import DialogSignIn from "../Dialog/dialogSignIn/dialogSignIn";
import { Link } from "@mui/material";
import { hideLoader, showLoader } from "../../redux/reducers/appLoader-reducer";
import useMobileDetection from "../../Hooks/useMobileDetection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import CultureButton from "../Culture/CultureButton";

const validationSchema = yup.object({
  username: yup.string().required("Campo Obbligatorio"),
  password: yup.string().required("Campo Obbligatorio"),
});

const initialValues = {
  username: "",
  password: "",
};

const urlBase = process.env.REACT_APP_URL;

export default function LoginForm() {
  const { t } = useTranslation();
  const state = useSelector((state) => state.userDate);
  const configurations = useSelector((state) => state.configurations);
  const isMobile = useMobileDetection(1199);
  const { dimensioniLogoLogin: grandezzaLogo, appVersion: versionNumber } = handlerConfiguration(configurations)
  const authConfiguration = configurations.configurazioniAutenticazione

  const [changedRow, setchangedRow] = useState();
  const [dialogSignInState, setDialogSignInState] = useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true, // rinizializza il valore che gli viene passato con rowInfo
    onSubmit: (values) => {
      setchangedRow({
        username: values.username,
        password: values.password,
      });
    },
  });

  /*
   è nell'interceptor che vengono recuperati i dati dell'utente, e salvati nel localstorage,
   per poi essere inseriti nel contesto dell'applicazione tramite la function thunk "RefreshToken"
   */
  useEffect(() => {
    let isMounted = true;
    if (changedRow != null) {
      (async () => {
        dispatch(showLoader());
        let body = changedRow;
        const { action, method } = loginUtente;
        let url = urlBase + action;
        try {
          await axios({
            method: method,
            url,
            data: body,
          });
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
          dispatch(hideLoader());
        }
        if (isMounted) {
          dispatch(hideLoader());
        }
      })();
    }
    return () => {
      isMounted = false;
      dispatch(hideLoader());
    };
  }, [changedRow]);


  return Object.entries(state).length !== 0 ? (
    <Navigate to="/prenotazioni" />
  ) : (
    <>
      {Object.keys(authConfiguration).length > 0 && (
        <Grid container component="main" height={"100vh"}>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                marginTop: !isMobile ? "25%" : "15%",
                marginBottom: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ marginBottom: "20px" }}>
                <img src={Logo} alt="Logo" width={grandezzaLogo + "px"} />
              </Box>
              {/* <Typography variant="h4">
                  DESK BOOKING
                </Typography> */}

              {isMobile && (
                <Typography className="leftSideLoginTextMobile">
                  {t("login_paragraph_1")}
                </Typography>
              )}

              {isMobile && (
                <Typography className="leftSideLoginTextMobile">
                  {t("login_paragraph_2")}
                </Typography>
              )}

              <form
                onSubmit={formik.handleSubmit}
                style={{ marginTop: 1, width: 300 + "px" }}
              >
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <FontAwesomeIcon icon={faUser} style={{ color: '#00000088', marginBottom: 12, marginRight: 10 }} size="lg" />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label={t("Username_Access_Email")}
                    name="username"
                    autoComplete="username"
                    variant="standard"
                    onChange={formik.handleChange("username")}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <FontAwesomeIcon icon={faKey} style={{ color: '#00000088', marginBottom: 12, marginRight: 10 }} size="lg" />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t("psw")}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    variant="standard"
                    onChange={formik.handleChange("password")}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {t("login")}
                  </Button>
                </Box>
                <Box
                  sx={{
                    marginTop: "0%",
                    marginBottom: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    color: "silver",
                  }}
                >
                  <Typography variant="subtitle2">v.{versionNumber}</Typography>
                  <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <CultureButton closeMenu={()=>{}} />
                  </div>
                </Box>
                <Grid container className="flex-center-center">
                  {/* <Grid item xs>
                    <Link href="#" variant="body2">
                        Hai dimenticato la password?
                      </Link>
                  </Grid> */}
                  <Grid item xs={12} className="flex-center-center">
                    <Link sx={{ cursor: "pointer" }} onClick={() => { setDialogSignInState(true) }} variant="body2">
                      {t("Request_Access_Credentials")}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
          {!isMobile && (
            <Grid item xs={12} lg={6} sx={{ backgroundColor: "#416AB4" }}>
              <Box
                sx={{
                  marginTop: "37%",
                  marginBottom: 15,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">
                  <b className="leftSideLoginText">DESK BOOKING</b>
                </Typography>
                <br />

                <Box>
                  <Typography className="leftSideLoginText">
                    {t("Manage_workstations")}
                  </Typography>
                  <Typography className="leftSideLoginText">
                    {t("Organize_autonomy")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
      <DialogSignIn isOpen={dialogSignInState} returnIsOpen={setDialogSignInState} />
      <Notification />
    </>
  );
}

import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import './culture.css';
import it from '../../../src/assests/culture/it.png';
import enGb from '../../../src/assests/culture/enGB.png';
import { setEnglishCulture, setItalianCulture } from '../../redux/reducers/culture-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { cultures } from '../../const/general/cultures';

export default function CultureButton({ closeMenu }) {
  const dispatch = useDispatch();
  const culture = useSelector((state) => state.culture.currentCulture);

  return (
    <div className="culture-button-container">
      <Tooltip title="Italiano" arrow>
        <div
          className="culture-button culture-size"
          style={{
            backgroundImage: `url(${it})`,
            opacity: culture !== cultures.italian ? '0.5' : '',
            cursor: culture === cultures.italian ? 'default' : ''
          }}
          onClick={culture !== cultures.italian ? () => { dispatch(setItalianCulture()); closeMenu() } : null}
        ></div>
      </Tooltip>
      <Tooltip title="English" arrow>
        <div
          className="culture-button culture-size"
          style={{
            backgroundImage: `url(${enGb})`,
            opacity: culture !== cultures.english ? '0.5' : '',
            cursor: culture === cultures.english ? 'default' : ''
          }}
          onClick={culture !== cultures.english ? () => { dispatch(setEnglishCulture()); closeMenu() } : null}
        ></div>
      </Tooltip>
    </div>
  );
}

import { Grid, Tooltip } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sincronizzaUtenti } from "../../../../../../const/urlAction";
import { closeDialogUser } from "../../../../../../redux/reducers/dialogUser-reducer";
import SelectOrganizationalUnits from "../../SelectOrganizationalUnits";
import ToggleAdminInclude from "../../ToggleAdminInclude";
import TextOrganizationalUnits from "../../../componentUserDialog/TextOrganizationalUnits";
import ContainerPills from "../../../../../UI/ContainerPills/ContainerPills";
import SincronizzazioneUsersPills from "../../../../../UI/ContainerPills/Pills/SincronizzazioneUsersPills";
import handlerError from "../../../../../../functions/handlerError";
import { notificationOpen } from "../../../../../../redux/reducers/notification-reducer";
import SendIcon from "@mui/icons-material/Send";
import AvvisoFormMessage, {
} from "../../../../../UI/Form/AvvisoFormMessage";
import { utentiSincronizzazioneTaskOn } from "../../../../../../redux/reducers/TaskAsync";
import { hideLoader, showLoader } from "../../../../../../redux/reducers/appLoader-reducer";
import { useTranslation } from "react-i18next";

const urlBase = process.env.REACT_APP_URL;

export default function DialogViewSincronizzazioneUser({
  action = 0,
  testoSpiegazioneUso = <></>,
  toggleAdmin = false,
  pannelPills = false,
  selectField = false,
  setAction = () => null,
}) {
  const dispatch = useDispatch();
  const OU = useSelector(
    (state) => state.configurations.configurazioniAutenticazione.organizationalUnits
  );
  const [resetAdminAccount, setResetAdminAccount] = useState(false);
  const [oulist, setOuList] = useState([]);
  const [saveEvent, setSaveEvent] = useState(undefined);
  const [reload, setReload] = useState(false); //l'unico scopo è quello di ricaricare il component
  const { t } = useTranslation();

  useEffect(() => {
    if (action > 0) {
      (async () => {
        let body = {
          SincronizzaDaAD: true,
          OrganizationalUnits: oulist,
          ResetAdminAccounts: resetAdminAccount,
        };

        const { action, method } = sincronizzaUtenti;
        let url = urlBase + action;
        dispatch(showLoader())
        try {
          const response = await axios({
            method: method,
            url,
            data: body,
          });
          if (response.data.ok) {
            dispatch(
              notificationOpen({
                message: "Sincronizzazione avviata in background...",
                status: "success",
              })
            );
            dispatch(utentiSincronizzazioneTaskOn())
            dispatch(closeDialogUser());
          }
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
        }
        dispatch(hideLoader())
      })();
    }
  }, [action]);

  useEffect(() => {
    return () => {
      setAction(0);
    };
  }, []);

  function returnOuListSelect(value) {
    let list = [];
    value.map((x) => {
      list.push(x.denominazione);
    });
    setOuList(list);
  }

  function returnOuListText(value) {
    setOuList(value);
  }

  function handleDelete(value) {
    let arr = oulist;
    arr.splice(value, 1);
    setOuList(arr);
    setReload(!reload);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AvvisoFormMessage msg={testoSpiegazioneUso} />
        </Grid>
        {toggleAdmin && (
          <Grid item xs={12} sx={{ paddingTop: "10px" }}>
            <ToggleAdminInclude returnValueToogle={setResetAdminAccount} />
          </Grid>
        )}
        {selectField && (
          <Grid item xs={12} mb={3} >
            {OU.length > 0 ? (
              <SelectOrganizationalUnits
                OrganizationalUnits={OU}
                returnValueFromSelect={returnOuListSelect}
              />
            ) : (
              <>
                <Grid container className="flex-center-center">
                  <Grid item xs={10} sm={10} md={10} lg={10}>
                    <TextOrganizationalUnits
                      returnValueFromText={returnOuListText}
                      eventButton={saveEvent}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}

                  >
                    <Tooltip title="Aggiungi">
                      <span
                      >
                        <SendIcon className="buttonSincro" onClick={(e) => setSaveEvent(e)} />
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {pannelPills && OU.length < 1 && (
          <Grid item xs={12}>
            <ContainerPills
              params={{ Component: SincronizzazioneUsersPills, handleDelete }}
              element={oulist}
              noElementsString={t("No_OU")}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { dialogPageTypes } from "../../../const/dialogPageTypes";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import { closeDialog } from "../../../redux/reducers/DialogGeneral";
import { useDispatch } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import {
  addZona,
  editZona,
  getZonaById,
  deleteZona,
} from "../../../const/urlAction";
import axios from "axios";
import { reloadingDataTableUsers } from "../../../redux/reducers/dataTableUsers-reducer";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import {
  changeStatusZonaTextDialog,
  dialogButtonGeneral,
  dialogButtonConfirm,
  changeStatusZonaTitleDialog,
  dialogEditZone,
  dialogAddZone,
} from "../../../const/DialogData";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import handlerError from "../../../functions/handlerError";
import { useParams } from "react-router-dom";
import { BoxDialogDisabilita } from "../DisableDialog/DialogDisabilita";
import { ShowImgPlanimetria } from "../../ShowImgPlanimetria/ShowImgPlanimetria";
import { hideLoader, showLoader } from "../../../redux/reducers/appLoader-reducer";
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../../Hooks/useMobileDetection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";

let formData = new FormData();

const urlBase = process.env.REACT_APP_URL;

const ZoneDialog = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [isRenderingDialog, setIsRenderingDialog] = useState(false);
  const [changedRow, setchangedRow] = useState();
  const [radioButtonPlanimetria, setRadioButtonPlanimetria] = useState("mantieni");
  const backButtonState = useSelector((state) => state.dialogBackButton);
  const dialogState = useSelector((state) => state.dialogGeneral);
  const [selectedEdificio, setSelectedEdificio] = useState("");
  const [IdEdificioToBe, setiIdEdificioToBe] = useState(""); //passa il valore
  const [inputRoleSelect, setInputRoleSelect] = useState("");
  const dispatch = useDispatch();
  const { rowInfo, dialogType, dialogPageType } = dialogState;
  const configurations = useSelector((state) => state.configurations.configurazioniGlobali);
  let validationSchema;
  const isMobile = useMobileDetection(950);
  const fileInputRef = useRef(null);
  const appLoadingState = useSelector((state) => state.appLoader.loading);

  if (dialogType === "edit") {
    validationSchema = yup.object({
      denominazione: yup.string().required("Campo Obbligatorio."),
      Sigla: yup.string().required("Campo Obbligatorio."),
      idEdificio: yup.string().required("Campo Obbligatorio."),
    });
  } else if (dialogType === "add") {
    validationSchema = yup.object({
      denominazione: yup.string().required("Campo Obbligatorio."),
      Sigla: yup.string().required("Campo Obbligatorio."),
    });
  }

  let initialValues = {};

  if (dialogType === "edit") {
    initialValues = {
      denominazione: rowInfo?.denominazione,
      Sigla: rowInfo?.sigla,
      idEdificio: rowInfo?.idEdificio,
      idSfondoPlanimetria: rowInfo?.idSfondoPlanimetria,
      nomeImagineSfondoPlanimetria: rowInfo?.nomeImagineSfondoPlanimetria,
    };
  } else if (dialogType === "add") {
    initialValues = {
      denominazione: "",
      Sigla: "",
      idEdificio: "",
      nomeImagineSfondoPlanimetria: "",
      idSfondoPlanimetria: ""
    };
  } else if (dialogType === "delete") {
    initialValues = {
      denominazione: rowInfo?.denominazione,
      Sigla: rowInfo?.sigla,
    };
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true, // rinizializza il valore che gli viene passato con rowInfo
    onSubmit: (values) => {
      if (radioButtonPlanimetria === "modifica" && values.file == null) {
        formik.setFieldError(
          "file",
          configurations.enableImportCadFiles ? t("modify_planimetry_file_cad_format_error") : t("modify_planimetry_file_format_error")
        );
      }
      if (dialogType === "edit") {
        setchangedRow({
          id: values.id,
          denominazione: values.denominazione,
          Sigla: values.Sigla,
          idEdificio: params.idEdificio,
          Image: values.file,
          RadioImageHandler:
            radioButtonPlanimetria === "cancella" ? true : false,
        });
      } else if (dialogType === "add") {
        setchangedRow({
          denominazione: values.denominazione,
          Sigla: values.Sigla,
          idEdificio: params.idEdificio,
          Image: values.file,
        });
      }
    },
  });

  //Gestisce il cambio di Datatable con la Dialog Aperta, la chiude e il dialogPageType impedisce di far partire la chiamata
  useEffect(() => {
    dispatch(closeDialog());
  }, [window.onhashchange]);

  useEffect(() => {
    if (
      dialogState.isOpen &&
      dialogType === "edit" &&
      dialogPageType === dialogPageTypes.zone
    ) {
      (async () => {
        setSelectedEdificio("");
        setIsRenderingDialog(true);

        const { action, method } = getZonaById;
        let url = urlBase + action + rowInfo?.id;

        try {
          const response = await axios({
            method: method,
            url,
          });

          if (response.data.ok) {
            dispatch(reloadingDataTableUsers());
            Object.keys(response.data.result).map((key) => {
              formik.setFieldValue(
                `${[key]}`,
                `${response.data.result[key]}`,
                true
              );
            });
            setSelectedEdificio(response.data.result.edificio);

            setIsRenderingDialog(false);
          } else {
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          }
        } catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(
              notificationOpen({
                message: `${error.response.data.message}`,
                status: "error",
              })
            );
          } else {
            dispatch(
              notificationOpen({
                message: "Operazione fallita: Server non raggiungibile.",
                status: "error",
              })
            );
          }
        }
        setIsRenderingDialog(false);
      })();
    }
  }, [dialogState.isOpen]);

  const closeForm = () => {
    setRadioButtonPlanimetria("mantieni");
    formik.resetForm(initialValues)
    dispatch(closeDialog());
  }

  useEffect(() => {
    if (changedRow != null && formik.isValid) {
      (async () => {
        let action, method, url;
        let formData = new FormData();

        if (dialogType === "add" || dialogType === "edit") {
          Object.keys(changedRow).map((key) => {
            formData.append(`${key}`, changedRow[key]);
          });

          if (dialogType === "edit") {
            action = editZona.action;
            method = editZona.method;
          } else {
            action = addZona.action;
            method = addZona.method;
          }

          url = urlBase + action;
        }
        dispatch(showLoader())
        try {
          const response = await axios({
            method: method,
            url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.data.ok) {
            formik.resetForm(initialValues);
            dispatch(reloadingDataTableUsers());
            dispatch(closeDialog());
            setRadioButtonPlanimetria("mantieni");
          }
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));

          if (error?.response?.data?.errors != undefined) {
            let keys = Object.keys(error?.response?.data?.errors);
            keys.map((key) => {
              formik.setFieldError(key, error?.response?.data?.errors[key]);
            });
          }
        }
        dispatch(hideLoader())
      })();
    }
  }, [changedRow]);

  const deleteRestore = (rowInfo) => {
    if (dialogState.dialogType === "delete") {
      (async () => {
        setIsRenderingDialog(true);

        const { id } = rowInfo;
        const { action, method } = deleteZona;
        let url = urlBase + action;
        dispatch(showLoader())
        try {
          const response = await axios({
            method: method,
            url,
            data: id,
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.data.ok) {
            formik.resetForm(initialValues);
            dispatch(reloadingDataTableUsers());
            setIsRenderingDialog(false);
          }

          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        } catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(
              notificationOpen({
                message: `${error.response.data.message}`,
                status: "error",
              })
            );
          } else {
            dispatch(
              notificationOpen({
                message: "Operazione fallita: Server non raggiungibile.",
                status: "error",
              })
            );
          }
        }
        setIsRenderingDialog(false);
        dispatch(hideLoader())
      })();

      dispatch(closeDialog());
    }
  };


  /* React Mui non ha il textField di tipo input,
  forzandolo, il tasto input trigghera i comportamenti di tutti gli altri tasti,
  per impedirglielo viene fatto il controllo sull'evento  */
  function handleChangeFile(event) {
    if (event?.currentTarget?.files[0]) {
      let filename = event?.currentTarget?.files[0]?.name;
      let filenameExtension = filename.substring(filename.lastIndexOf("."), filename.length);
      if (event?.currentTarget?.files[0]?.type?.startsWith("image")) {
        formik.setFieldValue("file", event.currentTarget.files[0]);
      } else if (configurations.enableImportCadFiles && (filenameExtension.includes("dwg") || filenameExtension.includes("dxf"))) {
        formik.setFieldValue("file", event.currentTarget.files[0]);
      } else {
        formik.setFieldError(
          "file",
          configurations.enableImportCadFiles ? t("planimetry_file_cad_format_error") : t("planimetry_file_format_error")
        );
      }
    }
  }

  useEffect(() => {
    dispatch(closeDialog());
  }, [backButtonState])

  const handlePlanimetriaChange = (event) => {
    setRadioButtonPlanimetria(event.target.value);
  };

  return (
    <>
      {dialogState.dialogType === "delete" && (
        <>
          <Dialog
            open={dialogState.isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={'sm'}
          >
            <DialogTitle>{changeStatusZonaTitleDialog(rowInfo?.dataDel, t)}</DialogTitle>
            <DialogContent className="border-Content-dialog">
              <DialogContentText id="alert-dialog-description">
                {changeStatusZonaTextDialog(rowInfo?.dataDel, t)} <b>{rowInfo?.denominazione}</b> (<b>{rowInfo?.sigla}</b>)?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => dispatch(closeDialog())}>
                {t(dialogButtonConfirm.Cancel)}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteRestore(rowInfo)}
              >
                {t(dialogButtonConfirm.Confirm)}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {(dialogState.dialogType === "add" ||
        dialogState.dialogType === "edit") &&
        !isRenderingDialog && (
          <>
            <Dialog
              open={dialogState.isOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              fullScreen={isMobile}
              maxWidth={'sm'}
            >
              <DialogTitle>
                {dialogState.dialogType === "edit"
                  ? t(dialogEditZone.DialogTitle)
                  : t(dialogAddZone.DialogTitle)}
              </DialogTitle>
              <DialogContent className="border-Content-dialog">
                <FormControl fullWidth>
                  <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        margin="dense"
                        inputProps={{
                          maxLength: 3,
                        }}
                        id="Sigla"
                        label={t("abbreviation")}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.Sigla}
                        onChange={formik.handleChange("Sigla")}
                        error={
                          formik.touched.Sigla && Boolean(formik.errors.Sigla)
                        }
                        helperText={
                          formik.touched.Sigla && formik.errors.Sigla
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        margin="dense"
                        id="denominazione"
                        label={t("denomination")}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.denominazione}
                        onChange={formik.handleChange("denominazione")}
                        error={
                          formik.touched.denominazione &&
                          Boolean(formik.errors.denominazione)
                        }
                        helperText={
                          formik.touched.denominazione &&
                          formik.errors.denominazione
                        }
                      />
                    </Grid>
                    {formik.values.nomeImagineSfondoPlanimetria !== null &&
                      formik.values.nomeImagineSfondoPlanimetria !== undefined &&
                      formik.values.nomeImagineSfondoPlanimetria !== "null" &&
                      formik.values.nomeImagineSfondoPlanimetria !== ""
                      && (
                        <ShowImgPlanimetria idZona={rowInfo?.id} nomeImgPlanimetria={rowInfo?.nomeImagineSfondoPlanimetria} />
                      )}
                    {formik.values.idSfondoPlanimetria !== null &&
                      formik.values.idSfondoPlanimetria !== "" &&
                      formik.values.nomeImagineSfondoPlanimetria !== "null" &&
                      formik.values.idSfondoPlanimetria !== undefined && (
                        <Grid
                          sx={{ margin: "20px 0 2px 0" }}
                          item
                          xs={12}
                          sm={12}
                        >
                          <FormLabel component="legend">
                            <b>{t("Floor_Plan_Management")}</b>
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-label="recoveryEnabled"
                            name="row-radio-group-enabled"
                            onChange={(event) => {
                              handlePlanimetriaChange(event);
                            }}
                            defaultValue="mantieni"
                          >
                            <FormControlLabel
                              value="mantieni"
                              control={<Radio />}
                              label={t("Keep")}
                            />
                            <FormControlLabel
                              value="cancella"
                              control={<Radio />}
                              label={t("Cancel")}
                            />
                            <FormControlLabel
                              value="modifica"
                              control={<Radio />}
                              label={t("edit")}
                            />
                          </RadioGroup>
                        </Grid>
                      )}
                    {(radioButtonPlanimetria === "modifica" ||
                      formik.values.idSfondoPlanimetria === null ||
                      formik.values.idSfondoPlanimetria === "" ||
                      formik.values.idSfondoPlanimetria === "null" ||
                      formik.values.idSfondoPlanimetria === undefined) && (
                        <Grid
                          sx={{ margin: "15px 0 2px 0" }}
                          item
                          container
                          xs={12}
                          sm={12}
                        >
                          <Grid item xs={12} sm={12}>
                            <FormLabel component="legend">
                              <b> {t("Area_plan")}</b>
                            </FormLabel>
                          </Grid>
                          <Grid item xs={2} sm={2}>
                            <IconButton
                              color="primary"
                              aria-label={t("upload_picture")}
                              component="span"
                            >
                              <label htmlFor="file" className="file-label">
                                <FontAwesomeIcon icon={faImage} size="2x" cursor={'pointer'} />
                              </label>
                            </IconButton>
                            <input
                              style={{ display: 'none' }}
                              ref={fileInputRef}
                              margin="dense"
                              id="file"
                              type="file"
                              onChange={(event) => handleChangeFile(event)}
                            />
                          </Grid>
                          <Grid item xs={10} sm={10}>
                            <Typography sx={{ position: 'relative', top: '1.2rem' }}>{formik.values.file?.name}</Typography>
                          </Grid>
                          {radioButtonPlanimetria === "modifica" && (
                            <Grid item xs={12} sm={12}>
                              <Typography
                                fontSize={12}
                                sx={{ paddingTop: "5px" }}
                                color="#3e6bb4"
                              >
                                {t("warning-floorplan")}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      )}
                  </Grid>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => closeForm()}>
                  {t(dialogButtonGeneral.Cancel)}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={appLoadingState}
                  onClick={() => formik.handleSubmit()}
                >
                  {t(dialogButtonGeneral.Confirm)}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      {dialogState.dialogType === "disableRangeData" && (
        <BoxDialogDisabilita isOpen={dialogState.isOpen} dialogType={dialogState.dialogType} rowInfo={rowInfo} closeForm={closeForm} />
      )}
      {dialogState.dialogType === "ripristinaAll" && (
        <BoxDialogDisabilita isOpen={dialogState.isOpen} dialogType={dialogState.dialogType} rowInfo={rowInfo} closeForm={closeForm} />
      )}
    </>
  );
};

export default ZoneDialog;

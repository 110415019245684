import { Typography } from "@mui/material"
import { fontStyle } from "@mui/system"
import React from "react"
import { useTranslation } from "react-i18next";


export default function InfoStanza({infoElement}) {
    const {posto, isSalaRiunione} =  infoElement;
    const { t } = useTranslation();
    const textPostazione = t("workstation") + " ";
    const textSala = t("Meeting_room") + " ";

    return(
        <React.Fragment>
            <Typography sx={{display:"flex", alignItems:"center", justifyContent:"left", padding:"2px 5px 2px", backgroundColor:'#3e6bb4', color: '#F9F9FA'}}><b>
                {isSalaRiunione ?  textSala : textPostazione + posto }
                </b></Typography>
        </React.Fragment>
    )
}
import moment from "moment";

export default function associaInfoToPlaceholderStepper(t,step, stateDati, stateInfoStepper) {

  const { id } = step;
  const { dataGiorno, dataInizio, dataFine, ripetizioneDataFine, ripetizioneGiorni, ripetizioneStatus, utentePrenotazione } = stateDati;
  const { Edificio, Zona, Stanza, Postazione } = stateInfoStepper;


  let field = "";

  switch (true) {
    case id === 0:
      field = "" + moment(dataGiorno).format("DD/MM/YYYY") + " " + dataInizio + " - " + dataFine;
      if (ripetizioneGiorni.length > 0) field += " " + t("(multiple)")
      break;
    case id === 1:
      field = Edificio.sigla + " - " + Zona.sigla;
      break;
    case id === 2:
      field = Stanza.sigla;
      break;
    case id === 3:
      field = Stanza.isSalaRiunione ? t("Meeting_room") : Postazione.posto ? t("workstation") + " " + Postazione.posto : t("workstation");

      let prenotazioneDialogTitle = t("Booking") + (Stanza.isSalaRiunione ? " " + t("meetingRoom_v1") : t("postazione_v2") );
      document.getElementById("alert-dialog-title").innerHTML = prenotazioneDialogTitle;      
      
      break;
      default: break;
    }
    

  return field;
}
import { Typography } from "@mui/material";
import Logo from "../../assests/logo.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Unauthorized() {
    const navigate = useNavigate();
    setTimeout(function() {navigate("/")},1000)
    const { t } = useTranslation(); 
    return(
    <div style={{textAlign: 'center', marginTop:'50px', overflow:"hidden"}}>
    <img src={Logo} width={350}/>
        <Typography variant='h4' mt={1} >{t("SessionExpired")}</Typography>
        <Typography mt={2}>{t("RedirectToLoginPage")}</Typography>
    </div>
    )
}
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next"; // Importa la funzione useTranslation
import { dialogPageTypes } from "../../const/dialogPageTypes";
import { Grid, Divider, Typography } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import TableToolbar from "../UI/TableToolbar/TableToolbar";
import { CDCard, CDCardContent, CDCardHeader } from "../../const/CDCardStyles";
import CheckValidSortModel from "../../functions/CheckValidSortModel";
import { StanzeHeader } from "../../const/DatatableData";
import { getStanzeDataTableByIdZona } from "../../const/urlAction";
import { useSelector, useDispatch } from "react-redux";
import { setPageTypeStanza } from "../../redux/reducers/dialogStanze-reducer";
import StanzeDialog from "../Dialog/StanzeDialog";
import handlerError from "../../functions/handlerError";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import Notification from "../UI/Notification/Notification";
import { useParams } from "react-router-dom";
import PlanimetriaGestione from "../Planimetria/PlanimetriaGestione/PlanimetriaGestione";
import { removeDataGridHeader } from "../../functions/handlerDataGrid";
import usePathTextDetail from "../../Hooks/usePathTextDetail";
import { openEmptyDialog, setPageType } from "../../redux/reducers/DialogGeneral";
import useCheckDisabilitazioniSuPostazioni from "../../Hooks/useCheckDisabilitazioniSuPostazioni";
import { TitleDatable } from "../TitleDatable/TitleDataTable";
import useHeaderDataGridI18n from "../../Hooks/I18n/useHeaderDataGridI18n";
import ButtonBoxStanze from "./ButtonBoxStanze";
import MobileViewList from "../UI/mobileView/list/MobileViewList";
import MobileViewContainer from "../UI/mobileView/mobileViewContainer";
import { rowStructuresEnum } from "../UI/mobileView/enum/rowStructuresEnum";
import { handleDialogToEditStanza } from "./operations/handleDialogEditStanza";
import { useRef } from "react";
import handlePaginationAndRowCleanupMobileView from "../../functions/mobileView/handlePaginationAndRowCleanupMobileView";
import useViewportAdaptedHeight from "../../Hooks/Adaptive/useViewportAdaptedHeight";

const typePage = "Stanze";
const urlBase = process.env.REACT_APP_URL;
const rowPage = 10;

const StanzeDatatable = () => {
  const { t } = useTranslation(); // Inizializza la funzione useTranslation
  const dispatch = useDispatch();
  const params = useParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const viewState = useSelector((state) => state.viewState);
  const reloading = useSelector((state) => state.dataTableUsers);
  const [searchFilter, setSearchFilter] = useState("");
  const [searchStatus, setSearchStatus] = useState(false);
  const lastSearchFilter = useRef(); //determina se deve resettare le row
  const lastPageIndex = useRef(); //determina se deve resettare le row
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const CDCardContentRef = useRef(null);
  const [rows, setRows] = useState([]);
  const [dettaglioZona, setDettaglioZona] = useState({});
  const [rowsCount, setRowsCount] = useState(rows.length);
  const [loading, setLoading] = useState(false);
  const [isMapActive, setIsMapActive] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const header = useHeaderDataGridI18n(
    isMapActive
      ? []
      : StanzeHeader.headers
  );
  const path = usePathTextDetail(params);
  const isDisabilitazioniOnEntity = useCheckDisabilitazioniSuPostazioni();
  const StyledDataGrid = removeDataGridHeader(DataGrid, viewState);

  const handleSearchFilter = (searchText) => {
    setSearchFilter(searchText);
    setSearchStatus(!searchStatus);
    setPage(0);
    setRows([]);
  };

  function changeViewToMap() {
    setIsMapActive(!isMapActive);
    return !isMapActive;
  }

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  });

  const handleResizeWindow = () => {
    if (window.innerWidth > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    let isMounted = true;
    const { sortField, sortOrder } = CheckValidSortModel(
      sortModel,
      StanzeHeader.initialFieldName
    );

    (async () => {
      setLoading(true);
      const { idZona } = params;
      const { action, method } = getStanzeDataTableByIdZona;
      let url = urlBase + action;

      const requestBody = {
        pageIndex: page,
        pageSize: pageSize,
        sortColumnName: sortField,
        sortDirection: sortOrder,
        applyPaginationAndSort: true,
        searchFilter: searchFilter,
        IdZona: idZona,
      };

      const cleanRow = handlePaginationAndRowCleanupMobileView(
        lastSearchFilter.current,
        lastPageIndex.current,
        searchFilter,
        page,
        pageSize,
        requestBody
      );

      try {
        const response = await axios({
          method: method,
          url,
          data: requestBody,
        });
        if (isMounted) {
          setDettaglioZona(response.data.result.dettaglio);
          const newRows = response.data.result.result;
          const newRowsCount = response.data.result.totalFiltered;
          cleanRow || !isMobile && !viewState
            ? setRows(newRows)
            : setRows((oldRows) => [...oldRows, ...newRows]);
          setRowsCount(newRowsCount);
          lastSearchFilter.current = requestBody.searchFilter;
          lastPageIndex.current = requestBody.pageIndex;

          if (!response.data.ok) {
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          }
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
        setLoading(false);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [
    page,
    pageSize,
    sortModel,
    searchStatus,
    searchFilter,
    reloading,
    isMapActive,
  ]);

  const adaptedHeight = useViewportAdaptedHeight(
    ".adaptHeight",
    19,
    CDCardContentRef,
    !loading
  );

  //gestisce il cambio di View su dispositivo desktop tra desktop e mobile
  useEffect(() => {
    if (page != 0 || pageSize != rowPage) setRows([])
    setPage(0)
    setPageSize(rowPage)
  }, [viewState])

  return (
    <>
      {isMobile ? (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding adaptHeight"
                title={
                  <>
                    <TitleDatable
                      nameDataTable={t("rooms")}
                      pathDataTable={path}
                    />
                  </>
                }
              />
              <Divider />
              <CDCardContent
                className="CDCardContent-Mobile small-padding-mobilerow"
                style={{ height: adaptedHeight }}
                ref={CDCardContentRef}
              >
                <Grid container item>
                  <MobileViewContainer>
                    <TableToolbar
                      typePage={t("Stanze")}
                      enableAdd={true}
                      enableSearch={true}
                      enableExport={true}
                      isSalaRiunione={false}
                      map={false}
                      showResetDisable={isDisabilitazioniOnEntity}
                      changeViewToMap={changeViewToMap}
                      handleAdd={() => {
                        dispatch(setPageTypeStanza(dialogPageTypes.stanze));
                        dispatch(openEmptyDialog("add"));
                      }}
                      initialSearchText={searchFilter}
                      startSearch={handleSearchFilter}
                    />
                  </MobileViewContainer>
                  <MobileViewList
                    rows={rows}
                    BoxButton={ButtonBoxStanze}
                    typeStructure={rowStructuresEnum.DataTableRowStructures}
                    handleDialogToEdit={handleDialogToEditStanza}
                    setPage={setPage}
                    areMoreRowsAvailable={(page + 1) * pageSize < rowsCount}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
        </>
      ) : !isMobile && !viewState ? (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding"
                title={
                  <>
                    <TitleDatable
                      nameDataTable={t("rooms")}
                      pathDataTable={path}
                    />
                  </>
                }
              />
              <Divider />
              <CDCardContent>
                <Grid container item>
                  {isMapActive ? (
                    <>
                      <TableToolbar
                        typePage={"planimetriaGestione"}
                        map={true}
                        mobile={false}
                        changeViewToMap={changeViewToMap}
                      />
                      <PlanimetriaGestione dettaglio={dettaglioZona} />
                    </>
                  ) : (
                    <StyledDataGrid
                      rows={rows}
                      rowCount={rowsCount}
                      getRowId={(row) => row.sigla}
                      columns={header}
                      page={page}
                      pageSize={pageSize}
                      onPageChange={(newPage) => setPage(newPage)}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      onSortModelChange={(newSortModel) => {
                        setSortModel(newSortModel);
                      }}
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      disableSelectionOnClick
                      sortModel={sortModel}
                      sortingMode="server"
                      sortingOrder={["asc", "desc"]}
                      paginationMode="server"
                      loading={loading}
                      sx={{ border: 0 }}
                      disableColumnMenu
                      disableColumnFilter
                      disableColumnSelector
                      autoHeight
                      autoWidth
                      components={{
                        Toolbar: () => (
                          <TableToolbar
                            typePage={t("Stanze")}
                            enableAdd={true}
                            enableSearch={true}
                            enableExport={true}
                            isSalaRiunione={false}
                            map={true}
                            showResetDisable={isDisabilitazioniOnEntity}
                            changeViewToMap={changeViewToMap}
                            handleAdd={() => {
                              dispatch(
                                setPageTypeStanza(dialogPageTypes.stanze)
                              );
                              dispatch(openEmptyDialog("add"));
                            }}
                            initialSearchText={searchFilter}
                            startSearch={handleSearchFilter}
                          />
                        ),
                        NoRowsOverlay: () => (
                          <GridOverlay>
                            <Typography>{t("NoRowsOverlay")}</Typography>
                          </GridOverlay>
                        ),
                        NoResultsOverlay: () => (
                          <GridOverlay>
                            <Typography>{t("NoRowsOverlay")}</Typography>
                          </GridOverlay>
                        ),
                      }}
                    />
                  )}
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding adaptHeight"
                title={
                  <>
                    <TitleDatable
                      nameDataTable={t("rooms")}
                      pathDataTable={path}
                    />
                  </>
                }
              />
              <Divider />
              <div className="adaptHeight" style={{ backgroundColor: "white" }}>
                <div style={{ margin: "20px" }}>
                  <TableToolbar
                    typePage={t("Stanze")}
                    enableAdd={true}
                    enableSearch={true}
                    enableExport={true}
                    isSalaRiunione={false}
                    map={false}
                    showResetDisable={isDisabilitazioniOnEntity}
                    changeViewToMap={changeViewToMap}
                    handleAdd={() => {
                      dispatch(setPageTypeStanza(dialogPageTypes.stanze));
                      dispatch(openEmptyDialog("add"));
                    }}
                    initialSearchText={searchFilter}
                    startSearch={handleSearchFilter}
                  />
                </div>
              </div>
              <CDCardContent
                className="CDCardContent-Mobile"
                style={{ height: adaptedHeight }}
                ref={CDCardContentRef}
              >
                <Grid container item>
                  <MobileViewList
                    rows={rows}
                    BoxButton={ButtonBoxStanze}
                    typeStructure={rowStructuresEnum.DataTableRowStructures}
                    handleDialogToEdit={handleDialogToEditStanza}
                    setPage={setPage}
                    areMoreRowsAvailable={(page + 1) * pageSize < rowsCount}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
        </>
      )}
      <Notification />
      <StanzeDialog />
    </>
  );
};

export default StanzeDatatable;

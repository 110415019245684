import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUtentiFilteredWithoutUser } from "../../../const/urlAction";
import handlerError from "../../../functions/handlerError";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { AutocompleteCustom } from "../../UI/AutoComplete/AutoComplete";
import { useTranslation } from "react-i18next";

const urlBase = process.env.REACT_APP_URL;
export function AutocompleteBoxHistoryFilterUtente({dispatchUtente, isActiveRadioButtonView, expanded, startValue={}}) {
    const dispatch = useDispatch();
    const [usersRows, setUsersRows] = React.useState([]);
    const [utente, setUtente] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");
    const { t } = useTranslation(); 
    //recupero lista utenti
    useEffect(() => {
        (async () => {
          const { action, method } = getUtentiFilteredWithoutUser;
          let url = urlBase + action;
          try {
            const response = await axios({
              method: method,
              url,
            });
            setUsersRows(response.data.result);
          } catch (error) {
            console.error(error);
            const notificationError = handlerError(error);
            dispatch(notificationOpen(notificationError));
          }
        })();
      }, []);
    
    const handleValueUsers = (selectedValue) => {
        dispatchUtente({type:"ADD_UTENTE", payload:selectedValue || null})
        setUtente(selectedValue === undefined ? null : selectedValue);
      };


    return (
        <React.Fragment>
           <AutocompleteCustom
          label={t("Filter_by_User")}
          field={["nominativo"]}
          checkField={'idUtente'}
          keyId={'idUtente'}
          fullWidth ={false}
          clean={isActiveRadioButtonView === "true"} 
          errorMsg={errorMsg}
          startValueAutoComplete={startValue}
          optionsAutoComplete={usersRows}
          handleValueReturned={handleValueUsers}
        /> 
        </React.Fragment>
    )
}
export default function checkScroll(ref) {
    let result = {
        verticalScrollRef: false,
        horizontalScrollRef: false,
        timeStamp: new Date()
    };
    
    if (ref?.current) {
        result.verticalScrollRef = ref.current.scrollHeight > ref.current.clientHeight;
        result.horizontalScrollRef = ref.current.scrollWidth > ref.current.clientWidth;
    }

    return result;
}
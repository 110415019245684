
export default function calcoloGrandezzaContainer(containerWithMaxWidth, isMobile,idMainComponent) {
  const parentElement = document.querySelector("#"+`${containerWithMaxWidth}`);
  const larghezzaSchermo = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const altezzaSchermo = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  let larghezzaDialog = 0;
  let altezzaTotaleAltriElementi = 0;

  if (parentElement) {
    const computedStyle = window.getComputedStyle(parentElement);
    larghezzaDialog = parseFloat(computedStyle.width.replace("px", ''));
  

    // Calcolo altri elementi nella dialog
    Array.from(parentElement.children[0].children).forEach(child => {
      if (!child.classList.contains("MuiSnackbar-root")) {  //si aggiunge erroneamente una dialog
        if (!verificaID(child, idMainComponent)) {
          altezzaTotaleAltriElementi += child.offsetHeight;
        }
      }
    });
  }


  const larghezza = parentElement ? larghezzaDialog : larghezzaSchermo
  const altezza = (altezzaSchermo * (isMobile ? 0.9 : 0.8)) - altezzaTotaleAltriElementi; 
  
  const altezzaContainer = (altezza && altezza > 0) ? `${altezza}px` : altezzaSchermo * (isMobile ? 0.9 :0.8);
  const larghezzaContenitore = larghezza && larghezza > 0 ? `${larghezza}px` : "100%";
  

    return (
      [ larghezzaContenitore, altezzaContainer ]
    );
  }

  function verificaID(elemento, id) {
    if (elemento.id === id) return true;
    for (let i = 0; i < elemento.children.length; i++) {
      if (verificaID(elemento.children[i], id)) return true;
    }
    
    return false;
  }
  

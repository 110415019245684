import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

export default function PostazioniDatatableNominativoDevice({ element = {} }) {
  const qrcodeDinamico = useSelector(
    (state) => state.configurations.configurazioniGlobali.qrCodeDinamico
  );

  return (
    <>
      {<Grid container item xs={12} sm={9} md={6} lg={5} xl={4}>
        <Grid item xs={8} sm={10} md={11}>
          <p style={{ fontSize: "15px", margin: "0" }}>
            {element.row.nominativoDevice}
          </p>
        </Grid>
      </Grid>}
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const roomTypesSlice = createSlice({
    name:'roomTypes',
    initialState,
    reducers: {
        saveRoomTypes: (state, payload) => { 
            return payload.payload
         }
    }
})

export const { saveRoomTypes } = roomTypesSlice.actions;

const { reducer } = roomTypesSlice;

export default reducer;
import { useSelector } from "react-redux";
import DialogViewSignInUserActiveDirectory from "../../../../../components/Dialog/UserDialog/componentUserDialog/viewUserDialog/SignInUserView/DialogViewSignInUserActiveDirectory";
import DialogViewSignInUserLocalDatabase from "../../../../../components/Dialog/UserDialog/componentUserDialog/viewUserDialog/SignInUserView/DialogViewSignInUserLocalDatabase";
import { labelArrayDialogUserSignIn } from "../../../../RadioButton/RadioButtonListRadio";

export function ListTabsDialogUserSignIn(
  isCommitted, 
  setIsCommitted
) {
  const authConfiguration = useSelector((state) => state.configurations.configurazioniAutenticazione);
  let arrayTabs = []

  // if(authConfiguration.enableActiveDirectoryAuth) arrayTabs.push({ componentTab: <DialogViewSignInUserActiveDirectory isCommitted={isCommitted} setIsCommitted={setIsCommitted} />, label: labelArrayDialogUserSignIn[0]})
  if(authConfiguration.enableDatabaseAuth) arrayTabs.push({ componentTab: <DialogViewSignInUserLocalDatabase isCommitted={isCommitted} setIsCommitted={setIsCommitted} />, label: labelArrayDialogUserSignIn[1] })
    
  return arrayTabs;
  }
import PaginationMobileView from "../PaginationMobileView/PaginationMobileView";
import MobileViewRow from "../row/MobileViewRow";

export default function MobileViewList({ typeStructure = 0, rows = [], BoxButton = null, handleDialogToEdit = () => null, setPage =  () => null, areMoreRowsAvailable }) {

  return (
    <>
      {rows.map((row, index) => {
        return <MobileViewRow key={index} BoxButton={BoxButton} row={row} typeStructure={typeStructure} handleDialogToEdit={handleDialogToEdit} />;
      })}
      {areMoreRowsAvailable && <PaginationMobileView setPage={setPage} />}
    </>
  );
}

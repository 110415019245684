import React from "react";
import Grid from '@mui/material/Grid';
import { RowPrenotazioneCollegaMobile } from "./RowPrenotazioneCollega/RowPrenotazioneCollega";
import CardPrenotazione from "./CardPrenotazione/CardPrenotazione";
import useMobileDetection from "../../../Hooks/useMobileDetection";
import "./ListaPrenotazioniCollega.styles.css";

export default function ListaPrenotazioniCollega({ prenotazioni }) {

  const isMobile = useMobileDetection(950);

  return (<>
    {prenotazioni.length > 0 && (
      <Grid container spacing={2}>
        {prenotazioni.map((prenotazione, index) => {
          return <Grid item xs={12} sm={6} md={6} key={"prenotazione_key_" + index}>
            {isMobile
              ? <RowPrenotazioneCollegaMobile
                    prenotazione={prenotazione}
                    index={index} />
              : <CardPrenotazione
                    prenotazione={prenotazione}
                    index={index} />
            }
          </Grid>
        })}
      </Grid>)}
  </>);

}
import { rowStructuresEnum } from "../enum/rowStructuresEnum";
import DataTableRowStructures from "../rowStructures/DataTableRowStructures";
import PostazioneRowStructures from "../rowStructures/PostazioneRowStructures";
import UserRowStructures from "../rowStructures/UserRowStructures";

export default function handlerViewStructures(num) {
    let component;
  switch (num) {
    case rowStructuresEnum.DataTableRowStructures:
        component = DataTableRowStructures;
      break;
    case rowStructuresEnum.PostazioneRowStructures:
        component = PostazioneRowStructures;
      break;
    case rowStructuresEnum.UserRowStructures:
      component = UserRowStructures;
      break;
    default:
        console.error("MobileView Type Structure missed")
      break;
  }

  return component;
}

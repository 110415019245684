import { 
    Button,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Grid, 
    Typography, 
    TextareaAutosize, 
    FormControl 
  } from "@mui/material";
  import axios from "axios";
  import * as yup from "yup";
  import { useFormik } from "formik";
  import React, { useState, useRef, useEffect } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { dialogButtonConfirm } from "../../../../../const/DialogData";
  import { urlAbilitaEntita } from "../../../../../functions/UrlParams";
  import { hideLoader, showLoader } from "../../../../../redux/reducers/appLoader-reducer";
  import { reloadingDataTableBuildings } from "../../../../../redux/reducers/dataTableBuildings-reducer";
  import { notificationOpen } from "../../../../../redux/reducers/notification-reducer";
  import { useTranslation } from "react-i18next";
  import { DatapickerBoxDisabilita } from "../../../DisableDialog/DatapickerBoxDisabilita";
  import { typoStyle } from "../../../../../const/Dialog/Disabilita/dialogDisabilitaText";
  import { checkRangeDateDisabilitazione } from "../../../../../functions/checkRangeDate";
  import useMobileDetection from "../../../../../Hooks/useMobileDetection";
  import { textDisableWorkstations } from "../../../../../const/Dialog/Disabilita/dialogDisabilitaText";
  
  const urlBase = process.env.REACT_APP_URL;
  const validationSchema = yup.object({});
  const initialValues = {};
  
  export default function DialogViewBuildingDisable({rowInfo, returnIsOpen, isOpen}) {
  
    const { t } = useTranslation(); 
    const dispatch = useDispatch();
    const isMobile = useMobileDetection(950);
    const textArea = useRef(undefined);
    const [changedRow, setChangedRow] = useState(null);
    const [valueDatapicker, setValueDatapicker] = useState({
      DataOraInizio: undefined,
      DataOraFine: undefined
    });
    const { dialogPageType } = useSelector((state) => state.dialogGeneral);
    const { entita, action, method } = urlAbilitaEntita(dialogPageType, rowInfo.id);
  
    const formik = useFormik({
      initialValues,
      validationSchema: validationSchema,
      enableReinitialize: true,
      onSubmit: (values) => {
        setChangedRow({
          IdEntita: entita,
          DataOraInizioPrenotazione: valueDatapicker.DataOraInizio,
          DataOraFinePrenotazione: valueDatapicker.DataOraFine,
          Note: textArea.current.value.trim() || null
        });
      },
    });
  
    const closeDialog = () => {
      formik.resetForm(initialValues);
      returnIsOpen(false);
    };
  
    useEffect(() => {
      if (changedRow != null) {
        (async () => {
          dispatch(showLoader());
          let url = urlBase + action;
          try {
            const response = await axios({
              method: method,
              url,
              data: changedRow,
              headers: {
                "Content-Type": "application/json",
              },
            });
  
            if (response.data.ok) {
              formik.resetForm(initialValues);
              dispatch(reloadingDataTableBuildings());
              dispatch(closeDialog());
            }
            dispatch(hideLoader());
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          } catch (error) {
            console.error(error);
            dispatch(hideLoader());
            dispatch(
              notificationOpen({
                message: error.response.data.message,
                status: "error",
              })
            );
          }
        })();
      }
    }, [changedRow]);
  
    function disableButton() {}
  
    function returnValueInizio(value) {
      setValueDatapicker({ ...valueDatapicker, DataOraInizio: value });
    }
  
    function returnValueFine(value) {
      setValueDatapicker({ ...valueDatapicker, DataOraFine: value });
    }
  
    let isButtonDisabled = checkRangeDateDisabilitazione(
      valueDatapicker.DataOraInizio,
      valueDatapicker.DataOraFine
    );
  
    useEffect(() => {
      setValueDatapicker({
        DataOraInizio: undefined,
        DataOraFine: undefined,
      });
    }, [isOpen]);
    
    return (
        <>
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            fullScreen={isMobile}
            maxWidth={"sm"}
        >
            <DialogTitle>{t("detail_disabling")}</DialogTitle>
            <DialogContent className="border-Content-dialog">
            <FormControl fullWidth>
                <Grid item sx={{ marginBottom: "5px" }}>
                <div>
                    <Typography sx={typoStyle}>
                      {textDisableWorkstations(t, rowInfo.denominazione)}
                    </Typography>
                </div>
                </Grid>
                <DatapickerBoxDisabilita
                disableButton={disableButton}
                valueInizio={returnValueInizio}
                valueFine={returnValueFine}
                />
                <Grid item sx={{ marginTop: "18px" }}>
                <label>
                    <Typography className="labelAutoCompletePills" sx={{ marginBottom: "2px" }}>
                    {t("annotation")}
                    </Typography>
                </label>
                <TextareaAutosize
                    ref={textArea}
                    minRows={6}
                    maxRows={6}
                    placeholder={t("note_placeholder")}
                    style={{ width: "99%" }}
                />
                </Grid>
            </FormControl>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => closeDialog()}>
                {t(dialogButtonConfirm.Cancel)}
            </Button>
            <Button
                type="submit"
                variant="contained"
                disabled={isButtonDisabled}
                onClick={() => formik.handleSubmit()}
            >
                {t("confirm")}
            </Button>
            </DialogActions>
        </Dialog>
        </>
    );
  }
  
import { idTipologiaStanzaToKeyI18n } from "../../const/I18n/tipoStanzaKeyI18n";

export default function convertTipoStanzaToKeysI18n(idTipoStanza) {
    return idTipologiaStanzaToKeyI18n[idTipoStanza]

}






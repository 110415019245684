export default function SetActionOnDisponibilita(giorno,time,type,id) {
  const {edificio,zona,stanza,postazione} = sortIdType(type,id);
let inizio = time[0]
let fine = time[1]

  let body = {
    GiornoInizio: giorno,
    OraInizio:inizio.toString(),
    OraFine:fine.toString(),
    IdEdificio: edificio || null,
    IdZona: zona || null,
    IdStanza: stanza || null,
  };


  return body;
}

function sortIdType(type, id) {
  let edificio = null ; let zona = null; let stanza = null;let postazione = null;

  switch (true) {
    case type === 1:
      edificio = id;
      break;
    case type === 2:
      zona = id;
      break;
    case type === 3:
      stanza = id;
      break;
    case type === 4:
      postazione = id;
      break;
    default:
      break;
  }

  return {edificio,zona,stanza,postazione};
}

import moment from "moment";
import numberToDay from "./numerToDay";
import { i18n } from "react-i18next"; 

export default function isEdificioOpenInOrarioPrenotazione(t,edificio,dataGiorno,dataInizio,dataFine ) {
    let result = false
    let msg = ""

    // Controllo sugli orari 
    const baseDate = new Date(null); // Data di riferimento (1 Gennaio 1970) 
    const dateApertura = new Date(baseDate);
    const dateChiusura = new Date(baseDate);

     // Controllo sul giorno della settimana 
     const datePrenInizio = new Date(dataGiorno); 
     let dayOfWeekPren = datePrenInizio.getDay();
    if (dayOfWeekPren === 0) dayOfWeekPren = 7; 
    const orarioEdificio = edificio.orariAperturaEdificio.find(d => d.idGiornoSettimana === dayOfWeekPren); 
    let dayOfWeek = numberToDay(dayOfWeekPren)
    if(!orarioEdificio || !orarioEdificio.apertura) return [false, msg = t("msg_building_edificio_2") +  t(dayOfWeek)];  

    // Estrai ora e minuti
    const [oraInizio, minutiInizio] = dataInizio.split(":");
    const [oraFine, minutiFine] = dataFine.split(":"); 
    
    const dateFine = new Date(baseDate); dateFine.setHours(parseInt(oraFine), parseInt(minutiFine), 0, 0); 
    const dateInizio = new Date(baseDate); dateInizio.setHours(parseInt(oraInizio), parseInt(minutiInizio), 0, 0);
    // Estrai solo ora e minuti dagli orari di apertura e chiusura dell'edificio 
    
    const [oraApertura, minutiApertura] = orarioEdificio.orarioApertura.split(":").slice(0, 2);
    const [oraChiusura, minutiChiusura] = orarioEdificio.orarioChiusura.split(":").slice(0, 2); 
    
    dateApertura.setHours(parseInt(oraApertura), parseInt(minutiApertura), 0, 0); 
    dateChiusura.setHours(parseInt(oraChiusura), parseInt(minutiChiusura), 0, 0); 
    
    // Verifica se la prenotazione rientra negli orari di apertura 
    result = dateInizio >= dateApertura && dateFine <= dateChiusura;
    msg = result ? "" : t("msg_building_edificio") + `${moment(dateApertura).format("HH:mm")} - ${moment(dateChiusura).format("HH:mm") })`
    return [result,msg]
}
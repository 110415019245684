import { SupportedLanguage } from "../../const/I18n/SupportedLanguage";


//converte dal formato del BE al formato che vuole react-i18n
export function getLocalizedString(culture) {
    switch (culture) {
      case SupportedLanguage.enUS:
        return 'en';
      case SupportedLanguage.it:
        return 'it';
      default:
        return 'it';
    }
  }
  

//converte dal formato di react-i18n al formato del BE
  export function getLocalizedStringToBe(culture) {
    switch (culture) {
      case 'en':
        return  SupportedLanguage.enUS;
      case 'it':
        return  SupportedLanguage.it;
      default:
        return SupportedLanguage.it;
    }
  }
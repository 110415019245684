import axios from "axios";
import { Grid, Tooltip, Button, DialogTitle } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
    GetPartecipantiRiunioneByPrenotazioneId, DeletePartecipanteRiunione,
    SincronizzaNotificaRiunione, AddPartecipanteRiunione
} from "../../../../../const/urlAction";
import ContainerPills from "../../../../UI/ContainerPills/ContainerPills";
import PartecipantiPills from "../../../../UI/ContainerPills/Pills/PartecipantiPills";
import TextPartecipantiRiunione from "../../../../Planimetria/PlanimetriaPrenotazione/PartecipantiRiunione/TextPartecipantiRiunione";
import useMobileDetection from "../../../../../Hooks/useMobileDetection";
import { useTranslation } from "react-i18next";

const now = new Date();
const regex = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';

const urlBase = process.env.REACT_APP_URL;
export default function Riunione({
    id,
    titoloRiunione,
    dataFinePrenotazione
}) {

    const [isLoading, setIsLoading] = useState();
    const [partecipantiList, setPartecipantiList] = useState([]);
    const [saveEvent, setSaveEvent] = useState(undefined);
    const [reload, setReload] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [isDisable, setIsDisable] = useState(true);
    const isEditable = new Date(dataFinePrenotazione) > now
    const isMobile = useMobileDetection(950);
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            setUpdating(false);

            const { action, method } = GetPartecipantiRiunioneByPrenotazioneId;
            let url = urlBase + action + id;

            try {
                const response = await axios({
                    method: method,
                    url,
                });
                if (response.data.ok) {
                    setPartecipantiList(response.data.result)
                }
            } catch (error) {
                console.error(error);
            }
            setIsLoading(false);
        })();
    }, [updating])

    function returnPartecipantiListText(value) {
        var found = partecipantiList.some(el => el.nominativo === value);
        if (!found) {
            (async () => {
                setIsLoading(true)
                setUpdating(false);
                const { action, method } = AddPartecipanteRiunione;
                let url = urlBase + action;

                const requestBody = {
                    nominativo: value,
                    idPrenotazione: id,
                };

                try {
                    const response = await axios({
                        method: method,
                        url,
                        data: requestBody,
                    });
                    if (response.data.ok) {
                        setUpdating(!updating)
                    }
                } catch (error) {
                    console.error(error);
                }
                setIsLoading(false);
            })();

        }

    }

    useEffect(() => {

        if (partecipantiList.filter(e => e.nominativo.match(regex) && !e.notificaInvitoSpedita).length > 0) {
            setIsDisable(false)
        } else {
            setIsDisable(true)
        }


    }, [partecipantiList])

    function sincronizzaPartecipanti() {
        (async () => {
            setIsLoading(true)
            setUpdating(false);
            const { action, method } = SincronizzaNotificaRiunione;
            let url = urlBase + action + id;

            try {
                const response = await axios({
                    method: method,
                    url,
                });
                if (response.data.ok) {
                    setUpdating(!updating)
                    setPartecipantiList([])
                }
            } catch (error) {
                console.error(error);
            }
            setIsLoading(false);
        })();
    }

    function handleDelete(value, idPartecipante) {
        (async () => {
            const { action, method } = DeletePartecipanteRiunione;
            let url = urlBase + action + idPartecipante;

            try {
                const response = await axios({
                    method: method,
                    url,
                });
                if (response.data.ok) {
                    setUpdating(!updating)
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }


    return (
        <Grid container className="flex-center-center" sx={{ minWidth: isMobile ? "" : "500px" }}>
            <Grid item xs={10}>
                <DialogTitle textAlign="center">{titoloRiunione ? titoloRiunione : "Riunione"}</DialogTitle>
            </Grid>
            {isEditable &&
                <>
                    <Grid item xs={8} sm={8} md={8} lg={8}>
                        <TextPartecipantiRiunione
                            returnValueFromText={returnPartecipantiListText}
                            eventButton={saveEvent} />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                        <Tooltip title={t("Handle_Add")}>
                            <span>
                                <SendIcon className="buttonSincro" onClick={(e) => setSaveEvent(e)} />
                            </span>
                        </Tooltip>
                    </Grid>
                </>}
            <Grid item xs={10}>
                <ContainerPills
                    params={{ Component: PartecipantiPills, handleDelete, isEditable }}
                    element={partecipantiList}
                    noElementsString={t("No_participants")}
                />
            </Grid>
            {isEditable && <Grid className="flex-bottom-left" item xs={6} sm={6} md={6} lg={6}>
                <Button
                    className="button-paddingLeft"
                    variant="contained"
                    disabled={isDisable}

                    onClick={() => sincronizzaPartecipanti()}>
                    Invia Notifica Partecipanti
                </Button>
            </Grid>}
        </Grid>
    )
}
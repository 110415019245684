import { Chip, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOspitiFromPlanimetriaStepper,
  deleteOspitiPrenotazioneInfo,
} from "../../../../../../redux/reducers/prenotazioneStepper-reducer";

const idElementChipOspite = "chipOspite";

export default function PannelloOspiti() {
  const stateDati = useSelector((state) => state.prenotazioneStepper);
  const { ospitiPrenotazioneInfo } = stateDati;
  const dispatch = useDispatch();
  const refBoxPills = useRef();
  const { t } = useTranslation(); 

  function handleDelete(el) {
    dispatch(
      deleteOspitiFromPlanimetriaStepper({
        nominativo: el.nominativo,
        ospite: true,      // sono solo ospiti quelli gestiti in questo pannello
        utente: el.utente,
      })
    );
  }

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Box
          className={`boxElementi-Planimetria boxElementi-Planimetria-overflow ${
            refBoxPills?.current?.scrollWidth <=
              refBoxPills?.current?.clientWidth && "flex-center-center"
          }`}
          ref={refBoxPills}
          sx={{ width: "100%" }}
        >
          {ospitiPrenotazioneInfo.length <= 0 && 
          <Typography style={{opacity: "50%"}}>{t("booking_guest_panel")}</Typography>
          }
          {ospitiPrenotazioneInfo.map((x, index) => {
            return (
              <Chip
                size="small"
                id={idElementChipOspite}
                key={index}
                label={x.nominativo}
                onDelete={() => {
                  handleDelete(x);
                }}
              />
            );
          })}
        </Box>
      </Grid>
    </>
  );
}

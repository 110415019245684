import { Grid, Typography } from "@mui/material";
import { padding } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ContainerPills({
  params = undefined,
  element = [],
  noElementsString
}) {
  const [reload, setReload] = useState(false);
  if (!params) console.error("Component non implementato");
  const { Component, handleDelete, isEditable } = params;
  useEffect(() => {
    setReload(!reload);
  }, [element])

  return (
    <>
      <Grid container>
        <Grid item mb={2} className="boxElementi-SuggerimentoPrenotazione" sx={{ minHeight: "100px", display: element.length === 0 ? "flex" : "block", cursor: "default" }}>
          {element.length === 0 && <Typography className="element-msg-containerPills" fontSize={14}>{noElementsString}</Typography>}
          {element.map((el, index) => {
            return <div key={index} style={{ margin: "3px", display: "inline-block" }}>
              <Component label={el} index={index} handleDelete={handleDelete} isEditable={isEditable} />
            </div>;
          })}
        </Grid>
      </Grid>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next"; // Aggiunto import per la traduzione
import { dialogPageTypes } from "../../const/dialogPageTypes";
import { Grid, Button, Divider, Typography, Tooltip } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import TableToolbar from "../UI/TableToolbar/TableToolbar";
import { styled } from "@mui/material/styles";
import { CDCard, CDCardContent, CDCardHeader } from "../../const/CDCardStyles";
import CheckValidSortModel from "../../functions/CheckValidSortModel";
import { UsersHeader, UsersHeaderMobile } from "../../const/DatatableData";
import {
  getUsersPaginated,
  exportUsers,
  getUserRoles,
} from "../../const/urlAction";
import AdvancedFilterPanel, {
  AdvancedFilterScissor,
  MenuCollapse,
} from "./AdvancedFilterPanel";
import { useSelector, useDispatch } from "react-redux";
import {
  openEmptyDialogUser,
  setPageType,
} from "../../redux/reducers/dialogUser-reducer";
import UserDialog from "../Dialog/UserDialog/UserDialog";
import handlerError from "../../functions/handlerError";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import { loadUserRolesState } from "../../redux/reducers/userRolesSlice-reducer";
import Notification from "../UI/Notification/Notification";
import { getUsers } from "../../const/urlAction";
import { loadUsersState } from "../../redux/reducers/usersSlice-reducer";
import { removeDataGridHeader } from "../../functions/handlerDataGrid";
import AddIcon from "@mui/icons-material/Add";
import { TitleDatable } from "../TitleDatable/TitleDataTable";
import SynchronizeButton from "./customButton/SynchronizeButton";
import useTaskAsyncRun from "../../Hooks/TaskAsync/useTaskAsyncRun";
import useHeaderDataGridI18n from "../../Hooks/I18n/useHeaderDataGridI18n";
import MobileViewContainer from "../UI/mobileView/mobileViewContainer";
import MobileViewList from "../UI/mobileView/list/MobileViewList";
import ButtonBoxUsers from "./ButtonBoxUsers";
import { rowStructuresEnum } from "../UI/mobileView/enum/rowStructuresEnum";
import { handleDialogToEditUser } from "./operations/handleDialogToEditUser";
import { useRef } from "react";
import handlePaginationAndRowCleanupMobileView from "../../functions/mobileView/handlePaginationAndRowCleanupMobileView";
import useViewportAdaptedHeight from "../../Hooks/Adaptive/useViewportAdaptedHeight";

const rowHeight = 120;
const mobileLimitWidth = 1000;
const typePage = "Utenti";
const urlBase = process.env.REACT_APP_URL;
const rowPage = 10;

const UsersDatatable = () => {
  const dispatch = useDispatch();
  const viewState = useSelector((state) => state.viewState);
  const user = useSelector((state) => state.userDate);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const state = useSelector((state) => state.userDate.policies);
  const reloading = useSelector((state) => state.dataTableUsers);
  const lastSearchFilter = useRef(); //determina se deve resettare le row
  const lastPageIndex = useRef(); //determina se deve resettare le row
  const [searchFilter, setSearchFilter] = useState("");
  const [searchStatus, setSearchStatus] = useState(false);
  const [page, setPage] = useState(0);
  const CDCardContentRef = useRef(null);
  const [pageSize, setPageSize] = useState(rowPage);
  const [rows, setRows] = useState([]);
  const [expandedState, setExpandedState] = useState(false);
  const [rowsCount, setRowsCount] = useState(rows.length);
  const [loading, setLoading] = useState(false);
  const header = useHeaderDataGridI18n(UsersHeader.headers);
  const [sortModel, setSortModel] = useState([
    {
      field: UsersHeader.initialFieldName,
      sort: UsersHeader.initialFieldOrder,
    },
  ]);
  const [filterParameters, setFilterParameters] = useState(true);
  const [filterDomainAuth, setFilterDomainAuth] = useState(null);

  const { t } = useTranslation(); // Aggiunto hook per la traduzione

  const StyledDataGrid = removeDataGridHeader(DataGrid, viewState);

  const handleFilterData = (radioUserEnabled, radioUserDomainAuth) => {
    setFilterParameters(JSON.parse(radioUserEnabled));
    setFilterDomainAuth(JSON.parse(radioUserDomainAuth));
  };

  const handleSearchFilter = (searchText) => {
    setSearchFilter(searchText);
    setSearchStatus(!searchStatus);
  };

  const handleResizeWindow = () => {
    if (window.innerWidth > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };


  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  });

  useEffect(() => {
    let isMounted = true;
    const { sortField, sortOrder } = CheckValidSortModel(
      sortModel,
      UsersHeader.initialFieldName
    );

    (async () => {
      setLoading(true);
      const { action, method } = getUsersPaginated;
      let url = urlBase + action;

      const requestBody = {
        pageIndex: page,
        pageSize: pageSize,
        sortColumnName: sortField,
        sortDirection: sortOrder,
        applyPaginationAndSort: true,
        searchFilter: searchFilter,
        radioUserEnabled: filterParameters,
        radioUserDomainAuth: filterDomainAuth
      };

      const cleanRow = handlePaginationAndRowCleanupMobileView(lastSearchFilter.current,lastPageIndex.current,searchFilter,page,pageSize,requestBody)

      try {
        const response = await axios({
          method: method,
          url,
          data: requestBody,
        });
        if (isMounted) {
          const newRows = response.data.result.result;
          const newRowsCount = response.data.result.totalFiltered;
          cleanRow || !isMobile && !viewState ? setRows(newRows) : setRows(oldRows => [...oldRows, ...newRows]);
          setRowsCount(newRowsCount);
          lastSearchFilter.current = requestBody.searchFilter;
          lastPageIndex.current = requestBody.pageIndex;
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
        setLoading(false);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [
    page,
    pageSize,
    sortModel,
    searchStatus,
    searchFilter,
    reloading,
    filterParameters,
    filterDomainAuth
  ]);

  useEffect(() => {
    (async () => {
      const { action, method } = getUsers;
      let url = urlBase + action;
      const requestBody = {};
      try {
        const response = await axios({
          method: method,
          url,
          data: requestBody,
        });

        if (response.data.ok) {
          dispatch(loadUsersState(response.data.result));
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
  }, []);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const { action, method } = getUserRoles;
      let url = urlBase + action;
      const requestBody = {};
      try {
        const response = await axios({
          method: method,
          url,
          data: requestBody,
        });

        if (response.data.ok && isMounted) {
          dispatch(loadUserRolesState(response.data.result));
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setExpandedState(false);
  }, [isMobile]);

  const adaptedHeight = useViewportAdaptedHeight(".adaptHeight", 19 , CDCardContentRef, !loading, [viewState, isMobile]);

  //gestisce il cambio di View su dispositivo desktop tra desktop e mobile
  useEffect(() => {
    if(page != 0 || pageSize != rowPage) setRows([])
    setPage(0)
    setPageSize(rowPage)
  },[viewState])

  return (
    <>
      {isMobile ? (
        <>
          <Grid container variant="No-Padding">
            <Grid item component={CDCard} xs={12} sm={12} >
              <CDCardHeader
                className="CDCardHeader-Padding adaptHeight"
                title={
                  <>
                    <TitleDatable nameDataTable={t("user_list")} />
                  </>
                }
              />
              <Divider />
              <CDCardContent className="CDCardContent-Mobile small-padding-mobilerow" style={{height: adaptedHeight}} ref={CDCardContentRef}>
                <Grid container item >
                  <MobileViewContainer >
                    <TableToolbar
                      typePage={typePage}
                      enableAdd={true}
                      customButton={<SynchronizeButton />}
                      isSalaRiunione={false}
                      enableSearch={true}
                      enableExport={true}
                      handleAdd={() => {
                        dispatch(setPageType(dialogPageTypes.user));
                        dispatch(openEmptyDialogUser("add"));
                      }}
                      initialSearchText={searchFilter}
                      startSearch={handleSearchFilter}
                    />
                  </MobileViewContainer>
                  <MobileViewList
                    rows={rows}
                    BoxButton={ButtonBoxUsers}
                    typeStructure={rowStructuresEnum.UserRowStructures}
                    handleDialogToEdit={handleDialogToEditUser}
                    setPage={setPage}
                    areMoreRowsAvailable={(page + 1) * pageSize < rowsCount}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
        </>
      ) : !isMobile && !viewState ? (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding"
                title={
                  <>
                    <TitleDatable nameDataTable={t("user_list")} />
                  </>
                }
              />
              <Divider />
              <CDCardContent>
                <AdvancedFilterPanel
                  handleFilterData={handleFilterData}
                  startValueRadioButton={filterParameters}
                  startValueFilterAuth={filterDomainAuth}
                />
                <Divider sx={{ mb: 2 }} />
                <Grid container item>
                  <StyledDataGrid
                    rows={rows}
                    rowCount={rowsCount}
                    getRowId={(row) => row.username}
                    columns={header}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) =>
                      setSortModel(newSortModel)
                    }
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    sortingMode="server"
                    sortingOrder={["asc", "desc"]}
                    paginationMode="server"
                    loading={loading}
                    sx={{ border: 0 }}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    autoHeight
                    autoWidth
                    components={{
                      Toolbar: () => (
                        <TableToolbar
                          typePage={typePage}
                          enableAdd={true}
                          customButton={<SynchronizeButton />}
                          isSalaRiunione={false}
                          enableSearch={true}
                          enableExport={true}
                          handleAdd={() => {
                            dispatch(setPageType(dialogPageTypes.user));
                            dispatch(openEmptyDialogUser("add"));
                          }}
                          initialSearchText={searchFilter}
                          startSearch={handleSearchFilter}
                        />
                      ),
                      NoRowsOverlay: () => (
                        <GridOverlay>
                          <Typography>{t("no_items")}</Typography>{" "}
                        </GridOverlay>
                      ),
                      NoResultsOverlay: () => (
                        <GridOverlay>
                          <Typography>{t("no_items")}</Typography>{" "}
                        </GridOverlay>
                      ),
                    }}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
          <GenericServiceComponent />
        </>
      ) : (
        <>
          <Grid container variant="No-Padding">
            <Grid item component={CDCard} xs={12} sm={12} >
              <CDCardHeader
                className="CDCardHeader-Padding adaptHeight"
                title={
                  <>
                    <TitleDatable nameDataTable={t("user_list")} />
                  </>
                }
              />
              <Divider />
              <div  className="adaptHeight" style={{ backgroundColor: "white" }}>
                <div style={{ margin: "20px" }}>
                <TableToolbar
                      typePage={typePage}
                      enableAdd={true}
                      customButton={<SynchronizeButton />}
                      isSalaRiunione={false}
                      enableSearch={true}
                      enableExport={true}
                      handleAdd={() => {
                        dispatch(setPageType(dialogPageTypes.user));
                        dispatch(openEmptyDialogUser("add"));
                      }}
                      initialSearchText={searchFilter}
                      startSearch={handleSearchFilter}
                    />
                </div>
                </div>
              <CDCardContent className="CDCardContent-Mobile" style={{height: adaptedHeight}} ref={CDCardContentRef}>
                <Grid container item >
                  <MobileViewList
                    rows={rows}
                    BoxButton={ButtonBoxUsers}
                    typeStructure={rowStructuresEnum.UserRowStructures}
                    handleDialogToEdit={handleDialogToEditUser}
                    setPage={setPage}
                    areMoreRowsAvailable={(page + 1) * pageSize < rowsCount}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
        </>
      )}
      <Notification />
      <UserDialog />
    </>
  );
};

export default UsersDatatable;

function GenericServiceComponent() {
  useTaskAsyncRun();
  return null;
}

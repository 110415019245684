import React, { useEffect } from "react"
import { DayButton } from "../../Dashboard/Stepper/stepperComponent/DayRipetizioniBox/DayButton";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DayReminderButton } from "./DayReminderButton";
import { useState } from "react";
import axios from "axios";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import handlerError from "../../../functions/handlerError";
import { enableDisableDailyReminder, getInfoAccount } from "../../../const/urlAction";
import useWeekDayArrayI18nEuropeanSunday from "../../../Hooks/I18n/useWeekDayArrayI18nEuropeanSunday";

const urlBase = process.env.REACT_APP_URL;

export default function DayReminderBox({ fetchInfoAccount, giorniSelezionati, setGiorniSelezionati, backupGiorniSelezionati, setBackupGiorniSelezionati }) {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const buttonValue = useWeekDayArrayI18nEuropeanSunday(t);

    const returnValueFromButton = (newValue) => {

        const { statusButton, value } = newValue;

        statusButton ? setGiorniSelezionati((old) => [...old, value])
            : setGiorniSelezionati(giorniSelezionati.filter(giorni => giorni !== value))

        if (value) {
            (async () => {
                const { action, method } = enableDisableDailyReminder;
                let url = urlBase + action + value;
                try {
                    const response = await axios({
                        method: method,
                        url
                    });

                    statusButton ? setBackupGiorniSelezionati((old) => [...old, value])
                        : setBackupGiorniSelezionati(backupGiorniSelezionati.filter(giorni => giorni !== value))

                } catch (error) {

                    setGiorniSelezionati(backupGiorniSelezionati);

                    console.error(error);
                    const notificationError = handlerError(error);
                    dispatch(notificationOpen(notificationError));
                }

            })();
        }

    }


    if (giorniSelezionati) {
        return (
            <React.Fragment>
                {buttonValue.map((value, index) => {
                    let activated = giorniSelezionati.includes(value.value)
                    return <DayReminderButton key={index} buttonValue={value} isActivated={activated} method={returnValueFromButton} state={giorniSelezionati} />
                })}

            </React.Fragment>
        )
    } else {
        return <></>
    }
}
import { Chip } from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const regex = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'

export default function PartecipantiPills({
    label = undefined,
    index = undefined,
    handleDelete = () => null,
    isEditable = false
}) {

    if(!label) console.error(" la label nel component chip non è definita")
    if(index == undefined) console.error("l'ordinamento non è definito nel component chip")


    if ( typeof label == "string") {
     return (
       
        <Chip
            variant="outlined"
            color="primary"
            size="small"
            label={label}
            onDelete={isEditable ? () => handleDelete(index): "" }
            icon={label.match(regex) ? <MailIcon/> : <AccountCircleIcon />}
        />)
    } else {
        return (
            <Chip
                variant="outlined"
                color="primary"
                size="small"
                label={label.nominativo}
                onDelete={isEditable ? () => handleDelete(index, label.id) : ""}
                icon={label.nominativo.match(regex) ? <MailIcon/> : <AccountCircleIcon />}
            />)
    }
}
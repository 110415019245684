import ButtonBoxUsers from "../components/UsersDatatable/ButtonBoxUsers";
import ButtonBoxZone from "../components/ZoneDatatable/ButtonBoxZone";
import ButtonBoxStanze from "../components/StanzeDatatable/ButtonBoxStanze";
import ButtonBoxPostazioni from "../components/PostazioniDatatable/ButtonBoxPostazioni";
import { Grid, Typography } from "@mui/material";
import './DatatableData.css'
import DoneIcon from '@mui/icons-material/Done';
import ButtonBoxBuilding from '../components/BuildingDataTable/ButtonBoxBuilding';
import ButtonBoxRichiesteCredenziali from "../components/RichiesteAccesso/RichiestaCredenzialiAccesso/RichiesteAccessoComponent/ButtonBoxRichiesteCredenziali";
import PostazioniDatatableNominativoDevice from "../components/PostazioniDatatable/PostazioniDatatableComponent/PostazioniDatatableNominativoDevice";

import CustomRowContent from "../components/UI/DataGrid/CustomRowContent";
import convertTipoStanzaToKeysI18n from "../functions/DataTable/convertTipoStanzaToNameDataTable";
import { t } from "i18next";

const minWidthColumn = 160;
const minWidthColumnWithButton = 250;
const spaceColumnEllipsis = 10

export const RichiesteAccessoHeader = {
  initialFieldName: "dateRequest",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "dateRequest",
      headerName: "requestDate",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "username",
      headerName: "emailUsername",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "lastName",
      headerName: "lastName",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "firstName",
      headerName: "firstName",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "email",
      headerName: "email",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "userAction",
      headerName: "",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumnWithButton,
      getActions: (cell) => {
        return [
          <ButtonBoxRichiesteCredenziali
            activeRow={cell.id}
            rowInfo={cell.row}
          />,
        ];
      },
      headerAlign: "center",
      align: "right",
    },
  ],
};

export const StanzeHeader = {
  initialFieldName: "Sigla",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "sigla",
      headerName: "abbreviation",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "denominazione",
      headerName: "designation",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "Tipologia Stanza",
      type: "actions",
      headerName: "typeRoom",
      minWidth: minWidthColumnWithButton,
      flex: 1,
      resizable: false,
      editable: false,
      getActions: (x) => {
        const { idTipologiaStanza } = x.row;
        let result = convertTipoStanzaToKeysI18n(idTipologiaStanza)
        return [<Typography className="typo-room-datatable">{t(result.titleDataTable)}</Typography>];
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "planimetria",
      type: "actions",
      headerName: "floorPlan",
      minWidth: minWidthColumn,
      flex: 1,
      resizable: false,
      editable: false,
      getActions: (x) => {
        const { idSfondoPlanimetria } = x.row;
        //tabIndex permette di identificare quale Row è stata chiamata nel dataTable
        return [<>{idSfondoPlanimetria ? <DoneIcon /> : ""}</>];
      },
      headerAlign: "center",
      align: "center",
    },

    {
      field: "stanzeActions",
      type: "actions",
      headerName: "",
      minWidth: minWidthColumnWithButton,
      flex: 1,
      resizable: false,
      editable: false,
      getActions: (x) => {
        //tabIndex permette di identificare quale Row è stata chiamata nel dataTable
        return [<ButtonBoxStanze row={x.row} />];
      },
      headerAlign: "center",
      align: "right",
    },
  ],
};

export const UsersHeader = {
  initialFieldName: "username",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "lastName",
      headerName: "lastName",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "firstName",
      headerName: "firstName",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "email",
      headerName: "email",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "roles",
      headerName: "roles",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
      valueGetter: (cell) => {
        let arrayRoles = cell.row.roles.map((x) => {
          let word = x.description.split(/(?=[A-Z])/);
          return word.join(" ");
        });
        return arrayRoles.join(", ");
      },
    },
    {
      field: "userAction",
      headerName: "",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumnWithButton,
      getActions: (cell) => {
        return [<ButtonBoxUsers row={cell.row} />];
      },
      headerAlign: "center",
      align: "right",
    },
  ],
};

export const ZoneHeader = {
  initialFieldName: "Sigla",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "sigla",
      headerName: "abbreviation",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "denominazione",
      headerName: "designation",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "planimetria",
      type: "actions",
      headerName: "floorPlan",
      minWidth: minWidthColumn,
      flex: 1,
      resizable: false,
      editable: false,
      getActions: (x) => {
        const { idSfondoPlanimetria } = x.row;
        //tabIndex permette di identificare quale Row è stata chiamata nel dataTable
        return [<>{idSfondoPlanimetria ? <DoneIcon /> : ""}</>];
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "zoneAction",
      type: "actions",
      headerName: "",
      minWidth: minWidthColumnWithButton,
      flex: 1,
      resizable: false,
      editable: false,
      getActions: (x) => {
        //tabIndex permette di identificare quale Row è stata chiamata nel dataTable
        return [<ButtonBoxZone row={x.row} />];
      },
      headerAlign: "center",
      align: "right",
    },
  ],
};

export const PostazioniHeader = {
  initialFieldName: "posto",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "posto",
      headerName: "workstation",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
      renderCell: (x) => {
        return (
          <Grid container item xs={12} sm={9} md={6} lg={5} xl={4}>
            <Grid item xs={8} sm={10} md={11}>
              <p style={{ fontSize: "15px", margin: "0" }}>
                <CustomRowContent  posto={x.row.posto} tipoStanza={x.row.idTipologiaStanza} />
              </p>
            </Grid>
          </Grid>
        );
      },
      headerAlign: "left",
      align: "left",
    },
    {
      field: "nomeDevice",
      headerName: "deviceName",
      flex: 2,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
      renderCell: (x) => {
        return <PostazioniDatatableNominativoDevice element={x} />;
      },
      headerAlign: "left",
      align: "left",
    },
    {
      field: "riservata",
      headerName: "reservedFor",
      type: "actions",
      flex: 3,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
      align: "left",
      getActions: (cell) => {
        return [
          <>
            {cell.row.utentiPostazione.map((x) => x.nomeUtente).join(", ")}
          </>
        ];
      },
    },
    {
      field: "dotazioni",
      headerName: "equipment",
      type: "actions",
      flex: 3,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
      align: "left",
      getActions: (cell) => {
        return [
          <>
            {cell.row.dotazioni.map((x) => x.descrizione).join(", ")}
          </>
        ];
      },
    },
    {
      field: "postazioniActions",
      type: "actions",
      headerName: "",
      minWidth: minWidthColumnWithButton,
      flex: 1,
      resizable: false,
      editable: false,
      getActions: (x) => {
        //tabIndex permette di identificare quale Row è stata chiamata nel dataTable
        return [
          <ButtonBoxPostazioni
            activeRow={x.id}
            row={x.row}
            isSalaRiunione={x.row.isSalaRiunione}
          />,
        ];
      },
      headerAlign: "center",
      align: "right",
    },
  ],
};

export const PostazioniHeaderNoQrCodeDinamico = {
  initialFieldName: "posto",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "posto",
      headerName: "workstation",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
      renderCell: (x) => {
        return (
          <Grid container
          item
          xs={12}
          sm={9}
          md={6}
          lg={5}
          xl={4}
          >
           
            <Grid item xs={8} sm={10} md={11}>
              <p style={{ fontSize: "15px", margin: "0" }}>
              <CustomRowContent posto={x.row.posto} tipoStanza={x.row.idTipologiaStanza} />
              </p>
            </Grid>
          </Grid>
        );
      },
      headerAlign: "left",
      align: "left",
    },
    {
      field: "riservata",
      headerName: "reservedFor",
      type: "actions",
      flex: 3,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
      align:'left',
      getActions: (cell) => {
        let width = cell.columns[1].computedWidth - spaceColumnEllipsis
        return [
          <div className="textOverflowEllipsis" style={{maxWidth:width}}>
            {
              cell.row.utentiPostazione
              .map(x => x.nomeUtente)
                  .join(', ')
            }
          </div>
          ];
        }
    },
    {
      field: "dotazioni",
      headerName: "equipment",
      type: "actions",
      flex: 3,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
      align: "left",
      getActions: (cell) => {
        let width = cell.columns[2].computedWidth - spaceColumnEllipsis
        return [
          <div className="textOverflowEllipsis" style={{maxWidth:width}}>
            {cell.row.dotazioni.map((x) => x.descrizione).join(", ")}
          </div>
        ];
      },
    },
    {
      field: "postazioniActions",
      type: "actions",
      headerName: "",
      minWidth: minWidthColumnWithButton,
      flex: 1,
      resizable: false,
      editable: false,
      getActions: (x) => {
        //tabIndex permette di identificare quale Row è stata chiamata nel dataTable
        return [<ButtonBoxPostazioni row={x.row} />];
      },
      headerAlign: "center",
      align: "right",
    },
  ],
};

export const BuildingHeader = {
  initialFieldName: "denominazione",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "sigla",
      headerName: "abbreviation",
      flex: 1,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "denominazione",
      headerName: "designation",
      flex: 2,
      resizable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "Responsabili",
      headerName: "managers",
      type: "actions",
      flex: 4,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
      align: "left",
      getActions: (cell) => {
        let width = cell.columns[2].computedWidth - spaceColumnEllipsis
        return [
          <div className="textOverflowEllipsis" style={{maxWidth:width}}>
            {cell.row.utentiResponsabiliPrenotazioni
              .map((x) => x.nominativo)
              .join(", ")}
          </div>
        ];
      },
    },
    {
      field: "buildingsActions",
      headerName: "",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumnWithButton,
      getActions: (cell) => {
        return [
          <ButtonBoxBuilding row={cell.row} rowInfoId={cell.id} />,
        ];
      },
      headerAlign: "right",
      align: "right",
    },
  ],
};




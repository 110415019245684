import { Paper, Grid, Avatar } from "@mui/material";
import "../mobileViewList.css";
import { handleDialogToEditUser } from "../../../UsersDatatable/operations/handleDialogToEditUser";
import { multiDialogAction } from "../../../../const/Dialog/MultiDialogAction";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useRef } from "react";
import useAdaptiveEllipsis from "../../../../Hooks/Adaptive/useAdaptiveEllipsis";

export default function UserRowStructures({ row, BoxButton }) {
  const dispatch = useDispatch();
  const [paragraphWidth, setParagraphWidth] = useState(null);
  const gridItemRef = useRef(null);

  useAdaptiveEllipsis(gridItemRef, setParagraphWidth, -13);

  return (
    <>
      <Paper className="row-mobileView box-shadow-light ">
        <Grid container>
        <Grid item xs={2} sm={1} style={{maxWidth:"55px"}} >
            <div className="booking-card-left-section bk-icon-mob flex-center-center">
              <Grid container item justifyContent="center" xs={12} sm={12} md={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {row.immagineAvatar ? 
                      (<Avatar
                        sx={{ bgcolor: "#000", width: 27, height: 27, fontSize: 27 / 2 }}
                        src={"data:image/*;base64," + row.immagineAvatar}
                      >
                      </Avatar> )
                      :
                      (
                  <div
                    style={{
                      background: "#3e6bb4",
                      width: "27px",
                      height: "27px",
                      borderRadius: "26px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "11px",
                        color: "white",
                        fontStyle: "regular",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      {row.firstName.charAt(0).toUpperCase()}
                      {row.lastName.charAt(0).toUpperCase()}
                    </div>
                  </div>)}
                </div>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            xs={10}
            sx={{paddingTop:"5px"}}
            onClick={(event) =>
              handleDialogToEditUser(
                { dialogType: multiDialogAction.edit, rowInfo: row },
                dispatch,
                event
              )
            }
          >
            <Grid
              item
              xs={12}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ maxWidth: "100%" }}
                className="mobileRow-center-text"
                ref={gridItemRef}
              >
                <p className="font-segoeui firstRow-mobileView textOverflowEllipsis" style={{ width: paragraphWidth }}>
                  {" "}
                  {row.fullName}
                </p>
                <p className="font-segoeui secondRow-mobileView textOverflowEllipsis" style={{ width: paragraphWidth }}>
                  {row.email}
                </p>
              </Grid>
            </Grid>
            {BoxButton && (
              <Grid
                item
                xs={12}
              >
                <BoxButton row={row} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

import React, { useState } from "react";
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FaqAccordion({question, answer }) {
  const [expanded, setExpanded] = useState(false);
  const toggleAccordion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <MuiAccordion className="box-shadow-light accordion" expanded={expanded} onChange={toggleAccordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className="accordion-question">
        <span dangerouslySetInnerHTML={{ __html: question }} />
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="body-accordion">
        <Typography className="accordion-answer">
          <span dangerouslySetInnerHTML={{ __html: answer }} />
        </Typography>
      </AccordionDetails>
    </MuiAccordion>
  );
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeSidebarState, closeSidebar } from "../../redux/reducers/sidebar-reducer";
import { changeViewHomeToDefault } from "../../redux/reducers/handlerBackToDefaultView-reducer";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import MuiSwipeableDrawer from "@mui/material/SwipeableDrawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box } from "@mui/material";
import { policies } from "../../const/policies";
import logo from "../../assests/logo.png"
import useMobileDetection from "../../Hooks/useMobileDetection";
import { faAddressCard, faCalendarDay, faCalendarWeek, faCity, faQuestion, faSquarePollVertical, faUsersGear, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const drawerWidth = 240;
const minWidthMobileSidebar = "230px";
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(8),
    }),
  },
  "& .MuiTypography-root": {
    fontWeight: 600,
    color: "#3e6bb4",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "30px",
    color: "#3e6bb4",
  },
}));

const SwipeableDrawer = styled(MuiSwipeableDrawer, {
  shouldForwardProp: (prop) => prop,
})(() => ({
  "& .MuiTypography-root": {
    fontWeight: 500,
    color: "#3e6bb4",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "30px",
    color: "#3e6bb4",
  },
}));

const Sidebar = ({ initialState, xsSize, t }) => {
  const userDate = useSelector((state) => state.userDate);
  const dispatch = useDispatch();
  const { sidebar } = initialState;
  const [sidebarState, setSidebarState] = useState(sidebar);
  const isMobile = useMobileDetection();
  const { policies: statePolicies, responsabileEdificio } = userDate;

  const drawerContent = (
    <>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        {xsSize ? (
          <></>
        ) : (
          <>
            <img
              src={logo}
              alt="Desk Booking"
              title="Desk Booking"
              className="logo logo-margin"
            />
            <IconButton onClick={() => dispatch(changeSidebarState())}>
              <ChevronLeftIcon />
            </IconButton>
          </>
        )}
      </Toolbar>
      <List>
        <ListItem component={Link} to={"/prenotazioni"} onClick={() => { dispatch(changeViewHomeToDefault()) }}>
          <Tooltip disableFocusListener title={t("bookings")}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faCalendarDay} className="pathIcon" style={{ fontSize: 25, marginLeft: 5 }} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={t("my_reservations_v2")} />
        </ListItem>

        {statePolicies.includes(policies.utentiVisualizzazione) && (
          <ListItem component={Link} to={"/utenti"}>
            <Tooltip disableFocusListener title={t("users")}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faUsersGear} className="pathIcon" style={{ fontSize: 25 }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t("associated_users_v2")} />
          </ListItem>
        )}
        {(statePolicies.includes(policies.VisualizzazioneCompleta) || responsabileEdificio.length > 0) && (
          <ListItem component={Link} to={"/edifici"}>
            <Tooltip disableFocusListener title={t("building_v3")}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faCity} className="pathIcon" style={{ fontSize: 25 }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t("associated_buildings_v2")} />
          </ListItem>
        )}
        <ListItem component={Link} to={"/disponibilita"} onClick={() => { dispatch(changeViewHomeToDefault()) }}>
          <Tooltip disableFocusListener title={t("availability")}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faCalendarWeek} className="pathIcon" style={{ fontSize: 27, marginLeft: 4 }} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={t("availability_v2")} />
        </ListItem>
        {!isMobile && <ListItem component={Link} to={"/planner"} onClick={() => { dispatch(changeViewHomeToDefault()) }}>
          <Tooltip disableFocusListener title={t("planner")}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faSquarePollVertical} className="pathIcon" style={{ fontSize: 27, marginLeft: 4 }} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={t("planner_v2")} />
        </ListItem>}
      {(statePolicies.includes(policies.gestioneRichiestaCredenziali)) && (
        <ListItem component={Link} to={"/richiesteAccesso"}>
          <Tooltip disableFocusListener title={t("request_credentials")}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faAddressCard} className="pathIcon" style={{ fontSize: 27, marginLeft: 1 }} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={t("request_credentials_V2")} />
        </ListItem>
      )}
        <ListItem component={Link} to={"/trovaCollega"} onClick={() => { dispatch(changeViewHomeToDefault()) }}>
          <Tooltip disableFocusListener title={t("find_colleague")}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faMagnifyingGlass} className="pathIcon" style={{ fontSize: 25, marginLeft: 3 }} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={t("find_colleague_V2")} />
        </ListItem>
        <ListItem component={Link} to={"/faq"} onClick={() => { dispatch(changeViewHomeToDefault()) }}>
          <Tooltip disableFocusListener title={t("Faq")}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faQuestion} className="pathIcon" style={{ fontSize: 25, marginLeft: 5 }} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={t("FAQ")} />
        </ListItem>
        </List>
    </>
  );

  useEffect(() => {
    setSidebarState(sidebar);
  }, [sidebar]);

  return (
    <>
      {xsSize ? (
        <>
          <SwipeableDrawer
            anchor={"left"}
            open={sidebarState}
            onOpen={() => dispatch(changeSidebarState())}
            onClose={() => dispatch(closeSidebar())}
            onClick={() => dispatch(closeSidebar())}
            sx={{ minWidth: "100%" }}            
            disableSwipeToOpen={true}
          >
            <Box
              sx={{ width: "auto", minWidth: minWidthMobileSidebar }}
              role="presentation"
            >
              {drawerContent}
            </Box>
          </SwipeableDrawer>
        </>
      ) : (
        <>
          <Drawer
            variant="permanent"
            open={sidebarState}
            sx={{ fontWeight: 500 }}
          >
            {drawerContent}
          </Drawer>
        </>
      )}
    </>
  );
};

export default Sidebar;

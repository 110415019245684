import moment from "moment";

export default function CreateRequestBodyTuttePrenotazioni(date, sortField, sortOrder) {
    const {
        utente,
        DataInizio,
        DataFine,
        Postazione,
        Stanza,
        Zona,
        Edificio,
        RadioButtonUser,
        MostraDisabilitazioni,
        PrenotazioniPassate
      } = date;

      const requestBody = {
        pageIndex: 1,
        pageSize: 10,
        sortColumnName: sortField,
        sortDirection: sortOrder,
        applyPaginationAndSort: true,
        DataInizio:
          DataInizio === undefined || DataInizio === null
            ? DataInizio
            : moment(DataInizio).format(),
        DataFine:
          DataFine === undefined || DataFine === null
            ? DataFine
            : moment(DataFine).format(),
        idUtente: utente?.idUtente || null,
        IdPostazione: Postazione?.id,
        MostraSoloPrenotazioniPersonali: RadioButtonUser,
        IdStanza: Stanza?.id,
        IdZona: Zona?.id,
        IdEdificio: Edificio?.id,
        MostraDisabilitazioni,
        PrenotazioniPassate,
      };

    return requestBody
}
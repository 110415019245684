import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { faCalendarCheck, faCalendarDay, faCalendarXmark } from "@fortawesome/free-solid-svg-icons";
import CancelIcon from "@mui/icons-material/Cancel";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Grid, Typography } from "@mui/material";
import "../index.css";
import {
  CHECKIN,
  DISABILITATA,
  DISPONIBILE,
  OCCUPATA,
  RISERVATA,
} from "../const/Planimetria/StatoPostazione";
import ResponseQrCode from "../components/Dashboard/QrCode/ComponentQrCode/GeneralComponentQrCode/ResponseQrCode";
import { coloreDisabilitata, coloreDisponibile, coloreOccupato, coloreRiservata, coloreCheckIn } from "../const/general/colorApp";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { STATOPRENOTAZIONE } from "../const/Dialog/StatoPrenotazione/StatoPrenotazione";

const grandezzaLogo = "75px"
const grandezzaScritte = "25px"

// export const coloreDisponibile = "#28a745"
// export const coloreOccupato = "#dc3545"
// const coloreDisabilitata = "#979797"
// const coloreRiservata = "#979797"

export default function CreateStatusPostazioneScannedQrCode(
  checkInTypology,
  statoAlMomentoDelloScan,
  postazione
) {
  const { t } = useTranslation();
  let icon;
  let text;


  //   #28a745
  // #fd7e14
  const {isSalaRiunione} = postazione;
  
  let tipologiaPrenotazione = isSalaRiunione ? t("Meeting_room") : t("workstation");

  switch (true) {
    case statoAlMomentoDelloScan === DISPONIBILE:
      icon = <FontAwesomeIcon icon={faCalendarCheck} style={{color:coloreDisponibile, width: grandezzaLogo, height: grandezzaLogo}}  ></FontAwesomeIcon>;
      text = (
        <ResponseQrCode text1={tipologiaPrenotazione} text2={t("available")} color={coloreDisponibile} fontSize={grandezzaScritte} className="flex-center-center" />
      );
      break;
    case statoAlMomentoDelloScan === OCCUPATA:
      icon = <FontAwesomeIcon icon={faCalendarXmark} style={{color:coloreOccupato, width: grandezzaLogo, height: grandezzaLogo}}  ></FontAwesomeIcon>;
      text = (
        <ResponseQrCode text1={tipologiaPrenotazione} text2="occupata" color={coloreOccupato} fontSize={grandezzaScritte}className="flex-center-center" />
      );
      break;
    case statoAlMomentoDelloScan === DISABILITATA:
      icon = (
        <DoNotDisturbIcon style={{ fontSize: grandezzaLogo, color: coloreDisabilitata }} />
      );
      text = (
        <ResponseQrCode text1={tipologiaPrenotazione} text2={t("not_available")} color={coloreDisabilitata} fontSize={grandezzaScritte} className="flex-center-center" />
      );
      break;
    case statoAlMomentoDelloScan === RISERVATA:
      icon = (
        <DoNotDisturbIcon style={{ fontSize: grandezzaLogo, color: coloreRiservata }} />
      );
      text = (
        <ResponseQrCode text1={tipologiaPrenotazione} text2={t("not_available")}  color={coloreRiservata} fontSize={grandezzaScritte} className="flex-center-center" />
      );
      break;
      case checkInTypology === STATOPRENOTAZIONE.CHECKIN:
        icon = (
          <FontAwesomeIcon icon={faCalendarDay} style={{color:coloreCheckIn, width: grandezzaLogo, height: grandezzaLogo}}  ></FontAwesomeIcon>
        );
        text = (
          <ResponseQrCode text1={""} text2={t("Checkout_booking")}  color={coloreCheckIn} fontSize={grandezzaScritte} className="flex-center-center" />
        );
        break;
      case statoAlMomentoDelloScan === CHECKIN:
      icon = (
        <FontAwesomeIcon icon={faCalendarDay} style={{color:coloreCheckIn, width: grandezzaLogo, height: grandezzaLogo}}  ></FontAwesomeIcon>
      );
      text = (
        <ResponseQrCode text1={""} text2={t("Checkin_booking")}  color={coloreCheckIn} fontSize={grandezzaScritte} className="flex-center-center" />
      );
      break;
  }
  
  return (
    <>
      <Grid mb={1} item className="flex-center-center" xs={12}>
        {icon}
      </Grid>
      <Grid item className="flex-center-center" xs={12}>
        {text}
      </Grid>
    </>
  );
}

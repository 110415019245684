export const orariAperturaEdificioDefault = [
	{
		idGiornoSettimana: "1",
		apertura: true,
		orarioApertura: "09:00",
		orarioChiusura: "18:00",
	},
	{
		idGiornoSettimana: "2",
		apertura: true,
		orarioApertura: "09:00",
		orarioChiusura: "18:00",
	},
	{
		idGiornoSettimana: "3",
		apertura: true,
		orarioApertura: "09:00",
		orarioChiusura: "18:00",
	},
	{
		idGiornoSettimana: "4",
		apertura: true,
		orarioApertura: "09:00",
		orarioChiusura: "18:00",
	},
	{
		idGiornoSettimana: "5",
		apertura: true,
		orarioApertura: "09:00",
		orarioChiusura: "18:00",
	},
	{
		idGiornoSettimana: "6",
		apertura: false,
		orarioApertura: "09:00",
		orarioChiusura: "18:00",
	},
	{
		idGiornoSettimana: "7",
		apertura: false,
		orarioApertura: "09:00",
		orarioChiusura: "18:00",
	},
]
import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../Hooks/useMobileDetection";

export function ShowImgEdificio({ image, nomeFotoEdificio = "Foto edificio" }) {
  const { t } = useTranslation();
  const isMobile = useMobileDetection(950);

  return (
    <React.Fragment>
      <Grid container item>
        <Grid item>
          <label className="labelCustom marginToplabel">
            <Typography> {t("Building_picture")}</Typography>
          </label>
        </Grid>
        <Grid xs={12} item>
          <img
            src={"data:image/png;base64," + image}
            alt={nomeFotoEdificio}
            width={isMobile? "100%" : "auto"}
            height="150"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

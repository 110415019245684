import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeStatusBuildingTextDialog, changeStatusBuildingTitleDialog, dialogButtonConfirm } from "../../../../../const/DialogData";
import { initialValuesBuildingDelete } from "../../../../../const/formik/Building/initialValuesBuilding";
import { deleteBuilding } from "../../../../../const/urlAction";
import { hideLoader, showLoader } from "../../../../../redux/reducers/appLoader-reducer";
import { reloadingDataTableBuildings } from "../../../../../redux/reducers/dataTableBuildings-reducer";
import { notificationOpen } from "../../../../../redux/reducers/notification-reducer";
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../../../../Hooks/useMobileDetection";

const urlBase = process.env.REACT_APP_URL;

export default function DialogViewBuildingDelete({rowInfo,returnIsOpen,isOpen, deleteRestore}) {

  const appLoadingState = useSelector((state) => state.appLoader.loading);
  const { t } = useTranslation(); 
  const dispatch = useDispatch();
  const initialValues = initialValuesBuildingDelete;
  const isMobile = useMobileDetection(950);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const closeDialog = () => {
    formik.resetForm(initialValues);
    returnIsOpen(false);
  };

  function deleteRestore(rowInfo) {
      (async () => {

        const { id } = rowInfo;
        const { action, method } = deleteBuilding;
        let url = urlBase + action;
        dispatch(showLoader())
        try {
          const response = await axios({
            method: method,
            url,
            data: id,
            headers: {
              'Content-Type': 'application/json'
            }
          });

          if (response.data.ok) {
              dispatch(reloadingDataTableBuildings());
          }
          dispatch(notificationOpen({ message: response.data.message, status: "success" }));
        }
        catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(notificationOpen({ message: `${error.response.data.message}`, status: "error" }));
          } else {
            dispatch(notificationOpen({ message: "Operazione fallita: Server non raggiungibile.", status: "error" }));
          }
        }
        dispatch(hideLoader())
         closeDialog();
      })();
  };
  
  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>
          {changeStatusBuildingTitleDialog(rowInfo?.deleted, t)}
        </DialogTitle>
        <DialogContent className="border-Content-dialog">
          <DialogContentText id="alert-dialog-description">
            {changeStatusBuildingTextDialog(rowInfo?.deleted, t)}
            <b>
              {rowInfo?.row?.denominazione || rowInfo.denominazione} ({rowInfo?.row?.sigla || rowInfo?.sigla})
            </b>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()}>
            {t(dialogButtonConfirm.Cancel)}
          </Button>
          <Button variant="contained" disabled={appLoadingState} onClick={() => deleteRestore(rowInfo)}>
            {t(dialogButtonConfirm.Confirm)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import {
  Button,
  Checkbox,
  DialogContentText,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Autocomplete,
  IconButton,
} from "@mui/material";
import { dialogPageTypes } from "../../const/dialogPageTypes";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import {
  addStanza,
  editStanza,
  getStanzaById,
  deleteStanza,
  getUsersFiltered,
  getAllDotazioni,
  GetTipologiaStanzaAbilitate,
  getDotazioniFilteredByTipologiaStanza,
} from "../../const/urlAction";
import axios from "axios";
import { reloadingDataTableUsers } from "../../redux/reducers/dataTableUsers-reducer";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  dialogButtonGeneral,
  dialogButtonConfirm,
  changeStatusStanzaTitleDialog,
  changeStatusStanzaTextDialog,
  dialogEditStanze,
  dialogAddStanze,
} from "../../const/DialogData";

import { notificationOpen } from "../../redux/reducers/notification-reducer";
import handlerError from "../../functions/handlerError";
import { useParams } from "react-router-dom";
import { BoxDialogDisabilita } from "./DisableDialog/DialogDisabilita";
import { closeDialog } from "../../redux/reducers/DialogGeneral";
import { ShowImgPlanimetria } from "../ShowImgPlanimetria/ShowImgPlanimetria";
import convertPositionToInt from "../../functions/convertPositionToInt";
import { hideLoader, showLoader } from "../../redux/reducers/appLoader-reducer";
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../Hooks/useMobileDetection";
import BoxAutoCompletePills from "../UI/BoxAutoCompletePills/BoxAutoCompletePills";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";

const urlBase = process.env.REACT_APP_URL;

const StanzeDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams();
  const [isRenderingDialog, setIsRenderingDialog] = useState(false);
  const [changedRow, setchangedRow] = useState(null);
  const backButtonState = useSelector((state) => state.dialogBackButton);
  const isMobile = useMobileDetection(950);
  const [radioButtonPlanimetria, setRadioButtonPlanimetria] =
    useState("mantieni");
  const dialogState = useSelector((state) => state.dialogGeneral);
  const { rowInfo, dialogType, dialogPageType, isOpen } = dialogState;
  const configurations = useSelector(
    (state) => state.configurations.configurazioniGlobali
  );
  const qrcodeDinamico = useSelector(
    (state) => state.configurations.configurazioniGlobali.qrCodeDinamico
  );
  const maxDotazioni = useSelector(
    (state) => state.configurations.configurazioniPostazione.maxDotazioni
  );
  const appLoadingState = useSelector((state) => state.appLoader.loading);
  const [dotazioni, setDotazioni] = useState([]);
  const [selectedDotazioni, setSelectedDotazioni] = useState([]);
  const [tipologiaStanza, setTipologiaStanza] = useState({});
  const [selectedTipologiaStanza, setSelectedTipologiaStanza] = useState({});
  const [users, setUsers] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [inputUserSelect, setInputUserSelect] = useState("");
  const [inputDotazioneSelect, setInputDotazioneSelect] = useState("");
  const fileInputRef = useRef(null);
  let validationSchema;
  let initialValues = {};

  if (dialogType === "edit") {
    validationSchema = yup.object({
      denominazione: yup.string().required("Campo Obbligatorio."),
      sigla: yup.string().required("Campo Obbligatorio."),
    });
    initialValues = {
      id: rowInfo?.id,
      denominazione: rowInfo?.denominazione,
      sigla: rowInfo?.sigla,
      idZona: rowInfo?.idZona,
      nomeImagineSfondoPlanimetria: rowInfo?.nomeImagineSfondoPlanimetria,
      idSfondoPlanimetria: rowInfo?.idSfondoPlanimetria,
      utentiPostazione: rowInfo?.utentiPostazione,
      dotazioni: rowInfo?.dotazioni,
      isSalaRiunione: rowInfo?.isSalaRiunione,
      nominativoDevice: rowInfo?.nominativoDevice ?? "",
    };
  } else if (dialogType === "add") {
    validationSchema = yup.object({
      denominazione: yup.string().required("Campo Obbligatorio."),
      sigla: yup.string().required("Campo Obbligatorio."),
    });
    initialValues = {
      denominazione: "",
      sigla: "",
      idZona: "",
      nomeImagineSfondoPlanimetria: "",
      idSfondoPlanimetria: "",
      isSalaRiunione: false,
      nominativoDevice: "",
      utentiPostazione: [],
      dotazioni: [],
    };
  } else if (dialogType === "delete") {
    initialValues = {
      denominazione: rowInfo?.denominazione,
      sigla: rowInfo?.sigla,
      idZona: rowInfo?.idZona,
    };
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true, // rinizializza il valore che gli viene passato con rowInfo
    onSubmit: (values) => {
      if (dialogType === "edit") {
        if (radioButtonPlanimetria === "modifica" && values.file == null) {
          formik.setFieldError(
            "file",
            configurations.enableImportCadFiles
              ? t("modify_planimetry_file_cad_format_error")
              : t("modify_planimetry_file_format_error")
          );
        }
        setchangedRow({
          id: values.id,
          denominazione: values.denominazione,
          sigla: values.sigla,
          idZona: rowInfo?.idZona,
          posX: convertPositionToInt(dialogState?.rowInfo?.posX) || 0,
          posY: convertPositionToInt(dialogState?.rowInfo?.posY) || 0,
          image: values.file,
          radioImageHandler:
            radioButtonPlanimetria === "cancella" ? true : false,
          isSalaRiunione: values.isSalaRiunione,
          utentiPostazione: selectedUsers,
          nominativoDevice: values.nominativoDevice,
          dotazioni: selectedDotazioni,
        });
      } else if (dialogType === "add") {
        setchangedRow({
          denominazione: values.denominazione,
          sigla: values.sigla,
          idZona: params.idZona,
          posX: convertPositionToInt(dialogState?.body?.posX) || 0,
          posY: convertPositionToInt(dialogState?.body?.posY) || 0,
          image: values.file,
          isSalaRiunione: values.isSalaRiunione,
          utentiPostazione: selectedUsers,
          nominativoDevice: values.nominativoDevice,
          dotazioni: selectedDotazioni,
          tipologiaStanza: selectedTipologiaStanza.id
        });
      }
    },
  });

  const handleChangeUserSelect = (event, value) => {
    setSelectedUsers(value);
  };

  const handleInputChangeUserSelect = (event, value) => {
    setInputUserSelect(value);
  };

  const handleChangeDotazioneSelect = (event, value) => {
    if (value.length <= maxDotazioni) setSelectedDotazioni(value);
  };

  const handleChangeTipologiaStanzaSelect = (event, value) => {
    setSelectedTipologiaStanza(value);
  };

  const handleInputChangeDotazioneSelect = (event, value) => {
    setInputDotazioneSelect(value);
  };

  useEffect(() => {
    let isMounted = true;
    if (
      dialogState.isOpen &&
      dialogType === "edit" &&
      dialogPageType === dialogPageTypes.stanze
    ) {
      (async () => {
        setIsRenderingDialog(true);
        const { action, method } = getStanzaById;
        let url = urlBase + action + rowInfo?.id;

        try {
          const response = await axios({
            method: method,
            url,
          });
          if (isMounted) {
            if (response.data.ok) {
              Object.keys(response.data.result).map((key) => {
                formik.setFieldValue(
                  `${[key]}`,
                  response.data.result[key]  !== null ? `${response.data.result[key]}` :  null,
                  true
                );
              });
              setSelectedUsers(response.data.result.utentiPostazione);
              setSelectedDotazioni(response.data.result.dotazioni);
              setSelectedTipologiaStanza(response.data.result.tipologiaStanza);
              setIsRenderingDialog(false);
            } else {
              dispatch(
                notificationOpen({
                  message: response.data.message,
                  status: "success",
                })
              );
            }
          }
        } catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(
              notificationOpen({
                message: `${error.response.data.message}`,
                status: "error",
              })
            );
          } else {
            dispatch(
              notificationOpen({
                message: "Operazione fallita: Server non raggiungibile.",
                status: "error",
              })
            );
          }
          setIsRenderingDialog(false);
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [dialogState.isOpen]);

  useEffect(() => {
    let isMounted = true;
    if (changedRow != null && formik.isValid) {
      (async () => {
        let action, method, url;
        let formData = new FormData();

        if (dialogType === "add" || dialogType === "edit") {
          Object.keys(changedRow).map((key) => {
            if (Array.isArray(changedRow[key])) {
              formData.append(`${key}JSON`, JSON.stringify(changedRow[key]));
            } else {
              formData.append(`${key}`, changedRow[key]);
            }
          });

          if (dialogType === "edit") {
            action = editStanza.action;
            method = editStanza.method;
          } else {
            action = addStanza.action;
            method = addStanza.method;
          }

          url = urlBase + action;
        }

        dispatch(showLoader());
        try {
          const response = await axios({
            method: method,
            url,
            data: formData,
          });
          if (isMounted) {
            if (response.data.ok) {
              formik.resetForm(initialValues);
              dispatch(reloadingDataTableUsers());
              dispatch(closeDialog());
              setRadioButtonPlanimetria("mantieni");
              setSelectedDotazioni([]);
              setSelectedUsers([]);
            }
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          }
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));

          if (error?.response?.data?.errors != undefined) {
            let keys = Object.keys(error?.response?.data?.errors);
            keys.map((key) => {
              formik.setFieldError(key, error?.response?.data?.errors[key]);
            });
          }
        }
        dispatch(hideLoader());
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [changedRow]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const { action, method } = GetTipologiaStanzaAbilitate;
      let url = urlBase + action;

      try {
        const response = await axios({
          method: method,
          url,
        });
        if (isMounted) {
          setTipologiaStanza(response.data.result);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const { action, method } = getUsersFiltered;
      let url = urlBase + action;

      try {
        const response = await axios({
          method: method,
          url,
        });
        if (isMounted) {
          setUsers(response.data.result);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (rowInfo.id && isOpen && rowInfo.isSalaRiunione) {
        const { action, method } = getDotazioniFilteredByTipologiaStanza;
        let url = urlBase + action + "?idStanza=" + rowInfo.id;
        try {
          const response = await axios({
            method: method,
            url,
          });
          if (isMounted) {
            setDotazioni(response.data.result);
          }
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
        }
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [isOpen]);

  const deleteRestore = (rowInfo) => {
    let isMounted = true;
    if (dialogState.dialogType === "delete") {
      (async () => {
        setIsRenderingDialog(true);

        const { id } = rowInfo;
        const { action, method } = deleteStanza;
        let url = urlBase + action;
        dispatch(showLoader());
        try {
          const response = await axios({
            method: method,
            url,
            data: id,
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (isMounted) {
            if (response.data.ok) {
              formik.resetForm(initialValues);
              dispatch(reloadingDataTableUsers());
            }

            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
            setIsRenderingDialog(false);
          }
        } catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(
              notificationOpen({
                message: `${error.response.data.message}`,
                status: "error",
              })
            );
          } else {
            dispatch(
              notificationOpen({
                message: "Operazione fallita: Server non raggiungibile.",
                status: "error",
              })
            );
          }
          setIsRenderingDialog(false);
        }
        dispatch(hideLoader());
      })();
      dispatch(closeDialog());

      return () => {
        isMounted = false;
      };
    }
  };

  const closeForm = () => {
    setRadioButtonPlanimetria("mantieni");
    formik.resetForm(initialValues);
    dispatch(closeDialog());
    setSelectedDotazioni([]);
    setSelectedUsers([]);
  };

  /* React Mui non ha il textField di tipo input,
  forzandolo, il tasto input trigghera i comportamenti di tutti gli altri tasti,
  per impedirglielo viene fatto il controllo sull'evento  */
  function handleChangeFile(event) {
    if (event?.currentTarget?.files[0]) {
      let filename = event?.currentTarget?.files[0]?.name;
      let filenameExtension = filename.substring(
        filename.lastIndexOf("."),
        filename.length
      );
      if (event?.currentTarget?.files[0]?.type?.startsWith("image")) {
        formik.setFieldValue("file", event.currentTarget.files[0]);
      } else if (
        configurations.enableImportCadFiles &&
        (filenameExtension.includes("dwg") || filenameExtension.includes("dxf"))
      ) {
        formik.setFieldValue("file", event.currentTarget.files[0]);
      } else {
        formik.setFieldError(
          "file",
          configurations.enableImportCadFiles
            ? t("planimetry_file_cad_format_error")
            : t("planimetry_file_format_error")
        );
      }
    }
  }

  const handlePlanimetriaChange = (event) => {
    setRadioButtonPlanimetria(event.target.value);
  };

  useEffect(() => {
    dispatch(closeDialog());
  }, [backButtonState]);

  return (
    <>
      {dialogState.dialogType === "delete" && (
        <>
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"sm"}
          >
            <DialogTitle>
              {changeStatusStanzaTitleDialog(rowInfo?.dataDel, t)}
            </DialogTitle>
            <DialogContent className="border-Content-dialog">
              <DialogContentText id="alert-dialog-description">
                {changeStatusStanzaTextDialog(rowInfo?.dataDel, t)}{" "}
                <b>{rowInfo?.denominazione}</b> (<b>{rowInfo?.sigla}</b>)?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => dispatch(closeDialog())}>
                {t(dialogButtonConfirm.Cancel)}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteRestore(rowInfo)}
              >
                {t(dialogButtonConfirm.Confirm)}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {(dialogState.dialogType === "add" ||
        dialogState.dialogType === "edit") &&
        !isRenderingDialog && (
          <>
            <Dialog
              open={isOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              fullScreen={isMobile}
              maxWidth={"sm"}
            >
              <DialogTitle>
                {dialogState.dialogType === "edit"
                  ? t(dialogEditStanze.DialogTitle)
                  : t(dialogAddStanze.DialogTitle)}
              </DialogTitle>
              <DialogContent className="border-Content-dialog">
                <FormControl fullWidth>
                  <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        margin="dense"
                        inputProps={{
                          maxLength: 3,
                        }}
                        id="sigla"
                        label={t("abbreviation")}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.sigla}
                        onChange={formik.handleChange("sigla")}
                        error={
                          formik.touched.sigla && Boolean(formik.errors.sigla)
                        }
                        helperText={formik.touched.sigla && formik.errors.sigla}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        margin="dense"
                        id="denominazione"
                        label={t("denomination")}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.denominazione}
                        onChange={formik.handleChange("denominazione")}
                        error={
                          formik.touched.denominazione &&
                          Boolean(formik.errors.denominazione)
                        }
                        helperText={
                          formik.touched.denominazione &&
                          formik.errors.denominazione
                        }
                      />
                    </Grid>
                    {(dialogState.dialogType === "add" ||
                      dialogState.dialogType === "edit") && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            sx={dialogState.dialogType === "add" ? { margin: "15px 0 0 0" } : {}}
                          >
                            {dialogState.dialogType === "add" ? (
                              <>
                                <Autocomplete
                                  id="tipologiaStanza"
                                  size="small"
                                  label
                                  options={tipologiaStanza}
                                  value={selectedTipologiaStanza}
                                  disableCloseOnSelect
                                  limitTags={1}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value
                                  }
                                  getOptionLabel={(option) =>
                                    option.descrizione || ""
                                  }
                                  onChange={handleChangeTipologiaStanzaSelect}
                                  onInputChange={
                                    handleChangeTipologiaStanzaSelect
                                  }
                                  renderOption={(
                                    props,
                                    option,
                                  ) => {
                                    return (
                                      <li key={option?.idDotazione} {...props}>
                                        {option.descrizione}
                                      </li>
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t("typeRoom")}
                                      variant="standard"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <Fragment>
                                            {params.InputProps.endAdornment}
                                          </Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              </>
                            ) : (
                              <>
                                <Grid item xs={12} sm={12}>
                                  <TextField
                                    margin="dense"
                                    id="tipologiaStanza"
                                    label={t("typeRoom")}
                                    type="text"
                                    fullWidth
                                    readOnly
                                    variant="standard"
                                    value={selectedTipologiaStanza.descrizione}
                                    error={
                                      formik.touched.tipologiaStanza && Boolean(formik.errors.tipologiaStanza)
                                    }
                                    helperText={formik.touched.tipologiaStanza && formik.errors.tipologiaStanza}
                                  />
                                </Grid>
                                {/* <Autocomplete
                                  readOnly
                                  id="tipologiaStanza"
                                  size="small"
                                  label
                                  options={tipologiaStanza}
                                  value={selectedTipologiaStanza}
                                  disableCloseOnSelect
                                  limitTags={1}
                                  isOptionEqualToValue={(option, value) => {
                                    return option.id === value.id
                                  }
                                  }
                                  getOptionLabel={(option) => 
                                    option.descrizione || ""
                                  
                                  }
                                   onChange={handleChangeTipologiaStanzaSelect}
                                   onInputChange={
                                     handleChangeTipologiaStanzaSelect
                                   }
                                  renderOption={(
                                    props,
                                    option,
                                  ) => {
                                    return (
                                      <li key={option?.idDotazione} {...props}>
                                        {option.descrizione}
                                      </li>
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t("typeRoom")}
                                      variant="standard"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <Fragment>
                                            {params.InputProps.endAdornment}
                                          </Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                /> */}
                              </>
                            )}
                          </Grid>
                          {/* <Grid item xs={12} sm={12}>
                            <Typography fontSize={12} color="#3e6bb4">
                              {t("text_8")}
                            </Typography>
                          </Grid> */}
                        </>
                      )}
                    {formik.values.nomeImagineSfondoPlanimetria !== null &&
                      formik.values.nomeImagineSfondoPlanimetria !==
                      undefined &&
                      formik.values.nomeImagineSfondoPlanimetria !== "null" &&
                      formik.values.nomeImagineSfondoPlanimetria !== "" && (
                        <ShowImgPlanimetria
                          idStanza={rowInfo?.id}
                          nomeImgPlanimetria={
                            rowInfo?.nomeImagineSfondoPlanimetria
                          }
                        />
                      )}
                    {formik.values.idSfondoPlanimetria !== null &&
                      formik.values.idSfondoPlanimetria !== "null" &&
                      formik.values.idSfondoPlanimetria !== "" &&
                      formik.values.idSfondoPlanimetria !== undefined && (
                        <Grid
                          sx={{ margin: "20px 0 0 0" }}
                          item
                          xs={12}
                          sm={12}
                        >
                          <FormLabel component="legend">
                            <b>{t("Floor_Plan_Management")}</b>
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-label="recoveryEnabled"
                            name="row-radio-group-enabled"
                            onChange={(event) => {
                              handlePlanimetriaChange(event);
                            }}
                            defaultValue="mantieni"
                          >
                            <FormControlLabel
                              value="mantieni"
                              control={<Radio />}
                              label={t("Keep")}
                            />
                            <FormControlLabel
                              value="cancella"
                              control={<Radio />}
                              label={t("Cancel")}
                            />
                            <FormControlLabel
                              value="modifica"
                              control={<Radio />}
                              label={t("edit")}
                            />
                          </RadioGroup>
                        </Grid>
                      )}
                    {(radioButtonPlanimetria === "modifica" ||
                      formik.values.idSfondoPlanimetria === null ||
                      formik.values.idSfondoPlanimetria === "null" ||
                      formik.values.idSfondoPlanimetria === "" ||
                      formik.values.idSfondoPlanimetria === undefined) && (
                        <Grid sx={{ margin: "15px 0 0 0" }} item container xs={12} sm={12}>
                          <Grid item xs={12} sm={12}>
                            <FormLabel component="legend">
                              <b> {t("Room_plan")}</b>
                            </FormLabel>
                          </Grid>
                          <Grid item  xs={2} sm={2}>
                            <IconButton
                              color="primary"
                              aria-label={t("upload_picture")}
                              component="span"
                            >
                              <label htmlFor="file" className="file-label">
                                <FontAwesomeIcon icon={faImage} size="2x" cursor={'pointer'} />
                              </label>
                            </IconButton>
                            <input
                              style={{ display: 'none' }}
                              ref={fileInputRef}
                              margin="dense"
                              id="file"
                              type="file"
                              onChange={(event) => handleChangeFile(event)}
                            />
                          </Grid>
                          <Grid item xs={10} sm={10}>
                            <Typography sx={{ position: 'relative', top: '1.2rem' }}>{formik.values.file?.name}</Typography>
                          </Grid>
                          {radioButtonPlanimetria === "modifica" && (
                            <Grid item xs={12} sm={12}>
                              <Typography
                                fontSize={12}
                                sx={{
                                  paddingTop: "5px",
                                  position: "relative",
                                  top: "5px",
                                }}
                                color="#3e6bb4"
                              >
                                {t("text_11")}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    {(formik.values.isSalaRiunione === true ||
                      formik.values.isSalaRiunione === "true") &&
                      (dialogState.dialogType === "add" ||
                        dialogState.dialogType === "edit") && (
                        <>
                          {qrcodeDinamico && (
                            <Grid item xs={12} sm={12}>
                              <TextField
                                margin="dense"
                                id="nominativoDevice"
                                label={t("deviceName")}
                                type="text"
                                fullWidth
                                InputProps={{}}
                                variant="standard"
                                value={formik.values.nominativoDevice}
                                onChange={formik.handleChange(
                                  "nominativoDevice"
                                )}
                                error={
                                  formik.touched.nominativoDevice &&
                                  Boolean(formik.errors.nominativoDevice)
                                }
                                helperText={
                                  formik.touched.nominativoDevice &&
                                  formik.errors.nominativoDevice
                                }
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} sm={12}>
                            <Autocomplete
                              multiple
                              id="utentiPostazione"
                              size="small"
                              options={users} //tutti ma sotto forma di json selectedUsers idUtente Nominativo
                              value={selectedUsers}
                              disableCloseOnSelect
                              limitTags={1}
                              isOptionEqualToValue={(option, value) =>
                                option.idUtente === value.idUtente
                              }
                              getOptionLabel={(option) =>
                                option.nominativo || ""
                              }
                              onChange={handleChangeUserSelect}
                              onInputChange={handleInputChangeUserSelect}
                              renderOption={(props, option, { selected }) => {
                                return (
                                  <li key={option?.idUtente} {...props}>
                                    {option.nominativo}
                                  </li>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("meeting_room_reserved_for")}
                                  variant="standard"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <Fragment>
                                        {params.InputProps.endAdornment}
                                      </Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <BoxAutoCompletePills items={dotazioni} activeItems={selectedDotazioni} onActiveItemsChange={setSelectedDotazioni}/>
                          </Grid>
                        </>
                      )}
                  </Grid>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => closeForm()}>
                  {t(dialogButtonGeneral.Cancel)}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={appLoadingState}
                  onClick={() => formik.handleSubmit()}
                >
                  {t(dialogButtonGeneral.Confirm)}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      {(dialogState.dialogType === "disableRangeData" ||
        dialogState.dialogType === "ripristinaAll") && (
          <BoxDialogDisabilita
            isOpen={dialogState.isOpen}
            dialogType={dialogState.dialogType}
            rowInfo={rowInfo}
            closeForm={closeForm}
          />
        )}
    </>
  );
};

export default StanzeDialog;

import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  returnDateFine,
  returnDateInizio,
  returnDataGiorno,
  cambiaRadioButtonPrenotazionePerAltri,
  resetUtentePrenotazione,
  handlerSelectUtenti,
  returnUtentePrenotazione,
} from "../../../../redux/reducers/prenotazioneStepper-reducer";
import { AutocompleteCustom } from "../../../UI/AutoComplete/AutoComplete";
import { getUsersFiltered } from "../../../../const/urlAction";
import axios from "axios";
import handlerError from "../../../../functions/handlerError";
import { notificationOpen } from "../../../../redux/reducers/notification-reducer";
import PannelloSuggerimentiPrenotazione from "../../PannelloSuggerimentoPrenotazione/PannelloSuggerimentiPrenotazione";
import RipetizioniBox from "./RipetizioniBox";
import GeneralCustomDateTimePicker from "../../../UI/TimePicker/GeneralCustomDateTimePicker";
import RadioButtonCustom from "../../../UI/RadiobuttonCustom.js/RadioButtonCustom.js";
import {
  radioButtonPrenotazioneStepperMeAndOtherAndGuests,
  radioButtonPrenotazioneStepperMeAndGuests,
  radioButtonPrenotazioneStepperMeAndOther,
  radioButtonPrenotazioneStepperOnlyMe,
} from "../../../../const/RadioButton/RadioButtonListRadio";
import handlerConfiguration from "../../../../functions/general/handlerConfiguration";
import moment from "moment";
import PrenotazioniOspiti from "./PrenotazioneOspiti/PrenotazioniOspiti";
import PannelloOspiti from "./PrenotazioneOspiti/componentsPrenotazioneOspiti/PannelloOspiti";
import useGetAvatarUserLogged from "../../../../Hooks/UserDate/useGetAvatarUserLogged";
import userLoggedToPrenotazioneObj from "../../../../functions/general/userLoggedToPrenotazioneObj";
import { useTranslation } from "react-i18next";
import { policies as policiesPermission } from "../../../../const/policies";
import { Box } from "@mui/system";
import handlerLabelPrenotazione from "../../../../functions/stepper/suggerimenti/handlerLabelPrenotazione";
import useMobileDetection from "../../../../Hooks/useMobileDetection";

const minDate = "1970-01-01T";
const now = new Date(Date.now());

const urlBase = process.env.REACT_APP_URL;

export function DataPickerBoxHome({
  setStepNoAutoSkipped,
  framesIndex,
  disabled = false,
}) {
  const configurations = useSelector((state) => state.configurations);
  const userDate = useSelector((state) => state.userDate);

  const {
    orarioInizioDefault: OraInizio,
    orarioFineDefault: OraFine,
    maxGiorniRipetizione,
    guests
  } = handlerConfiguration(configurations);


  const maxday = new Date(
    new Date(Date.now()).setDate(now.getDate() + maxGiorniRipetizione)
  );
  const { t } = useTranslation();
  const prenotazioneDati = useSelector((state) => state.prenotazioneStepper);
  const [usersRows, setUsersRows] = React.useState([]);
  const [dialogOspitiState, setDialogOspitiState] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [isFirst, setIsFirst] = useState(true);
  let avatarUserLogged = useGetAvatarUserLogged();
  const [radioTipoPrenotazione, setRadioTipoPrenotazione] = React.useState(
    radioButtonPrenotazioneStepperMeAndOtherAndGuests[0].value
  );
  const [isRepeatBookingConfiguredCorrectly, setIsRepeatBookingConfiguredCorrectly] = React.useState(false)
  const isMobile = useMobileDetection()
  const dispatch = useDispatch();
  const [valueDataGiorno, setValueDataGiorno] = React.useState(null);
  const [valueDataInizio, setValueDataInizio] = React.useState(
    new Date(minDate + OraInizio)
  );

  const [valueDataFine, setValueDataFine] = React.useState(
    new Date(minDate + OraFine)
  );

  const handleChangeData = (newValue) => {
    if (newValue !== null) {
      dispatch(returnDataGiorno(newValue.toString()));
      setValueDataGiorno(newValue);
    } else {
      dispatch(returnDataGiorno("null"));
      setValueDataGiorno("null");
    }
  };
  const {
    dataGiorno,
    dataInizio,
    dataFine,
    utentePrenotazione,
    radioButtonPrenotazionePerAltri,
    ospitiPrenotazioneInfo,
    dataGiornoFromPrenotazioniComponent,
  } = prenotazioneDati;

  useEffect(() => {
    //qui faccio i miei controlli
    let giorno = dataGiorno ?? dataGiornoFromPrenotazioniComponent
    let isGiornoCorrente = new Date(giorno).toDateString() === new Date().toDateString()
    let timeNow = new Date(Date.now());

    let orarioInizio =
      isGiornoCorrente
        ? new Date().setHours(timeNow.getHours() + 1, 0, 0)
        : new Date(minDate + dataInizio)

    let defaultOrarioFine = new Date(minDate + dataFine).getHours()
    let oraOrarioInizio = new Date(orarioInizio).getHours()

    let orarioFine =
      oraOrarioInizio < defaultOrarioFine
        ? new Date(minDate + dataFine)
        : new Date().setHours(timeNow.getHours() + 2, 0, 0)

    setValueDataGiorno(giorno);
    setValueDataInizio(orarioInizio);
    setValueDataFine(orarioFine);

    dispatch(returnDateInizio(moment(orarioInizio).format("HH:mm")))
    dispatch(returnDateFine(moment(orarioFine).format("HH:mm")))
    dispatch(returnDataGiorno(giorno?.toString()))
    setRadioTipoPrenotazione(radioButtonPrenotazionePerAltri.toString());
  }, []);

  const handleChangeOrarioInizio = (newValue) => {
    if (newValue !== null) {
      let newDate = staticDateWithTimeValue(newValue);
      dispatch(returnDateInizio(newDate));
      setValueDataInizio(new Date(minDate + newDate));
    } else {
      dispatch(returnDateInizio("null"));
      setValueDataInizio("null");
    }
  };

  const handleChangeOrarioFine = (newValue) => {
    if (newValue !== null) {
      let newDate = staticDateWithTimeValue(newValue);
      dispatch(returnDateFine(newDate.toString()));
      setValueDataFine(new Date(minDate + newDate));
    } else {
      dispatch(returnDateFine("null"));
      setValueDataFine("null");
    }
  };

  const handleValueUsers = (selectedValue) => {
    selectedValue = selectedValue.map((x) => ({
      nominativo: x.nominativo,
      idStanza: x.idStanza,
      idPosto: x.idPosto,
      posto: x.posto,
      immagineAvatar: x.immagineAvatar,
      ospite: x.ospite || false,
      utente: x.idUtente || x.utente,
    }));

    const value = selectedValue.filter((item, index, self) => {
      return !self
        .slice(index + 1)
        .some(
          (other) =>
            other.utente === item.utente &&
            other.nominativo === item.nominativo &&
            other.ospite === item.ospite
        );
    });

    dispatch(handlerSelectUtenti(value));
  };

  useEffect(() => {
    (async () => {
      const { action, method } = getUsersFiltered;
      let url = urlBase + action;
      try {
        const response = await axios({
          method: method,
          url,
        });
        setUsersRows(response.data.result);
        setStepNoAutoSkipped((old) => {
          if (old.indexOf(framesIndex) === -1) return [...old, framesIndex];
          return old;
        });
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
  }, []);

  function returnRadioButtonValue(value) {
    let valueRadio = value;
    setRadioTipoPrenotazione(valueRadio);
    dispatch(cambiaRadioButtonPrenotazionePerAltri(valueRadio));
    dispatch(resetUtentePrenotazione());
  }

  const { labelArr, showLabel, showOspiti } = handlerLabelPrenotazione(userDate, usersRows, guests);

  //se seleziono me stesso e ho degli ospiti, devo creare la prenotazione per me stesso.
  useEffect(() => {
    if (!isFirst) {
      let prenotazioneUtenteLoggato = utentePrenotazione.some(
        (x) => x.utente === null
      ); //la prenotazione dell'utente loggato viene inseria a null nell'elenco prenotazione;
      if (
        ospitiPrenotazioneInfo.length > 0 &&
        radioTipoPrenotazione === radioButtonPrenotazioneStepperMeAndOtherAndGuests[0].value
      ) {
        if (!prenotazioneUtenteLoggato) {
          let prenotazioneUserLogged = userLoggedToPrenotazioneObj(
            userDate,
            avatarUserLogged
          );
          setReload(!reload);
          dispatch(
            returnUtentePrenotazione([
              ...utentePrenotazione,
              prenotazioneUserLogged,
            ])
          );
        }
      } else if (prenotazioneUtenteLoggato) {
        let prenotazioniWithOutUserLogged = utentePrenotazione.filter((x) => {
          if (x.utente !== null) return x;
        });
        setReload(!reload);
        dispatch(returnUtentePrenotazione(prenotazioniWithOutUserLogged));
      }
    } else {
      setIsFirst(false);
    }
  }, [radioTipoPrenotazione, ospitiPrenotazioneInfo]);
  return (
    <React.Fragment>
      <Grid
        container
        rowSpacing={5}
        columnSpacing={5}
        className="container-stepper-datapickerbox"
      >
        {showLabel && <>
          <Grid container className="box-stepper-reserve-and-ospiti">
            <Grid item xs={12} className="stepper-grid">
              <RadioButtonCustom
                GroupName={t("reserve")}
                startValueReset={radioButtonPrenotazionePerAltri}
                labelList={
                  labelArr
                }
                handleChange={returnRadioButtonValue}
                tabNumber={
                  labelArr
                }
              />
            </Grid>
            {radioTipoPrenotazione ===
              radioButtonPrenotazioneStepperMeAndOtherAndGuests[1].value && (
                <Grid item xs={12} md={12} className="select-stepper-reserve">
                  <AutocompleteCustom
                    label={t("Book_behalf_of")}
                    field={["nominativo"]}
                    checkField={"nominativo"}
                    fullWidth={false}
                    keyId={"nominativo"}
                    openOnFocus={true}
                    optionsAutoComplete={usersRows}
                    handleValueReturned={handleValueUsers}
                    startValueAutoComplete={utentePrenotazione.filter((x) => {
                      if (x.ospite === false) return x;
                    })}
                    multiple={true}
                  />
                </Grid>
              )}
            {showOspiti && <Grid item container xs={12} className="box-stepper-guests">
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setDialogOspitiState(!dialogOspitiState);
                  }}
                >
                  {t("guests")}
                </Button>
              </Grid>
            </Grid>}
          </Grid>
          {showOspiti && <Grid item xs={12} md={12} className="stepper-grid">
            <PannelloOspiti ospiti={[]} />
          </Grid>}
        </>}
        <Grid rowSpacing={5} columnSpacing={5} container item xs={12}>
          <Grid item xs={12} sm={4} className="stepper-grid">
            <GeneralCustomDateTimePicker
              TypeTimePicker={5}
              label={t("Booking_date")}
              valueStart={valueDataGiorno}
              handleChange={handleChangeData}
              minDate={now}
              maxDate={maxday}
              placeholder={t("Booking_date_placeholder")}
            />
          </Grid>
          <Grid item xs={6} sm={4} className="stepper-grid">
            <GeneralCustomDateTimePicker

              TypeTimePicker={1}
              label={t("Start_time")}
              valueStart={valueDataInizio}
              handleChange={handleChangeOrarioInizio}
            />
          </Grid>
          <Grid item xs={6} sm={4} className="stepper-grid">
            <GeneralCustomDateTimePicker
              TypeTimePicker={1}
              label={t("End_time")}
              valueStart={valueDataFine}
              handleChange={handleChangeOrarioFine}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} className="stepper-grid">
          <RipetizioniBox maxday={maxday} minDayValue={valueDataGiorno} isConfiguredCorrectly={setIsRepeatBookingConfiguredCorrectly} />
        </Grid>
        <Grid container mb={isMobile ? 3 : 0} item xs={12} className="stepper-grid">
          <PannelloSuggerimentiPrenotazione label={t("Tips")} showSuggerimenti={(radioTipoPrenotazione ===
            radioButtonPrenotazioneStepperMeAndOtherAndGuests[0].value ||
            utentePrenotazione.length === 1) &&
            ospitiPrenotazioneInfo.length === 0}
            isRepeatBookingConfiguredCorrectly={isRepeatBookingConfiguredCorrectly}
            />
        </Grid>
      </Grid>
      <PrenotazioniOspiti
        open={dialogOspitiState}
        handleClose={setDialogOspitiState}
        selectOptions={usersRows}
        selectedOption={utentePrenotazione}
      />
    </React.Fragment>
  );
}

/* trasforma un orario in una data statica più orario flessibile*/
function staticDateWithTimeValue(newValue) {
  let hours = new Date(newValue).getHours();
  let minutes = new Date(newValue).getMinutes();
  let date = new Date(null);
  return moment(new Date(date.setHours(hours, minutes))).format("HH:mm");
}

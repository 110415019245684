import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { policies as permessi } from "../../const/policies";
import { useState } from "react";
import useGenerateQrCode from "../../Hooks/useGenerateQrCode";
import useMobileDetection from "../../Hooks/useMobileDetection";
import { GenerateQrCodePostazione } from "../../const/urlAction";
import { faBan, faPen, faQrcode, faRotateRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { handleDialogToEditPostazioni } from "./operations/handleDialogToEditPostazioni";

const ButtonBoxPostazioni = ({ row: rowInfo }) => {
  const configuration = useSelector((state) => state.configurations.configurazioniGlobali);
  const { enableQrCodeScanner, qrCodeDinamico } = configuration;
  const isSalaRiunione = rowInfo?.isSalaRiunione;
  const [qrCodeToGenerateBody, setQrCodeToGenerateBody] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector((state) => state.userDate);
  const { policies, responsabileEdificio } = state;
  const dispatch = useDispatch();
  const viewState = useSelector((state) => state.viewState);

  function handleDownloadQrCode(rowInfo) {
    const { id, qrCodePostazione } = rowInfo;
    setQrCodeToGenerateBody({ id, qrCodePostazione });
  }

  useGenerateQrCode(qrCodeToGenerateBody, setIsLoading, GenerateQrCodePostazione);

  const isMobile = useMobileDetection(600);
  const { t } = useTranslation();

  return (
    <>
      {!isMobile && !viewState && policies.indexOf(permessi.postazioniInserimentoModifica) !== -1 && (
        <GridActionsCellItem
          icon={
            <Tooltip title={t("edit")}>
              <FontAwesomeIcon icon={faPen} style={{ fontSize: 25 }} className="pathIcon" />
            </Tooltip>
          }
          label={t("user")}
          onClick={() =>
            handleDialogToEditPostazioni({ dialogType: "editUtenti", rowInfo }, dispatch)
          }
        />
      )}
      {(enableQrCodeScanner && !isMobile && !viewState ) && (!qrCodeDinamico || !rowInfo?.nominativoDevice) &&
        (policies.indexOf(permessi.postazioniDownloadQRCode) !== -1 ||
          responsabileEdificio.length > 0) && (
          <GridActionsCellItem
            icon={
              <Tooltip title={t("download_qr_code")}>
                {<FontAwesomeIcon icon={faQrcode} style={{ fontSize: 25 }} className="pathIcon" />}
              </Tooltip>
            }
            label={t("delete")}
            onClick={() => handleDownloadQrCode(rowInfo)}
          />
        )}
      {(policies.indexOf(permessi.postazioniEliminazioneRipristino) !== -1 ||
        responsabileEdificio.length > 0) && (
        <GridActionsCellItem
          sx={{ '& .MuiTouchRipple-root': { display: 'none' }}}
          className={isMobile || viewState ? "button-ButtonBox-Mobile forceNoPaddingLeft" : ""}
          icon={
            <Tooltip title={t("disable")}>
              {!isMobile && !viewState 
                ? <FontAwesomeIcon icon={faBan} style={{ fontSize: 25 }} className="pathIcon" />
                : <div style={{ display: "flex", alignItems: "center" }}>
                    <>
                      <FontAwesomeIcon icon={faBan} size="xs" style={{color: "#636161", marginRight: "3px" }} /> 
                      <Typography sx={{ fontSize: 10, color: "#636161"}}>{t("disable_v3")}</Typography>
                    </>
                  </div>
               }
            </Tooltip>
          }
          label={t("user")}
          onClick={() =>
            handleDialogToEditPostazioni({ dialogType: "disableOne", rowInfo }, dispatch)
          }
        />
      )}
      {(policies.indexOf(permessi.postazioniEliminazioneRipristino) !== -1 ||
        responsabileEdificio.length > 0) && (
        <GridActionsCellItem
        sx={{ '& .MuiTouchRipple-root': { display: 'none' }}}
        className={isMobile ? "button-ButtonBox-Mobile" : ""}
          icon={
            <Tooltip title={t("restore")}>
            {!isMobile && !viewState
            ? <FontAwesomeIcon icon={faRotateRight} style={{ fontSize: 25 }} className="pathIcon" />
            :<div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faRotateRight} size="xs" style={{ color: "#636161", marginRight: "3px" }} />
            <Typography sx={{ fontSize: 10, color: "#636161" }}>{t("restore_v2")}</Typography>
          </div>
            }</Tooltip>
          }
          label={t("user")}
          onClick={() =>
            handleDialogToEditPostazioni({ dialogType: "restoreOne", rowInfo }, dispatch)
          }
        />
      )}
      {!isSalaRiunione &&
        policies.indexOf(permessi.postazioniEliminazioneRipristino) !== -1 && (
          <GridActionsCellItem
          sx={{ '& .MuiTouchRipple-root': { display: 'none' }}}
           className={isMobile ? "button-ButtonBox-Mobile" : ""}
            icon={
              <Tooltip title={t("delete")}>
                {!isMobile && !viewState
                ? <FontAwesomeIcon icon={faTrash} style={{ fontSize: 25 }} className="pathIcon" />
                :<div style={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon icon={faTrash} size="xs" style={{ color: "#636161", marginRight: "3px" }} />
                <Typography sx={{ fontSize: 10, color: "#636161" }}>{t("delete_v3")}</Typography>
              </div>
                }</Tooltip>
            }
            label={t("delete")}
            onClick={() =>
              handleDialogToEditPostazioni({ dialogType: "delete", rowInfo }, dispatch)
            }
          />
        )}
    </>
  );
};

export default ButtonBoxPostazioni;

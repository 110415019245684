import { Grid } from "@mui/material";
import { flexbox } from "@mui/system";
import { BackButton } from "../Button/BackButton";
import { BackButtonChangeView } from "../Button/BackButtonChangeView";

export function TitleDatable({
  denominazione = "",
  nameDataTable = "",
  pathDataTable = "",
  changeView = null,
  mostraTipologia = false,
}) {
 
  const text = mostraTipologia ? denominazione + " (" + nameDataTable + ")"
                  : nameDataTable
 
  return (
    <>
      <div className="TitleDatable flex-center" >
        <Grid container className="flex-center" >
          <Grid
            item
            xs={9}
            sm={9}
            md={9}
            lg={9}
            xl={9}
            columnGap={1}
            className="flex-center"
          >
             {changeView ? (
              <BackButtonChangeView changeView={changeView} />
            ) : (
              <BackButton />
            )}
            {nameDataTable !== "" && text}
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            {nameDataTable !== "" && pathDataTable}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

import { useTranslation } from "react-i18next";

const useHeaderDataGridI18n = (header = []) => {
  const { t } = useTranslation();

  const translatedHeaders = header.map((item) => {
    return {
      ...item,
      headerName: t(item.headerName),
    };
  });
  
  return translatedHeaders;
};

export default useHeaderDataGridI18n;

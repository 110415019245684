import { Button } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { urlAbilitaEntita } from "../../../functions/UrlParams";
import { reloadingDataTableUsers } from "../../../redux/reducers/dataTableUsers-reducer";
import { closeDialog } from "../../../redux/reducers/DialogGeneral";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { hideLoader, showLoader } from "../../../redux/reducers/appLoader-reducer";

const urlBase = process.env.REACT_APP_URL;

export default function ButtonBoxRiattivaTuttePostazioni({ buttonText = "conferma" }) {

  const dispatch = useDispatch();
  const [changedRow, setchangedRow] = useState(null);
  const { dialogPageType, rowInfo } = useSelector((state) => state.dialogGeneral);
  const { entita, action, method } = urlAbilitaEntita(dialogPageType, rowInfo.id);

  useEffect(() => {
    if (changedRow != null) {
      (async () => {
        dispatch(showLoader());
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data: changedRow,
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.data.ok) {
            dispatch(reloadingDataTableUsers());
            dispatch(closeDialog());
          }
          dispatch(hideLoader());
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        } catch (error) {
          console.error(error);
          dispatch(hideLoader());
          dispatch(
            notificationOpen({
              message: error.response.data.message,
              status: "error",
            })
          );
        }
        
      })();
    }
  }, [changedRow]);

  function RiattivaTuttePostazioni() {
    setchangedRow({
      IdEntita: entita,
      DataOraInizioPrenotazione: null,
      DataOraFinePrenotazione: null,
      RestorePostazioni: true,
    });
  }

  return (
    <>
      <Button variant="contained" onClick={() => RiattivaTuttePostazioni()}>
        {buttonText}
      </Button>      
    </>
  );
}

import TextPartecipantiRiunione from "./TextPartecipantiRiunione";
import { Grid, Tooltip, FormControlLabel, Switch, Typography, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import PartecipantiPills from "../../../UI/ContainerPills/Pills/PartecipantiPills";
import ContainerPills from "../../../UI/ContainerPills/ContainerPills";
import { useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import { setPartecipantiRiunione, setInvioNotificaInvito, setTitoloRiunione } from "../../../../redux/reducers/prenotazioneStepper-reducer";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const idSalaRiunione = 'id'
export const ospiti = 'ospiti'

export default function PartecipantiRiunione({
    ospitiDaPrenotazioneStepper = [], // sono i partecipanti che ho selezionato nella stepper
    salaRiunione,
    titoloRiunione = "",
        })
        {
    const state = useSelector((state) => state.prenotazioneStepper.invioNotificaInvito[salaRiunione?.id]);
    const [partecipantiList, setPartecipantiList] = useState([]);
    const [saveEvent, setSaveEvent] = useState(undefined);
    const [reload, setReload] = useState(false);
    const [checked, setChecked] = useState(state || true);
     const dispatch = useDispatch();
     const { t } = useTranslation(); 
    function returnPartecipantiListText(value) {
        setPartecipantiList((old) => [...old, value]);
     }

    function changeInvioNotificaInvito(value) {
        setChecked(value[1]);
        dispatch(setInvioNotificaInvito({idPosto:value[0],invioNotificaInvito:value[1]}));
    }
     function handleDelete(value) {
        let arr = [...partecipantiList];
        arr.splice(value, 1);
        setPartecipantiList(arr)
        
        setReload(!reload);
    }
     useEffect(() => {
        let ospitiDellaSalaCorrente = ospitiDaPrenotazioneStepper?? [] // recupera solo quelli della sala corrente se presente oppure un array vuoto
        let listaAggiornataUtenti = ospitiDellaSalaCorrente.every(x => partecipantiList.includes(x)) // controlla se tutti gli utenti recuperati sono già presenti
        if(salaRiunione && !listaAggiornataUtenti) {
            let ospitiDellaRiunione = partecipantiList.concat(ospitiDellaSalaCorrente)
            setPartecipantiList(ospitiDellaRiunione)
            dispatch(setPartecipantiRiunione({[idSalaRiunione]: salaRiunione.id, [ospiti]: partecipantiList}));
        }
     }, [ospitiDaPrenotazioneStepper])

     useEffect(() => {
         if(salaRiunione) {
            dispatch(setPartecipantiRiunione({[idSalaRiunione]: salaRiunione.id, [ospiti]: partecipantiList}));
        }
     }, [partecipantiList])
    
    function changeTitoloRiunione(value) {
        dispatch(setTitoloRiunione({idPosto:value[0],titoloRiunione:value[1]}));
    }

useEffect(() => {
    if(state !== undefined)setChecked(state)
},[state])

    return(
        <Grid container className="flex-center-center RiunioniPanel">
            <Grid item xs={12} sm={12} md={8} lg={8}>
             <TextField
                margin="dense"
                id="TitoloRiunione"
                label={t("Meeting_Title")}
                fullWidth
                variant="standard"
                value={titoloRiunione}
                onChange={(e) => changeTitoloRiunione([salaRiunione.id,e.target.value])}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
            <FormControlLabel
              control = {<Switch  checked={checked} onChange={(event) => changeInvioNotificaInvito([salaRiunione.id,event.target.checked])} />}
              label={<Typography>{t("Invite_email")}</Typography>}/>
                
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
                <TextPartecipantiRiunione 
                    returnValueFromText={returnPartecipantiListText}
                    allPartecipantiPills={partecipantiList}
                    eventButton={saveEvent}/>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
                <Tooltip title="Aggiungi">
                    <span>
                        <SendIcon className="buttonSincro" onClick={(e) => setSaveEvent(e)}/>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
            <ContainerPills
              params={{ Component: PartecipantiPills, handleDelete }}
              element={partecipantiList}
            />
          </Grid>
        </Grid>
    )

}
import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef } from "react";
import { TabPanelComponentToShow } from "./TabPanelComponentToShow";
import { useTranslation } from 'react-i18next';
import useViewportAdaptedHeight from "../../../Hooks/Adaptive/useViewportAdaptedHeight";
import BalanceCustomMainTabs from "../../../functions/BalanceAdaptiveHeightForUseAdaptiveHook/BalanceCustomMainTabsComponent";
import useMobileDetection from "../../../Hooks/useMobileDetection";


export default function CustomMainTabsComponent({ tabPanel, lastTabsSelected, setLastTabsSelected, noTabsComponent = <>Nessuna Tab selezionata</>, render }) {
  const actionRef = useRef();
  const [valueTabSelected, setValueTabSelected] = React.useState(lastTabsSelected);
  const [balanceAdaptive, setBalanceAdaptive] = React.useState(null);  // Usa uno stato per balanceAdaptive
  const { t } = useTranslation();
  const refBox = useRef(null);

  const handleChange = (event, newValue) => {
    if(newValue > 1) {
      setValueTabSelected(newValue)
      setLastTabsSelected(0);
    } else {
      setValueTabSelected(newValue)
      setLastTabsSelected(newValue);
    }
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      actionRef.current.updateIndicator();
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [valueTabSelected]);

  React.useEffect(() => {
    const newBalance = BalanceCustomMainTabs(valueTabSelected);
    setBalanceAdaptive(newBalance);
  }, [valueTabSelected]);

  let adaptHeight = useViewportAdaptedHeight(
    ".adaptHeightDialog",
    balanceAdaptive,
    refBox,
    true
  );

  const isMobile = useMobileDetection();

  //le dialog non vengono gestite in tutti i casi nel medesimo modo
  adaptHeight = isMobile ? adaptHeight : '100%'
  
  return (
    <>
      <Box className="adaptHeightDialog">
        <Tabs value={valueTabSelected} onChange={handleChange} action={actionRef} centered>
          {tabPanel.map((tab, index) => {
            return <Tab key={index} label={t(tab.label)} />;
          })}
        </Tabs>
      </Box>
      {tabPanel.length > 0 ? 
        tabPanel.map((tab, index) => {
          if (index === valueTabSelected) {
            return (
              <TabPanelComponentToShow
                key={index}
                componentTab={tab.componentTab}
                index={index}
                withPadding={false}
                height={adaptHeight}
                refBox={refBox}
                overFlowY={true}
              />
            );
          }
          return null;
        })
      : noTabsComponent}
    </>
  );
}

import { Card, Skeleton } from "@mui/material";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import {
  showLoader,
  hideLoader,
} from "../../../redux/reducers/appLoader-reducer";
import MostraMappa from "../mostraMappa/MostraMappa";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { getPrenotazioneDettaglioUtentePlanimetriaByIdPrenotazione } from "../../../const/urlAction";
import handlerError from "../../../functions/handlerError";
import { planimetriaUrlParamsForBackgroundPlanimetriaMostraInfo } from "../../../functions/UrlParams";
import InfoSubMenu from "../InfoSubMenu/InfoSubMenu";

const urlBase = process.env.REACT_APP_URL;

export default function PlanimetriaMostraRiepilogoPrenotazione({
  dati = [],
  returnSizePlanimetria,
}) {
  const dispatch = useDispatch();
  const [utentePrincipale, setUtentePrincipale] = useState({});
  const [elementiDalDb, setElementiDalDb] = useState([]);
  const [SfondoPlanimetria, setSfondoPlanimetria] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // const appLoadingState = useSelector((state) => state.appLoader.loading);

  const {
    id: idPrenotazione,
    dataInizioPrenotazione,
    dataFinePrenotazione,
    idPostazione,
    idStanza,
  } = dati;

  //ritorna le dimensioni alla dialog
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      returnSizePlanimetria({
        larghezzaPlanimetria: SfondoPlanimetria?.larghezzaPlanimetria,
        altezzaPlanimetria: SfondoPlanimetria?.altezzaPlanimetria,
      });
    }

    return () => {
      isMounted = false;
    };
  }, [SfondoPlanimetria]);

  //recupera gli elementi
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { method, action } =
        getPrenotazioneDettaglioUtentePlanimetriaByIdPrenotazione;

      const body = {
        idPrenotazione,
        idPostazione,
        dataPrenotazioneInizio: dataInizioPrenotazione,
        DataPrenotazioneFine: dataFinePrenotazione,
      };

      let url = urlBase + action;
      try {
        const response = await axios({
          method: method,
          url,
          data: body,
          headers: {
            "Content-Type": "application/json",
          },
        });
        setUtentePrincipale(response.data.result.utentePrincipale);
        setElementiDalDb(response.data.result.postazioni);
        setSfondoPlanimetria(response.data.result.planimetria);
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
      // dispatch(hideLoader());
      setIsLoading(false);
    })();
  }, []);

  let imageBackground =
    planimetriaUrlParamsForBackgroundPlanimetriaMostraInfo(idStanza);

  function actionOnClickIcon(event, id, index, status) {
    return index; //ritorna l'indice dell'elemento selezionato
  }
  return (
    <>
      {/* {!appLoadingState && ( */}
      {!isLoading 
      ? (
        <MostraMappa
          dettaglioSfondoPlanimetria={SfondoPlanimetria}
          elementiDalDb={elementiDalDb}
          infoPannelloRiepilogativo={utentePrincipale}
          imageBackground={imageBackground}
          // IconsToCreate={listaPostazioneIcon}
          actionOnClickButton={actionOnClickIcon}
          SubMenu={InfoSubMenu}
        />
      ) 
      : (
        <>
          <Skeleton variant="rectangular" height={240} />
        </>
        )}
    </>
  );
}

import DialogViewEditUserLocalDatabase from "../../../../../components/Dialog/UserDialog/componentUserDialog/viewUserDialog/EditUserView/DialogViewEditUserLocalDatabase";
import DialogViewEditUserActiveDirectory from "../../../../../components/Dialog/UserDialog/componentUserDialog/viewUserDialog/EditUserView/DialogViewEditUserActiveDirectory";
import { labelArrayDialogUserAddEdit } from "../../../../RadioButton/RadioButtonListRadio";


export function ListTabsDialogoUserEdit(infoUser,authConfiguration, returnValue, isCommitted, setIsCommitted ) {
  let arrayTabs = []

  if(authConfiguration.enableDatabaseAuth) arrayTabs.push({ componentTab: <DialogViewEditUserLocalDatabase infoUser={infoUser} returnValue={returnValue} isCommitted={isCommitted} setIsCommitted={setIsCommitted} />, label: labelArrayDialogUserAddEdit[1] })
  if(authConfiguration.enableActiveDirectoryAuth) arrayTabs.push({ componentTab: <DialogViewEditUserActiveDirectory infoUser={infoUser} returnValue={returnValue} isCommitted={isCommitted} setIsCommitted={setIsCommitted} />, label: labelArrayDialogUserAddEdit[0]})

  return arrayTabs;
  }
import React from "react";
import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import {policies as permessi} from "../../const/policies"
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import { useNavigate } from 'react-router-dom';
import useMobileDetection from '../../Hooks/useMobileDetection';
import { useState } from 'react';
import useGenerateQrCode from '../../Hooks/useGenerateQrCode';
import { GenerateQrCodeStanza } from '../../const/urlAction';
import { faBan, faPen, faQrcode, faTrash, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { handleDialogToEditStanza, handleDialogToEnableDisableStanza } from "./operations/handleDialogEditStanza";
import { dialogPageTypes } from "../../const/dialogPageTypes";
import handlerButtonRoom from "../../functions/boxButton/handlerButtonRoom";

const ButtonBoxStanze = ({ row:rowInfo }) => {
  const configuration = useSelector((state) => state.configurations.configurazioniGlobali);
  const { enableQrCodeScanner, qrCodeDinamico } = configuration;
  const state = useSelector((state) => state.userDate);
  const { policies, responsabileEdificio } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [idQrCodeToGenerate, setIdQrCodeToGenerate] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const viewState = useSelector((state) => state.viewState);
  const isSalaRiunione = rowInfo?.isSalaRiunione;
  const idStanza = rowInfo?.id;
  const isMobile = useMobileDetection(600);

  function handleDownloadQrCode(rowInfo) {
    const { id } = rowInfo;
    setIdQrCodeToGenerate(id);
  }

  useGenerateQrCode(idQrCodeToGenerate, setIsLoading, GenerateQrCodeStanza);

  let icon = handlerButtonRoom(rowInfo.idTipologiaStanza);

  return (
    <>
      {!isMobile && !viewState && policies.indexOf(permessi.zoneInserimentoModifica) !== -1 && (
        <GridActionsCellItem
          icon={
            <Tooltip title={t("edit")}>
              <FontAwesomeIcon
                icon={faPen}
                style={{ fontSize: 25 }}
                className="pathIcon"
              />
            </Tooltip>
          }
          label={t("edit")}
          onClick={(event) => handleDialogToEditStanza({ type: "edit", row:rowInfo }, dispatch, event)}
        />
      )}
      {!isSalaRiunione && !isMobile && !viewState && policies.indexOf(permessi.stanzeVisualizzazione) !== -1 && (
        <GridActionsCellItem
          icon={
            <Tooltip title={t("manage_element")}>
              {icon}
            </Tooltip>
          }
          label={t("workstations")}
          onClick={() => navigate("" + idStanza)}
        />
      )}
      {enableQrCodeScanner && !qrCodeDinamico && !isMobile && !viewState &&
        (policies.indexOf(permessi.postazioniDownloadQRCode) !== -1 ||
          responsabileEdificio.length > 0) && (
          <GridActionsCellItem
            icon={
              <Tooltip title={t("download_qr_code")}>
                {<FontAwesomeIcon
                  icon={faQrcode}
                  style={{ fontSize: 25 }}
                  className="pathIcon"
                />}
              </Tooltip>
            }
            label={t("download_qr_code")}
            onClick={() => handleDownloadQrCode(rowInfo)}
          />
        )}
      {(policies.indexOf(permessi.edificiAbilitaDisabilita) !== -1 ||
        responsabileEdificio.length > 0) && (
        <GridActionsCellItem
          sx={{ '& .MuiTouchRipple-root': { display: 'none' }}}
          className={isMobile || viewState ? "button-ButtonBox-Mobile forceNoPaddingLeft" : ""}
          icon={
            <Tooltip title={t("disable_v3")}>
              {!isMobile && !viewState 
                ? <FontAwesomeIcon icon={faBan} style={{ fontSize: 25 }} className="pathIcon" />
                : <div style={{ display: "flex", alignItems: "center" }}>
                    <>
                      <FontAwesomeIcon icon={faBan} size="xs" style={{color: "#636161", marginRight: "3px" }} /> 
                      <Typography sx={{ fontSize: 10, color: "#636161"}}>{t("disable_v3")}</Typography>
                    </>
                  </div>
               }
            </Tooltip>
          }
          label={t("disable_v3")}
          onClick={(event) =>
            handleDialogToEnableDisableStanza({ dialogPageType: dialogPageTypes.stanze, dialogType: "disableRangeData", rowInfo }, dispatch, event)
          }
        />
      )}
      {(policies.indexOf(permessi.edificiAbilitaDisabilita) !== -1 ||
        responsabileEdificio.length > 0) && (
        <GridActionsCellItem
        sx={{ '& .MuiTouchRipple-root': { display: 'none' }}}
        className={isMobile ? "button-ButtonBox-Mobile" : ""}
          icon={
            <Tooltip title={t("restore_v2")}>
            {!isMobile && !viewState
            ? <FontAwesomeIcon icon={faRotateRight} style={{ fontSize: 25 }} className="pathIcon" />
            :<div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faRotateRight} size="xs" style={{ color: "#636161", marginRight: "3px" }} />
            <Typography sx={{ fontSize: 10, color: "#636161" }}>{t("restore_v2")}</Typography>
          </div>
            }</Tooltip>
          }
          label={t("restore_v2")}
          onClick={(event) =>
            handleDialogToEnableDisableStanza({ dialogPageType: dialogPageTypes.stanze, dialogType: "ripristinaAll", rowInfo }, dispatch, event)
          }
        />
      )}
      {policies.indexOf(permessi.stanzeEliminazioneRipristino) !== -1 && (
        <GridActionsCellItem
        sx={{ '& .MuiTouchRipple-root': { display: 'none' }}}
        className={isMobile ? "button-ButtonBox-Mobile" : ""}
          icon={
            <Tooltip title={!rowInfo?.dataDel ? t("delete") : t("enable")}>
              {rowInfo?.dispositivoAbilitato ? 
                <FontAwesomeIcon
                  icon={faBan}
                  style={{ fontSize: 25 }}
                  className="pathIcon"
                />
               : 
               !isMobile && !viewState ? <FontAwesomeIcon
                  icon={faTrash}
                  style={{ fontSize: 25 }}
                  className="pathIcon"
                />
                : <><FontAwesomeIcon icon={faTrash} size="xs" style={{color: "#636161", marginRight: "3px" }} /> <Typography sx={{ fontSize: 10, color: "#636161"}}>{t("delete_v3")}</Typography></>}
            </Tooltip>
          }
          label={t("delete")}
          onClick={(event) => handleDialogToEditStanza({ type: "delete", row:rowInfo }, dispatch, event)}
        />
      )}
    </>
  );
};

export default ButtonBoxStanze;

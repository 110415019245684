import axios from "axios";
import moment from "moment";
import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listaPostazioneIcon,
  listaStanzaIcon,
} from "../../../../const/Planimetria/IconPlanimetria";
import { DISPONIBILE } from "../../../../const/Planimetria/StatoPostazione";
import { GetPostazioniWithStatusByIdStanza } from "../../../../const/urlAction";
import handlerError from "../../../../functions/handlerError";
import useBackGroundPlanimetria from "../../../../Hooks/useBackgroundPlanimetria";
import { hideLoader } from "../../../../redux/reducers/appLoader-reducer";
import { savePostazioneCompletoInPrenotazioneStepper } from "../../../../redux/reducers/infoPrenotazione-reducer";
import { notificationOpen } from "../../../../redux/reducers/notification-reducer";
import {
  cambiaStatoTastoPrenotazione,
  returnPostazionePrenotata,
} from "../../../../redux/reducers/prenotazioneStepper-reducer";
import PlanimetriaPrenotazine from "../../../Planimetria/PlanimetriaPrenotazione/PlanimetriaPrenotazione";
import { AutocompleteCustom } from "../../../UI/AutoComplete/AutoComplete";
import LegendaComponent from "../../../Planimetria/PlanimetriaPrenotazione/legenda/legendaMap/LegendaComponent";
import { Box } from "@mui/system";

const typePage = "stanza";
const urlBase = process.env.REACT_APP_URL;

export default function PostazionePlanimetriaBoxHome({
  setStepNoAutoSkipped,
  framesIndex,
}) {
  const dispatch = useDispatch();
  const [elementiDalDb, setElementiDalDb] = useState([]);
  const [dettaglioStanza, setDettaglioStanza] = useState({});
  const [legendaInfoStanza, setLegendaInfoStanza] = useState([]);
  const info = useSelector((state) => state.infoPrenotazione);
  const state = useSelector((state) => state.prenotazioneStepper);
  const planimetriaState = useSelector((state) => state.planimetria);
  const userSelectedInStepper = useSelector((state) => state.multiUserHandlerInStepper);

  const {
    dataFine,
    dataGiorno,
    dataInizio,
    ripetizioneGiorni,
    ripetizioneDataFine,
    ripetizioneStatus,
    utentePrenotazione,
    dataGiornoFromPrenotazioniComponent,
    postazioneId,
  } = state;
  
  useLayoutEffect(() => {
    (async () => {
      const requestBody = {
        idUtentePrenotazione: utentePrenotazione.idUtente || "",
        dataPrenotazione: moment(dataGiorno ?? dataGiornoFromPrenotazioniComponent).format(),
        oraInizioPrenotazione: dataInizio,
        oraFinePrenotazione: dataFine,
        dataFineRipetizioni: moment(ripetizioneDataFine).format(),
        giorniRipetizione: ripetizioneGiorni,
        IdStanza: info.idStanza,
      };

      const { method, action } = GetPostazioniWithStatusByIdStanza;

      let url = urlBase + action;
      try {
        const response = await axios({
          method: method,
          url,
          data: requestBody,
          headers: {
            "Content-Type": "application/json",
          },
        });
        setLegendaInfoStanza(response.data.result.legendaInfo);
        setDettaglioStanza(response.data.result.dettaglio);
        setElementiDalDb(response.data.result.listaEntita);
        setStepNoAutoSkipped((old) => {
          if (old.indexOf(framesIndex) === -1) return [...old, framesIndex];
          return old;
        });
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
      dispatch(hideLoader());
    })();

    return () => {
      dispatch(savePostazioneCompletoInPrenotazioneStepper({}));
    };
  }, [userSelectedInStepper, planimetriaState]);

  function actionOnClickIcon(event, id, index, posto, status) {
    if (status === DISPONIBILE) {
      dispatch(returnPostazionePrenotata(id));

    }
    return {index, posto}; //ritorna l'indice dell'elemento selezionato
  }

  // controlla che il numero di postazioni prenotate e lo confronta con il numero di utenti selezionati.
  useEffect(() => {
    let numUtenti = state.utentePrenotazione.length;
    let arrPosto = state.utentePrenotazione.map((x) => x.idPosto || -1);
    arrPosto = arrPosto.filter(x =>  x > 0);

    if(numUtenti === 0) {
      state.postazioneId !== 0
      ? dispatch(cambiaStatoTastoPrenotazione(false))
      : dispatch(cambiaStatoTastoPrenotazione(true));
    } else {
      arrPosto.length === numUtenti
      ? dispatch(cambiaStatoTastoPrenotazione(false))
      : dispatch(cambiaStatoTastoPrenotazione(true));
    }
  }, [state.utentePrenotazione]);

  let imageBackground = useBackGroundPlanimetria(info);

  return (
    <>
      {imageBackground !== 0 && elementiDalDb.length > 0 && (
        <PlanimetriaPrenotazine
          dettaglioSfondoPlanimetria={dettaglioStanza}
          elementiDalDb={elementiDalDb}
          typePage={typePage}
          imageBackground={imageBackground}
          IconsToCreate={listaPostazioneIcon}
          actionOnClickButton={actionOnClickIcon}
        />
        )}
        {legendaInfoStanza.length > 0 && <LegendaComponent infoMap={legendaInfoStanza}/>}
    </>
  );
}

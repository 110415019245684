import { Divider, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CDCard, CDCardContent, CDCardHeader } from "../../const/CDCardStyles";
import FaqAccordion from "./FaqAccordion";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import handlerError from '../../functions/handlerError';
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import { getFaq } from "../../const/urlAction";

const urlBase = process.env.REACT_APP_URL;

export default function FaqComponent() {
  /* const faq = useSelector((state) => state.configurations.configurazioniGlobali.faq); */
  const dispatch = useDispatch();
  const culture = useSelector((state) => state.culture.currentCulture);
  const [faq, setFaq] = useState();

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const { action, method } = getFaq;
      let url = urlBase + action + culture;
      try {
        const response = await axios({
          method: method,
          url,
        });
        if (isMounted) {
          setFaq(response.data.result);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [culture]);

  return (
    <Grid container>
      <Grid item id="adaptiveCard" component={CDCard} xs={12} sm={12} >
        <CDCardHeader
          className="CDCardHeader-Padding forceCdCardHeader-height"
          title={" FAQ"
          }
        />
        <Divider />
        <Grid container item className="faqGridContainer">
          <CDCardContent
            className="no-padding-MuiCardContent-root "
            style={{ width: "100%" }}
          >
            {faq?.q_and_A && faq?.q_and_A.map((x, index) => {
              return (
                <FaqAccordion key={index} answer={x.answer} question={x.question} />
              )
            })
            }
          </CDCardContent>
        </Grid>
      </Grid>
    </Grid>)
}
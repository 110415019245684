import { Grid, Typography, Button, DialogContent, Avatar } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { checkOutPrenotazione } from "../../../../../../const/urlAction";
import handlerError from "../../../../../../functions/handlerError";
import {
  hideLoader,
  showLoader,
} from "../../../../../../redux/reducers/appLoader-reducer";
import { notificationOpen } from "../../../../../../redux/reducers/notification-reducer";
import { loadprenotazioniTableState } from "../../../../../../redux/reducers/prenotazioneTable-reducer";
import TitleScannedQrCode from "../../GeneralComponentQrCode/titleScannedQrCode";
import ResponseQrCode from "../../GeneralComponentQrCode/ResponseQrCode";
import { grandezzaScritte } from "../../../../../../functions/createLogoPostazioneScannedQrCode";
import { Box } from "@mui/system";
import { enumCheckInTypology } from "../../../../../../const/prenotazioni/enumCheckInTypology";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faWarning } from "@fortawesome/free-solid-svg-icons";

const urlBase = process.env.REACT_APP_URL;

const grandezzaScritteCheckQr = "18px";

export default function BodyComponentPostazioneCheckOut({
  prenotazioneCheckIn,
  checkInDependency = 0,
  returnComponentState = () => { },
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (checkInDependency) {
      (async () => {
        dispatch(showLoader());
        const { action, method } = checkOutPrenotazione;
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data: {
              id: prenotazioneCheckIn.id,
              checkInTypology: enumCheckInTypology.QrCodeCheckIn,
              notaCancellazione: "QrCode_check-out",
            },
          });
          if (response.data.ok) {
            returnComponentState(false);
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          }
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
        }
        dispatch(loadprenotazioniTableState());
        dispatch(hideLoader());
      })();
    }
  }, [checkInDependency]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding={2}
        boxSizing="border-box"
      >
        {/* <Grid container>
          <TitleScannedQrCode title={t("Check-out_Reservation")} />
        </Grid> */}
        <Grid container className="flex-center-center">
          <Grid mt={2} item xs={12} className="flex-center-center">
            {prenotazioneCheckIn.ospite || !prenotazioneCheckIn.immagineAvatar
              ? <Avatar
                alt="Nome Utente"
                children={<FontAwesomeIcon icon={faUser} className="avatarIcon" />}
                sx={{ width: 100, height: 100 }}
              />
              :
              <Avatar
                alt="Nome Utente"
                src={"data:image/*;base64," + prenotazioneCheckIn.immagineAvatar}
                sx={{ width: 100, height: 100 }}
              />
            }
          </Grid>
          {/* Nome e Cognome */}
          {prenotazioneCheckIn.ospite
            ? <>
              <Grid item xs={12}>
                <Typography textAlign={"center"} variant="h6" fontWeight="bold">{prenotazioneCheckIn.ospite}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography textAlign={"center"} sx={{ color: "grey" }}>{t("guest_of_user")}{" "}{prenotazioneCheckIn.nominativo}</Typography>
              </Grid>
            </>
            :
            <Grid item xs={12}>
              <Typography textAlign={"center"} variant="h6" fontWeight="bold">{prenotazioneCheckIn.nominativo}</Typography>
            </Grid>
          }
          <Typography mt={1} className="cardRiepilogoText">
            {moment(prenotazioneCheckIn.dataOraInizioPrenotazione).format(
              "HH:mm"
            )}{" "}
            -{" "}
            {moment(prenotazioneCheckIn.dataOraFinePrenotazione).format(
              "HH:mm"
            )}
          </Typography>
        </Grid>
        <Grid mt={4} container>
          <ResponseQrCode
            text1={t("Confirm_check-out")}
            fontSize={grandezzaScritteCheckQr}
            className="flex-center-center"
          />
        </Grid>
      </Box >
    </>
  );
}

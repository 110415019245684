import { useEffect } from "react";
import { useState } from "react";
import ToogleCustom from "../../../UI/Toggle/ToogleCustom";
import { useTranslation } from "react-i18next";

export default function ToggleAdminInclude({
  returnValueToogle = () => undefined,
}) {
  const [toggleState, setToggleState] = useState(false);
  const { t } = useTranslation(); 

  function handleChangeToggle(event) {
    setToggleState(event.target.checked);
  }

  useEffect(() => {
    returnValueToogle(toggleState);
  }, [toggleState]);

  return (
    <>
          <ToogleCustom
            handleChangeToggle={handleChangeToggle}
            toggleState={toggleState}
            formLabel= {t("msg_1")}
            id="applicaAdmin"
          />
    </>
  );
}

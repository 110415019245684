import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { dialogButtonRiepilogo } from "../../../../const/DialogData";
import { planimetriaUrlParamsForBackgroundPlanimetriaMostraInfo } from "../../../../functions/UrlParams";
import useMobileDetection from "../../../../Hooks/useMobileDetection";
import PlanimetriaMostraRiepilogoPrenotazione from "../../../Planimetria/PlanimetriaMostraRiepilogoPrenotazione/PlanimetriaMostraRiepilogoPrenotazione";
import PlanimetriaDisponibilita from "../PlanimetriaDispoibilita/PlanimetriaDisponibilita";
import { useTranslation } from "react-i18next";

const now = new Date(Date.now());
export default function DialogMostraMappaDisponibilita({ rows = [], dataSelected = now , rangeTime = [], activeBackStepPath = () => {}, returnHandlerStateDialogMappaDisponibilita = () => null  }) {
  const [isOpenRiepilogo, setIsOpenRiepilogo] = useState(false);
  const { t } = useTranslation();
let dettaglioSfondoPlanimetria = {
  altezzaPlanimetria: rows?.planimetria?.planimetria.altezzaPlanimetria,
  larghezzaPlanimetria: rows?.planimetria?.planimetria.larghezzaPlanimetria,
  idSfondoPlanimetria: rows?.planimetria?.planimetria.idSfondoPlanimetria
}

useEffect(() => {
  returnHandlerStateDialogMappaDisponibilita( () => setIsOpenRiepilogo);
},[])

  function closeDialogRiepilogo() {
    setIsOpenRiepilogo(false)
    activeBackStepPath(old => !old)
  }

  useEffect(() => {
    if(rows?.planimetria?.postazioni?.length > 0) setIsOpenRiepilogo(true)
  },[rows.planimetria?.postazioni])
  
  const isMobile = useMobileDetection();

  return (
    <Dialog
      fullScreen={isMobile}
      open={isOpenRiepilogo}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: Math.max(rows?.planimetria?.planimetria.larghezzaPlanimetria,500),
            minWidth:"300px"
          },
          "& .MuiTabs-flexContainer": {
            justifyContent: "center",
            minWidth:"300px"
          },
        },
      }}
    >
      <>
        <Grid container columnSpacing={30}>
          <Grid item></Grid>
          <Grid item sm={12} md={12} xs={12}>
            <DialogTitle>{t("detail_Occupation")}</DialogTitle>
            <PlanimetriaDisponibilita
              dettaglioSfondoPlanimetria={dettaglioSfondoPlanimetria}
              idStanza={rows?.planimetria?.idStanza}
              dati={rows?.planimetria?.postazioni}
              dataSelected={dataSelected}
              rangeTime={rangeTime}
              />
            <DialogActions className="border-Action-dialog">
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Button onClick={() => closeDialogRiepilogo()}>
                        {t(dialogButtonRiepilogo.Indietro)}
                      </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </Grid>
      </>
    </Dialog>
  );
}

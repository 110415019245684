
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import GroupsIcon from "@mui/icons-material/Groups";

import {
  DISABILITATA,
  DISPONIBILE,
  OCCUPATA,
  RISERVATA,
} from "./StatoPostazione";
import { faCheck, faDesktop, faDoorClosed, faSquare, faSquareParking, faStar, faUtensils } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



//General
export const iconSelectedElement = <FontAwesomeIcon icon={faCheck} />;

//Stanza Default
export const iconStanzaDefault = <FontAwesomeIcon icon={faDoorClosed}  style={{alignSelf: "center"}}/>;

//Postazione Default
export const iconPostazioneDefault = <FontAwesomeIcon icon={faSquare} />;

//Stanza ufficio
export const iconStanzaUfficio = <FontAwesomeIcon icon={faDoorClosed}  style={{alignSelf: "center"}}/>;
export const iconPostazioneUfficio = <FontAwesomeIcon icon={faDesktop} />;

//Stanza Riunione
export const iconStanzaRiunione = <GroupsIcon />;
export const iconPostazioneRiunione = <GroupsIcon />;

//Stanza mensa
export const iconStanzaMensa = <FontAwesomeIcon icon={faUtensils} size="xl"/>
export const iconPostoStanzaMensa = <FontAwesomeIcon icon={faUtensils} size="xl" />

//Stanza parcheggio
export const iconStanzaParcheggio = <FontAwesomeIcon icon={faSquareParking}  size="xl" />
export const iconPostoStanzaParcheggio = <FontAwesomeIcon icon={faSquareParking} size="xl" />

//Postazione
export const iconDisablePostazione = <DoNotDisturbIcon />;
export const iconBusyPostazione = <FontAwesomeIcon icon={faDesktop} /> ;
export const iconReservedPostazione = <DoNotDisturbIcon />;

//Postazione SalaRiunioni
export const iconBusySalaRiunionePostazione = <GroupsIcon />;

//vengono esportati nel metodo assegnaIconeInPlanimetriaGestione
export const stanzaUfficioIcon = { general: iconStanzaUfficio };
export const postazioneIconStanzaUfficio = { general: iconPostazioneUfficio };
export const stanzaRiunioneIcon = { general: iconStanzaRiunione };
export const postazioneInSalaRiunione = { general: iconPostazioneRiunione };
export const stanzaMensaIcon = { general: iconStanzaMensa };
export const postazioneIconStanzaMensa = { general: iconPostoStanzaMensa };
export const stanzaParcheggioIcon = { general: iconStanzaParcheggio };
export const postazioneIconStanzaParcheggio = { general: iconPostoStanzaParcheggio };
export const postazioneIconSemplice = { general: iconPostazioneDefault };

export const listaPostazioneIcon = {
  general: iconPostazioneDefault,
  salaRiunione: iconPostazioneRiunione,
  stanzaMensa: iconPostoStanzaMensa,
  stanzaParcheggio: iconPostoStanzaParcheggio,
  stanzaUfficio: iconPostazioneUfficio,
  disable: iconDisablePostazione,
  busy: iconBusyPostazione,
  reserved: iconReservedPostazione,
};

export const listaPostazioneSalaRiunioniIcon = {
  general: iconPostazioneRiunione,
  disable: iconDisablePostazione,
  busy: iconBusySalaRiunionePostazione,
  reserved: iconReservedPostazione,
};

export const iconsSalaRiunioni = {
  postazione: iconPostazioneRiunione,
  stanza: iconStanzaRiunione,
};

export const iconsStanzaMensa = {
  postazione: iconPostazioneRiunione,
  stanza: iconStanzaRiunione,
};

//lo style fa riferimento alle classi presenti nel css 'PlanimetriaIcons.css'
export function statusToIcon(
  Icon,
  element,
  iconWithStyle = false,
  userPointOfView = null
) {
  let icon = Icon.general;
  let style = "generalElement";

  if (element?.utentiPostazione) {
    let utentiPostazione = element.utentiPostazione.map(x => x.idUtente)
    if(utentiPostazione.includes(userPointOfView?.utente)) {
      element?.prenotazioni.length > 0 
      ? element.status = OCCUPATA
      : element.status = DISPONIBILE;
    }
  }
  
  if (iconWithStyle) {
    if (element.postazioniDisponibili === false)
    return { icon: icon, style: "PostazioneDisabled" };
    switch (element.status) {
      case OCCUPATA:
        icon = iconBusyPostazione;
        style = "PostazioneBusy";
        break;
        case DISABILITATA:
          icon = iconBusyPostazione;
          style = "PostazioneDisabled";
          break;
          case RISERVATA:
            icon = iconReservedPostazione;
            style = "Postazionereserved";
        break;
    }
  }

  return { icon, style };
}

export function statusToIconStanzaSalaRiunione(
  Icon,
  element,
  iconWithStyle = false,
  userPointOfView = null,
) {
  let icon = Icon.general;
  let style = "generalElement";

  if (element?.utentiPostazione) {
    let utentiPostazione = element.utentiPostazione.map(x => x.idUtente)
    if(utentiPostazione.includes(userPointOfView?.utente)) {
      element?.prenotazioni.length > 0 
      ? element.status = OCCUPATA
      : element.status = DISPONIBILE;
    }
  }

  if (true) {
    switch (true) {
      case element.status === OCCUPATA:
        icon = iconBusyPostazione;
        style = "PostazioneBusy";
        break;
      case element.status === DISABILITATA:
        icon = iconBusyPostazione;
        style = "PostazioneDisabled";
        break;
      case element.status === RISERVATA:
        icon = iconReservedPostazione;
        style = "Postazionereserved";
        break;
    }
  }
  return { icon, style };
}

export function statusToIconRiepilogoPrenotazione(
  Icon,
  element,
  utentePrincipale = undefined
) {
  const {prenotazioni, isSalaRiunione, idTipologiaStanza} = element;
  
  let IconsToCreate

  switch(idTipologiaStanza) {
    case 1:
      IconsToCreate = postazioneIconStanzaUfficio
    break;
    case 2:
      IconsToCreate = postazioneInSalaRiunione
    break;
    case 3:
      IconsToCreate = postazioneIconStanzaParcheggio
    break;
    case 4:
      IconsToCreate = postazioneIconStanzaMensa
    break;
  }

  let icon = IconsToCreate.general;
  let style = "generalElement";
if(prenotazioni.length == 0)  
    return { icon: icon, style: "generalElement" };
if (prenotazioni.every((x) => x.disabilitato === true))
    return { icon: iconReservedPostazione, style: "PostazioneDisabled" };
  if (prenotazioni.length > 1) 
    return {icon:icon, style: "PostazioneBusy" }

  return { icon, style };
}

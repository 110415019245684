import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import {policies as permessi} from "../../const/policies"
import { useNavigate } from 'react-router-dom';
import {multiDialogAction} from '../../const/Dialog/MultiDialogAction';
import { faBan, faLocationDot, faPen, faTrash, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../Hooks/useMobileDetection";
import { Typography } from "@mui/material";
import { handleDialogEditBuilding, handleDialogToEnableDisableBuilding } from "./operations/handleDialogEditBuilding";
import { dialogPageTypes } from "../../const/dialogPageTypes";

const ButtonBoxBuilding = ({ row: rowInfo, rowInfoId }) => {

  const state = useSelector((state) => state.userDate);
  const { policies, responsabileEdificio } = state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const idEdificio = rowInfoId;
  const isMobile = useMobileDetection(600);
  const viewState = useSelector((state) => state.viewState);


  return (
    <>
      {!isMobile && !viewState && policies.indexOf(permessi.edificiInserimentoModifica) != -1 && <GridActionsCellItem
        icon={
          <Tooltip title={t("edit")} >
            <FontAwesomeIcon icon={faPen}   style = {{ fontSize : 25}} className="pathIcon" />
          </Tooltip>
        }
        label={t("edit")}
        onClick={() => handleDialogEditBuilding({type:multiDialogAction.edit, row:rowInfo}, dispatch)}
      />}
      {!isMobile && !viewState && policies.indexOf(permessi.zoneVisualizzazione) != -1 && <GridActionsCellItem
        icon={
          <Tooltip title={t("manage_zones")} >
            <FontAwesomeIcon icon={faLocationDot}
            style={{fontSize: 25}} className="pathIcon"
             />
          </Tooltip>
        }
        label={t("rooms")}
        onClick={() => navigate("" + idEdificio)}
      />}
      {(policies.indexOf(permessi.edificiAbilitaDisabilita) !== -1 ||
        responsabileEdificio.length > 0) && (
        <GridActionsCellItem
          sx={{ '& .MuiTouchRipple-root': { display: 'none' }}}
          className={isMobile || viewState ? "forceNoPaddingLeft" : ""}
          icon={
            <Tooltip title={t("disable_v3")}>
              {!isMobile && !viewState 
                ? <FontAwesomeIcon icon={faBan} style={{ fontSize: 25 }} className="pathIcon" />
                : <div style={{ display: "flex", alignItems: "center" }}>
                    <>
                      <FontAwesomeIcon icon={faBan} size="xs" style={{color: "#636161", marginRight: "3px" }} /> 
                      <Typography sx={{ fontSize: 10, color: "#636161"}}>{t("disable_v3")}</Typography>
                    </>
                  </div>
               }
            </Tooltip>
          }
          label={t("disable_v3")}
          onClick={(event) =>
            handleDialogToEnableDisableBuilding({ type: multiDialogAction.disable, dialogPageType: dialogPageTypes.building, row: rowInfo }, dispatch, event)
          }
        />
      )}
      {(policies.indexOf(permessi.edificiAbilitaDisabilita) !== -1 ||
        responsabileEdificio.length > 0) && (
        <GridActionsCellItem
        sx={{ '& .MuiTouchRipple-root': { display: 'none' }}}
        className={isMobile || viewState ? "" : ""}
          icon={
            <Tooltip title={t("restore_v2")}>
            {!isMobile && !viewState
            ? <FontAwesomeIcon icon={faRotateRight} style={{ fontSize: 25 }} className="pathIcon" />
            :<div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faRotateRight} size="xs" style={{ color: "#636161", marginRight: "3px" }} />
            <Typography sx={{ fontSize: 10, color: "#636161" }}>{t("restore_v2")}</Typography>
          </div>
            }</Tooltip>
          }
          label={t("restore_v2")}
          onClick={(event) =>
            handleDialogToEnableDisableBuilding({ type: multiDialogAction.enable, dialogPageType: dialogPageTypes.building, row: rowInfo }, dispatch, event)
          }
        />
      )}
      
      {policies.indexOf(permessi.edificiEliminazioneRipristino) != -1 && <GridActionsCellItem
      sx={{ '& .MuiTouchRipple-root': { display: 'none' }}}
      className={isMobile ? "button-ButtonBox-Mobile" : ""}
        icon={
          <Tooltip title={rowInfo.dispositivoAbilitato ? t("enable") : t("delete")}>
           { rowInfo.dispositivoAbilitato 
           ? <FontAwesomeIcon icon={faBan} className="pathIcon" /> 
           : !isMobile && !viewState ? <FontAwesomeIcon icon={faTrash} className="pathIcon" style = {{fontSize : 28}} /> 
                       : <> <FontAwesomeIcon icon={faTrash} size="xs" style={{color: "#636161", marginRight: "3px" }} /> 
                       <Typography sx={{ fontSize: 10, color: "#636161"}}>{t("delete_v3")}</Typography></>}
          </Tooltip> 
        }
        label={t("delete")}
        onClick={(event) => handleDialogEditBuilding({type:multiDialogAction.delete, row:rowInfo}, dispatch, event)}
      />}
      </>
  );
};

export default ButtonBoxBuilding;

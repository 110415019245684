import { Autocomplete, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getAdressFromGooglePlacesApi, getDetailsPlaceFromGooglePlaces } from "../../../../../../const/urlAction";
import handleErrorResponse from "../../../../../../functions/handlerError";
import { notificationOpen } from "../../../../../../redux/reducers/notification-reducer";
import { useDispatch } from "react-redux";

const debounceTime = 1000;
const urlBase = process.env.REACT_APP_URL;

export default function AutocompleteAddressField({ formik }) {
  const dispatch = useDispatch();
  const [typeAdress, setTypeAdress] = useState("");
  const [placePredictions, setPlacePredictions] = useState([]);
  const [timerId, setTimerId] = useState(null);
  const { t } = useTranslation();

  //Viene chiamato dall Autocomplete(MAPS) quando selezioniamo un luogo
  const saveSelected = (description) => {
    if (placePredictions && placePredictions.length > 0) {
      for (const place of placePredictions) {
        if (place.description === description) {

          const placeId = place.placeId;
          (async () => {
            const { method, action } = getDetailsPlaceFromGooglePlaces;
            let url = urlBase + action + "?placeId=" + placeId;
            try {
              const response = await axios({
                method: method,
                url,
              });
              if (response.data) {
                formik.setFieldValue("Indirizzo", response.data.result.formattedAddress);
                formik.setFieldValue("Coordinate", response.data.result.latitude + ", " + response.data.result.longitude);
              }
            } catch (error) {
              console.error(error);
              const response = handleErrorResponse(error);
              if (response) {
                dispatch(notificationOpen({ message: response.message, status: response.status }));
              }
            }
          })();
          break
        }
      }
    }
  };

  useEffect(() => {
    if (typeAdress) {
      (async () => {
        const { method, action } = getAdressFromGooglePlacesApi;
        let url = urlBase + action + "?address=" + typeAdress;
        try {
          const response = await axios({
            method: method,
            url,
          });

          if (response.data) {
            setPlacePredictions(response.data.result);
          }
        } catch (error) {
          console.error(error);
          const response = handleErrorResponse(error);
          if (response) {
            dispatch(notificationOpen({ message: response.message, status: response.status }));
          }
        }
      })();
    }
  }, [typeAdress]);

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Autocomplete
          id="googleAutocomplete"
          options={placePredictions.map(item => {
            return item["description"];
          })}
          onChange={(event, newValue) => {
            saveSelected(newValue);
          }}
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              label={t("Type_address")}
              onChange={(evt) => {
                if (timerId) {
                  clearTimeout(timerId);
                }
                let newReftimeId = setTimeout(() => {
                  setTypeAdress(evt.target.value);
                }, debounceTime);
                setTimerId(newReftimeId);
              }}
              value={params?.description}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextField
          InputProps={{ readOnly: true }}
          margin="dense"
          id="Indirizzo"
          label={t("Address")}
          type="text"
          fullWidth
          variant="standard"
          value={formik.values.Indirizzo || ""}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          InputProps={{ readOnly: true }}
          margin="dense"
          id="Coordinate"
          label={t("Coordinates")}
          type="text"
          fullWidth
          variant="standard"
          value={formik.values.Coordinate || ""}
        />
      </Grid>
    </>
  );
}

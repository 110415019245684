const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
const singleWordNameRegex = /^\w+$/;
const cleanedNameRegex = /\s*\(.*?\)\s*/;

function containsEmail(inputString) {
  return emailRegex.test(inputString);
}

function singleWordPattern(inputString) {
  return singleWordNameRegex.test(inputString);
}

function checkComplexPattern(inputString) {
  cleanedNameRegex.lastIndex = 0
  return cleanedNameRegex.test(inputString);
}

function removeComplexChar(inputString) {
  const cleanedName = inputString.replace(cleanedNameRegex, "");
  const words = cleanedName.split(" ").filter(n => n);
  if (words.length === 1 && words[0].length > 1) {
    return words[0].slice(0, 2).toUpperCase();
  }
  return words.map((n) => n[0]).join("").slice(0, 2).toUpperCase();
}


function removePunctuation(inputString) {
  return inputString.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
}

export default function nameToAvatar(name) {

  if (containsEmail(name) || singleWordPattern(name)) {
    return removePunctuation(name).slice(0, 2).toUpperCase();
  } else if (checkComplexPattern(name)) {
    return removeComplexChar(name);
  } else {
    const trimmedName = name.trim();
    return trimmedName
    .split(" ")
    .map((n, index, arr) => arr.length > 1 ? n[0] : n.slice(0, 2))
    .join("")
    .slice(0, 2)
    .toUpperCase();
  }
}

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import handlerError from "../../../functions/handlerError";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import React from "react";
import axios from "axios";
import { dialogButtonConfirm } from "../../../const/DialogData";
import { closeDialogRiepilogo } from "../../../redux/reducers/DialogRiepilogo-reducer";
import { loadprenotazioniTableState } from "../../../redux/reducers/prenotazioneTable-reducer";
import CustomNote from "../../UI/TextField/customNote";
import { hideLoader, showLoader } from "../../../redux/reducers/appLoader-reducer";
import { useTranslation } from "react-i18next";

const urlBase = process.env.REACT_APP_URL;

export default function DialogRemovePrenotazione({ isOpenRemove = false, object, returnTextNote, handleState }) {
  const dispatch = useDispatch();
  const { noteField, actionApi, body, viewComponent } = object
  const { t } = useTranslation(); 
  const appLoadingState = useSelector((state) => state.appLoader.loading);

  const changeReservation = () => {
    if (isOpenRemove) {
      (async () => {
        dispatch(showLoader());
        const { action, method } = actionApi;
        let url = urlBase + action;

        try {
          const response = await axios({
            method: method,
            url,
            data: body,
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.data.ok) {
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
            dispatch(closeDialogRiepilogo());
            dispatch(loadprenotazioniTableState());
          } else {
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "error",
              })
            );
          }
          handleState(false);
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
        }
        dispatch(hideLoader());
      })();
    }
  };


  
  return (
    <>
      {!appLoadingState ? (
        <Dialog
          open={isOpenRemove}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"sm"}
        //onBlur={(e) =>handlerBlur(e)}
        >
          <DialogTitle>{viewComponent && viewComponent.textTitle}</DialogTitle>
          <DialogContent className="border-Content-dialog">
            <DialogContentText sx={{ marginBottom: "15px" }}>
              {viewComponent && viewComponent?.textPrincipal}
            </DialogContentText>
            {noteField && <CustomNote returnValue={returnTextNote} min={3} max={3} />}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleState(false)}>
              {t(dialogButtonConfirm.Cancel)}
            </Button>
            <Button variant="contained" onClick={() => changeReservation()}>
              {t(dialogButtonConfirm.Confirm)}
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
}


import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AutocompleteCustom } from "../../UI/AutoComplete/AutoComplete";
import { BuildingHeader } from "../../../const/DatatableData";
import {
  getEdificiFiltered,
  getZonePrenotazione,
  getStanzaPrenotazione,
  getPostazionePrenotazione,
} from "../../../const/urlAction";
import axios from "axios";
import { useDispatch } from "react-redux";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import handlerError from "../../../functions/handlerError";
import { useSelector } from "react-redux";
import { active, disable } from "../../../redux/reducers/prenotazioniAdvancedMenuFilter-reducer";
import { useTranslation } from "react-i18next";

const typePage = "Homepage";
const urlBase = process.env.REACT_APP_URL;

export function AutocompleteBoxHistoryFilterPostazione({ dispatchPostazione, personale, utenteSelected, expanded, returnResetFunction = () => null, isResetting, viewSelected, svuota, startValue = {} }) {
  const [invio, setInvio] = useState(true);
  const [edificio, setEdificio] = useState({});
  const [edificioRows, setEdificioRows] = useState([]);
  const [zona, setZona] = useState({});
  const [zonaRows, setZonaRows] = useState([]);
  const [isDisabledZona, setIsDisabledZona] = useState(true);
  const [stanza, setStanza] = useState({});
  const [stanzaRows, setStanzaRows] = useState([]);
  const [isDisabledStanza, setIsDisabledStanza] = useState(true);
  const [postazione, setPostazione] = useState({});
  const [postazioneRows, setPostazioneRows] = useState([]);
  const [isDisabledPostazione, setIsDisabledPostazione] = useState(true);
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const state = useSelector((state) => state.userDate);
  const prenotazioniAdvancedMenuFilter = useSelector((state) => state.prenotazioniAdvancedMenuFilter);
  const { t } = useTranslation();
  const [sortModel, setSortModel] = useState([
    {
      field: BuildingHeader.initialFieldName,
      sort: BuildingHeader.initialFieldOrder,
    },
  ]);
  const { responsabileEdificio, utenteResponsabileUtenti } = state;

  //TODO:


  //svuota Autocomplete a cascata
  useEffect(() => {
    if (isDisabledZona && edificio == null) {
      handleValueZona(null)
      handleValueStanza(null)
      handleValuePostazione(null)
    }
    else if (isDisabledStanza && zona === null) {
      handleValueStanza(null)
      handleValuePostazione(null)
    }
    else if (isDisabledPostazione && stanza === null) {
      handleValuePostazione(null)
    }

  }, [isDisabledZona, isDisabledStanza, isDisabledPostazione])

  useEffect(() => {
    dispatch(disable())
  }, [])

  function svuotoDaEdificio() {
    setZona(null)
    startValue = { ...startValue, Zona: null, Stanza: null, Postazione: null }
    setZonaRows([])
    setIsDisabledZona(true)
    setStanza(null)
    setStanzaRows([])
    setIsDisabledStanza(true)
    setPostazione(null)
    setPostazioneRows([])
    setIsDisabledPostazione(true)
  }

  function svuotoDaZona() {
    setStanza({})
    startValue = { ...startValue, Stanza: null, Postazione: null }
    setStanzaRows([])
    setIsDisabledStanza(true)
    setPostazione({})
    setPostazioneRows([])
    setIsDisabledPostazione(true)
  }
  function svuotoDaStanza() {
    setPostazione({})
    setPostazioneRows([])
    startValue = { ...startValue, Postazione: null }
    setIsDisabledPostazione(true)
  }

  //Chiamata di Edificio
  useEffect(() => {
    (async () => {
      setLoading(true);
      const body = {
        IdUtentePrenotazione: utenteSelected?.idUtente || "",
        IsDisabilitazione: viewSelected === "true",
        IsPersonale: personale === "true"
      }

      const { action, method } = getEdificiFiltered;
      let url = urlBase + action;

      try {
        const response = await axios({
          method: method,
          url,
          data: body,
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setEdificioRows(response.data.result);
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }

      setLoading(false);
    })();
  }, [expanded, personale, isResetting, utenteSelected]);

  //Chiamata di Zona
  useEffect(() => {
    if (JSON.stringify(edificio) !== "{}") {

      (async () => {

        setIsDisabledZona(true);

        if (edificio === null) svuotoDaEdificio()

        if (edificio !== null) {

          const { action, method } = getZonePrenotazione;
          let url = urlBase + action;

          try {
            const response = await axios({
              method: method,
              url,
              data: edificio.id,
              headers: {
                'Content-Type': 'application/json'
              }
            });
            setZonaRows(response.data.result);
          } catch (error) {
            console.error(error);
            const notificationError = handlerError(error);
            dispatch(notificationOpen(notificationError));
          }
          setIsDisabledZona(false);
        }
      })();

    }
  }, [edificio]);

  //Chiamata di Stanza
  useEffect(() => {
    if (JSON.stringify(zona) !== "{}") {
      (async () => {
        setIsDisabledStanza(true);

        if (zona === null) svuotoDaZona();

        if (zona !== null) {

          const { action, method } = getStanzaPrenotazione;
          let url = urlBase + action;

          try {
            const response = await axios({
              method: method,
              url,
              data: zona.id,
              headers: {
                'Content-Type': 'application/json'
              }
            });
            setStanzaRows(response.data.result);
          } catch (error) {
            console.error(error);
            const notificationError = handlerError(error);
            dispatch(notificationOpen(notificationError));
          }
          setIsDisabledStanza(false);
        }
      })();
    }
  }, [zona]);

  //Chiamata di postazione
  useEffect(() => {
    if (JSON.stringify(stanza) !== "{}") {
      (async () => {
        setIsDisabledPostazione(true);

        if (stanza === null) svuotoDaStanza();

        if (stanza !== null) {

          const { action, method } = getPostazionePrenotazione;
          let url = urlBase + action;

          try {
            const response = await axios({
              method: method,
              url,
              data: stanza.id,
              headers: {
                'Content-Type': 'application/json'
              }
            });
            setPostazioneRows(response.data.result);
          } catch (error) {
            console.error(error);
            const notificationError = handlerError(error);
            dispatch(notificationOpen(notificationError));
          }
          setIsDisabledPostazione(false);
        }
      })();
    }
  }, [stanza]);

  useEffect(() => {
    if (postazioneRows && postazioneRows.length === 1 && stanza && stanza.isSalaRiunione) {
      handleValuePostazione(postazioneRows[0]);
    }
  }, [postazioneRows])



  const handleValueEdificio = (selectedValue) => {
    let Edificio = null
    if (selectedValue !== null && selectedValue !== undefined) {
      Edificio = selectedValue
    }
    dispatchPostazione({ type: "ADD_EDIFICIO", payload: Edificio })
    setEdificio(Edificio);
  };

  const handleValueZona = (selectedValue) => {
    let zona = null
    if (selectedValue !== null && selectedValue !== undefined) {
      zona = selectedValue
    }
    svuotoDaZona()
    dispatchPostazione({ type: "ADD_ZONA", payload: zona })
    setZona(zona);
  };

  const handleValueStanza = (selectedValue) => {
    let stanza = null
    svuotoDaStanza()
    if (selectedValue !== null && selectedValue !== undefined) {
      stanza = selectedValue
    }
    dispatchPostazione({ type: "ADD_STANZA", payload: stanza })
    setStanza(stanza);
    if (stanza?.isSalaRiunione) dispatchPostazione({ type: "ADD_POSTAZIONE", payload: postazioneRows[0] })
  };

  const handleValuePostazione = (selectedValue) => {

    let postazione = null
    if (selectedValue !== null && selectedValue !== undefined) {
      postazione = selectedValue
    }
    dispatchPostazione({ type: "ADD_POSTAZIONE", payload: postazione })
    setPostazione(postazione);
  };

  return (
    <React.Fragment>
      <Grid container
        columnSpacing={51}
        rowSpacing={1}
        sx={{ marginBottom: "60px" }}>
        <>
          <Grid item xs={12}>
            <AutocompleteCustom
              label={t("building")}
              field={["denominazione", /* "sigla" */]}
              optionsAutoComplete={edificioRows}
              handleValueReturned={handleValueEdificio}
              startValueAutoComplete={startValue.Edificio}
              clean={prenotazioniAdvancedMenuFilter}
              mostraAvatar={false}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteCustom
              label={t("Zone")}
              field={["denominazione", /* "sigla" */]}
              optionsAutoComplete={zonaRows}
              handleValueReturned={handleValueZona}
              isDisabled={isDisabledZona}
              startValueAutoComplete={startValue.Zona}
              clean={prenotazioniAdvancedMenuFilter}
              mostraAvatar={false}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteCustom
              label={t("stanza_v4")}
              field={["denominazione",/*  "sigla" */]}
              optionsAutoComplete={stanzaRows}
              handleValueReturned={handleValueStanza}
              isDisabled={isDisabledStanza}
              startValueAutoComplete={startValue.Stanza}
              clean={prenotazioniAdvancedMenuFilter}
              mostraAvatar={false}
            />
          </Grid>
          {!stanza?.isSalaRiunione && <Grid item xs={12}>
            <AutocompleteCustom
              label={t("workstation")}
              field={["posto"]}
              optionsAutoComplete={postazioneRows}
              handleValueReturned={handleValuePostazione}
              isDisabled={isDisabledPostazione}
              startValueAutoComplete={startValue.Postazione}
              clean={prenotazioniAdvancedMenuFilter}
              mostraAvatar={false}
            />
          </Grid>}
        </>
      </Grid>
    </React.Fragment>
  );
}

import React from 'react';
import { makeStyles } from '@mui/styles';
import { StaticTimePicker } from "@mui/x-date-pickers";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect } from 'react';

  
export default function StaticTimePickerCustom(props) {
    const { t } = useTranslation();

      return (
        <StaticTimePicker
          {...props}
          slotProps={{ actionBar: { actions: [] } }}
          localeText={{toolbarTitle:t('select_time')}}
          minTime={props.minDate}
          maxTime={props.maxDate}
        />
      );
    };
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { faCity, faDoorOpen, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const edificio = "edificioDetail"
const zona = "zonaDetail"
const stanza = "stanzaDetail"

export default function creazionePathTextEntita(entitaDetail = {}) {
let body;

if(!entitaDetail) return <></>

if(Object.entries(entitaDetail).length !== 0) {

    const keys = Reflect.ownKeys(entitaDetail).filter(x => x.toLowerCase().includes("detail"));
    
//   if(keys.indexOf(edificio) !== -1) body = <div style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"12px"}}> <ApartmentIcon />  {entitaDetail[edificio].denominazione} ({entitaDetail[edificio].sigla})</div>
//   if(keys.indexOf(zona) !== -1) body = <div style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"12px"}}> {body} <LocationOnIcon /> {entitaDetail[zona].denominazione} ({entitaDetail[zona].sigla})</div>
//   if(keys.indexOf(stanza) !== -1) body = <div style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"12px"}}> {body} <MeetingRoomIcon /> {entitaDetail[stanza].denominazione} ({entitaDetail[stanza].sigla})</div>


//   body = <div style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"12px"}}> {body} {assegnazioneIcona(keys)} {entitaDetail.denominazione} ({entitaDetail.sigla})</div>
 
if(keys.indexOf(edificio) !== -1) body = <div style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"12px", paddingRight:"5px"}}> <FontAwesomeIcon icon={faCity} /> {entitaDetail[edificio].sigla.toUpperCase()}</div>
if(keys.indexOf(zona) !== -1) body = <div style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"12px", paddingRight:"5px"}}> {body} <FontAwesomeIcon icon={faLocationDot} /> {entitaDetail[zona].sigla.toUpperCase()}</div>
if(keys.indexOf(stanza) !== -1) body = <div style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"12px", paddingRight:"5px"}}> {body} <FontAwesomeIcon icon={faDoorOpen} /> {entitaDetail[stanza].sigla.toUpperCase()}</div>


   body = <div style={{display:"flex", alignItems:"center", justifyContent:"center", fontSize:"12px"}}> {body} {assegnazioneIcona(keys)} {entitaDetail.sigla.toUpperCase()}</div>
 

 return <div style={{float:"right"}}>{body}</div>
}
}

function assegnazioneIcona(keys) {
const elementNumber = keys.length

let icon = "";
switch (true) {
    case elementNumber === 0:
        icon = <FontAwesomeIcon icon={faCity} />
        break;
    case elementNumber === 1:
        icon = <FontAwesomeIcon icon={faLocationDot} />
        break;
    case elementNumber === 2:
        icon = <FontAwesomeIcon icon={faDoorOpen} />
        break;
}
    return icon
}
import {
    Grid,
    TextField,
  } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function AddressField({formik}){

const { t } = useTranslation();

    return (
        <>
        <Grid item xs={12} sm={9}>
        <TextField
          margin="dense"
          id="Indirizzo"
          label={t("Address")}
          type="text"
          fullWidth
          variant="standard"
          value={formik.values.Indirizzo || ''}
          onChange={(e) => formik.setFieldValue("Indirizzo",e.target.value) }
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          margin="dense"
          id="Coordinate"
          label={t("Coordinates")}
          type="text"
          fullWidth
          variant="standard"
          value={formik.values.Coordinate || ''}
          onChange={(e) => formik.setFieldValue("Coordinate",e.target.value) }
        />
      </Grid>
      </>
    )
}
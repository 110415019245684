import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paper, Grid } from "@mui/material";
import "../mobileViewList.css";
import { useTranslation } from "react-i18next";
import LayerDescender from "../general/LayerDescender";
import { multiDialogAction } from "../../../../const/Dialog/MultiDialogAction";
import { useDispatch } from "react-redux";
import { useRef } from "react";
import { useState } from "react";
import useAdaptiveEllipsis from "../../../../Hooks/Adaptive/useAdaptiveEllipsis";

export default function PostazioneRowStructures({row, BoxButton, handleDialogToEdit = () => null}) {
    const { t } = useTranslation(); 
    const dispatch = useDispatch();
    const gridItemRef = useRef(null);
    const [paragraphWidth, setParagraphWidth] = useState(null);
  
  useAdaptiveEllipsis(gridItemRef, setParagraphWidth, -13);

    return (
        <>
        <Paper className="row-mobileView box-shadow-light ">
          <Grid container>
        <Grid item xs={2} sm={1} style={{maxWidth:"55px"}} >
              <div className="booking-card-left-section bk-icon-mob flex-center-center">
                <Grid container item justifyContent="center" xs={2} sm={2} md={1}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        background: "#3e6bb4",
                        width: "27px",
                        height: "27px",
                        borderRadius: "26px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "13px",
                          color: "white",
                          fontStyle: "regular",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        {row.posto}
                      </div>
                    </div>
                  </div>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={10} 
            >
              <Grid
                item
                xs={12}
                sx={{paddingTop:"5px"}}
                onClick={() => handleDialogToEdit({dialogType:"editUtenti", rowInfo:row}, dispatch)}
              >
                <Grid item xs={10} sm={10} md={11} className="mobileRow-center-text" ref={gridItemRef}>
                  <p  className="font-segoeui firstRow-mobileView textOverflowEllipsis">{t("workstation")  +" "+  row.posto }</p>
                  <p className="font-segoeui secondRow-mobileView textOverflowEllipsis" style={{width:paragraphWidth}}>
                     {row.utentiPostazione.length > 0 ? row.utentiPostazione.map(element => element.nomeUtente).join(', ') :  t("Not_reserved")}
                  </p>
                  <p className="font-segoeui thirdRow-mobileView textOverflowEllipsis" style={{width:paragraphWidth}}>
                     {row.dotazioni.length > 0 ? row.dotazioni.map(element => element.descrizione).join(', ') :  t("no_equipment")}
                  </p>
                </Grid>
              </Grid>
              {BoxButton && (
                <Grid
                  item
                  xs={12}
                >
                  <BoxButton row={row} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </>
    )
}
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import ApartmentIcon from "@mui/icons-material/Apartment";
import axios from "axios";
import { notificationOpen } from "../redux/reducers/notification-reducer";
import handlerError from "../functions/handlerError";
import { useParams } from "react-router-dom";
import { getZonaById, getBuilding } from "../const/urlAction";
import { urlDettaglioEntita } from "../functions/UrlParams";
import creazionePathTextEntita from "../functions/creazionePathTextEntita";

const urlBase = process.env.REACT_APP_URL;

const usePathTextDetail = () => {
  const [entitaDetail, setEntitaDetail] = useState({});
  const viewState = useSelector((state) => state.viewState);
  const dispatch = useDispatch();
  const params = useParams();

    const {entita, action, method} = urlDettaglioEntita(params)

  useEffect(() => {
    let isMounted = true;
    if( entita !== undefined) {
    (async () => {
      let url = urlBase + action;
      try {
        const response = await axios({
          method: method,
          url,
          data: entita,
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        if (response.data.ok && isMounted) {
          setEntitaDetail(response.data.result);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
    }
    return () => {
      isMounted = false;
    }
  }, [entita]);

  const path = creazionePathTextEntita(entitaDetail)

  return path;

};

export default usePathTextDetail;

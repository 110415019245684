import { Divider, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import "../PlanimetriaIcons.css";
import {
  aggiornaElementoPlaceholderMappa,
  creaElementoMappa,
} from "../../../functions/creaElementoMappa";
import { iconSelectedElement } from "../../../const/Planimetria/IconPlanimetria";
import Notification from "../../UI/Notification/Notification";
import ElementSubMenu from "../SubMenu/ElementSubmenu";
import { DISPONIBILE } from "../../../const/Planimetria/StatoPostazione";
import {
  aggiustamentiMenuContestualePlanimetriaPrenotazione,
  aggiustamentiPlanimetriaPrenotazione,
} from "../../../const/Planimetria/gestionePlanimetria";
import sfondoDefault from "../../../assests/sfondoPlanimetriaDefault.png";
import {
  savePostazioneCompletoInPrenotazioneStepper,
  saveStanzaCompletoInPrenotazioneStepper,
} from "../../../redux/reducers/infoPrenotazione-reducer";
import useNoPassiveEventListener from "../../../Hooks/useNoPassiveEventListener";
import useScrollGrabOnContainer from "../../../Hooks/useScrollGrabToContainer/useScrollGrabOnContainer";
import { addDoubleClickEvent } from "../../../functions/Utils/addDoubleClickEvent";
import {
  calcolaStato,
  handlerMobileZoom,
  handlerScale,
} from "../../../functions/handlerZoom";
import useMobileDetection from "../../../Hooks/useMobileDetection";
import {
  calcolaPercentualeDiMarginDaScaleImage,
  moltiplicaPercentualeScalling,
} from "../../../functions/calcolaScaleImageDelloZoom";
import ShowTips from "../../showTips/showTips";
import {
  initialValueScaleZoomDesktop,
  initialValueScaleZoomMobile,
} from "../../../const/Planimetria/valueScaleZoomPlanimetria";
import { containerClass } from "../../../const/Planimetria/costantiPlanimetria";
import handlerConfiguration from "../../../functions/general/handlerConfiguration";
import PartecipantiRiunione from "./PartecipantiRiunione/PartecipantiRiunione";
import { handlerUtentiPrenotazione } from "../../../redux/reducers/prenotazioneStepper-reducer";
import { selectUserInMultiUser } from "../../../redux/reducers/MultiUserInStepper-reducer";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import AvatarImage from "../../Avatar/AvatarImage";
import ShowDispositiviOnPlanimetria from "./legenda/ShowDispositiviOnPlanimetria";
import calcoloGrandezzaContainer from "../../../functions/planimetria/calcoloGrandezzaContainer";
import { ApplyScalePlanimetria, IncrementaDimensioneImageAlloZoom, IncrementaDimensioneValoreAlloZoom } from "../../../functions/planimetria/applyScalePlanimetria";
import checkScroll from "../../../functions/planimetria/checkScroll";
import ResponsiveImagePlanimetria from "../../../functions/planimetria/ResponsiveImagePlanimetria";
import responsiveImagePlanimetria from "../../../functions/planimetria/ResponsiveImagePlanimetria";

export const idMainComponent = "main-component"
const grandezzaIcona = 24;
const PropStanza = "posto";
export const idDialogPadre = "dialog-stepper";
const msgAvvisoSelezioneUtente =
  "Per procedere con la prenotazione di una postazione, è necessario selezionare un utente";

const componentName = "PlanimetriaPrenotazione";
export default function PlanimetriaPrenotazine({
  elementiDalDb = [],
  typePage = "",
  imageBackground,
  actionOnClickButton,
  dettaglioSfondoPlanimetria = {},
}) {
  const configurations = useSelector((state) => state.configurations);

  const {
    utentePrenotazione: utentiEPostazioneDaInserire,
    partecipantiRiunione,
    titoloRiunione,
    invioNotificaInvito,
  } = useSelector((state) => state.prenotazioneStepper);

  const infoPrenotazioneStepper = useSelector(
    (state) => state.prenotazioneStepper
  );

  const {
    larghezzaPlanimetriaDefault: PlanimetriaLarghezza,
    altezzaPlanimetriaDefault: PlanimetriaAltezza,
  } = handlerConfiguration(configurations);

  const isMobile = useMobileDetection();
  const refContainerDropzone = useRef(null);
  const dispatch = useDispatch();
  const refImage = useRef(null);
  const [selected, setSelected] = useState(-1);
  const [statusScrollPlanimetria, setStatusScrollPlanimetria] = useState({});
  const [elementiRecuperati, setElementiRecuperati] = useState([]);
  const [dettaglioSalaRiunione, setDettaglioSalaRiunione] = useState(undefined);
  const [titoloRiunioneSala, setTitoloRiunioneSala] = useState("");
  const isSecondRender = useRef(false);
  const [forzaRerenderPerAggiornamentoDom, setForzaRerenderPerAggiornamentoDom] = useState(false);
  const [ospitiDaInserireInSalaRiunione, setOspitiDaInserireInSalaRiunione] =
    useState([]);
  const [isOpenSubmenu, setIsOpenSubmenu] = useState(false);
  const userSelectedInStepper = useSelector(
    (state) => state.multiUserHandlerInStepper
  );
  const [scaleZoom, setScaleZoom] = useState(initialValueScaleZoomDesktop);
  const [infoPrenotazioniToSubmenu, setinfoPrenotazioniToSubmenu] = useState(
    []
  );
  const [infoPositionSubMenuAndId, setInfoPositionSubMenuAndId] = useState({
    id: 0,
    x: 0,
    y: 0,
    status: 0,
    descrizioneStatus: "Disponibile",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [initDimensioneWidth, setInitDimensioneWidth] = useState(0);
  const [initDimensioneHeight, setInitDimensioneHeight] = useState(0);

  const {
    altezzaPlanimetria,
    larghezzaPlanimetria,
    idSfondoPlanimetria,
    isSalaRiunione,
  } = dettaglioSfondoPlanimetria;

  let deltaScale =
    handlerScale -
    (isMobile ? initialValueScaleZoomMobile : initialValueScaleZoomDesktop);

  useEffect(() => {
    setIsOpenSubmenu(false);
    let elementiDaMostrare = elementiDalDb.map((el) => {
      return {
        element: creaElementoMappa(el, true, userSelectedInStepper),
        ...el,
      };
    });

    let elementi = elementiDaMostrare;

    if (
      elementiDaMostrare.length > 0 &&
      elementiDaMostrare.every((x) => x.hasOwnProperty(PropStanza))
    ) {
      // se gli elementi non hanno la proprietà posto, non è una stanza ma una zona

      elementi = utentiEPostazioneDaInserire.some(
        (x) => x.posto != null || undefined
      )
        ? aggiornaStatoPostazioniPlanimetriaConPrenotazioniInCorso(
            utentiEPostazioneDaInserire,
            elementiDaMostrare,
            userSelectedInStepper
          )
        : elementiDaMostrare;

      // Gestisce il punto di vista dell'utente selezionato nella prenotazione multipla
      if (
        userSelectedInStepper.posto &&
        elementi[0]?.idStanza === userSelectedInStepper?.idStanza
      ) {
        setSelected({ index: userSelectedInStepper.index, status: 1 });
        dispatch(
          savePostazioneCompletoInPrenotazioneStepper({
            posto: userSelectedInStepper.posto,
          })
        ); //tiene aggiornata la postazione nelle info della stepper
      } else {
        setSelected(-1);
      }
    }
    setElementiRecuperati(elementi);
  }, [elementiDalDb, userSelectedInStepper]);

  function aggiornaStatoPostazioniPlanimetriaConPrenotazioniInCorso(
    utentiEPostazioneDaInserire = [],
    elementiRecuperati = [],
    userSelectedInStepper = null
  ) {
    let arr = utentiEPostazioneDaInserire.filter(
      (x) => x.posto != null || undefined
    );

    if (!arr.length) return null;

    let elementiDaModificare = arr.filter((x) => {
      let result = elementiRecuperati.filter((y) => x.posto === y.posto);
      if (result) {
        return x;
      }
    });

    if (
      !elementiDaModificare.length ||
      !elementiRecuperati.length ||
      !elementiRecuperati.every((x) => x.hasOwnProperty("posto"))
    )
      return null;

    let result = aggiornaElementoPlaceholderMappa(
      elementiRecuperati,
      elementiDaModificare,
      userSelectedInStepper,
      infoPrenotazioneStepper,
      utentiEPostazioneDaInserire
    );

    return result;
  }

  function openSubmenu(event, id, descrizioneStatus, status, selectedElement) {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (elementiRecuperati[selectedElement?.index] !== undefined) {
      const { posX, posY, prenotazioni } =
        elementiRecuperati[selectedElement.index];
      setInfoPositionSubMenuAndId((prev) => {
        prev.id == id
          ? setIsOpenSubmenu(!isOpenSubmenu)
          : setIsOpenSubmenu(true);
        return {
          id,
          status,
          descrizioneStatus,
          x:
            event.screenX +
            refContainerDropzone?.current?.scrollLeft -
            refContainerDropzone.current.parentElement.parentElement
              .parentElement.parentElement.parentElement.parentElement
              .parentElement.offsetLeft -
            aggiustamentiPlanimetriaPrenotazione.aggiustamentoPosizioneLeftSuPlanimetriaPrenotazione +
            aggiustamentiMenuContestualePlanimetriaPrenotazione.aggiustamentoPosizioneLeftSuMenuContestualePlanimetriaPrenotazione,
          y:
            event.screenY -
            refContainerDropzone.current.parentElement.parentElement
              .parentElement.parentElement.parentElement.parentElement
              .parentElement.offsetTop +
            refContainerDropzone?.current?.scrollTop -
            aggiustamentiPlanimetriaPrenotazione.aggiustamentoPosizioneTopSuPlanimetriaPrenotazione,
        };
      });
      setinfoPrenotazioniToSubmenu(prenotazioni);
    }
  }

  function onClickIcon(event, index, el) {
    const {
      id,
      status,
      statusDescrizione,
      postazioniDisponibili,
      posto,
      idStanza,
    } = el;
    if (el.idZona != undefined) {
      dispatch(
        saveStanzaCompletoInPrenotazioneStepper({
          id: el.id,
          sigla: el.sigla,
          denominazione: el.denominazione,
          isSalaRiunione: el.isSalaRiunione,
        })
      );
    }

    let selectedElement = actionOnClickButton(
      event,
      id,
      index,
      el.posto,
      status,
      postazioniDisponibili
    );
    if (status == DISPONIBILE ) {
      if (
        Object.keys(userSelectedInStepper).length === 0 &&
        infoPrenotazioneStepper.radioButtonPrenotazionePerAltri &&
        infoPrenotazioneStepper.utentePrenotazione.length > 0
      ) {
        // se non è selezionato nessun utente impedisce di selezionare la postazione e lancia una notifica all'utente.
        dispatch(
          notificationOpen({
            message: msgAvvisoSelezioneUtente,
            status: "warning",
          })
        );
        return;
      }
      setSelected({ index: selectedElement.index, status });
      dispatch(
        selectUserInMultiUser({
          ...userSelectedInStepper,
          posto: selectedElement.posto,
          idStanza: idStanza,
          index: selectedElement.index,
        })
      ); //mantiene coerenti i dati a livello di redux
      dispatch(
        savePostazioneCompletoInPrenotazioneStepper({
          posto,
          index: selectedElement.index,
        })
      ); //viene usato nella Stepper per gestire le informazioni da mostrare nei pannelli riepilogativi
      const utenteConPostoAssociato = {
        ...userSelectedInStepper,
        idStanza,
        posto,
        id,
        index: selectedElement.index,
      };
      dispatch(handlerUtentiPrenotazione(utenteConPostoAssociato));
    } else {
      if (event)
        openSubmenu(event, id, statusDescrizione, status, selectedElement);
    }
  }

  function onTouchMobile(event, index, el) {
    event.preventDefault();
    event.stopPropagation();
    const { id, status, statusDescrizione, postazioniDisponibili, posto } = el;

    if (el.idZona != undefined) {
      dispatch(
        saveStanzaCompletoInPrenotazioneStepper({
          id: el.id,
          sigla: el.sigla,
          denominazione: el.denominazione,
          isSalaRiunione: el.isSalaRiunione,
        })
      );
    }

    let selectedElement = actionOnClickButton(
      event,
      id,
      index,
      status,
      postazioniDisponibili
    );
    if (status == DISPONIBILE) {
      setSelected({ index: selectedElement.index, status });
      dispatch(
        selectUserInMultiUser({
          ...userSelectedInStepper,
          posto: selectedElement.posto,
          idStanza: el.idStanza,
          index: selectedElement.index,
        })
      ); //mantiene coerenti i dati a livello di redux
      dispatch(savePostazioneCompletoInPrenotazioneStepper({ posto })); //viene usato nella Stepper durante la prenotazione
      const utenteConPostoAssociato = { ...userSelectedInStepper, posto, id };
      dispatch(handlerUtentiPrenotazione(utenteConPostoAssociato));
    } else {
      openSubmenu(undefined, id, statusDescrizione, status, selectedElement);
    }
  }
  //se è presente un solo elemento nella stanza o sala, lo auto-seleziona.
  useEffect(() => {
    if (
      utentiEPostazioneDaInserire.length <= 1 &&
      elementiRecuperati?.length === 1 &&
      selected === -1 &&
      elementiRecuperati[0].status === 1
    ) {
      //dispatch(resetPostazioniPrenotate())
      let el = elementiRecuperati[0];
      el.prenotazioni = [];
      el.status = 1;
      onClickIcon(null, 0, el);
    }
    if (dettaglioSfondoPlanimetria.isSalaRiunione) {
      const idPostazioneSalaRiunione = elementiDalDb[0].id;
      setOspitiDaInserireInSalaRiunione(
        partecipantiRiunione[idPostazioneSalaRiunione]
      );

      let keyIdPostazioneTitoloRiunione = Object.keys(titoloRiunione);

      let indexTitolo = keyIdPostazioneTitoloRiunione.map((x, index) => {
        if (x == idPostazioneSalaRiunione) {
          return index;
        }
      });

      let filteredIndexTitolo = indexTitolo.filter((x) => x !== undefined)[0];
      let idPostazioneSala = keyIdPostazioneTitoloRiunione[filteredIndexTitolo];
      let objTitolo = titoloRiunione[idPostazioneSala];
      if (objTitolo) setTitoloRiunioneSala(objTitolo);

      setDettaglioSalaRiunione(elementiDalDb[0]);
    }
  }, [elementiRecuperati, userSelectedInStepper, titoloRiunione]);

  function closeMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    setIsOpenSubmenu(false);
  }

  function stopEvent(event) {
    event.stopPropagation();
  }
  const [larghezzaContainer, altezzaContainer] = calcoloGrandezzaContainer(idDialogPadre, isMobile,idMainComponent );

  useScrollGrabOnContainer(refContainerDropzone, refImage);

  const { height, width, marginLeft, marginTop } = 
  calcolaPercentualeDiMarginDaScaleImage(
    isMobile ? initialValueScaleZoomMobile : initialValueScaleZoomDesktop,
    handlerScale,
    {
      altezzaCustomImg: altezzaPlanimetria || PlanimetriaAltezza,
      larghezzaCustomImg: larghezzaPlanimetria || PlanimetriaLarghezza,
    },
    {
      altezzaContainer,
      larghezzaContainer,
    },
    refContainerDropzone,
    scaleZoom
  );

  
  //gestisce l'aggiornamento del dom tramite due render,controlla la proprietà Scroll del container
  useEffect(() => {
    if (isSecondRender.current) {
      let statusScroll = checkScroll(refContainerDropzone);
      setStatusScrollPlanimetria(statusScroll)
      isSecondRender.current = false; 
    } else {
      setForzaRerenderPerAggiornamentoDom(prev => !prev);
      isSecondRender.current = true;
    }
  }, [scaleZoom, forzaRerenderPerAggiornamentoDom, marginTop,marginLeft, width, height ]);

  useNoPassiveEventListener(refImage, "wheel", setScaleZoom, statusScrollPlanimetria);

  //gestisce la grandezza dell'immagine
  useEffect(() => { 
    setInitDimensioneWidth(width + marginLeft);
    setInitDimensioneHeight(height + marginTop);
  },[marginLeft,marginTop, width, height])

  const deltaWidth = ApplyScalePlanimetria(
    isMobile ? initialValueScaleZoomMobile : initialValueScaleZoomDesktop,
    scaleZoom,
    initDimensioneWidth
    )

    const deltaHeight = ApplyScalePlanimetria(
      isMobile ? initialValueScaleZoomMobile : initialValueScaleZoomDesktop,
      scaleZoom,
      initDimensioneHeight
      )
    

    const marginWidth = IncrementaDimensioneValoreAlloZoom(
      marginLeft,
      deltaWidth,
      scaleZoom
    )

    const marginHeight = IncrementaDimensioneValoreAlloZoom(
      marginTop,
      deltaHeight,
      scaleZoom
      )

      const altezzaContainerZoom = IncrementaDimensioneImageAlloZoom(
        Math.abs(marginTop),
        deltaHeight 
        )

      const larghezzaContainerZoom = IncrementaDimensioneImageAlloZoom(
        Math.abs(marginLeft),
        deltaWidth
        )

let responsiveWidth = responsiveImagePlanimetria(initDimensioneWidth, larghezzaContainerZoom, larghezzaContainer, Math.abs(marginLeft))
let responsiveHeight =  responsiveImagePlanimetria(initDimensioneHeight, altezzaContainerZoom, altezzaContainer, Math.abs(marginTop))

    return (
    <React.Fragment>
      <Grid container className={true ? "" : "dontshow" }>
        <Grid item xs={12} sm={12} id={idMainComponent}>
        <div
          className="container-map"
            style={{
              overflow: "auto",
              maxWidth: "100%",
              maxHeight:altezzaContainer,
              height:altezzaContainer + Math.abs(marginTop),
              width:larghezzaContainer + Math.abs(marginLeft)
            }}
            ref={refContainerDropzone}
          >
            {Object.keys(dettaglioSfondoPlanimetria).length !== 0 && (
              <div
                className={containerClass}
                ref={refImage}
                style={{
                  position: "relative",
                  marginLeft: `${
                    marginWidth 
                  }px`,
                  marginTop: `${
                    marginHeight 
                  }px`,
                  transform: `scale(${scaleZoom})`,
                  backgroundImage: idSfondoPlanimetria
                    ? `url(${imageBackground})`
                    : `url(${sfondoDefault})`,
                  height: `${responsiveHeight}px`,
                  width: `${responsiveWidth}px`,
                  backgroundRepeat: "no-repeat",
                }}
                onClick={(e) =>
                  addDoubleClickEvent(
                    e,
                    { function: closeMenu },
                    {
                      function: handlerMobileZoom,
                      params: {
                        saveStateFunction: setScaleZoom,
                        refContainer: refContainerDropzone,
                      },
                    },
                    { ableToDesktop: false, isMobile, timer: 200 }
                  )
                }
              >
                {elementiRecuperati?.map((el, index) => {
                  let isAvatar =
                    el.prenotazioni?.[0]?.utentePrenotatoFullname != null &&
                    el.prenotazioni?.every(
                      (x) =>
                        x.utentePrenotato ===
                        el.prenotazioni?.[0].utentePrenotato
                    );
                  return (
                    <Draggable
                      onStart={() => false}
                      position={{ x: el.posX, y: el.posY }}
                      key={index}
                    >
                      <div
                        className={el.element.style}
                        style={{
                          position: "absolute",
                          width: `${grandezzaIcona}px`,
                          height: `${grandezzaIcona}px`,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: isAvatar ? "#fff" : ""
                        }}
                        onClick={(event) => onClickIcon(event, index, el)}
                        onTouchStart={(event) =>
                          onTouchMobile(event, index, el)
                        }
                        onTouchStartCapture={(event) => stopEvent(event)}
                      >
                        {el?.idStanza && (
                          <ShowDispositiviOnPlanimetria el={el} />
                        )}
                        {selected.index === index &&
                        selected.status === DISPONIBILE ? (
                          iconSelectedElement
                        ) : isAvatar ? (
                          <AvatarImage
                          isOspite={el.prenotazioni?.[0].ospite}
                            size={34}
                            bgColor={"#ad3a3a"}
                            ImmagineAvatar={
                              el.prenotazioni.length >= 1
                                ? el.prenotazioni?.[0].immagineAvatar
                                : null
                            }
                            isUserLoggedImage={false}
                            isCentered={false}
                            isInputDisabled={true}
                            name={el.prenotazioni?.[0].ospite ? el.prenotazioni?.[0].nominativoOspite : el.prenotazioni?.[0].utentePrenotatoFullname}
                          />
                        ) : (
                          el.element.icon
                        )}
                      </div>
                    </Draggable>
                  );
                })}
                <ElementSubMenu
                  open={isOpenSubmenu}
                  mostraTasti={false}
                  infoPrenotazioniToSubmenu={infoPrenotazioniToSubmenu}
                  infoPositionSubMenuAndId={infoPositionSubMenuAndId}
                  typeComponent={typePage}
                  anchorEl={anchorEl}
                />
              </div>
            )}
          </div>
        </Grid>   
      </Grid>
      <Notification />
      {isMobile && <ShowTips parent={componentName} />}
      {isSalaRiunione && elementiDalDb[0].status === DISPONIBILE ? (
          <PartecipantiRiunione
            ospitiDaPrenotazioneStepper={ospitiDaInserireInSalaRiunione}
            salaRiunione={dettaglioSalaRiunione}
            titoloRiunione={titoloRiunioneSala}
          />
        ) : null}
    </React.Fragment>
  );
}

import { useEffect } from "react";

export default function useAdaptiveEllipsis(gridItemRef, setState, adjustment = 0) {

    useEffect(() => {
        if (gridItemRef?.current) {
          const computedStyles = window.getComputedStyle(gridItemRef?.current);
          const maxWidth = computedStyles.getPropertyValue('width');
          const numericMaxWidth = parseFloat(maxWidth);
          const value = numericMaxWidth + adjustment;
          setState(value + "px")
        }
      }, [gridItemRef]);
}
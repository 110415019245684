
export const accountListUserRadioButtonInHistoryDatatable = [{ label: "my_only", value: "true" },{ label: "all_v2", value: "false" }];
  
export const accountListViewRadioButtonInHistoryDatatable = [{ label: "reservations", value: "false" },{ label: "disabilities", value: "true" }];

/**Opzioni ordinamento per data in ricerca avanzata prenotazioni*/
export const dateSortOptionsAdvancedSearch = [{ label: "oldest", value: true },{ label: "newest", value: false }];

export const radioButtonPrenotazioneStepper = [{ label: "for_me", value: 'false' }, { label: "for_other_users", value: 'true' }, { label: "for_guests_only", value: "ospiti" }];

export const radioButtonPrenotazioneStepperMeAndOtherAndGuests = [{ label: "for_me", value: 'false' }, { label: "for_other_users", value: 'true' }, { label: "for_guests_only", value: "ospiti" }];
export const radioButtonPrenotazioneStepperMeAndOther = [{ label: "for_me", value: 'false' }, { label: "for_other_users", value: 'true' }];
export const radioButtonPrenotazioneStepperMeAndGuests = [{ label: "for_me", value: 'false' }, { label: "for_guests_only", value: "ospiti" }];
export const radioButtonPrenotazioneStepperOnlyMe = [{ label: "for_me", value: 'false' }];

export const labelArrayDialogUserAddEdit = ["domain_credentials", "email_password"];
export const labelArrayDialogUserSincronizzazione = ["domain_credentials", "email_password"];
//export const labelArrayDialogUserSignIn = ["domain_credentials", "email_password"];
export const labelArrayDialogUserSignIn = ["email_password"];

export const labelPlannerOrdineAlfabetico = [{ label: "A_Z", value: "Alfabetico" },{ label: "Total_Hours", value: "NumOre" }];
import {
  Button,
  Dialog,
  DialogActions,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { QrReader } from "react-qr-reader";
import { useDispatch } from "react-redux";
import { checkPostazioneStato } from "../../../const/urlAction";
import GeneralBackButton from "../../../functions/backButton/generalBackButton";
import qrCodeBackButton from "../../../functions/backButton/qrCodeBackButton";
import handlerError from "../../../functions/handlerError";
import useHandlerBackButton from "../../../functions/Utils/handlerBackButtonAndroid";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { loadprenotazioniTableState } from "../../../redux/reducers/prenotazioneTable-reducer";
import PostazioneScannedQrCode from "./ComponentQrCode/PostazioneScannedQrCode/PostazioneScannedQrCode";
import ScannerQrCode from "./ComponentQrCode/ScannerQrCode";
import "./cssQrCode.css";

const urlBase = process.env.REACT_APP_URL;

export default function DialogQrCode({
  isOpenQrCode = false,
  returnStateFromComponent,
}) {
  const dispatch = useDispatch();
  const [data, setData] = useState(undefined);
  const [isScanActive, setIsScanActive] = useState(true);
  const [postazioneScannedfromQRcode, setPostazioneScannedfromQRcode] =
    useState(null);
  const [dialogState, setDialogState] = useState(isOpenQrCode);

  useEffect(() => {
    setDialogState(isOpenQrCode);
  }, [isOpenQrCode]);

  useEffect(() => {
    returnStateFromComponent(dialogState);
  }, [dialogState]);

  useEffect(() => {
    if (data) {
      setIsScanActive(false);
      let isMounted = true;
      (async () => {
        const { action, method } = checkPostazioneStato;
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data: data,
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.data.ok && isMounted) {
            if (response.data.result === undefined) {
              if (response.data.esito == 0) {
                setDialogState(false);
                dispatch(
                  notificationOpen({
                    message: response.data.message,
                    status: "success",
                  })
                );
                dispatch(loadprenotazioniTableState())
              } else {
                setDialogState(false);
                dispatch(
                  notificationOpen({
                    message: response.data.message,
                    status: "warning",
                  })
                );
              }
            } else {
              setPostazioneScannedfromQRcode(response.data.result);
            }
          }
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
        }
        setIsScanActive(true);
      })();
      return () => {
        isMounted = false;
      };
    }
  }, [data]);

  useHandlerBackButton(
    {
      scannerQr: setPostazioneScannedfromQRcode,
      scannerQrState: postazioneScannedfromQRcode,
      PostazioneScannedQrCode: setDialogState,
      PostazioneScannedQrCodeState: dialogState,
    },
    qrCodeBackButton,
    dispatch
  );
  return (
    <>
      <Dialog
        open={dialogState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullScreen
      >
        {!postazioneScannedfromQRcode ? (
          <ScannerQrCode
            componentState={dialogState}
            returnValue={setData}
            returnComponentState={setDialogState}
            isScanActive={isScanActive}
          />
        ) : (
          <PostazioneScannedQrCode
            returnComponentState={setDialogState}
            postazioneScanned={postazioneScannedfromQRcode}
            returnBackComponentView={setPostazioneScannedfromQRcode}
            closeGeneralDialog={setDialogState}
          />
        )}
      </Dialog>
    </>
  );
}

import { LegendaItemPosition } from "../../../../const/legenda/LegendaItem";
import LegendaElement from "./PlanimetriaLegendaElement/LegendaElement";

export default function ShowDispositiviOnPlanimetria({ el }) {
    
    let positionItems = LegendaItemPosition[el.dotazioni.length]
    return (
      <>
        {el.dotazioni &&
          el.dotazioni.map((x, index) => {
            const {top, right} = positionItems[index]
            return (
              <LegendaElement el={x} positionT={top} positionR={right} key={index} />
            );
          })}
      </>
    );
  }
  
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const roleWarning = "text_12"
export const credenzialiDiDominioWarning = "text_13";

export const UserDialogTextActiveDirectory = "text_3";

export const UserDialogTextLocalDatabase = "text_4";

export default function AvvisoFormMessage({msg="default message"}) {
    const { t } = useTranslation();
    const htmlString = t(msg)
    
    return(
        <>
        <Typography sx={{fontSize:"12px", color:"#3e6bb4"}} dangerouslySetInnerHTML={{ __html: htmlString }}></Typography>
        </>
    )
};
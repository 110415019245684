import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function LayerDescender({ icon, id }) {
    const navigate = useNavigate();

    return (
    <>
    <Grid xs={12} item className="flex-center-center height-tot" onClick={() => navigate("" + id)}>
      <FontAwesomeIcon icon={icon} />
    </Grid>
    </>
  );
}

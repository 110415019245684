import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next"; // Importa useTranslation dal package react-i18next
import { Grid, Divider, Typography } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import TableToolbar from "../UI/TableToolbar/TableToolbar";
import { CDCard, CDCardContent, CDCardHeader } from "../../const/CDCardStyles";
import CheckValidSortModel from "../../functions/CheckValidSortModel";
import { BuildingHeader } from "../../const/DatatableData";
import { getBuildingPaginated } from "../../const/urlAction";
import { useSelector, useDispatch } from "react-redux";
import handlerError from "../../functions/handlerError";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import Notification from "../UI/Notification/Notification";
import { removeDataGridHeader } from "../../functions/handlerDataGrid";
import { TitleDatable } from "../TitleDatable/TitleDataTable";
import BuildingDialogRefactored from "../Dialog/BuildingDialog/BuildingDialogRefactored";
import { multiDialogAction } from "../../const/Dialog/MultiDialogAction";
import { closeDialogBuilding } from "../../redux/reducers/DialogBuilding-reducer";
import useHeaderDataGridI18n from "../../Hooks/I18n/useHeaderDataGridI18n";
import MobileViewList from "../UI/mobileView/list/MobileViewList";
import ButtonBoxBuilding from "./ButtonBoxBuilding";
import MobileViewContainer from "../UI/mobileView/mobileViewContainer";
import { rowStructuresEnum } from "../UI/mobileView/enum/rowStructuresEnum";
import { handleDialogEditBuilding } from "./operations/handleDialogEditBuilding";
import handlePaginationAndRowCleanupMobileView from "../../functions/mobileView/handlePaginationAndRowCleanupMobileView";
import useViewportAdaptedHeight from "../../Hooks/Adaptive/useViewportAdaptedHeight";

const typePage = "Edifici";
const urlBase = process.env.REACT_APP_URL;
const rowPage = 10;

const Building = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector((state) => state.dialogBuilding);
  const reloading = useSelector((state) => state.dataTableBuildings);
  const [searchFilter, setSearchFilter] = useState("");
  const [clicked, setClicked] = useState({ type: "", count: 0, rowInfo: [] });
  const [elementoToDialog, setElementoToDialog] = useState({});
  const [searchStatus, setSearchStatus] = useState(false);
  const [page, setPage] = useState(0);
  const lastSearchFilter = useRef(); //determina se deve resettare le row
  const lastPageIndex = useRef(); //determina se deve resettare le row
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const viewState = useSelector((state) => state.viewState);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const CDCardContentRef = useRef(null);
  const [rowsCount, setRowsCount] = useState(rows.length);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const header = useHeaderDataGridI18n(BuildingHeader.headers);
  const [sortModel, setSortModel] = useState([
    {
      field: BuildingHeader.initialFieldName,
      sort: BuildingHeader.initialFieldOrder,
    },
  ]);
  const StyledDataGrid = removeDataGridHeader(DataGrid, viewState);

  const handleSearchFilter = (searchText) => {
    setSearchFilter(searchText);
    setSearchStatus(!searchStatus);
    setPage(0);
    setRows([]);
  };

  useEffect(() => {
    let isMounted = true;
    const { sortField, sortOrder } = CheckValidSortModel(
      sortModel,
      BuildingHeader.initialFieldName
    );

    (async () => {
      setLoading(true);

      const { action, method } = getBuildingPaginated;
      let url = urlBase + action;

      const requestBody = {
        pageIndex: page,
        pageSize: pageSize,
        sortColumnName: sortField,
        sortDirection: sortOrder,
        applyPaginationAndSort: true,
        searchFilter: searchFilter,
      };

      const cleanRow = handlePaginationAndRowCleanupMobileView(
        lastSearchFilter.current,
        lastPageIndex.current,
        searchFilter,
        page,
        pageSize,
        requestBody
      );

      try {
        const response = await axios({
          method: method,
          url,
          data: requestBody,
        });
        if (isMounted) {
          const newRows = response.data.result.result;
          const newRowsCount = response.data.result.totalFiltered;
          cleanRow || !isMobile && !viewState
            ? setRows(newRows)
            : setRows((oldRows) => [...oldRows, ...newRows]);
          setRowsCount(newRowsCount);
          lastSearchFilter.current = requestBody.searchFilter;
          lastPageIndex.current = requestBody.pageIndex;

          if (!response.data.ok) {
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          }
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
        setLoading(false);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [page, pageSize, sortModel, searchStatus, searchFilter, reloading]);

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  });

  const handleResizeWindow = () => {
    if (window.innerWidth > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  //gestisce il cambio di View su dispositivo desktop tra desktop e mobile
  useEffect(() => {
    if (page != 0 || pageSize != rowPage) setRows([])
    setPage(0)
    setPageSize(rowPage)
  }, [viewState])

  //gestisce lo stato della dialog dal contexto perchè il BoxButton non ha l'accesso diretto
  useEffect(() => {
    if (dialogState.count > 0) {
      setClicked((old) => {
        return { type: dialogState.type, count: old.count + 1 };
      });
      setElementoToDialog(dialogState.rowInfo);
    }
  }, [dialogState]);

  // Si assicura che all'apertura del componente la Dialog sia chiusa.
  useEffect(() => {
    return () => {
      dispatch(closeDialogBuilding());
    };
  }, []);

  const adaptedHeight = useViewportAdaptedHeight(".adaptHeight", 19, CDCardContentRef, !loading, [viewState, isMobile]);

  return (
    <>
      {isMobile ? (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding adaptHeight forceCdCardHeader-height"
                title={
                  <>
                    <TitleDatable nameDataTable={t("building_v3")} />
                  </>
                }
              />
              <Divider />
              <CDCardContent
                className="CDCardContent-Mobile small-padding-mobilerow"
                id="mainElement"
                ref={CDCardContentRef}
                sx={{ height: adaptedHeight }}
              >
                <Grid container item>
                  <MobileViewContainer>
                    <TableToolbar
                      typePage={typePage}
                      enableAdd={true}
                      isSalaRiunione={false}
                      enableSearch={true}
                      enableExport={true}
                      handleAdd={() => {
                        setClicked((old) => {
                          return {
                            type: multiDialogAction.add,
                            count: old.count + 1,
                          };
                        });
                      }}
                      initialSearchText={searchFilter}
                      startSearch={handleSearchFilter}
                    />
                  </MobileViewContainer>
                  <MobileViewList
                    rows={rows}
                    BoxButton={ButtonBoxBuilding}
                    typeStructure={rowStructuresEnum.DataTableRowStructures}
                    handleDialogToEdit={handleDialogEditBuilding}
                    setPage={setPage}
                    areMoreRowsAvailable={(page + 1) * pageSize < rowsCount}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
        </>
      ) : !isMobile && !viewState ? (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding forceCdCardHeader-height"
                title={
                  <>
                    <TitleDatable nameDataTable={t("building_v3")} />
                  </>
                }
              />
              <Divider />
              <CDCardContent>
                <Grid container item>
                  <StyledDataGrid
                    rows={rows}
                    rowCount={rowsCount}
                    getRowId={(row) => row.id}
                    columns={header}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) =>
                      setSortModel(newSortModel)
                    }
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    sortingMode="server"
                    sortingOrder={["asc", "desc"]}
                    paginationMode="server"
                    loading={loading}
                    sx={{ border: 0 }}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    autoHeight
                    autoWidth
                    components={{
                      Toolbar: () => (
                        <TableToolbar
                          typePage={typePage}
                          enableAdd={true}
                          isSalaRiunione={false}
                          enableSearch={true}
                          enableExport={true}
                          handleAdd={() => {
                            setClicked((old) => {
                              return {
                                type: multiDialogAction.add,
                                count: old.count + 1,
                              };
                            });
                          }}
                          initialSearchText={searchFilter}
                          startSearch={handleSearchFilter}
                        />
                      ),
                      NoRowsOverlay: () => (
                        <GridOverlay>
                          <Typography>{t("NoRowsOverlay")}</Typography>
                        </GridOverlay>
                      ),
                      NoResultsOverlay: () => (
                        <GridOverlay>
                          <Typography>{t("NoRowsOverlay")}</Typography>
                        </GridOverlay>
                      ),
                    }}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding adaptHeight forceCdCardHeader-height"
                title={
                  <>
                    <TitleDatable nameDataTable={t("building_v3")} />
                  </>
                }
              />
              <Divider />
              <div className="adaptHeight" style={{ backgroundColor: "white" }}>
                <div style={{ margin: "20px" }}>
                  <TableToolbar
                    typePage={typePage}
                    enableAdd={true}
                    isSalaRiunione={false}
                    enableSearch={true}
                    enableExport={true}
                    handleAdd={() => {
                      setClicked((old) => {
                        return {
                          type: multiDialogAction.add,
                          count: old.count + 1,
                        };
                      });
                    }}
                    initialSearchText={searchFilter}
                    startSearch={handleSearchFilter}
                  />
                </div>
              </div>
              <CDCardContent
                className="CDCardContent-Mobile"
                id="mainElement"
                ref={CDCardContentRef}
                sx={{ height: adaptedHeight }}
              >
                <Grid container item>
                  <MobileViewList
                    rows={rows}
                    BoxButton={ButtonBoxBuilding}
                    typeStructure={rowStructuresEnum.DataTableRowStructures}
                    handleDialogToEdit={handleDialogEditBuilding}
                    setPage={setPage}
                    areMoreRowsAvailable={(page + 1) * pageSize < rowsCount}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
        </>
      )}
      <Notification />
      {clicked.count > 0 && (
        <BuildingDialogRefactored
          clicked={clicked}
          rowInfo={elementoToDialog}
        />
      )}
    </>
  );
};

export default Building;

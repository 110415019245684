import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { getCallTypes } from "../../const/urlAction";
import { Grid, Button, Card, CardContent, IconButton, CardActions, Collapse, Typography } from "@mui/material";
import { FormControl, FormLabel, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './AdvancedFilterPanel.module.css';
import { changeFontSizeRadioButton } from "../../functions/handlerDataGrid";
import { useSelector } from "react-redux";
import useMobileDetection from "../../Hooks/useMobileDetection";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next"; 
import { AutenticationType } from "../../const/SignInUser/AutenticationType";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  })
}));

export default function AdvancedFilterPanel({ isFetching, handleFilterData, startValueRadioButton, startValueFilterAuth }) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const authConfig = useSelector((state) => state.configurations.configurazioniAutenticazione);
  const [radioUserEnabled, setRadioRecoveryEnabled] = useState(startValueRadioButton);
  const [radioUserDomainAuth, setRadioUserDomainAuth] = useState(startValueFilterAuth);

  const handleUserChange = (event) => {
    setRadioRecoveryEnabled(event.target.value);
  };

  const handleAuthenticationChange = (event) => {
    setRadioUserDomainAuth(event.target.value);
  };

  function actionApplica() {
    handleFilterData(radioUserEnabled, radioUserDomainAuth);
    setExpanded(false)
  }

  const { t } = useTranslation(); // Aggiungi il modulo di traduzione

  return (
    <>
      <Grid container item>
        <Grid item component={Card} xs={12} sm={12} sx={{ boxShadow: 0 }}>
          <CardActions sx={{ justifyContent: 'flex-end', padding: "0 0 8px 0 !important" }} disableSpacing>
            <Typography variant="button">{t("expand_filters")}</Typography>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={3} sm={3}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">{t("typology")}</FormLabel>
                    <RadioGroup aria-label="recoveryEnabled" name="row-radio-group-enabled" onChange={handleUserChange} value={radioUserEnabled} row>
                      <FormControlLabel value={true} control={<Radio />} label={t("enabled_only")} />
                      <FormControlLabel value={false} control={<Radio />} label={t("disabled_only")} />
                      <FormControlLabel value={"null"} control={<Radio />} label={t("all")} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {(authConfig.enableActiveDirectoryAuth && authConfig.enableDatabaseAuth) && <Grid item xs={4} sm={4}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">{t("authentication")}</FormLabel>
                    <RadioGroup aria-label="recoveryDomain" name="row-radio-group-domain" onChange={handleAuthenticationChange} value={radioUserDomainAuth} row>
                      <FormControlLabel value={"null"} control={<Radio />} label={t("all")} />
                      {authConfig.enableActiveDirectoryAuth  && <FormControlLabel value={AutenticationType.ActiveDirectory} control={<Radio />} label={t("domain_only")} />}
                      {authConfig.enableDatabaseAuth && <FormControlLabel value={AutenticationType.Database} control={<Radio />} label={t("database_only")} />}
                    </RadioGroup>
                  </FormControl>
                </Grid>}
                <Grid container item>
                  <Grid item xs={12} className={styles.buttonGrid}>
                    <Button variant="contained" disabled={isFetching} className={styles.buttonStyle} onClick={() => {
                      actionApplica();
                    }}>{t("apply")}</Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
}

export function AdvancedFilterScissor({ expanded, isFetching, handleFilterData, returnExpandedState, labelCustom = null }) {

  const handleExpandClick = () => {
    returnExpandedState(!expanded);
  };

/*   const [radioUserEnabled, setRadioRecoveryEnabled] = useState(true);


  const handleUserChange = (event) => {
    setRadioRecoveryEnabled(event.target.value);
  };

  function actionApplica() {
    handleFilterData(radioUserEnabled);
    returnExpandedState(false)
  } */

  const { t } = useTranslation(); // Aggiungi il modulo di traduzione

  return (
    <>
      <Grid container item>
        <Grid item component={Card} xs={12} sm={12} sx={{ boxShadow: 0 }}>
          <CardActions sx={{ justifyContent: 'flex-end', padding: "0 0 8px 0" }} disableSpacing>
            {!labelCustom ? <Typography variant="button">{t("export_and_filter")}</Typography> : <Typography className="etichettaFiltroCustom">{labelCustom}</Typography>}
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </ExpandMore>
          </CardActions>
        </Grid>
      </Grid>
    </>
  );
}

export function MenuCollapse({ expanded, handleFilterData, isFetching, actionApplica, startValueRadioButton, returnExpandedStateAfterConfirm }) {

  const [radioUserEnabled, setRadioRecoveryEnabled] = useState(startValueRadioButton);

  const isMobile = useMobileDetection()

  const FormControlLabelCustom = changeFontSizeRadioButton(FormControlLabel, isMobile);

  const handleUserChange = (event) => {
    setRadioRecoveryEnabled(event.target.value);
  };

  function actionApplica() {
    handleFilterData(radioUserEnabled);
    returnExpandedStateAfterConfirm(false)
  }

  const { t } = useTranslation(); // Aggiungi il modulo di traduzione

  return (
    <>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container columnSpacing={3} rowSpacing={3}>
            <Grid item xs={12} sm={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{t("typology")}</FormLabel>
                <RadioGroup aria-label="recoveryEnabled" name="row-radio-group-enabled" onChange={handleUserChange} value={radioUserEnabled} row>
                  <FormControlLabel value={true} control={<Radio />} label={t("enabled_only")} />
                  <FormControlLabel value={false} control={<Radio />} label={t("disabled_only")} />
                  <FormControlLabel value={"null"} control={<Radio />} label={t("all")} />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container item>
              <Grid item xs={12} className={styles.buttonGrid}>
                <Button variant="contained" disabled={isFetching} className={styles.buttonStyle} onClick={() => {
                  actionApplica();
                }}>{t("apply")}</Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </>
  )
}

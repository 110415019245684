import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dialogButtonGeneral,
} from "../../../../../../const/DialogData";
import { initialValuesUserAdd } from "../../../../../../const/formik/User/initialValuesUser";
import { validationSchemaUserAddToAuthActiveDirectory } from "../../../../../../const/formik/User/validationSchemaUser";
import SwitchPassword from "../../switchPassword";
import { closeDialogUser } from "../../../../../../redux/reducers/dialogUser-reducer";
import { addAccount, GetUserFromActiveDirectory, getUsersFiltered } from "../../../../../../const/urlAction";
import axios from "axios";
import handlerError from "../../../../../../functions/handlerError";
import { notificationOpen } from "../../../../../../redux/reducers/notification-reducer";
import { reloadingDataTableUsers } from "../../../../../../redux/reducers/dataTableUsers-reducer";
import autoCompleteFormFromActiveDirectory from "../../../../../../functions/dialog/autoCompleteFormFromActiveDirectory";
import AvvisoFormMessage, { credenzialiDiDominioWarning, roleWarning } from "../../../../../UI/Form/AvvisoFormMessage";
import { hideLoader, showLoader } from "../../../../../../redux/reducers/appLoader-reducer";
import { useTranslation } from "react-i18next";

const urlBase = process.env.REACT_APP_URL;

export default function DialogViewAddUserActiveDirectory({
  isOpen = false,
  isCommitted,
  setIsCommitted
}) {
  const stateUserRoles = useSelector((state) => state.userRoles);
  const [changedRow, setchangedRow] = useState();
  const [stateUsers, setStateUsers] = useState([]);
  const dispatch = useDispatch();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { t } = useTranslation();

  const appLoadingState = useSelector((state) => state.appLoader.loading);

  let initialValues = initialValuesUserAdd();
  let validationSchema = validationSchemaUserAddToAuthActiveDirectory;

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const { action, method } = getUsersFiltered;
      let url = urlBase + action;
      try {
        const response = await axios({
          method: method,
          url,
        });
        if (isMounted) {
          setStateUsers(response.data.result);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [isOpen]);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true, // rinizializza il valore che gli viene passato con rowInfo
    onSubmit: (values) => {
      setchangedRow({
        FirstName: values.firstName,
        LastName: values.lastName,
        Username: values.username,
        Email: values.email,
        PhoneNumber: values.phoneNumber,
        roles: selectedRoles,
        users: selectedUsers,
      });
    },
  });

  useEffect(()=>{
    if(isCommitted) formik.submitForm();
    setIsCommitted(false);
  },[isCommitted])

  useEffect(() => {
    setSelectedRoles([]);
  }, [isOpen]);

  const handleChangeUserSelect = (event, value) => {
    setSelectedUsers(value);
  };

  const closeDialog = () => {
    formik.resetForm(initialValues);
    dispatch(closeDialogUser());
  };

  const handleChangeRoleSelect = (event, value) => {
    setSelectedRoles(value);
  };

  useEffect(() => {
    if (changedRow != null) {
      (async () => {
        let body, action, method, url;
        body = {
          ...changedRow,
        };

        action = addAccount.action;
        method = addAccount.method;

        url = urlBase + action;
        dispatch(showLoader())
        try {
          const response = await axios({
            method: method,
            url,
            data: body,
          });

          if (response.data.ok) {
            formik.resetForm(initialValues);
            dispatch(reloadingDataTableUsers());
            dispatch(closeDialogUser());
          }
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: response.data.ok ? "success" : "error",
            })
          );
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
          if (error?.response?.data?.errors != undefined) {
            let keys = Object.keys(error?.response?.data?.errors);
            keys.map((key) => {
              formik.setFieldError(key, error?.response?.data?.errors[key]);
            });
          }
        }
        dispatch(hideLoader())
      })();
    }
  }, [changedRow]);


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth>
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item container xs={12} sm={12}>
              <Grid item container xs={10} sm={10}>
                <TextField
                  margin="dense"
                  id="username"
                  label={t("username")}
                  type="text"
                  fullWidth
                  variant="standard"
                  value={formik.values.username}
                  onChange={formik.handleChange("username")}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={
                    formik.touched.username && formik.errors.username
                  }
                  inputProps={{
                    autoComplete: "new-Password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
                <AvvisoFormMessage msg={credenzialiDiDominioWarning} />
              </Grid>
              <Grid item container xs={2} sm={2}>
                <Button onClick={() => autoCompleteFormFromActiveDirectory(formik, true, dispatch)} disabled={!formik.values.username.trim().length > 0}>{t("complete")}</Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                margin="dense"
                id="email"
                label={t("email")}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.email}
                onChange={formik.handleChange("email")}
                error={
                  formik.touched.email && Boolean(formik.errors.email)
                }
                helperText={
                  formik.touched.email && formik.errors.email
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="firstName"
                label={t("firstName")}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.firstName}
                onChange={formik.handleChange("firstName")}
                error={
                  formik.touched.firstName &&
                  Boolean(formik.errors.firstName)
                }
                helperText={
                  formik.touched.firstName && formik.errors.firstName
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="lastName"
                label={t("lastName")}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.lastName}
                onChange={formik.handleChange("lastName")}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={
                  formik.touched.lastName && formik.errors.lastName
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                margin="dense"
                id="phoneNumber"
                label={t("Phone")}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange("phoneNumber")}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                id="roles"
                size="small"
                options={stateUserRoles}
                value={selectedRoles}
                disableCloseOnSelect
                limitTags={1}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id
                }
                getOptionLabel={(option) => option.description}
                onChange={handleChangeRoleSelect}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={
                        <CheckBoxOutlineBlankIcon fontSize="small" />
                      }
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.description}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Assigned_Roles")}
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
              <AvvisoFormMessage msg={roleWarning} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                id="users"
                size="small"
                options={stateUsers}
                value={selectedUsers}
                disableCloseOnSelect
                limitTags={1}
                isOptionEqualToValue={(option, value) =>
                  option.idUtente === value.idUtente
                }
                getOptionLabel={(option) => option.nominativo}
                onChange={handleChangeUserSelect}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedicon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.nominativo}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("associated_users")}
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormControl>
{/*         <DialogActions sx={{ mt: 3 }}>
          <Button onClick={() => closeDialog()}>
            {t(dialogButtonGeneral.Cancel)}
          </Button>
          <Button type="submit" variant="contained" disabled={appLoadingState}>
            {t(dialogButtonGeneral.Confirm)}
          </Button>
        </DialogActions> */}
      </form>
    </>
  );
}

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useTranslation } from "react-i18next";
import ShowMore from "../../showMore/showMore";

export default function PaginationMobileView({setPage}) {


    return (
        <>
        <ShowMore setPage={setPage}/>
        </>
    )
}
import { Grid, Typography, Avatar, Tooltip, Skeleton } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { notificationOpen } from "../../../../../redux/reducers/notification-reducer";
import handlerError from "../../../../../functions/handlerError";
import { getDettaglioPrenotazioneById } from "../../../../../const/urlAction";
import { maxValueText } from "../../../../../const/DialogRiepilogoText";
import { enumCheckInStatus } from "../../../../../const/prenotazioni/enumCheckInStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCity, faClock, faDesktop, faLocationDot, faUserPen, faUsers, faComputerMouse, faDoorOpen, faMapLocationDot, faCircleInfo, faUser } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { CDCard, CDCardContent } from "../../../../../const/CDCardStyles";
import { Box } from "@mui/system";
import useMobileDetection from "../../../../../Hooks/useMobileDetection";
import { iconMappingTipologiaStanza, labelMappingTipologiaStanza } from "../../../../../const/mapping/TipologiaStanza/tipologiaStanzaMapping";

const urlBase = process.env.REACT_APP_URL;

export default function Riepilogo({ id }) {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const { t } = useTranslation();
  const isMobile = useMobileDetection();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const { action, method } = getDettaglioPrenotazioneById;
      let url = urlBase + action + id;

      try {
        const response = await axios({
          method: method,
          url,
        });

        if (response.data.ok) {
          setRows(response.data.result);
        } else {
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }

      setIsLoading(false);
    })();
    return () => {
      setRows([]);
    };
  }, []);

  //gestisce se mostrare la data fine originale
  function appendDataFineOriginale(valueOriginal, value) {
    const time1 = new Date(valueOriginal)
    const time2 = new Date(value)
    return time1.getTime() === time2.getTime()
      ? ""
      : <Tooltip title={t("check_out_before_original_reservation") + moment(time1).format("HH:mm")} >
        <FontAwesomeIcon icon={faCircleInfo} className="infoIcon" />
      </Tooltip>
  }

  return (
    <>
      {!isLoading ? (
        <Grid item ml={2} mr={2} mt={2} mb={2} xs={12} sm={12} component={CDCard} sx={!isMobile ? { boxShadow: 'none', width: '800px!important' } : { boxShadow: 'none' }}>
          <CDCardContent sx={{ padding: '0' }}>
            <Grid container rowSpacing={2}>
              {/* Parte Sinistra */}
              <Grid container item xs={12} sm={6} className={!isMobile ? "cardRiepilogo" : "flex-center-center card-text-Primary"}>
                {/* Immagine dell'Avatar */}
                <Grid item xs={12} className="flex-center-center">
                  {rows.ospite || !rows.immagineAvatar
                    ? <Avatar
                      alt="Nome Utente"
                      children={<FontAwesomeIcon icon={faUser} className="avatarIcon" />}
                      sx={{ width: 100, height: 100 }}
                    />
                    :
                    <Avatar
                      alt="Nome Utente"
                      src={"data:image/*;base64," + rows.immagineAvatar}
                      sx={{ width: 100, height: 100 }}
                    />
                  }
                </Grid>
                {/* Nome e Cognome */}
                {rows.ospite
                  ? <>
                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight="bold">{rows.nominativoOspite}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ display: "inline", color: "grey" }}>{t("guest_of_user")}{" "}{rows.utentePrenotato}</Typography>
                    </Grid>
                  </>
                  :
                  <Grid item xs={12}>
                    <Typography variant="h6" fontWeight="bold">{rows.utentePrenotato}</Typography>
                  </Grid>
                }
                {/* Data e ora */}
                <Grid item xs={12}>
                  <Typography className="cardRiepilogoText">
                    {moment(rows.dataInizioPrenotazione).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {rows.disabilitato ? (
                    rows.isMaxValue === true ? (
                      <Typography className="cardRiepilogoText">
                        {moment(rows.dataInizioPrenotazione).format("HH:mm")}{" "} - {maxValueText}{" "}
                      </Typography>
                    ) : moment(rows.dataInizioPrenotazione).format("DD/MM/YYYY") ===
                      moment(rows.dataFinePrenotazione).format("DD/MM/YYYY") ? (
                      <Typography className="cardRiepilogoText">
                        {moment(rows.dataInizioPrenotazione).format("HH:mm")} - {moment(rows.dataFinePrenotazione).format("HH:mm")}
                      </Typography>
                    ) : (
                      <Typography className="cardRiepilogoText">
                        {moment(rows.dataInizioPrenotazione).format("HH:mm")} - {moment(rows.dataFinePrenotazione).format("HH:mm")}
                      </Typography>
                    )
                  ) : (
                    <Typography className="cardRiepilogoText">
                      {moment(rows.dataInizioPrenotazione).format("HH:mm")} - {moment(rows.dataFinePrenotazione).format("HH:mm")} {appendDataFineOriginale(rows.dataOraFinePrenotazioneOriginale, rows.dataFinePrenotazione)}
                    </Typography>
                  )}
                </Grid>
                {/* Status checkin */}
                {(!rows.disabilitato && rows.enableCheckIn && rows.checkInStatus !== enumCheckInStatus.future) && (
                  <Grid item xs={6} className="checkInTag flex-center-center" sx={{
                    background: rows.checkInStatus === enumCheckInStatus.notChecked ? '#e3525293' :
                      rows.checkInStatus === enumCheckInStatus.waiting ? '#ffe686' :
                        rows.checkInStatus === enumCheckInStatus.completed ? '#28a7458a' :
                          null
                  }}>
                    {rows.checkInStatus !== 0 && <> <Box>
                      <Typography sx={{
                        color: rows.checkInStatus === enumCheckInStatus.notChecked ? '#cb0505' :
                          rows.checkInStatus === enumCheckInStatus.waiting ? 'orange' :
                            rows.checkInStatus === enumCheckInStatus.completed ? '#137128' :
                              null,
                        textAlign: 'center',
                        lineHeight: '1.5',
                        fontSize: ' 0.8rem',
                        padding: '3px'
                      }}>
                        {rows.checkInStatus === enumCheckInStatus.waiting ?
                          t("waiting_check_in") :
                          rows.checkInStatus === enumCheckInStatus.notChecked ?
                            t("check_in_expired") :
                            rows.idUtenteCheckIn !== rows.idUtentePrenotato ?
                              t("check_in_time") + ": " + moment(rows.dataCheckIn).format("DD/MM/YYYY HH:mm") + " (" + rows.utenteCheckIn + ")" :
                              t("check_in_time") + ": " + moment(rows.dataCheckIn).format("DD/MM/YYYY HH:mm")}
                      </Typography>
                    </Box> </>}
                  </Grid>
                )}
              </Grid>
              {/* Parte Destra */}
              <Grid container item xs={12} sm={6} rowSpacing={1.8} sx={{ paddingLeft: "8px" }} className={!isMobile ? "flex-center-center" : ""}>
                {/* Indirizzo */}
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText cardRiepilogoDetailsText">
                    {rows.edificioIndirizzo 
                      ? (<Grid container>
                        <Grid xs={2} item className="cardRiepilogoIcon">
                          <FontAwesomeIcon icon={faMapLocationDot} className="iconBuilding" />
                        </Grid>
                        <Grid item xs={10}>
                          {" "}{rows.edificioIndirizzo}
                        </Grid>
                      </Grid>) 
                      : (<></>)}                    
                  </Typography>
                </Grid>
                {/* Edificio */}
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText cardRiepilogoDetailsText">
                    <Grid container>
                      <Grid xs={2} item className="cardRiepilogoIcon">
                        <FontAwesomeIcon icon={faCity} className="iconBuilding" />
                      </Grid>
                      <Grid item xs={10}>
                        {" "}{rows.edificioDenominazione}
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
                {/* Zona */}
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText cardRiepilogoDetailsText">
                    <Grid container>
                      <Grid xs={2} item className="cardRiepilogoIcon">
                        <FontAwesomeIcon icon={faLocationDot} className="iconBuilding" />
                      </Grid>
                      <Grid item xs={10}>
                        {" "}{rows.zonaDenominazione}
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
                {/* Stanza */}
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText cardRiepilogoDetailsText">
                    <Grid container>
                      <Grid xs={2} item className="cardRiepilogoIcon">
                        <FontAwesomeIcon icon={!rows.isSalaRiunione ? faDoorOpen : faUsers} className="iconBuilding" />
                      </Grid>
                      <Grid item xs={10}>
                        {" "}{rows.stanzaDenominazione}
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
                {/* Postazione */}
                {!rows.isSalaRiunione && 
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText cardRiepilogoDetailsText">
                      <Grid container>
                        <Grid xs={2} item className="cardRiepilogoIcon">
                          <FontAwesomeIcon icon={iconMappingTipologiaStanza[rows.idTipologiaStanza]} className="iconBuilding" />
                        </Grid>
                        <Grid item xs={10}>
                          {" "}{t(labelMappingTipologiaStanza[rows.idTipologiaStanza])}{" "}{rows.postazionePosto}
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>}
                {/* Dotazioni */}
                {rows.dotazioni && rows.dotazioni.length > 0 &&
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText cardRiepilogoDetailsText">
                      <Grid container>
                        <Grid xs={2} item className="cardRiepilogoIcon">
                          <FontAwesomeIcon icon={faComputerMouse} className="iconBuilding" />
                        </Grid>
                        <Grid item xs={10}>
                          {" "}{rows.dotazioni.map((dotazione) => { return dotazione }).join(", ")}
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>}
                {/* Utente inserimento */}
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText cardRiepilogoDetailsText">
                    <Grid container>
                      <Grid xs={2} item className="cardRiepilogoIcon">
                        <FontAwesomeIcon icon={faUserPen} className="iconBuilding" />
                      </Grid>
                      <Grid item xs={10}>
                        {" "}{rows.utenteInserimento}
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
                {/* Data inserimento */}
                <Grid item xs={12} >
                  <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText cardRiepilogoDetailsText">
                    <Grid container>
                      <Grid xs={2} item className="cardRiepilogoIcon">
                        <FontAwesomeIcon icon={faClock} className="iconBuilding" />
                      </Grid>
                      <Grid item xs={10}>
                        {" "}{moment(rows.dataIns).format("DD/MM/YYYY HH:mm")}
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CDCardContent>
        </Grid>
      )
        : <Skeleton variant="rectangular" height={330} width={830} />
      }
    </>
  );
}

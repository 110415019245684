import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dialogButtonConfirm, dialogSincronizzazioneUsers } from "../../../../../const/DialogData";
import { labelArrayDialogUserSincronizzazione } from "../../../../../const/RadioButton/RadioButtonListRadio";
import { ListTabsDialogoUserSincronizzazione } from "../../../../../const/Tabs/Dialog/DialogUser/DialogSincronizzazione/ListTabsDialogUserSincronizzazione";
import setTabsToShowSincronizzazione from "../../../../../functions/dialog/dialogUser/Sincronizzazione/setTabsToShowSincronizzazione";
import { closeDialogUser } from "../../../../../redux/reducers/dialogUser-reducer";
import MessageNoProvider from "../../../../UI/MessageNoProvider/MessageNoProvider";
import RadioButtonWithTabs from "../../../../UI/RadiobuttonCustom.js/RadioButtonWithTabs/RadioButtonWithTabs";
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../../../../Hooks/useMobileDetection";

export default function DialogSincronizzazioneUser({ isOpen = false }) {
  const [tab, setTab] = useState(0);
  const [action, setAction] = useState(0);
  const authConfiguration = useSelector((state) => state.configurations.configurazioniAutenticazione);
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const isMobile = useMobileDetection(950);

  const appLoadingState = useSelector((state) => state.appLoader.loading);

  useEffect(() => {
    setTab(setTabsToShowSincronizzazione(labelArrayDialogUserSincronizzazione));
    return () => {
      setAction(0)
    }
  }, []);

  const closeDialog = () => {
    dispatch(closeDialogUser());
  };

  function returnRunAction() {
    setAction(old => old + 1)
  }


  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen={isMobile}
      maxWidth={"sm"}
    >
      <DialogTitle>
        {t(dialogSincronizzazioneUsers.DialogTitle)}
      </DialogTitle>
      <DialogContent className="border-Content-dialog">
        <RadioButtonWithTabs
          GroupName={t("authentication_type")}
          tabPanel={ListTabsDialogoUserSincronizzazione(authConfiguration, action, setAction)}
          labelList={labelArrayDialogUserSincronizzazione}
          startValueReset={tab}
          noTabsComponent={<MessageNoProvider />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog()}>
          {t(dialogButtonConfirm.Cancel)}
        </Button>
        <Button type="submit" variant="contained"
          disabled={appLoadingState}
          onClick={() => { returnRunAction() }}
        >
          {t(dialogButtonConfirm.Confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

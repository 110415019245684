import React, { useState, useEffect } from "react";
import axios from "axios";
import { dialogPageTypes } from "../../const/dialogPageTypes";
import { Grid, Divider, Typography } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import TableToolbar from "../UI/TableToolbar/TableToolbar";
import { CDCard, CDCardContent, CDCardHeader } from "../../const/CDCardStyles";
import CheckValidSortModel from "../../functions/CheckValidSortModel";
import { ZoneHeader } from "../../const/DatatableData";
import { getZonePaginated } from "../../const/urlAction";
import { useSelector, useDispatch } from "react-redux";
import ZoneDialog from "../Dialog/ZoneDialog/ZoneDialog";
import handlerError from "../../functions/handlerError";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import Notification from "../UI/Notification/Notification";
import { useParams } from "react-router-dom";
import { removeDataGridHeader } from "../../functions/handlerDataGrid";
import usePathTextDetail from "../../Hooks/usePathTextDetail";
import { openEmptyDialog, setPageType } from "../../redux/reducers/DialogGeneral";
import useCheckDisabilitazioniSuPostazioni from "../../Hooks/useCheckDisabilitazioniSuPostazioni";
import { TitleDatable } from "../TitleDatable/TitleDataTable";
import { useTranslation } from "react-i18next";
import useHeaderDataGridI18n from "../../Hooks/I18n/useHeaderDataGridI18n";
import MobileViewContainer from "../UI/mobileView/mobileViewContainer";
import MobileViewList from "../UI/mobileView/list/MobileViewList";
import ButtonBoxZone from "./ButtonBoxZone";
import { rowStructuresEnum } from "../UI/mobileView/enum/rowStructuresEnum";
import { handleDialogToEditZone } from "./operations/handlerDialogToEditZone";
import { useRef } from "react";
import handlePaginationAndRowCleanupMobileView from "../../functions/mobileView/handlePaginationAndRowCleanupMobileView";
import useViewportAdaptedHeight from "../../Hooks/Adaptive/useViewportAdaptedHeight";

const typePage = "Zone";
const urlBase = process.env.REACT_APP_URL;
const rowPage = 10;

const ZoneDatatable = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const reloading = useSelector((state) => state.dataTableUsers);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const viewState = useSelector((state) => state.viewState);
  const [searchFilter, setSearchFilter] = useState("");
  const lastSearchFilter = useRef(); //determina se deve resettare le row
  const lastPageIndex = useRef(); //determina se deve resettare le row
  const CDCardContentRef = useRef(null);
  const [searchStatus, setSearchStatus] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rowPage);
  const [rows, setRows] = useState([]);
  const [rowsCount, setRowsCount] = useState(rows.length);
  const [loading, setLoading] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const header = useHeaderDataGridI18n(ZoneHeader.headers);
  const isDisabilitazioniOnEntity = useCheckDisabilitazioniSuPostazioni();
  const StyledDataGrid = removeDataGridHeader(DataGrid, viewState);
  const path = usePathTextDetail();

  const handleSearchFilter = (searchText) => {
    setSearchFilter(searchText);
    setSearchStatus(!searchStatus);
  };

  useEffect(() => {
    let isMounted = true;
    const { sortField, sortOrder } = CheckValidSortModel(
      sortModel,
      ZoneHeader.initialFieldName
    );

    (async () => {
      setLoading(true);
      const { idEdificio } = params;
      const { action, method } = getZonePaginated;
      let url = urlBase + action;
      const requestBody = {
        pageIndex: page,
        pageSize: pageSize,
        sortColumnName: sortField,
        sortDirection: sortOrder,
        applyPaginationAndSort: true,
        searchFilter: searchFilter,
        IdEdificio: idEdificio,
      };

      const cleanRow = handlePaginationAndRowCleanupMobileView(lastSearchFilter.current,lastPageIndex.current,searchFilter,page,pageSize,requestBody)

      try {
        const response = await axios({
          method: method,
          url,
          data: requestBody,
        });
        if (isMounted) {
          const newRows = response.data.result.result;
          const newRowsCount = response.data.result.totalFiltered;
          cleanRow || !isMobile && !viewState  ? setRows(newRows) : setRows(oldRows => [...oldRows, ...newRows]);
          setRowsCount(newRowsCount);
          lastSearchFilter.current = requestBody.searchFilter;
          lastPageIndex.current = requestBody.pageIndex;
          setLoading(false);

          if (!response.data.ok) {
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          }
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
        setLoading(false);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [page, pageSize, sortModel, searchStatus, searchFilter, reloading]);

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  });

  const handleResizeWindow = () => {
    if (window.innerWidth > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  const adaptedHeight = useViewportAdaptedHeight(".adaptHeight", 19 , CDCardContentRef, !loading, [viewState, isMobile]);

  //gestisce il cambio di View su dispositivo desktop tra desktop e mobile
  useEffect(() => {
    if(page != 0 || pageSize != rowPage) setRows([])
    setPage(0)
    setPageSize(rowPage)
  },[viewState])

  return (
    <>
      {isMobile ? (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding adaptHeight"
                title={
                  <>
                    <TitleDatable
                      nameDataTable={t("Zones")}
                      pathDataTable={path}
                    />
                  </>
                }
              />
              <Divider />
              <CDCardContent className="CDCardContent-Mobile small-padding-mobilerow" style={{height: adaptedHeight}} ref={CDCardContentRef}>
                <Grid container item>
                  <MobileViewContainer>
                    <TableToolbar
                      typePage={typePage}
                      enableAdd={true}
                      enableSearch={true}
                      enableExport={true}
                      isSalaRiunione={false}
                      showResetDisable={isDisabilitazioniOnEntity}
                      handleAdd={() => {
                        dispatch(setPageType(dialogPageTypes.zone));
                        dispatch(openEmptyDialog("add"));
                      }}
                      initialSearchText={searchFilter}
                      startSearch={handleSearchFilter}
                    />
                  </MobileViewContainer>
                  <MobileViewList
                    rows={rows}
                    BoxButton={ButtonBoxZone}
                    typeStructure={rowStructuresEnum.DataTableRowStructures}
                    handleDialogToEdit={handleDialogToEditZone}
                    setPage={setPage}
                    areMoreRowsAvailable={(page + 1) * pageSize < rowsCount}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
        </>
      ) : !isMobile && !viewState ? (
        <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding"
                title={
                  <>
                    <TitleDatable
                      nameDataTable={t("Zones")}
                      pathDataTable={path}
                    />
                  </>
                }
              />
              <Divider />
              <CDCardContent>
                <Grid container item>
                  <StyledDataGrid
                    rows={rows}
                    rowCount={rowsCount}
                    getRowId={(row) => row.sigla}
                    columns={header}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) =>
                      setSortModel(newSortModel)
                    }
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    disableSelectionOnClick
                    sortModel={sortModel}
                    sortingMode="server"
                    sortingOrder={["asc", "desc"]}
                    paginationMode="server"
                    loading={loading}
                    sx={{ border: 0 }}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    autoHeight
                    autoWidth
                    components={{
                      Toolbar: () => (
                        <TableToolbar
                          typePage={typePage}
                          enableAdd={true}
                          enableSearch={true}
                          enableExport={true}
                          isSalaRiunione={false}
                          showResetDisable={isDisabilitazioniOnEntity}
                          handleAdd={() => {
                            dispatch(setPageType(dialogPageTypes.zone));
                            dispatch(openEmptyDialog("add"));
                          }}
                          initialSearchText={searchFilter}
                          startSearch={handleSearchFilter}
                        />
                      ),
                      NoRowsOverlay: () => (
                        <GridOverlay>
                          <Typography>{t("NoRowsOverlay")}</Typography>
                        </GridOverlay>
                      ),
                      NoResultsOverlay: () => (
                        <GridOverlay>
                          <Typography>{t("NoRowsOverlay")}</Typography>
                        </GridOverlay>
                      ),
                    }}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
        </>
        ) : (
          <>
          <Grid container>
            <Grid item component={CDCard} xs={12} sm={12}>
              <CDCardHeader
                className="CDCardHeader-Padding adaptHeight"
                title={
                  <>
                    <TitleDatable
                      nameDataTable={t("Zones")}
                      pathDataTable={path}
                    />
                  </>
                }
              />
              <Divider />
              <div  className="adaptHeight" style={{ backgroundColor: "white" }}>
                <div style={{ margin: "20px" }}>
                    <TableToolbar
                      typePage={typePage}
                      enableAdd={true}
                      enableSearch={true}
                      enableExport={true}
                      isSalaRiunione={false}
                      showResetDisable={isDisabilitazioniOnEntity}
                      handleAdd={() => {
                        dispatch(setPageType(dialogPageTypes.zone));
                        dispatch(openEmptyDialog("add"));
                      }}
                      initialSearchText={searchFilter}
                      startSearch={handleSearchFilter}
                    />
                    </div>
                  </div>
                <CDCardContent className="CDCardContent-Mobile" id="mainElement"  style={{height: adaptedHeight}} ref={CDCardContentRef}>
                  <Grid container item>
                  <MobileViewList
                    rows={rows}
                    BoxButton={ButtonBoxZone}
                    typeStructure={rowStructuresEnum.DataTableRowStructures}
                    handleDialogToEdit={handleDialogToEditZone}
                    setPage={setPage}
                    areMoreRowsAvailable={(page + 1) * pageSize < rowsCount}
                  />
                </Grid>
              </CDCardContent>
            </Grid>
          </Grid>
        </>
      )}
      <Notification />
      <ZoneDialog />
    </>
  );
};

export default ZoneDatatable;

import { Box, height } from "@mui/system";

export function TabPanelComponentToShow({ componentTab, withPadding = true, height= "100%", refBox = null, overFlowY = false }) {
  
  return (
    <div ref={refBox} className={overFlowY ? "overflow-y overflow-x-none" : "overflow-x-none"}>
      <Box sx={withPadding ? { p: 3, height:height } : { height:height}}>{componentTab}</Box>
    </div>
  );
}

import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { policies as permessi } from "../../const/policies"
import { useNavigate } from 'react-router-dom';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { faBan, faPen, faTrash, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import useMobileDetection from '../../Hooks/useMobileDetection';
import { handleDialogToEditZone, handleDialogToEnableDisableZone } from './operations/handlerDialogToEditZone';
import { dialogPageTypes } from '../../const/dialogPageTypes';

const ButtonBoxZone = ({ row: rowInfo }) => {

  const { t } = useTranslation();
  const state = useSelector((state) => state.userDate);
  const { policies, responsabileEdificio } = state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMobileDetection(600);
  const viewState = useSelector((state) => state.viewState);
  const idZona = rowInfo.id

  return (
    <>
      {!isMobile && !viewState && policies.indexOf(permessi.zoneInserimentoModifica) != -1 && <GridActionsCellItem
        icon={
          <Tooltip title={t("edit")}>
            <FontAwesomeIcon icon={faPen}
            style={{fontSize: 25}} className="pathIcon"
             />
          </Tooltip>
        }
        label={t("edit")}
        onClick={(event) => handleDialogToEditZone({type:"edit", row:rowInfo}, dispatch, event)}
      />}
      {!isMobile && !viewState && policies.indexOf(permessi.stanzeVisualizzazione) != -1 &&<GridActionsCellItem
        icon={
          <Tooltip title={t("manage_rooms")}>
            <MeetingRoomIcon
            sx={{fontSize: 30 }}
            color="primary"
             />
          </Tooltip>
        }
        label={t("rooms")}
        onClick={() => navigate("" + idZona)}
      />}
      {(policies.indexOf(permessi.edificiAbilitaDisabilita) !== -1 ||
        responsabileEdificio.length > 0) && (
        <GridActionsCellItem
          sx={{ '& .MuiTouchRipple-root': { display: 'none' }}}
          className={isMobile || viewState ? "button-ButtonBox-Mobile forceNoPaddingLeft" : ""}
          icon={
            <Tooltip title={t("disable_v3")}>
              {!isMobile && !viewState 
                ? <FontAwesomeIcon icon={faBan} style={{ fontSize: 25 }} className="pathIcon" />
                : <div style={{ display: "flex", alignItems: "center" }}>
                    <>
                      <FontAwesomeIcon icon={faBan} size="xs" style={{color: "#636161", marginRight: "3px" }} /> 
                      <Typography sx={{ fontSize: 10, color: "#636161"}}>{t("disable_v3")}</Typography>
                    </>
                  </div>
               }
            </Tooltip>
          }
          label={t("disable_v3")}
          onClick={(event) =>
            handleDialogToEnableDisableZone({ dialogPageType: dialogPageTypes.zone, dialogType: "disableRangeData", rowInfo }, dispatch,event)
          }
        />
      )}
      {(policies.indexOf(permessi.edificiAbilitaDisabilita) !== -1 ||
        responsabileEdificio.length > 0) && (
        <GridActionsCellItem
        sx={{ '& .MuiTouchRipple-root': { display: 'none' }}}
        className={isMobile ? "button-ButtonBox-Mobile" : ""}
          icon={
            <Tooltip title={t("restore_v2")}>
            {!isMobile && !viewState
            ? <FontAwesomeIcon icon={faRotateRight} style={{ fontSize: 25 }} className="pathIcon" />
            :<div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon icon={faRotateRight} size="xs" style={{ color: "#636161", marginRight: "3px" }} />
            <Typography sx={{ fontSize: 10, color: "#636161" }}>{t("restore_v2")}</Typography>
          </div>
            }</Tooltip>
          }
          label={t("restore_v2")}
          onClick={(event) =>
            handleDialogToEnableDisableZone({ dialogPageType: dialogPageTypes.zone, dialogType: "ripristinaAll", rowInfo }, dispatch, event)
          }
        />
      )}
      {policies.indexOf(permessi.utentiVisualizzazione) != -1 &&<GridActionsCellItem
        sx={{ '& .MuiTouchRipple-root': { display: 'none' }}}
        className={isMobile ? "button-ButtonBox-Mobile" : ""}
        icon={
          <Tooltip title={!rowInfo.dataDel ? t("delete") : t("enable")}>
           { rowInfo.dataDel 
           ? <FontAwesomeIcon icon={faBan} style={{fontSize: 25}}  className="pathIcon"/> 
           : !isMobile && !viewState ? <FontAwesomeIcon icon={faTrash} style={{fontSize: 25}} className="pathIcon" />
                       : <><FontAwesomeIcon icon={faTrash} size="xs" style={{color: "#636161", marginRight: "3px" }} /> <Typography sx={{ fontSize: 10, color: "#636161"}}>{t("delete_v3")}</Typography></>}
           </Tooltip>
        }
        label={t("delete")}
        onClick={(event) => handleDialogToEditZone({type:"delete", row:rowInfo}, dispatch, event)}
      />}
      </>
  );
};

export default ButtonBoxZone;

import { dialogPageTypes } from "../../../const/dialogPageTypes";
import { openDialog, setPageType } from "../../../redux/reducers/DialogGeneral";

export const handleDialogToEditStanza = ({ type:dialogType, row: rowInfo }, dispatch, event = null) => {
  if(event) event.stopPropagation();  
  dispatch(setPageType(dialogPageTypes.stanze));
  dispatch(openDialog({ dialogType, rowInfo }));
};

export const handleDialogToEnableDisableStanza = ({dialogPageType: dialogPageType, dialogType: dialogType, rowInfo: rowInfo}, dispatch, event = null) => {
  if(event) event.stopPropagation();  
  dispatch(setPageType(dialogPageType));
  dispatch(openDialog({ dialogType, rowInfo }));
};
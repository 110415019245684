import { enumCheckInTypology } from "../const/prenotazioni/enumCheckInTypology";
import {
  deletePrenotazione as deletePrenotazioneAction,
  checkOutPrenotazione as checkOutPrenotazioneAction,
  checkInPrenotazione as checkInPrenotazioneAction
} from "../const/urlAction";

export const dialogList = {
  checkOutPrenotazione: "CHECKOUT",
  deletePrenotazione: "DELETE",
  checkInPrenotazione: "CHECKIN",
};

export default function createDialogConfirmActionInDialogRiepilogo(
  dialog,
  body,
  t
) {
  const { deletePrenotazione, checkOutPrenotazione, checkInPrenotazione } = dialogList;
  let object = {};

  switch (true) {
    case dialog === deletePrenotazione:
      object = {
        noteField: true,
        viewComponent: {
          textPrincipal: t("Confirm_delete"),
          textTitle: t("delete_booking"),
        },
        actionApi: deletePrenotazioneAction,
        body: body,
      };
      break;
    case dialog === checkOutPrenotazione:
      object = {
        noteField: false,
        actionApi: checkOutPrenotazioneAction,
        viewComponent: {
          textPrincipal: t("Confirm_checkout"),
          textTitle:t("Checkout_booking"),
        },
        body: body
      }
      break;
    case dialog === checkInPrenotazione:
      object = {
        noteField: false,
        actionApi: checkInPrenotazioneAction,
        viewComponent: {
          textPrincipal: t("Confirm_checkin"),
          textTitle: t("Check-in prenotazione"),
        },
        body: {...body,CheckInTypology: enumCheckInTypology.ManualCheckIn },
      };
      break;
  }
  return object;
}

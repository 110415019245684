import { useSelector } from "react-redux";
import AvvisoFormMessage from "../../../UI/Form/AvvisoFormMessage";
import { useTranslation } from "react-i18next";

export default function MessagePasswordRequired() {
    const configurations = useSelector((state) => state.configurations.configurazioniAutenticazione.configurazionePasswordRequisiti);
    const { t } = useTranslation(); 
 function createMessagePasswordRequired() {
    if(!configurations) return <></>

    let text = t("text_psw")
    if(configurations.requireLength)  text +=  t("text_psw_8")
    if(configurations.requireDigit)  text +=  t("text_psw_num")
    if(configurations.requireLowercase)  text +=  t("text_psw_lowc")
    if(configurations.requireNonAlphanumeric)  text +=  t("text_psw_special")
    if(configurations.requireUppercase)  text +=  t("text_psw_upperc")
    
    return text
 }

    return (
        <AvvisoFormMessage msg={createMessagePasswordRequired()} />
    )
}
import { useEffect, useState } from "react";


export default function useMobileDetection(width = 600) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < width);

    useEffect(() => {
        window.addEventListener('resize', handleResizeWindow);
        return () => {
            window.removeEventListener('resize', handleResizeWindow);
        };
    });
    
    const handleResizeWindow = () => {
      if(window.innerWidth > width){
        setIsMobile(false);
      } 
      else {
        setIsMobile(true);
      } 
    };

    return isMobile;
}


import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dialogButtonConfirm, dialogButtonGeneral, dialogButtonRiepilogo, dialogPasswordChangeAdvice } from "../../../const/DialogData";
import AvvisoFormMessage from "../../UI/Form/AvvisoFormMessage";
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../../Hooks/useMobileDetection";
import { closeDialogChangePasswordAdvice } from "../../../redux/reducers/dialogPasswordChangeAdvice-reducer";
import { setChangePasswordMessageRead } from "../../../const/urlAction";
import { hideLoader, showLoader } from "../../../redux/reducers/appLoader-reducer";
import axios from "axios";

const urlBase = process.env.REACT_APP_URL;

export default function DialogChangePasswordAdvice({ isOpen = false, returnIsOpen = () => null }) {
  const dialogState = useSelector((state) => state.dialogPasswordChangeAdvice);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMobileDetection();

  const closeDialog = () => {
    (async () => {

      const { action, method } = setChangePasswordMessageRead;
      let url = urlBase + action;
      dispatch(showLoader())

      await axios({
        method: method,
        url
      });

      dispatch(hideLoader())
    })();

    let deskbooking = JSON.parse(localStorage.getItem('deskbooking'));
    deskbooking.shouldChangePassword = false;
    localStorage.setItem('deskbooking', JSON.stringify(deskbooking));
    dispatch(closeDialogChangePasswordAdvice());
  };

  useEffect(() => {
    returnIsOpen(dialogState.isOpen)
  }, [dialogState])


  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        fullScreen={isMobile}
        maxWidth={"sm"}
      >
        <DialogTitle>
          {t(dialogPasswordChangeAdvice.DialogTitle)}
        </DialogTitle>
        <DialogContent className="border-Content-dialog">
          <AvvisoFormMessage msg={t("password_change_advice_test")} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()} variant="contained">
            {t(dialogButtonRiepilogo.HoCapito)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

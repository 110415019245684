import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { dialogAddUser, dialogButtonGeneral } from "../../../../../const/DialogData";
import { labelArrayDialogUserAddEdit } from "../../../../../const/RadioButton/RadioButtonListRadio";
import { ListTabsDialogoUserAdd } from "../../../../../const/Tabs/Dialog/DialogUser/DialogUserAdd/ListTabsDialogUserAdd";
import MessageNoProvider from "../../../../UI/MessageNoProvider/MessageNoProvider";
import RadioButtonWithTabs from "../../../../UI/RadiobuttonCustom.js/RadioButtonWithTabs/RadioButtonWithTabs";
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../../../../Hooks/useMobileDetection";
import { useDispatch, useSelector } from "react-redux";
import { closeDialogUser } from "../../../../../redux/reducers/dialogUser-reducer";
import { useState } from "react";

export default function DialogAddUser({
  isOpen,
  lastTabsSelected,
  setLastTabsSelected,
}) {
  const { t } = useTranslation();
  const isMobile = useMobileDetection(950);
  const appLoadingState = useSelector((state) => state.appLoader.loading);
  const dispatch = useDispatch();
  const [isCommitted, setIsCommitted] = useState(false);

  const commitDialog = () => {
    setIsCommitted(true);
  }

  const closeDialog = () => {
    dispatch(closeDialogUser());
  };

  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        fullScreen={isMobile}
        maxWidth={"sm"}
      >
        <DialogTitle>{t(dialogAddUser.DialogTitle)}</DialogTitle>
        <DialogContent className="border-Content-dialog">
          <RadioButtonWithTabs
            GroupName={t("authentication_type")}
            tabPanel={ListTabsDialogoUserAdd({ isCommitted, setIsCommitted })}
            labelList={labelArrayDialogUserAddEdit}
            startValueReset={labelArrayDialogUserAddEdit[0]}
            noTabsComponent={<MessageNoProvider />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()}>
            {t(dialogButtonGeneral.Cancel)}
          </Button>
          <Button onClick={() => commitDialog()} variant="contained" disabled={appLoadingState}>
            {t(dialogButtonGeneral.Confirm)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

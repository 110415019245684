import { Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { checkRangeDatePrenotazioniFilter } from "../../../functions/checkRangeDate";
import GeneralCustomDateTimePicker from "../../UI/TimePicker/GeneralCustomDateTimePicker";
import { useTranslation } from "react-i18next";

export function DatapickerBoxFilterHistory({dispatchDateOrari, disableButton, isResetting, startValue, minDate = null}) {
  const [valueDataGiornoInizio, setValueDataGiornoInizio] = React.useState(null);
  const [valueDataGiornoFine, setValueDataGiornoFine] = React.useState(null);
  const { isCorrectRangeData} = checkRangeDatePrenotazioniFilter(valueDataGiornoInizio || startValue.DataInizio || null, valueDataGiornoFine || startValue.DataFine || null)
  const { t } = useTranslation();

  useEffect(() => {
    disableButton(isCorrectRangeData);
  },[valueDataGiornoInizio,valueDataGiornoFine, isCorrectRangeData])

useEffect(()=> {
  setValueDataGiornoInizio(startValue.DataInizio)
  setValueDataGiornoFine(startValue.DataFine)
},[startValue.DataFine,startValue.DataInizio])


  const handleChangeDataInizio = (newValue) => {
    if (newValue !== null) {
      if (isCorrectRangeData) { dispatchDateOrari({type:"ADD_DATAINIZIO", payload: newValue.toString()});}
      setValueDataGiornoInizio(newValue);
    } else {
      dispatchDateOrari({type:"ADD_DATAINIZIO", payload:null});
      setValueDataGiornoInizio(null);
    }
  };

  const handleChangeDataFine = (newValue) => {
    if (newValue !== null) {
      if(isCorrectRangeData) {dispatchDateOrari({type:"ADD_DATAFINE", payload: newValue.toString()});}
      setValueDataGiornoFine(newValue);
    } else {
      dispatchDateOrari({type:"ADD_DATAFINE", payload: null});
      setValueDataGiornoFine(null);
    }
  };

  useEffect(()=> {
    setValueDataGiornoInizio(null);
    setValueDataGiornoFine(null);
  },[isResetting])
  

  return (
    <React.Fragment>
          <Grid container columnSpacing={3} rowSpacing={2}>
        <Grid item lg={6} md={6} xs={12} >
        <GeneralCustomDateTimePicker
              TypeTimePicker={7}
              minDate={minDate}
              label={t("Start_Date")}
              valueStart={valueDataGiornoInizio || startValue.DataInizio || null}
              handleChange={handleChangeDataInizio}
              placeholder={t("Booking_date_placeholder_time")}
            />
        </Grid>
        <Grid item lg={6} md={6} xs={12} >
        <GeneralCustomDateTimePicker
              TypeTimePicker={7}
              label={t("End_Date")}
              minDate={minDate}
              valueStart={valueDataGiornoFine || startValue.DataFine || null}
              handleChange={handleChangeDataFine}
              placeholder={t("Booking_date_placeholder_time")}
            />
        </Grid>
        </Grid>
    </React.Fragment>
  );
}

import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  changeSidebarState,
  closeSidebar,
} from "../../redux/reducers/sidebar-reducer";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { clearTokenTimeoutAndLogout, logoutUser } from "../../redux/reducers/user-reducer";
import { openDialogCambioPassword } from "../../redux/reducers/dialogCambioPassword-reducer";
import DialogCambioPassword from "../../components/Dialog/DialogCambioPassword";
import { Box } from "@mui/system";
import CardInfoUtente from "../../components/cardInfoUtente/CardInfoUtente";
import logo from "../../assests/logo_white.png";
import DettaglioUtenteDialog from "../../components/Dialog/DettaglioUtenteDialog/DettaglioUtenteDialog";
import { Link } from "react-router-dom";
import GeneralBackButton from "../../functions/backButton/generalBackButton";
import useHandlerBackButton from "../../functions/Utils/handlerBackButtonAndroid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUserGear } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import CultureButton from "../../components/Culture/CultureButton";

const redirectLogout = "/";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "#3e6bb4",
}));

const StyledMenu = styled(Menu)`
  & > .MuiPaper-root > .MuiList-root {
    padding-top: 0px !important;
  }
`;

const Navbar = ({ initialState, xsSize, t }) => {
  const isSidebarOpen = useSelector((state) => state.sidebar);
  const userState = useSelector((state) => state.userDate);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lookDiv = useRef(undefined);
  const [isDialogDettaglioUtenteOpen, setIsDialogDettaglioUtenteOpen] =
    useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const open = Boolean(anchorElement);

  const handleMenuClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  const OpenDialogCloseMenu = () => {
    dispatch(openDialogCambioPassword("cambioPsw"));
    setAnchorElement(null);
  };

  const { sidebar } = initialState;
  const [sidebarState, setSidebarState] = useState(sidebar);

  useEffect(() => {
    setSidebarState(sidebar);
  }, [sidebar]);

  const logoutToLogin = () => {
    // dispatch(logoutUser());
    dispatch(closeSidebar());
    dispatch(clearTokenTimeoutAndLogout(userState,navigate))
    // navigate(redirectLogout);
  };

  function returnCardInfoDialogState() {
    setAnchorElement(null);
    setIsDialogDettaglioUtenteOpen(true);
  }

  function returnCloseDialog() {
    setAnchorElement(null);
    setIsDialogDettaglioUtenteOpen(false);
  }

  useHandlerBackButton(
    { sidebarState, anchorElement, handleCloseMenu },
    GeneralBackButton,
    dispatch
  );

  return (
    <>
      <AppBar
        position="absolute"
        open={sidebarState}
        className="adaptHeight"
        sx={{
          width:
            xsSize || !sidebarState ? "100%" : `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <Toolbar className="navbar" sx={{ pr: "24px" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(changeSidebarState())}
            sx={{
              marginRight: "36px",
              ...(sidebarState && { display: "none" }),
            }}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Box sx={{ flexGrow: 1, fontWeigth: "bold" }}>
            <img
              onClick={() => {
                navigate("/prenotazioni");
              }}
              src={logo}
              alt="Desk Booking"
              title="Desk Booking"
              className={
                isSidebarOpen
                  ? "logo-hidden center-logo-navbar"
                  : "logo pointer center-logo-navbar"
              }
            />
          </Box>
          <IconButton
            color="inherit"
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleMenuClick}
          >
            <FontAwesomeIcon icon={faUserGear} />
          </IconButton>
          <StyledMenu
            id="basic-menu"
            ref={lookDiv}
            anchorEl={anchorElement}
            open={open}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiPaper-root": { minWidth: "300px" },
              "& .MuiPaper-root": { maxWidth: "300px" },
            }}
          >
            <CardInfoUtente
              returnCardInfoDialogState={returnCardInfoDialogState}
            />
            <div style={{paddingTop:10, paddingBottom:10}}>
              <CultureButton closeMenu={returnCloseDialog} />
            </div>
            {userState.hasPassword && (
              <MenuItem key="CambioPsw" onClick={() => OpenDialogCloseMenu()}>
                {t("change_password_v2")}
              </MenuItem>
            )}
            <MenuItem
              key="Logout"
              onClick={() => {
                logoutToLogin();
              }}
            >
              {t("logout")}
            </MenuItem>
          </StyledMenu>
        </Toolbar>
      </AppBar>
      
      {
      userState.hasPassword && <DialogCambioPassword t={t}/>}
      <DettaglioUtenteDialog
        dialogState={isDialogDettaglioUtenteOpen}
        returnCloseDialog={returnCloseDialog}
        t={t}
      />
    </>
  );
};

export default Navbar;

import { useTranslation } from "react-i18next";
import convertTipoStanzaToKeysI18n from "../../../functions/DataTable/convertTipoStanzaToNameDataTable";

export default function CustomRowContent({posto, tipoStanza}) {
    const { t } = useTranslation();

    const keysI18n = convertTipoStanzaToKeysI18n(tipoStanza)
    
    return (
        t(keysI18n.prefixRowDataTable) +" "+ posto
    )
}
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import store from "../store/index";
import enTranslations from './locales/en.json';
import itTranslations from './locales/it.json';

const resources = {
  en: {
    translation: enTranslations,
  },
  it: {
    translation: itTranslations,
  },
};

const updateI18nFallbackLng = () => {
  const state = store.getState();
  const calture = state?.culture?.currentCulture
  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng:calture,
      fallbackLng:'en',
      interpolation: {
        escapeValue: false,
      },
    });
};


// Aggiungi la sottoscrizione agli aggiornamenti dello store
const unsubscribe = store.subscribe(updateI18nFallbackLng);

// Chiamare updateI18nFallbackLng una volta all'avvio per inizializzare i18n con i dati iniziali dello store
updateI18nFallbackLng();

export default i18n;

import { TextField } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function TextPartecipantiRiunione({
    returnValueFromText = [],
    eventButton = undefined,
    allPartecipantiPills = [],
  }) {
    const [partecipantiPills, setPartecipantiPills] = useState("");
    const [valueFromText, setValueFromText] = useState("");
    const textField = useRef(undefined);
    const { t } = useTranslation(); 
    function handleChange(e) {
      if (e?.key === "Enter") {
        setPartecipantiPills(e.target.value.toUpperCase());
        e.target.value = "";
        textField.current.focus();
      } else if (eventButton?.type === "click") {
      }
    }
  
    //gestisce il click del button
    useEffect(() => {
      if (eventButton !== undefined) {
        if(valueFromText) {
          setPartecipantiPills(valueFromText?.target?.value.toUpperCase());
        textField.current.focus();
        valueFromText.target.value = "";
      }
    }
    }, [eventButton]);
  
    useEffect(() => {
      if (partecipantiPills.trim() != "" && !allPartecipantiPills.includes(partecipantiPills.toUpperCase()))
      returnValueFromText(partecipantiPills)
    }, [partecipantiPills]);

  //permette di inserire,cancellare e rinserire un elemento.  
  useEffect(() => {
    setPartecipantiPills("")
  },[partecipantiPills])

    //Permette di identificare quale tasto è stato premuto.
    useEffect(() => {
      window.addEventListener("keydown", handleChange);
  
      return () => {
        window.removeEventListener("keydown", handleChange);
      };
    }, []);


    return (
    <>
      <TextField
        ref={textField}
        margin="dense"
        id="PartecipantiRiunione"
        label={t("Meeting_Participants")}
        fullWidth
        variant="standard"
        onChange={(e) => setValueFromText(e)}
      />
    </>
    )
}
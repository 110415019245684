import { Button, DialogActions, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { QrReader } from "react-qr-reader";
import {
  dialogButtonQrCode,
} from "../../../../const/DialogData";
import adaptiveCssFromRef from "../../../../Hooks/Adaptive/useAdaptiveCssFromRef";
import TitleScannedQrCode from "./GeneralComponentQrCode/titleScannedQrCode";
import { useTranslation } from "react-i18next";

const qrCodeScanDelay = process.env.REACT_APP_QR_CODE_SCAN_DELAY;

export default function ScannerQrCode({
  returnValue,
  returnComponentState,
  isScanActive = true,
}) {
  const [data, setData] = useState(undefined);
  const [adaptiveHeightComponent, setAdaptiveHeightComponent] = useState(0);
  const buttonsBox = useRef(undefined);
  const { t } = useTranslation();
  useEffect(() => {
    returnValue(data);
  }, [data]);

  useEffect(() => {
    setAdaptiveHeightComponent(adaptiveCssFromRef(buttonsBox));
  }, [buttonsBox]);
  

  return (
    <>
      <div style={{ minHeight: `${adaptiveHeightComponent}px` }}>
        <TitleScannedQrCode title="Scan_workstation" />
        <QrReader
          scanDelay={qrCodeScanDelay}
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            if (result !== undefined) {
              var qrCode = result.text;
              if (isScanActive) setData(qrCode);
            }
          }}
          className="qrCode-CoreElement"
        />
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Box sx={{ padding: "0 10px 0 10px",
            /* height: '210px',  */
            overflow: 'auto',
          }}>
              <ol>
                <Typography className="space-Between-Typografy">
                  <li>
                    {t("text_5")}
                  </li>
                </Typography>
                <Typography className="space-Between-Typografy">
                  <li>
                  {t("text_6")}
                  </li>
                </Typography>
                <Typography className="space-Between-Typografy">
                  <li>
                  {t("text_7")}
                  </li>
                </Typography>
              </ol>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Grid item container ref={buttonsBox}>
        <Grid item>
          <DialogActions>
            <Button onClick={() => returnComponentState(false)}>
              {t(dialogButtonQrCode.Annulla)}
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
}

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Typography, Avatar } from "@mui/material";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DesktopMac from "@mui/icons-material/DesktopMac";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faCity, faDoorOpen, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { truncateString } from "../../../../functions/Utils/truncateString";
import { getTime, getDayOfWeek, formatDateRegularNoTime, dayofMonth, month } from "../../../../functions/formatDate";
import "../ListaPrenotazioniCollega.styles.css";
import "./RowPrenotazioneCollega.styles.css";
import { CDCard, CDCardContent } from "../../../../const/CDCardStyles";
import { Box } from "@mui/system";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import nameToAvatar from "../../../../functions/Avatar/NameToAvatar";

export function RowPrenotazioneCollegaMobile({ prenotazione, index }) {
  
  const {
    dataInizioPrenotazione,
    dataFinePrenotazione,
    zonaSigla,
    stanzaSigla,
    stanzaDenominazione,
    zonaDenominazione,
    edificioSigla,
    postazionePosto,
    utentePrenotato,
    immagineAvatar,
    ospite,
    nominativoOspite
  } = { ...prenotazione };

  const currentCulture = useSelector((state) => state?.culture?.currentCulture);
  return (
    <>
      <Grid item component={CDCard} xs={12} sm={12} >
        <CDCardContent>
          <Grid container spacing={1} alignItems="center" >
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ textAlign: 'left' }}>{truncateString(stanzaDenominazione, 15)} <ArrowRightAltIcon className="arrow" sx={{ position: 'relative', top: '0.4rem' }} /> {postazionePosto} </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="left">
                <Grid item xs={4} mr={1}>
                  <Box className="pillTag">
                    <Typography variant="subtitle2" className="pianoText">
                      {truncateString(zonaDenominazione, 10)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2} mr={1}>
                  <Box className="pillTag">
                    <Typography variant="subtitle2" className="pianoText">
                      {truncateString(edificioSigla, 3)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item mt={2} mb={2} justifyContent="space-around">
              <Grid item xs={2} sm={2} className="timeItemLeft">
                <Typography variant="h6" className="timeItemLeftText">
                  {getTime(dataInizioPrenotazione)}
                </Typography>
              </Grid>
              <Grid item xs={2} className="dashed-divider" ></Grid>
              <Grid item xs={4} sm={4} className="timeItemCenter">
                <Typography variant="h5" color="primary" className="timeItemCenterText">
                  {dayofMonth(dataInizioPrenotazione, currentCulture)} {""}
                  {month(dataFinePrenotazione, currentCulture)}
                </Typography>
              </Grid>
              <Grid item xs={2} className="dashed-divider" ></Grid>
              <Grid item xs={2} sm={2} className="timeItemRight">
                <Typography variant="h6" className="timeItemRightText">
                  {getTime(dataFinePrenotazione)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CDCardContent>
        <CDCardContent sx={{ backgroundColor: 'rgba(62, 107, 180, 0.05)' }}>
          <Grid container spacing={1} alignItems="center" >
              <Grid item>
                <Box className="avatarNameContainer">
                  {immagineAvatar && !ospite ? 
                    <Avatar className="avatarImage" sx={{ position: 'relative', top: '0.2rem' }} alt="Avatar" src={"data:image/*;base64," + immagineAvatar} />
                    : <Avatar className="avatarImage" sx={{ position: 'relative', top: '0.2rem' }}>{ospite ? nameToAvatar(nominativoOspite) : nameToAvatar(utentePrenotato)}</Avatar> }
                  <Typography variant="subtitle1" className="avatarName" >
                  {ospite ? nominativoOspite + " ("+utentePrenotato+")": utentePrenotato }
                  </Typography>
                </Box>
              </Grid>
          </Grid>
        </CDCardContent>
      </Grid>
    </>
  );
}

import { TextareaAutosize, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function CustomNote({
  returnValue,
  min = 4,
  max = 4,
  placeholder = "note_placeholder" ,
  style = { width: "99%" },
}) {
  const { t } = useTranslation(); 

  return (
    <>
      <label>
        <Typography className="labelAutoCompletePills" sx={{ marginBottom: "2px" }}>{t("annotation")}</Typography>
      </label>
      <TextareaAutosize
        onChange={ev => returnValue(ev.target.value)}
        minRows={min}
        maxRows={max}
        placeholder={t(placeholder)}
        style={style}
        autoFocus
      />
    </>
  );
}

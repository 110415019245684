import { Grid } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ElementiInseribili from "./ElementiInseribili";
import axios from "axios";
import {
  planimetriaUrlParamsForElements,
  planimetriaUrlParamsForElementsEdit,
  typeEntityPlanimetria,
} from "../../../functions/UrlParams";
import handlerError from "../../../functions/handlerError";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { creaElementoMappa } from "../../../functions/creaElementoMappa";
import { gestioneSubmenuDnD } from "../../../functions/gestioneSubmenuDnd";
import openDialogFromPlanimetria from "../../../functions/openDialogFromPlanimetria";
import { reloadingDataTableUsers } from "../../../redux/reducers/dataTableUsers-reducer";
import useBackGroundPlanimetria from "../../../Hooks/useBackgroundPlanimetria";
import Notification from "../../../components/UI/Notification/Notification";
import ElementSubMenu from "../SubMenu/ElementSubmenu";
import {
  aggiustamentiMenuContestualePlanimetriaGestione,
  aggiustamentiPlanimetriaGestione,
} from "../../../const/Planimetria/gestionePlanimetria";
import sfondoDefault from "../../../assests/sfondoPlanimetriaDefault.png";

import "./Planimetria.css";
import { policies } from "../../../const/policies";
import { assegnaIconeInPlanimetriaGestioneToolbar } from "../../../functions/assegnaIconeInPlanimetriaGestione";
import convertPositionToInt from "../../../functions/convertPositionToInt";
import { initialValueScaleZoomDesktop } from "../../../const/Planimetria/valueScaleZoomPlanimetria";
import useScrollGrabOnContainer from "../../../Hooks/useScrollGrabToContainer/useScrollGrabOnContainer";
import { containerClass } from "../../../const/Planimetria/costantiPlanimetria";
import { calcolaPercentualeDiMarginDaScaleImage, moltiplicaPercentualeScalling } from "../../../functions/calcolaScaleImageDelloZoom";
import { handlerScale } from "../../../functions/handlerZoom";
import handlerConfiguration from "../../../functions/general/handlerConfiguration";

let iconSelected = {};
let element = [];

const grandezzaIcona = 40;

const urlBase = process.env.REACT_APP_URL;

const PlanimetriaGestione = ({ dettaglio = {} }) => {
  const configurations = useSelector((state) => state.configurations);
  const {larghezzaPlanimetriaDefault : PlanimetriaLarghezza, altezzaPlanimetriaDefault : PlanimetriaAltezza} = handlerConfiguration(configurations)
  const refContainerDropzone = useRef(null);
  const refImage = useRef(undefined);
  const userDate = useSelector((state) => state.userDate);
  const stateDialogStanza = useSelector(
    (state) => state.dialogStanza.isOpenDialogStanza
  );
  const reloadingPlanimetria = useSelector((state) => state.dataTableUsers);
  const [updatePositionElement, setUpdatePositionElement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [elementiRecuperati, setElementiRecuperati] = useState([]);
  const [iconStartPosition, setIconStartPosition] = useState({ x: 0, y: 0 });
  const [elementiDalDb, setElementiDalDb] = useState([]);
  const [dettaglioEntita, setDettaglioEntita] = useState([]);
  const [isOpenSubmenu, setIsOpenSubmenu] = useState(false);
  const [isNotNewMove, setIsNotNewMove] = useState(false);
  const [typePage, setTypePage] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deltaClick, setDeltaClick] = React.useState(0);
  const [scaleZoom, setScaleZoom] = useState(initialValueScaleZoomDesktop);

  const { policies: statePolicies, responsabileEdificio } = userDate;
  const { isSalaRiunione, idTipologiaStanza } = dettaglioEntita;

  const [infoToSubmenu, setInfoToSubmenu] = useState({ id: 0, x: 0, y: 0 });
  const dispatch = useDispatch();
  const params = useParams();

  let permissionToMoveIconAndCreateIcon = statePolicies.includes(
    policies.VisualizzazioneCompleta
  );

  let deltaScale = handlerScale - initialValueScaleZoomDesktop;

  const {
    altezzaPlanimetria,
    denominazione,
    sigla,
    guidSfondoPlanimetria,
    larghezzaPlanimetria,
    idSfondoPlanimetria,
  } = dettaglio;

  const deltaPosIconaY =
    refContainerDropzone?.current?.offsetTop +
    aggiustamentiPlanimetriaGestione.aggiustamentoPosizioneTopSuPlanimetriaGestione;
  const deltaPosIconaX =
    refContainerDropzone?.current?.offsetLeft +
    aggiustamentiPlanimetriaGestione.aggiustamentoPosizioneLeftSuPlanimetriaGestione;
  const deltaDimensioniPlanimetria =
    refContainerDropzone?.current?.offsetTop +
    aggiustamentiPlanimetriaGestione.aggiustamentoDimensioniPlanimetriaSuSchermo;

  //metodo di assegnazione icona di default per la creazione degli elementi
  const IconsToToolBar = assegnaIconeInPlanimetriaGestioneToolbar(
    params.idStanza,
    idTipologiaStanza
  );
  
  const altezzaImmaginePlanimetria = idSfondoPlanimetria
    ? altezzaPlanimetria
    : Number(PlanimetriaAltezza);
  const width = idSfondoPlanimetria
    ? larghezzaPlanimetria
    : Number(PlanimetriaLarghezza);

  useEffect(() => {
    (async () => {
      const { id, type, action, method } =
        planimetriaUrlParamsForElements(params);
      let url = urlBase + action;
      setTypePage(type);
      try {
        const response = await axios({
          method: method,
          url,
          data: id,
          headers: {
            "Content-Type": "application/json",
          },
        });
        setDettaglioEntita(response.data.result.dettaglio);
        setElementiDalDb(response.data.result.listaEntita);
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
    setIsOpenSubmenu(false);

  }, [stateDialogStanza, reloadingPlanimetria]);

  useLayoutEffect(() => {
    setIsOpenSubmenu(false);
    setElementiRecuperati(
      elementiDalDb.map((el, index) => ({
        ...el,
        element: creaElementoMappa(el, false),
      }))
    );
    setLoading(!loading);
  }, [stateDialogStanza, elementiDalDb]);

  const changeIcons = (value) => {
    iconSelected = value;
  };

  function move(event, id, el) {

    event.preventDefault();
    event.stopPropagation();

    //gestisce la posizione del client rispetto allo schermo(in caso di finestra ridotta e spostata)
    let posizioneClientX = event.screenX - event.clientX;
    let posizioneClientY =
      event.screenY -
      event.clientY -
      aggiustamentiPlanimetriaGestione.aggiustamentoVariazionePosizioneClientSuPlanimetriaGestione;

    const newMove = {
      x: event.screenX - deltaPosIconaX,
      y: event.screenY - deltaPosIconaY,
    };

    let result = gestioneSubmenuDnD(iconStartPosition, newMove); // gestisce l'apertura del subMenu confrontando la posizione di partenza con la posizione di arrivo
    setIsNotNewMove(result);

    let dropzoneContainer = event.composedPath().filter(x => x.id === "dropzoneContainer")[0];
    if (!dropzoneContainer || result)
      return;

    setElementiRecuperati(
      elementiRecuperati.map((el, index) => {
        if (el.id === id) {
          setUpdatePositionElement({
            ...el,
            posX:
              event.screenX -
              deltaPosIconaX +
              refContainerDropzone?.current?.scrollLeft -
              posizioneClientX +
              deltaClick.posXclick,
            posY:
              event.screenY -
              deltaPosIconaY +
              refContainerDropzone?.current?.scrollTop -
              posizioneClientY +
              deltaClick.posYclick,
          });
          el = {
            ...el,
            posX:
              event.screenX -
              deltaPosIconaX +
              refContainerDropzone?.current?.scrollLeft -
              posizioneClientX +
              deltaClick.posXclick,
            posY:
              event.screenY -
              deltaPosIconaY +
              refContainerDropzone?.current?.scrollTop -
              posizioneClientY +
              deltaClick.posYclick,
          };
        }
        return el;
      })
    );

    setLoading(!loading);
  }

  useEffect(() => {
    if (updatePositionElement) {
      (async () => {
        const { id, type, action, method } =
          planimetriaUrlParamsForElementsEdit(params);

        let updateElement;
        let formData = new FormData();

        if (type === typeEntityPlanimetria.postazione) {
          updateElement = {
            id: updatePositionElement.id,
            Sigla: updatePositionElement.sigla,
            Denominazione: updatePositionElement.denominazione,
            idStanza: updatePositionElement.idStanza,
            posX: convertPositionToInt(updatePositionElement.posX),
            posY: convertPositionToInt(updatePositionElement.posY),
          };
        } else {
          Object.keys(updatePositionElement).map((key) => {
            formData.append(`${key}`, updatePositionElement[key]);
          });
        }
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data:
              type === typeEntityPlanimetria.postazione
                ? updateElement
                : formData,
            headers: {
              "Content-Type": "application/json",
            },
          });
          dispatch(reloadingDataTableUsers());
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
        }
      })();
    }
  }, [updatePositionElement]);

  const handleAdd = (event) => {
    //gestisce la posizione del client rispetto allo schermo(in caso di finestra ridotta e spostata)
    let posizioneClientX = event.screenX - event.clientX;
    let posizioneClientY =
      event.screenY -
      event.clientY -
      aggiustamentiPlanimetriaGestione.aggiustamentoVariazionePosizioneClientSuPlanimetriaGestione;

    let body = {
      dialogPageType: "add",
      body: {
        posX:
          event.screenX -
          deltaPosIconaX +
          refContainerDropzone?.current?.scrollLeft -
          posizioneClientX,
        posY:
          event.screenY -
          deltaPosIconaY +
          refContainerDropzone?.current?.scrollTop -
          posizioneClientY,
      },
    };

    openDialogFromPlanimetria(dispatch, typePage, body);
    setLoading(!loading);
  };

  function dragStart(event) { }
  function allowDrop(event) {
    event.preventDefault();
  }
  function drop(event) {
    event.preventDefault();
  }

  //gestisce l'apertura del menu
  function openSubmenu(event, id) {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    //gestisce la posizione del client rispetto allo schermo(in caso di finestra ridotta e spostata)
    let posizioneClientX = event.screenX - event.clientX;
    let posizioneClientY =
      event.screenY -
      event.clientY -
      aggiustamentiPlanimetriaGestione.aggiustamentoVariazionePosizioneClientSuPlanimetriaGestione;

    if (isNotNewMove || !permissionToMoveIconAndCreateIcon) {
      setInfoToSubmenu((prev) => {
        prev.id == id
          ? setIsOpenSubmenu(!isOpenSubmenu)
          : setIsOpenSubmenu(true);
        return {
          id,
          x:
            event.screenX -
            deltaPosIconaX +
            refContainerDropzone?.current?.scrollLeft +
            aggiustamentiMenuContestualePlanimetriaGestione.aggiustamentoPosizioneLeftSuMenuContestualePlanimetriaGestione -
            posizioneClientX,
          y:
            event.screenY -
            deltaPosIconaY +
            refContainerDropzone?.current?.scrollTop +
            aggiustamentiMenuContestualePlanimetriaGestione.aggiustamentoPosizioneTopSuMenuContestualePlanimetriaGestione -
            posizioneClientY,
        };
      });
    } else {
      setIsOpenSubmenu(false);
    }
  }

  /* si occupa di salvare il movimento iniziale (setIconStartPosition)
   e di calcolare il Delta tra la posizione dell'icona in cui si trova e il click effettivo del mouse sull'icona per iniziare il Drag (setDeltaClick).
   senza l'icona una volta trascinata verrebbe ridisegnata nel punto in cui si trovava il puntatore nel momento del drop, invece ora viene ridisegnata dove viene lasciata. 
   */
  function startMove(event, el, index) {
    let posizioneClientX = event.screenX - event.clientX;
    let posizioneClientY =
      event.screenY -
      event.clientY -
      aggiustamentiPlanimetriaGestione.aggiustamentoVariazionePosizioneClientSuPlanimetriaGestione;

    setIsNotNewMove(false);
    const startPosition = {
      x: event.screenX - deltaPosIconaX,
      y: event.screenY - deltaPosIconaY,
    };

    let newPosXSenzaDeltaFinestra =
      event.screenX -
      deltaPosIconaX +
      refContainerDropzone?.current?.scrollLeft -
      posizioneClientX;
    let newPosYSenzaDeltaFinestra =
      event.screenY -
      deltaPosIconaY +
      refContainerDropzone?.current?.scrollTop -
      posizioneClientY;

    setDeltaClick({
      posXclick: el.posX - newPosXSenzaDeltaFinestra,
      posYclick: el.posY - newPosYSenzaDeltaFinestra,
    });
    setIconStartPosition(startPosition);
  }

  let imageBackground = useBackGroundPlanimetria(params);

  function closeMenu() {
    setIsOpenSubmenu(false);
  }

  // useNoPassiveEventListener(refImage,"wheel", setScaleZoom);

  useScrollGrabOnContainer(refContainerDropzone, refImage);

  const { height, width: widthCalcolo, marginLeft, marginTop } = calcolaPercentualeDiMarginDaScaleImage(initialValueScaleZoomDesktop, handlerScale, idSfondoPlanimetria,
    { altezzaCustomImg: altezzaPlanimetria, larghezzaCustomImg: larghezzaPlanimetria },
    { altezzaDefault: PlanimetriaAltezza, larghezzaDefault: PlanimetriaLarghezza })


  return (
    <React.Fragment>
      {
        <>
          <Grid container style={{ marginTop: "25px" }}>
            <Grid item xs={12} sm={12}>
              {permissionToMoveIconAndCreateIcon && !isSalaRiunione && (
                <ElementiInseribili
                  allowDrop={allowDrop}
                  drop={drop}
                  dragStart={dragStart}
                  icons={IconsToToolBar}
                  changeIcons={changeIcons}
                />
              )}
              <div
                id="dropzoneContainer"
                style={{
                  position: "relative",
                  overflow: "auto",
                  maxWidth: "100%",
                  maxHeight: `calc( ${altezzaImmaginePlanimetria}px - (${altezzaImmaginePlanimetria -
                    window.innerHeight +
                    deltaDimensioniPlanimetria
                    }px))`,
                }}
                ref={refContainerDropzone}
              >
                {Object.keys(dettaglio).length !== 0 && (
                  <div
                    className={containerClass}
                    ref={refImage}
                    style={{
                      transform: `scale(${scaleZoom})`,
                      backgroundImage: idSfondoPlanimetria
                        ? `url(${imageBackground})`
                        : `url(${sfondoDefault})`,
                      backgroundRepeat: "no-repeat",
                      height: idSfondoPlanimetria
                        ? `${altezzaPlanimetria}px`
                        : `${PlanimetriaAltezza}px`,
                      width: idSfondoPlanimetria
                        ? `${larghezzaPlanimetria}px`
                        : `${PlanimetriaLarghezza}px`,
                      backgroundRepeat: "no-repeat",
                      marginLeft: `${marginLeft * ((scaleZoom - initialValueScaleZoomDesktop) * moltiplicaPercentualeScalling(deltaScale))}px`,
                      marginTop: `${marginTop * ((scaleZoom - initialValueScaleZoomDesktop) * moltiplicaPercentualeScalling(deltaScale))}px`,
                    }}

                    onClick={() => closeMenu()}
                    onDrop={handleAdd}
                    onDragOver={(event) => allowDrop(event)}
                  >
                    {elementiRecuperati.map((el, index) => (
                      <Draggable
                        onStart={
                          permissionToMoveIconAndCreateIcon
                            ? (event) => {
                              startMove(event, el, index);
                            }
                            : () => false
                        }
                        onStop={(event) => move(event, el.id, el)}
                        position={{ x: el.posX, y: el.posY }}
                        key={index}
                      >
                        <span
                          className={el.element.style}
                          style={{
                            position: "absolute",
                            width: `${grandezzaIcona}px`,
                            height: `${grandezzaIcona}px`,
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                          }}
                          onClick={(event) => openSubmenu(event, el.id)}
                          key={index}
                        >
                          {el.element.icon}
                        </span>
                      </Draggable>
                    ))}
                    <Draggable
                      onStop={(event) => move(event)}
                      position={{ x: 500, y: 512 }}
                    >
                      <div
                        onClick={handleAdd}
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                        }}
                      >
                        {element}
                      </div>
                    </Draggable>
                    {typePage !== "" && (
                      <ElementSubMenu
                        open={isOpenSubmenu}
                        infoPositionSubMenuAndId={infoToSubmenu}
                        isSalaRiunione={isSalaRiunione}
                        typeComponent={typePage}
                        anchorEl={anchorEl}
                      />
                    )}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          <Notification />
        </>
      }
    </React.Fragment>
  );
};

export default PlanimetriaGestione;

import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getUsersFiltered } from '../../../const/urlAction';
import axios from 'axios';
import { notificationOpen } from '../../../redux/reducers/notification-reducer';
import handlerError from '../../../functions/handlerError';
import handlerMultiViewComponent from '../../../functions/Utils/handlerMultiViewComponent';
import { listOfDialogBuildViewComponent } from '../../../const/Dialog/Building/listOfDialogBuildViewComponent';

const urlBase = process.env.REACT_APP_URL;

export default function BuildingDialogRefactored({ clicked, rowInfo }) {
  const [users, setUsers] = useState([]);
  const [stateDialog, setStateDialog] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const backButtonState = useSelector((state) => state.dialogBackButton);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clicked.count > 0) setStateDialog(true);
  }, [clicked.count]);
  
  const handleChangeUserSelect = (event, value) => {
    setSelectedUsers(value);
  };
  
  // Gestisce il cambio di Datatable con la Dialog Aperta, la chiude e il dialogPageType impedisce di far partire la chiamata
  useEffect(() => {
    if (stateDialog) setStateDialog(false);
  }, [window.onhashchange]);
  
  // Si occupa di gestire la chiusura della dialog attraverso il backButton
  useEffect(() => {
    if (stateDialog) setStateDialog(false);
  }, [backButtonState]);

  // Recupera la lista degli utenti
  useEffect(() => {
    let isMounted = true;
    (async () => {
      const { action, method } = getUsersFiltered;
      let url = urlBase + action;

      try {
        const response = await axios({
          method: method,
          url,
        });
        if (isMounted) {
          setUsers(response.data.result);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setSelectedUsers([]);
  }, [stateDialog]);

  const handlerBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setStateDialog(false);
    }
  };

  let PlaceHolderViewDialogBuild = handlerMultiViewComponent(
    listOfDialogBuildViewComponent,
    clicked.type
  );

  return (
    <>
      <PlaceHolderViewDialogBuild
        rowInfo={rowInfo}
        handlerBlur={handlerBlur}
        users={users}
        handleChangeUserSelect={handleChangeUserSelect}
        selectedUsers={selectedUsers}
        returnIsOpen={setStateDialog}
        isOpen={stateDialog}
      />
    </>
  );
}

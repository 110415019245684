import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  IconButton,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  dialogButtonGeneral,
  dialogEditBuilding,
} from "../../../../../const/DialogData";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Fragment } from "react";
import { useFormik } from "formik";
import { initialValuesBuildingEdit } from "../../../../../const/formik/Building/initialValuesBuilding";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { notificationOpen } from "../../../../../redux/reducers/notification-reducer";
import { reloadingDataTableBuildings } from "../../../../../redux/reducers/dataTableBuildings-reducer";
import axios from "axios";
import { useEffect } from "react";
import { editBuilding, getBuilding } from "../../../../../const/urlAction";
import { hideLoader, showLoader } from "../../../../../redux/reducers/appLoader-reducer";
import AddressField from "./addressForm/AddressField"
import AutocompleteAddressField from "./addressForm/AutocompleteAddressField";
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../../../../Hooks/useMobileDetection";
import { ShowImgEdificio } from "../../../../ShowImageEdificio/ShowImgEdificio";
import { TimePicker } from "@mui/x-date-pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import { validationSchemaBuildingEdit } from "../../../../../const/formik/Building/validationSchemaBuilding";

const urlBase = process.env.REACT_APP_URL;

export default function DialogViewBuildingEdit({
  isOpen = false,
  users,
  rowInfo,
  // selectedUsers,
  returnIsOpen,
}) {
  const dispatch = useDispatch();
  const [changedRow, setchangedRow] = useState();
  const [selectedUsers, setSelectedUsers] = useState();
  const initialValues = initialValuesBuildingEdit(rowInfo);
  const { t } = useTranslation();
  const isMobile = useMobileDetection(950);
  const apiKey = useSelector((state) => state.configurations.configurazioniGlobali.googlePlacesActive);
  const [radioButtonFotoEdificio, setRadioButtonFotoEdificio] = useState("mantieni");
  const fileInputRef = useRef(null);
  const appLoadingState = useSelector((state) => state.appLoader.loading);
  const configurations = useSelector((state) => state.configurations.configurazioniGlobali);

  const giorniSettimana = {
    1: t("Monday"),
    2: t("Tuesday"),
    3: t("Wednesday"),
    4: t("Thursday"),
    5: t("Friday"),
    6: t("Saturday"),
    7: t("Sunday")
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaBuildingEdit,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (radioButtonFotoEdificio === "modifica" && values.file == null) {
        formik.setFieldError(
          "file",
          t("modify_building_picture_error")
        );
      }
      setchangedRow({
        id: values.id,
        Denominazione: values.Denominazione,
        Sigla: values.Sigla,
        UtentiResponsabiliPrenotazioni: selectedUsers,
        Indirizzo: values.Indirizzo,
        Coordinate: values.Coordinate,
        FotoEdificio: values.file,
        RadioImageHandler:
          radioButtonFotoEdificio === "cancella" ? true : false,
        OrariAperturaEdificio: values.OrariAperturaEdificio
      });
    },
  });

  useEffect(() => {
    if (isOpen) {
      (async () => {
        setSelectedUsers([]);
        const { action, method } = getBuilding;
        let url = urlBase + action + rowInfo?.id;
        try {

          const response = await axios({
            method: method,
            url
          });

          if (response.data.ok) {
            let keyUp
            Object.keys(response.data.result).map((key) => {
              keyUp = key.charAt(0).toUpperCase() + key.slice(1);

              if (key === "utentiResponsabiliPrenotazioni" && response.data.result[key].length > 0) {
                formik.setFieldValue(`${[keyUp]}`, setSelectedUsers(response.data.result[key]), true);
              }

              if (key === "orariAperturaEdificio" && response.data.result[key].length > 0) {
                formik.setFieldValue(`${[keyUp]}`, response.data.result[key]);
              }

              if (key === "idFotoEdificio" && response.data.result[key]) {
                formik.setFieldValue(`IdFotoEdificio`, response.data.result[key]);
              }

              if (key === "foto" && response.data.result[key]) {
                formik.setFieldValue(`Foto`, response.data.result[key]);
              }

            });

          } else {
            dispatch(notificationOpen({ message: response.data.message, status: "success" }));
          }

        } catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(notificationOpen({ message: `${error.response.data.message}`, status: "error" }));
          } else {
            dispatch(notificationOpen({ message: "Operazione fallita: Server non raggiungibile.", status: "error" }));
          }
        }
      })();
    }
  }, [isOpen]);


  useEffect(() => {
    if (changedRow != null && formik.isValid) {
      (async () => {
        let formData, action, method, url;

        if (selectedUsers === null || selectedUsers === undefined) {
          formData = { ...changedRow, UtentiResponsabiliPrenotazioni: null };
        } else {
          formData = {
            ...changedRow,
            UtentiResponsabiliPrenotazioni: selectedUsers,
          };
        }

        action = editBuilding.action;
        method = editBuilding.method;

        url = urlBase + action;

        dispatch(showLoader())
        try {
          const response = await axios({
            method: method,
            url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.data.ok) {
            formik.resetForm(initialValues);
            dispatch(reloadingDataTableBuildings());
            closeDialog();
            setRadioButtonFotoEdificio("mantieni");
          }

          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        } catch (error) {
          console.error(error);

          if (error?.response?.data?.errors != undefined) {
            let keys = Object.keys(error?.response?.data?.errors);
            keys.map((key) => {
              formik.setFieldError(key, error?.response?.data?.errors[key]);
            });
          }
          if (error.response.data.message == "Edificio già presente.") {
            dispatch(
              notificationOpen({
                message: "Edificio già presente!",
                status: "error",
              })
            );
          }
          if (error.response.data.message == "Selezionare un Responsabile!") {
            dispatch(
              notificationOpen({
                message: `${error.response.data.message}`,
                status: "error",
              })
            );
          }
          if (error.response.data.message == "Formato non valido") {
            dispatch(
              notificationOpen({
                message: "Formato coordinate non valido",
                status: "error",
              })
            );
          }
        }
        dispatch(hideLoader())
      })();
    }
  }, [changedRow]);

  const closeDialog = () => {
    formik.resetForm(initialValues);
    returnIsOpen(false);
  };

  const handleChangeUserSelect = (event, value) => {
    setSelectedUsers(value);
  };

  const handleFotoEdificioChange = (event) => {
    setRadioButtonFotoEdificio(event.target.value);
  };

  function handleChangeFile(event) {
    if (event?.currentTarget?.files[0]) {
      let filename = event?.currentTarget?.files[0]?.name;
      let filenameExtension = filename.substring(filename.lastIndexOf("."), filename.length);
      if (event?.currentTarget?.files[0]?.type?.startsWith("image")) {
        formik.setFieldValue("file", event.currentTarget.files[0]);
      } else if (configurations.enableImportCadFiles && (filenameExtension.includes("dwg") || filenameExtension.includes("dxf"))) {
        formik.setFieldValue("file", event.currentTarget.files[0]);
      } else {
        formik.setFieldError(
          "file",
          configurations.enableImportCadFiles ? t("planimetry_file_cad_format_error") : t("planimetry_file_format_error")
        );
      }
    }
  }

  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        fullScreen={isMobile}
        maxWidth={"md"}
      >
        <DialogTitle> {t(dialogEditBuilding.DialogTitle)}</DialogTitle>
        <DialogContent className="border-Content-dialog">
          <FormControl fullWidth>
            <Grid container rowSpacing={1} columnSpacing={2}>
              <TextField
                margin="dense"
                id="id"
                label={t("Building_ID")}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.id}
                sx={{ display: "none" }}
              />
              <Grid item xs={12} sm={2}>
                <TextField
                  margin="dense"
                  inputProps={{
                    maxLength: 3,
                  }}
                  id="Sigla"
                  label={t("abbreviation")}
                  type="text"
                  fullWidth
                  variant="standard"
                  value={formik.values.Sigla}
                  onChange={formik.handleChange("Sigla")}
                  error={formik.touched.Sigla && Boolean(formik.errors.Sigla)}
                  helperText={formik.touched.Sigla && formik.errors.Sigla}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  margin="dense"
                  id="Denominazione"
                  label={t("denomination")}
                  type="text"
                  fullWidth
                  variant="standard"
                  value={formik.values.Denominazione}
                  onChange={formik.handleChange("Denominazione")}
                  error={
                    formik.touched.Denominazione &&
                    Boolean(formik.errors.Denominazione)
                  }
                  helperText={
                    formik.touched.Denominazione && formik.errors.Denominazione
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Autocomplete
                  multiple
                  id="UtentiResponsabiliPrenotazioni"
                  size="small"
                  options={users} //tutti ma sotto forma di json selectedUsers idUtente Nominativo
                  value={selectedUsers}
                  disableCloseOnSelect
                  limitTags={1}
                  isOptionEqualToValue={(option, value) =>
                    option.idUtente === value.idUtente
                  }
                  getOptionLabel={(option) => option.nominativo || ""}
                  onChange={handleChangeUserSelect}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.nominativo}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Building_Managers")}
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>{params.InputProps.endAdornment}</Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              {apiKey ?
                <AutocompleteAddressField formik={formik} />
                :
                <AddressField formik={formik} />
              }

              {formik.values.Foto !== null &&
                formik.values.Foto !== undefined &&
                formik.values.Foto !== "null" &&
                formik.values.Foto !== ""
                && (
                  <ShowImgEdificio image={formik.values.Foto} />
                )}
              {formik.values.IdFotoEdificio !== null &&
                formik.values.IdFotoEdificio !== "" &&
                formik.values.IdFotoEdificio !== undefined && (
                  <Grid
                    sx={{ margin: "0px 0 0px 0" }}
                    item
                    xs={12}
                    sm={12}
                  >
                    <FormLabel component="legend">
                      <b>{t("Building_Picture_Management")}</b>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="recoveryEnabled"
                      name="row-radio-group-enabled"
                      onChange={(event) => {
                        handleFotoEdificioChange(event);
                      }}
                      defaultValue="mantieni"
                    >
                      <FormControlLabel
                        value="mantieni"
                        control={<Radio />}
                        label={t("Keep")}
                      />
                      <FormControlLabel
                        value="cancella"
                        control={<Radio />}
                        label={t("Cancel")}
                      />
                      <FormControlLabel
                        value="modifica"
                        control={<Radio />}
                        label={t("edit")}
                      />
                    </RadioGroup>
                  </Grid>
                )}
              {(radioButtonFotoEdificio === "modifica" ||
                formik.values.IdFotoEdificio === null ||
                formik.values.IdFotoEdificio === "" ||
                formik.values.IdFotoEdificio === "null" ||
                formik.values.IdFotoEdificio === undefined) && (
                  <Grid
                    sx={{ margin: "8px 0 8px 0" }}
                    item
                    container
                    xs={12}
                    sm={12}
                  >
                    <Grid item xs={12} sm={12}>
                      <FormLabel component="legend">
                        <b> {t("Building_picture")}</b>
                      </FormLabel>
                    </Grid>
                    <Grid item xs={isMobile ? 2 : 1} sm={isMobile ? 2 : 1}>
                      <IconButton
                        color="primary"
                        aria-label={t("upload_picture")}
                        component="span"
                      >
                        <label htmlFor="file" className="file-label">
                          <FontAwesomeIcon icon={faImage} size="2x" cursor={'pointer'} />
                        </label>
                      </IconButton>
                      <input
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        margin="dense"
                        id="file"
                        type="file"
                        onChange={(event) => handleChangeFile(event)}
                      />
                    </Grid>
                    <Grid item xs={isMobile ? 10 : 11} sm={isMobile ? 10 : 11}>
                      <Typography sx={{ position: 'relative', top: '1.2rem', left: isMobile ? '1rem' : '' }}>{formik.values.file?.name}</Typography>
                    </Grid>
                  </Grid>
                )}
              <Grid
                sx={{ margin: "8px 0 2px 0" }}
                item
                xs={12}
                sm={12}
              >
                {formik.values.OrariAperturaEdificio.length > 0 && <FormLabel component="legend">
                  <b>{t("Opening_hours_Management")}</b>
                </FormLabel>}
              </Grid>
              {formik.values.OrariAperturaEdificio.map((orarioApertura, index) => (
                <>
                  <Grid item xs={3} sm={2}>
                    <Typography sx={{ position: 'relative', top: '1rem' }}><b>{giorniSettimana[formik.values.OrariAperturaEdificio[index].idGiornoSettimana]}</b></Typography>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          name={`OrariAperturaEdificio[${index}].apertura`}
                          checked={formik.values.OrariAperturaEdificio[index].apertura}
                          onChange={formik.handleChange}
                          sx={{ position: 'relative', top: '0.6rem' }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={3} sm={4}>
                    <TimePicker
                      label={t("Opening")}
                      name={`OrariAperturaEdificio[${index}].orarioApertura`}
                      value={formik.values.OrariAperturaEdificio[index].orarioApertura
                        ? new Date(`2000-01-01T${formik.values.OrariAperturaEdificio[index].orarioApertura}`)
                        : new Date()}
                      onChange={(date) => {
                        const formattedTime = date
                          ? date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })
                          : '';
                        formik.setFieldValue(`OrariAperturaEdificio[${index}].orarioApertura`, formattedTime);
                      }}
                      ampm={false}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          fullWidth: true,
                          error: formik.touched.OrariAperturaEdificio?.[index]?.orarioApertura && Boolean(formik.errors.OrariAperturaEdificio?.[index]?.orarioApertura),
                          helperText: formik.touched.OrariAperturaEdificio?.[index]?.orarioApertura && formik.errors.OrariAperturaEdificio?.[index]?.orarioApertura
                        }
                      }}
                      disabled={!formik.values.OrariAperturaEdificio[index].apertura}
                    />
                  </Grid>
                  <Grid item xs={3} sm={4}>
                    <TimePicker
                      label={t("Closure")}
                      name={`OrariAperturaEdificio[${index}].orarioChiusura`}
                      value={formik.values.OrariAperturaEdificio[index].orarioChiusura
                        ? new Date(`2000-01-01T${formik.values.OrariAperturaEdificio[index].orarioChiusura}`)
                        : new Date()}
                      onChange={(date) => {
                        const formattedTime = date
                          ? date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })
                          : '';
                        formik.setFieldValue(`OrariAperturaEdificio[${index}].orarioChiusura`, formattedTime);
                      }}
                      ampm={false}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          fullWidth: true,
                          error: formik.touched.OrariAperturaEdificio?.[index]?.orarioChiusura && Boolean(formik.errors.OrariAperturaEdificio?.[index]?.orarioChiusura),
                          helperText: formik.touched.OrariAperturaEdificio?.[index]?.orarioChiusura && formik.errors.OrariAperturaEdificio?.[index]?.orarioChiusura
                        }
                      }}
                      disabled={!formik.values.OrariAperturaEdificio[index].apertura}
                    />
                  </Grid>
                </>
              ))}
              <br></br>
              <br></br>
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()}>
            {t(dialogButtonGeneral.Cancel)}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={appLoadingState}
            onClick={() => formik.handleSubmit()}
          >
            {t(dialogButtonGeneral.Confirm)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

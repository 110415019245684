import { DialogTitle, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function TitleScannedQrCode({title = "Nessun titolo"}) {
  const { t } = useTranslation(); 
  return (
    <>
      <Grid container className="title-DialogQrCodeScan">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <DialogTitle >{t(title)}</DialogTitle >
        </Grid>
      </Grid>
    </>
  );
}

import { useEffect } from "react";
import { handlerZoom } from "../functions/handlerZoom";

export default function useNoPassiveEventListener(ref, event, func, statusScroll) {
useEffect(() => {
  if (ref.current) {
    let eventListener = (e) => {
      handlerZoom(e, func, statusScroll);
      };

      ref.current.addEventListener(event,eventListener, { passive: false });
      return () => {
        ref.current.removeEventListener(event, eventListener, { passive: false });
      };
    }
  }, [ref.current, statusScroll]);
}




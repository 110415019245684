import { Avatar, DialogContent, Grid, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import TextWarningQrCodeScanned from "../../TextWarningQrCodeScanned";
import { DISPONIBILE } from "../../../../../../const/Planimetria/StatoPostazione";
import GeneralCustomDateTimePicker from "../../../../../UI/TimePicker/GeneralCustomDateTimePicker";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faUser } from "@fortawesome/free-solid-svg-icons";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { DailyHourSlot, formatDateTime } from "../../../../../../functions/formatDate";

export default function BodyComponentPostazioneOccupata({ statoAlMomentoDelloScan, postazioneOccupata, setValueDataFine = () => null, valueDataFine, dataFinePrenotazione, hasPrenotazioniAttiveNelGiorno, prenotazioniDelGiorno }) {
  const { t } = useTranslation();
  const now = new Date();
  const maxDate = new Date(prenotazioniDelGiorno[0]?.dataInizioPrenotazione)

  const handleChangeOrarioFine = (newValue) => {
    if (newValue !== null) {
      setValueDataFine(newValue);
    } else {
      setValueDataFine(dataFinePrenotazione);
    }
  };

  return (
    <>
      {postazioneOccupata && (
        <>
          <Grid container item xs={12} sm={6} className={"flex-center-center card-text-Primary"}>
            {/* Immagine dell'Avatar */}
            <Grid mt={2} item xs={12} className="flex-center-center">
              {postazioneOccupata.ospite || !postazioneOccupata.immagineAvatar
                ? <Avatar
                  alt="Nome Utente"
                  children={<FontAwesomeIcon icon={faUser} className="avatarIcon" />}
                  sx={{ width: 100, height: 100 }}
                />
                :
                <Avatar
                  alt="Nome Utente"
                  src={"data:image/*;base64," + postazioneOccupata.immagineAvatar}
                  sx={{ width: 100, height: 100 }}
                />
              }
            </Grid>
            {/* Nome e Cognome */}
            {postazioneOccupata.ospite
              ? <>
                <Grid item xs={12}>
                  <Typography variant="h6" fontWeight="bold">{postazioneOccupata.ospite}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ display: "inline", color: "grey" }}>{t("guest_of_user")}{" "}{postazioneOccupata.nominativo}</Typography>
                </Grid>
              </>
              :
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="bold">{postazioneOccupata.nominativo}</Typography>
              </Grid>
            }
            <Grid container className="flex-center-center">
              <Typography className="cardRiepilogoText">
                {moment(postazioneOccupata.orarioInizioPrenotazione).format(
                  "HH:mm"
                )}{" "}
                -{" "}
                {moment(postazioneOccupata.orarioFinePrenotazione).format(
                  "HH:mm"
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {!postazioneOccupata && (
        <Grid container className="flex-center-center">
          {prenotazioniDelGiorno.lenght > 0 && <Grid item>
            <Typography className="qrcode-text-warning" sx={{ textAlign: 'center' }}>
              <ReportProblemIcon sx={{ position: "relative!important", top: "0.35rem!important" }} className="qrCode-icon-warning" />
              <b>Attenzione: </b>è già presente una prenotazione su questa postazione, con orario di inizio: <b>{DailyHourSlot(maxDate)}</b>.
            </Typography>
          </Grid>}
          <Grid item width={"inherit"}>
            <DialogContent sx={{ minHeight: "100px", paddingBottom: "0px" }}>
              {!(statoAlMomentoDelloScan != 1) && (
                <>
                  <Grid item xs={12} sm={12}>
                    <GeneralCustomDateTimePicker
                      TypeTimePicker={11}
                      label={t("End_time_of_booking")}
                      valueStart={valueDataFine}
                      handleChange={handleChangeOrarioFine}
                      minDate={now}
                      maxDate={maxDate}
                    />
                  </Grid>
                </>
              )}
            </DialogContent>
          </Grid>
        </Grid>
      )}
      {hasPrenotazioniAttiveNelGiorno &&
        statoAlMomentoDelloScan === DISPONIBILE && <TextWarningQrCodeScanned />}
    </>
  );
}

import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function IconLabelStep({activeStep, index}) {

    return (
        activeStep >= index + 1 
                ? <span className="iconStepper"><FontAwesomeIcon icon={faCheck} style={{fontSize:"1.2rem", paddingTop:"2px"}}/></span>
                : activeStep == index  
                   ? <span className="iconStepper"><Typography sx={{fontSize:"0.75rem"}}>{index + 1}</Typography></span>
                   : <span className="iconStepper iconStepper-off"><Typography sx={{fontSize:"0.75rem"}}>{index + 1}</Typography></span>
    )
}
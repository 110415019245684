import { Button, Grid, useTheme } from "@mui/material";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { Typography, Box } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import handlerError from "../../functions/handlerError";
import { getZonePrenotazione } from "../../const/urlAction";
import { useTranslation } from "react-i18next";
import { CDCard, CDCardContent } from "../../const/CDCardStyles";
import useMobileDetection from "../../Hooks/useMobileDetection";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCity,
  faClock,
  faDesktop,
  faLocationDot,
  faUserPen,
  faUsers,
  faStairs,
} from "@fortawesome/free-solid-svg-icons";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { hideLoader, showLoader } from "../../redux/reducers/appLoader-reducer";
import { truncateString } from "../../functions/Utils/truncateString";
import { lastActionOnStepperNext } from "../../redux/reducers/infoPrenotazione-reducer";
import defaultBuildingPicture from "../../assests/defaultBuilding.png";
import isEdificioOpenInOrarioPrenotazione from "../../functions/stepper/prenotazioneEdificioEZona/isEdificioOpenInOrarioPrenotazione";

const urlBase = process.env.REACT_APP_URL;

export default function CardEdificioStepper({
  edificio,
  setIsDisabledZona,
  svuotoDaEdificio,
  handleValueZona,
  handleValueEdificio,
  setStepNoAutoSkipped,
  framesIndex,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMobileDetection();
  const refBoxPills = useRef();
  const [isEdificioOpen, setEdificioOpen] = useState(false);
  const dataPrenotazione = useSelector((state) => state.prenotazioneStepper);

  const {  
    dataInizio,
    dataGiorno,
    dataFine,  
  } = dataPrenotazione;

  function choiceEdificioAndZona(zona) {
    dispatch(lastActionOnStepperNext());
    handleValueZona(zona);
    handleValueEdificio(edificio);
  }

  useEffect(() => {
    const [isOpen, msg] = isEdificioOpenInOrarioPrenotazione(t,edificio,dataGiorno,dataInizio,dataFine)
    setEdificioOpen([isOpen, msg]);
  }, []);
  
  return (
    <>
      <Grid m={1} component={CDCard} sx={{ paddingTop: "0px" }} className={isEdificioOpen[0] ? "" : "stepper-edificio-close"}>
        <Grid
          container
          item
          xs={12}
          sx={{ height: "120px", overflow: "hidden" }}
        >
          <img
            alt="Foto edificio"
            src={edificio.foto ? "data:image/png;base64," + edificio.foto : defaultBuildingPicture}
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
          {(!edificio.foto || !isEdificioOpen[0]) &&
            <Typography
              sx={{
                position: 'relative',
                top: '-50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                background: 'rgba(0,0,0,0.3)',
                color: 'whitesmoke',
                padding: '10px',
                borderRadius: '4px'
              }}>
              {t(!isEdificioOpen[0] ? isEdificioOpen[1]:"Picture_not_available")}
            </Typography>}
        </Grid>
        <CDCardContent>
          <Grid container item xs={12} >
            <Grid item xs={12}>
              <Typography
                variant="h7"
                color="textSecondary"
                sx={{ textAlign: "left" }}
              >
                <Grid container>
                  <Grid item xs={11}>
                    {" "}
                    {edificio.denominazione}{" "}
                    <Typography sx={{ display: "inline", color: "grey" }}>
                      {" "}
                      ({edificio.sigla})
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                sx={{ textAlign: "left" }}
              >
                <Grid container>
                  {edificio?.indirizzo && (
                    <>
                      <Grid item xs={11}>
                        {" "}
                        {edificio.indirizzo}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Typography>
            </Grid>
            <Grid item container xs={12} mt={1}>
              {edificio.zone.map((zona,index) => {
                return (
                  <Button
                    size="small"
                    disabled={!isEdificioOpen[0] || !zona.hasElements}
                    sx={{ marginRight: "5px", marginBottom: "5px" }}
                    variant="outlined"
                    m={0.5}
                    key={index}
                    onClick={() => choiceEdificioAndZona(zona)}
                  >
                    <Typography
                      className="ellipsis-text"
                    >
                      {zona.denominazione}
                    </Typography>
                  </Button>
                );
              })}
            </Grid>
          </Grid>
        </CDCardContent>
      </Grid>
    </>
  );
}

<Grid item xs={12} sm={12}></Grid>;

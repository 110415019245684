
export default function responsiveImagePlanimetria(dimensioneImage, larghezzaContainer, larghezzaContainerZoom, margin) {
    let dimensione = dimensioneImage

    if(typeof larghezzaContainerZoom === 'string') {
        larghezzaContainerZoom = parseInt(larghezzaContainerZoom.replace("px","",10))
        if(dimensioneImage > larghezzaContainerZoom) {
            dimensione = dimensioneImage + margin
        }
    }

    return dimensione
}
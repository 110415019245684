import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import handlerError from "../../../functions/handlerError";
import "../../../index.css";
import { closeDialog } from "../../../redux/reducers/DialogGeneral";
import { resetStatePrenotazioneStepper } from "../../../redux/reducers/infoPrenotazione-reducer";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { resetPrenotazione } from "../../../redux/reducers/prenotazioneStepper-reducer";
import { loadprenotazioniTableState } from "../../../redux/reducers/prenotazioneTable-reducer";
import GroupsIcon from "@mui/icons-material/Groups";
import { costruzioneTestoElementoSuggerimentoPrenotazione } from "../../../functions/costruzuioneTestoElementoSuggerimentoPrenotazione";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import handlerIconSuggerimenti from "../../../functions/stepper/suggerimenti/handlerIconSuggerimenti";
import { truncateString } from "../../../functions/Utils/truncateString";

const urlBase = process.env.REACT_APP_URL;

export function ElementSuggerimentoPrenotazione({
  elementoDaMostrare = {},
  handlerElementSelected,
}) {

  /*   let testo = costruzioneTestoElementoSuggerimentoPrenotazione(elementoDaMostrare); */

  const { isSalaRiunione, idTipologiaStanza } = elementoDaMostrare;

  let icon = handlerIconSuggerimenti(idTipologiaStanza)
  return (
    <>
      <Box
        className="ElementSuggerimentoPrenotazione"
        onClick={(e) => handlerElementSelected({ elementoDaMostrare, e })}
      >
        <Grid container className="flex-center-center">
          <Grid item xs={12} sm={12} md={12} lg={12} className="flex-center-center">
            <Typography position={"relative"}>
              {icon}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <Typography className="ElementSuggerimentoPrenotazione-text">
              {truncateString(elementoDaMostrare.stanzaDenominazione, 16)}
            </Typography>
          </Grid>
          {!isSalaRiunione && <Grid item xs={12} sm={12} md={12} lg={12} >
            <Typography className="ElementSuggerimentoPrenotazione-text">
              {elementoDaMostrare.posto}
            </Typography>
          </Grid>}

        </Grid>
      </Box>
    </>
  );
}

import { Button, CardActionArea, Chip, Divider, Grid, Paper, Typography } from "@mui/material";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import GroupsIcon from "@mui/icons-material/Groups";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { BookingTimelineSegment } from "../../../../UI/BookingTimeline/BookingTimeline";
import {
  DesktopMacOutlined,
  DesktopMac,
  Groups,
} from "@mui/icons-material";
import { CalendarViewMode } from "../../../../../const/calendarViewMode";
import "../../Prenotazioni.styles.css";
import "./RowPrenotazione.styles.css";
import {
  getDayofMonth,
  getTime,
  getDayOfWeek,
  formatDateRegularNoTime,
  dayofMonth,
  DayofWeekandMonthVisibile,
  month,
} from "../../../../../functions/formatDate";
import { openDialogRiepilogo } from "../../../../../redux/reducers/DialogRiepilogo-reducer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { enumViewModePrenotazioni } from "../../../../../const/prenotazioni/enumViewMode";
import CheckInStatusPill from "../CheckInStatusPill/CheckInStatusPill";
import useMobileDetection from "../../../../../Hooks/useMobileDetection";
import createStatusRowSide from "../../../../../functions/prenotazioni/createStatusRowSide";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faCity, faComputer, faDesktop, faDoorOpen, faLocationDot, faUser, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { truncateString } from "../../../../../functions/Utils/truncateString";
import { CDCard, CDCardContent } from "../../../../../const/CDCardStyles";
import { Box } from "@mui/system";
import { enumCheckInStatus } from "../../../../../const/prenotazioni/enumCheckInStatus";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import "../../../../../components/TrovaCollega/ListaPrenotazioniCollega/CardPrenotazione/CardPrenotazione.styles.css"

/**renderizza una card prenotazione in versione desktop munita di indicatore di timeline */
export function RowPrenotazioneConTimeline({
  prenotazioni,
  index,
  isSectionHeader,
  showYear,
  viewMode,
  viewOnlyMine = true,
  isOpenAdvancedSearch,
}) {
  return (
    <>
      <Grid className="row-timeline-container">
        <BookingTimelineSegment
          isOpenAdvancedSearch={isOpenAdvancedSearch}
          prenotazioni={prenotazioni}
          index={index}
          showDateLabel={isSectionHeader}
          showYear={showYear}
          viewMode={viewMode}
        />
        <RowPrenotazione
          viewOnlyMine={viewOnlyMine}
          prenotazione={prenotazioni[index]}
        />
      </Grid>
    </>
  );
}

/**Component della card con dati di prenotazione (formato esteso per desktop)*/
export function RowPrenotazione({ prenotazione, viewOnlyMine }) {
  const {
    dataInizioPrenotazione,
    dataFinePrenotazione,
    isSalaRiunione,
    zonaDenominazione,
    stanzaDenominazione,
    edificioDenominazione,
    postazionePosto,
    disabilitato,
    utentePrenotato,
    isMaxValue,
    id,
    ospite,
    nominativoOspite,
  } = { ...prenotazione };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let isShowNomeOspiteConUtente = viewOnlyMine;
  if (ospite) viewOnlyMine = false; // ridefinisce il modo di vedere la card se è una prenotazione per un ospite

  return (
    <>
      <Paper
        onClick={() => {dispatch(openDialogRiepilogo(id));}}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="row-prenotazione box-shadow-light flex-center-center"
      >
        <Grid container item sx={{ justifyContent: 'space-around' }}>
          <Grid container item xs={3} sm={3} md={3} lg={3} xl={3} columnSpacing={2} className="flex-center-center-text-center">
            <Grid item xs={12}>
              {viewOnlyMine === false && (
                <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText textNominativo" >
                  <b>{ospite ? isShowNomeOspiteConUtente ? nominativoOspite : <>{nominativoOspite}<Typography className="styleOspite">{" (" + utentePrenotato + ")"} </Typography></> : utentePrenotato}</b>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography className="textOrario">
                  {getTime(dataInizioPrenotazione) +
                    " - " +
                    getTime(dataFinePrenotazione)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
              {disabilitato === true && (
                <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText">
                  {formatDateRegularNoTime(dataInizioPrenotazione)} - {" "}
                  {isMaxValue
                    ? "n.d"
                    : formatDateRegularNoTime(dataFinePrenotazione)}
                </Typography>             
                )}
            </Grid>
          </Grid>

          <Grid container item xs={5} sm={5} md={5} lg={5} xl={5} className="flex-center-center">
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textSecondary" className="longDescriptionText textPostazione" >
               <b>{stanzaDenominazione}</b>
                {!isSalaRiunione && <> <ArrowRightAltIcon className="arrow biggerArrow"/> {" "} <b>{postazionePosto}</b> </>}
              </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText textZona">
                  <b>{edificioDenominazione}{" - "} {zonaDenominazione}</b>
                </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={3} sm={3} md={3} lg={3} xl={2} className="flex-center-end">
              <Grid item xs={12} className="checkInTag" sx={{
                background: prenotazione.checkInStatus === enumCheckInStatus.notChecked ? '#e3525293' :
                  prenotazione.checkInStatus === enumCheckInStatus.waiting ? '#ffe686' :
                    prenotazione.checkInStatus === enumCheckInStatus.completed ? '#28a7458a' :
                      null
              }}>
                {prenotazione.checkInStatus !== 0 && <> <Box>
                  <Typography sx={{
                    color: prenotazione.checkInStatus === enumCheckInStatus.notChecked ? '#cb0505' :
                      prenotazione.checkInStatus === enumCheckInStatus.waiting ? 'orange' :
                        prenotazione.checkInStatus === enumCheckInStatus.completed ? '#137128' :
                          null,
                    textAlign: 'center',
                    fontSize: ' 0.8rem',
                    padding: '0.5rem'
                  }}>
                    {prenotazione.checkInStatus === enumCheckInStatus.waiting ?
                      t("waiting_check_in") :
                      prenotazione.checkInStatus === enumCheckInStatus.completed ?
                        t("Check-in_completed") :
                        prenotazione.checkInStatus === enumCheckInStatus.notChecked ?
                          t("check_in_expired") :
                          null}
                  </Typography>
                </Box> </>}
              </Grid>
            </Grid>
        </Grid>
      </Paper>
    </>
  );
}

/**Component della card con dati di prenotazione (formato compatto per mobile) */
export function RowPrenotazioneCompact({
  prenotazione,
  viewMode,
  showYear,
  index,
  viewOnlyMine,
  advancedSearchMode,
}) {
  const {
    dataInizioPrenotazione,
    dataFinePrenotazione,
    isSalaRiunione,
    postazionePosto,
    utentePrenotato,
    disabilitato,
    id,
    ospite,
    nominativoOspite,
    stanzaDenominazione,
    zonaDenominazione,
    edificioDenominazione
  } = { ...prenotazione };
  const currentCulture = useSelector((state) => state?.culture?.currentCulture);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isMobile = useMobileDetection(950);
  let colorStatusCheckIn = createStatusRowSide(prenotazione.checkInStatus)

  if (ospite) viewOnlyMine = false; // ridefinisce il modo di vedere la card se è una prenotazione per un ospite

  return (<>
    {showYear
      ? <h3 style={index === 0 ? { textAlign: 'center', color: '#3e6bb4', marginTop: '0' } : { textAlign: 'center', color: '#3e6bb4' }}>{moment(prenotazione.dataInizioPrenotazione).format('YYYY')}</h3>
      : <></>
    }
    <Grid item component={CDCard} xs={12} sm={12} mt={2} mb={2}>
      <CDCardContent sx={{ paddingBottom: '0px!important', paddingTop: '0px!important' }} onClick={() => {
        dispatch(openDialogRiepilogo(id));
      }}>
        <Grid container spacing={1} alignItems="center" >
          <Grid item xs={2} sx={{ textAlign: 'center', position: 'relative' }}>
            {isSalaRiunione ? (
              <>
                <Groups sx={{
                  position: 'absolute',
                  width: '10.5em!important',
                  height: '10.5em!important',
                  left: '-4rem',
                  top: '0.5rem',
                  opacity: '5%'
                }} className={
                  disabilitato
                    ? "tl-icon-fill-disabled"
                    : "bk-icon-light"
                } />
              </>
            ) : (
              <>
                <DesktopMacOutlined sx={{
                  position: 'absolute',
                  width: '7.5em!important',
                  height: '7.5em!important',
                  left: '-4rem',
                  top: '2.2rem',
                  opacity: '5%',
                }} className={
                  disabilitato
                    ? "tl-icon-fill-disabled"
                    : "bk-icon-light"
                } />
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container columns={48} alignItems="center" justifyContent="space-between">
              <Grid item xs={39}>
                <Typography variant="h5" sx={{ textAlign: 'left' }}>{ospite ? truncateString(nominativoOspite, nominativoOspite.lenght) : utentePrenotato ? truncateString(utentePrenotato, utentePrenotato.lenght) : null}</Typography>
              </Grid>
              <Grid item xs={9} className="checkInTag" sx={{
                background: prenotazione.checkInStatus === enumCheckInStatus.notChecked ? '#e3525293' :
                  prenotazione.checkInStatus === enumCheckInStatus.waiting ? '#ffe686' :
                    prenotazione.checkInStatus === enumCheckInStatus.completed ? '#28a7458a' :
                      null
              }}>
                {prenotazione.checkInStatus !== 0 && <> <Box>
                  <Typography sx={{
                    color: prenotazione.checkInStatus === enumCheckInStatus.notChecked ? '#cb0505' :
                      prenotazione.checkInStatus === enumCheckInStatus.waiting ? 'orange' :
                        prenotazione.checkInStatus === enumCheckInStatus.completed ? '#137128' :
                          null,
                    textAlign: 'center',
                    lineHeight: '1.5',
                    fontSize: ' 0.7rem'
                  }}>
                    {prenotazione.checkInStatus === enumCheckInStatus.waiting ?
                      t("waiting_check_in") :
                      prenotazione.checkInStatus === enumCheckInStatus.completed ?
                        t("Check-in_completed") :
                        prenotazione.checkInStatus === enumCheckInStatus.notChecked ?
                          t("check_in_expired") :
                          null}
                  </Typography>
                </Box> </>}
              </Grid>
            </Grid>
          </Grid>
          <Grid container item mt={1} mb={4} justifyContent="space-around">
            <Grid item xs={2} sm={2} className="timeItemLeft">
              <Typography variant="h6" className="timeItemLeftText">
                {getTime(dataInizioPrenotazione)}
              </Typography>
            </Grid>
            <Grid item xs={2} className="dashed-divider"></Grid>
            <Grid item xs={4} sm={2} className="timeItemCenter">
              <Typography variant="h5" color="primary" className="timeItemCenterText">
                {dayofMonth(dataInizioPrenotazione, currentCulture)} {""}
                {month(dataFinePrenotazione, currentCulture)}
              </Typography>
            </Grid>
            <Grid item xs={2} className="dashed-divider"></Grid>
            <Grid item xs={2} sm={2} className="timeItemRight">
              <Typography variant="h6" className="timeItemRightText">
                {getTime(dataFinePrenotazione)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CDCardContent >
      <CDCardContent sx={{ backgroundColor: 'rgba(62, 107, 180, 0.05)' }}>
        <Grid container spacing={1} alignItems="center" >
          <Grid item container xs={12}>
            {ospite && <Grid item xs={12}>
              <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText" sx={{ textAlign: 'left' }}>
                <FontAwesomeIcon icon={faUser} className="iconOspite" />{" "}{utentePrenotato} ({t("referent")})
              </Typography>
            </Grid>}
            <Grid item xs={4}>
              <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText" sx={{ textAlign: 'left' }}>
                <FontAwesomeIcon icon={faLocationDot} className="iconBuilding" />{" "}{zonaDenominazione}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText" sx={{ textAlign: 'right' }}>
                <FontAwesomeIcon icon={faDoorOpen} className="iconBuilding" />{" "}{stanzaDenominazione}
                {!isSalaRiunione && <> <ArrowRightAltIcon className="arrow" sx={{ position: 'relative', bottom: '0.1rem' }} /> {" "} {postazionePosto} </>}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="textSecondary" className="longDescriptionText">
                <FontAwesomeIcon icon={faCity} className="iconBuilding" />{" "}{edificioDenominazione}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CDCardContent>
    </Grid >
  </>);
}

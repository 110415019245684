import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { notificationClose } from "../../../redux/reducers/notification-reducer";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import useMobileDetection from "../../../Hooks/useMobileDetection";

const posizioneSnackbar = { vertical: "top", horizontal: "center" };
const posizioneSnackbarMobile = { vertical: "bottom", horizontal: "center" };

const Notification = () => {
  const state = useSelector((state) => state.notification);
  const dispatch = useDispatch();
  const { open } = state;
  const isMobile = useMobileDetection(950);
  
  const handleClose = (event, reason) => {
    if (reason === "clickaway" || event != null) {
      dispatch(notificationClose());
    }
    setTimeout(() => dispatch(notificationClose()), 3000);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={isMobile ? posizioneSnackbarMobile : posizioneSnackbar}
        key={new uuidv4()}
        //key={posizioneSnackbar.vertical + posizioneSnackbar.horizontal}
      >
        <Alert onClose={handleClose} severity={state.severity}>
          {state.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Notification;

import {
  Button,
  Grid,
  Avatar,
  IconButton,
  Badge,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { faBan, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import nameToAvatar from "../../functions/Avatar/NameToAvatar";
import { Stack } from "@mui/system";

const urlBase = process.env.REACT_APP_URL;

export default function AvatarImage({ size, name, isInputDisabled, ImmagineAvatar, isCentered, bgColor, isUserLoggedImage, styleContainer, isOspite }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const inputRef = useRef();
  const [isChangedAvatar, setIsChangedAvatar] = useState();
  const [avatarImage, setAvatarImage] = useState(null);
  const [changedRow, setchangedRow] = useState();

  function handleChangeFile(event) {
    if (event?.currentTarget?.files[0]?.type?.startsWith("image")) {
      setchangedRow({
        Image: event.currentTarget.files[0],
      });
    } else {
      dispatch(
        notificationOpen({
          message: t("invalid_file_type"),
          status: "error",
        })
      );
    }
  }
  //GET PER RECUPERO AVATAR
  useEffect(() => {
    if (isUserLoggedImage) {
      (async () => {
        let url = urlBase + "Account/GetAvatar";

        try {
          const response = await axios({
            method: "get",
            url,
          });

          if (response.data) {
            setAvatarImage(isOspite ? null : response.data.result);
          }
        } catch (error) {
          setAvatarImage(null);
          console.error(error);
        }
      })();
    } else {
      setAvatarImage(isOspite ? null : ImmagineAvatar);
    }
    return () => {
      setAvatarImage(null);
    };
  }, [ImmagineAvatar, isChangedAvatar]);

  //POST PER ADD/EDIT IMMAGINE AVATAR
  useEffect(() => {
    if (changedRow != null) {
      (async () => {
        let formData = new FormData();

        Object.keys(changedRow).map((key) => {
          formData.append(`${key}`, changedRow[key]);
        });
        let url = urlBase + "Account/AddAvatar";
        try {
          const response = await axios({
            method: "post",
            url,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.data.ok) {
            setIsChangedAvatar(!isChangedAvatar);
          }
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: "success",
            })
          );
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [changedRow]);

  const DeleteAvatar = async (event) => {
    inputRef.current.value = "";
    let url = urlBase + "Account/DeleteAvatar";
    try {
      const response = await axios({
        method: "post",
        url,
      });

      if (response.data) {
        setAvatarImage(null);
      }
      dispatch(
        notificationOpen({
          message: response.data.message,
          status: "success",
        })
      );
    } catch (error) {
      console.error(error);
    }
  };



  return (
    <>
      {isCentered ? (
        <Grid item xs={12} sm={12} className="avatarContainer" sx={styleContainer}>
          <input
            accept="image/*"
            className="hiddenInput"
            id="icon-button-file"
            type="file"
            onChange={(event) => handleChangeFile(event)}
            disabled={isInputDisabled}
            ref={inputRef}
          />
          <label htmlFor="icon-button-file">
            <IconButton
              color="primary"
              aria-label={t("upload_picture")}
              component="span"
            >
              <Badge
                overlap="circular"
                invisible={isInputDisabled}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <Tooltip title={"Cancella"}>
                    <IconButton
                      onClick={(event) => DeleteAvatar(event)}
                      color="primary"
                      aria-label={t("delete")}
                      sx={{backgroundColor:'white'}}
                      disableRipple={true}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                  </Tooltip>
                }
              >
                <Avatar

                  sx={{ bgcolor: bgColor, width: size, height: size, fontSize: size / 2 }}
                  src={"data:image/*;base64," + (ImmagineAvatar ? ImmagineAvatar : avatarImage)}
                >
                  {name ? nameToAvatar(name) : <FontAwesomeIcon icon={faBan} style={{ fontSize: size ? size / 1.4 : 30 }} />}
                </Avatar>
              </Badge>
            </IconButton>
          </label>
        </Grid>
      ) : (
        <Avatar
          sx={{ bgcolor: bgColor, width: size, height: size, fontSize: size / 2 }}
          style={styleContainer}
          src={"data:image/*;base64," + avatarImage}
        >
          {name ? nameToAvatar(name) : <FontAwesomeIcon icon={faBan} style={{ fontSize: size ? size / 1.4 : 30 }} />}
        </Avatar>
      )}
    </>
  );
}

export const AvatarImageMemoized = React.memo(AvatarImage);

export default function handlePaginationAndRowCleanupMobileView(
  lastSearchFilter,
  lastPageIndex,
  searchFilter,
  page,
  pageSize,
  requestBody
) {
  let cleanRow = false;
if (lastSearchFilter !== searchFilter) {
  requestBody.pageIndex = 0;
  cleanRow = true;
}

lastPageIndex = !lastPageIndex ? 0 : lastPageIndex;

  if (lastPageIndex === page) {
    requestBody.pageIndex = 0;
    requestBody.pageSize = (page + 1) * pageSize;
    cleanRow = true;
  }

  return cleanRow;
}

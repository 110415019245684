import { enGB, it } from 'date-fns/locale'

export function getLocaleValue(locale) {
    switch (locale) {
      case 'it':
        return it;
      case 'en':
        return enGB;
      default:
        return it;
    }
  }
  
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RichiesteAccessoHeader } from "../../../const/DatatableData";
import { GetAllRichiestaCredenziali } from "../../../const/urlAction";
import CustomStyledDataGrid from "../../UI/DataGrid/StyledDataGrid";

const urlBase = process.env.REACT_APP_URL;

export default function RichiesteAccessoComponent() {
  const { t } = useTranslation(); 
  const [elements, setElements] = useState([]);
  const [loadingRow, setLoadingRow] = useState("");
  const [requestBody, setRequestBody] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsCount, setRowsCount] = useState(0);

  useEffect(() => {
    let isMounted = true;

    (async () => {
      if (requestBody) {
        const { action, method } = GetAllRichiestaCredenziali;
        let url = urlBase + action;
        setIsLoading(true);
        try {
          const response = await axios({
            method: method,
            url,
            data: requestBody,
          });
          if (response.data.ok && isMounted) {
            setRowsCount(response.data.result.totalFiltered);
            let elementsFormatted = response.data.result.result.map((x) => {
              return {
                ...x,
                dateRequest: moment(x.dateRequest).format(
                  "DD/MM/YYYY HH:mm"
                ),
              };
            });
            setElements(elementsFormatted);
          }
        } catch (error) {
          console.error(error);
        }
        setIsLoading(false);
      }
    })();
    return () => {
      isMounted = false;
    };
    setIsLoading(false);
  }, [loadingRow]);

  return (
    <>
      <CustomStyledDataGrid
        headers={RichiesteAccessoHeader}
        rows={elements}
        rowHeight={80}
        idRowsfield={"idRichiesta"}
        nameDataGrid={t("request_credentials")} 
        reloadRow={setLoadingRow}
        setSateSpinner={isLoading}
        setRequestBody={setRequestBody}
        rowsCount={rowsCount}
      />
    </>
  );
}

// const handlerError = (error) => {
//   let response = {
//     message: "Operazione fallita: Server non raggiungibile.",
//     status: false,
//   };

//   if (error.response.data.esito === 2) {
//     response = { message: error.response.data.message, status: "warning" };
//   } else if (error.response?.data?.message !== undefined) {
//     response = { message: error.response.data.message, status: false };
//   } else if (error.response?.status !== undefined) {
//     response = { message: handleErrorStatus(error.response.status) };
//   } else if (error.response !== undefined) {
//     response = { message: `${error.response.data.message}`, status: error.ok };
//   } else if (error.length > 0) {
//     response = { message: `${error}`, status: error.ok };
//   }

//     if (response.status === true) {
//     response.status = "success";
//   } else if (response.status === false) {
//     response.status = "error";
//   } else if (error.response.data.esito === 2) {
//     response.status = "warning";
//   } else {
//     response.status = "error";
//   }

//   return response;
// };

// const handleErrorStatus = (status) => {
//   let message = "";
//   switch (status) {
//     case 401:
//     case 403:
//       message = "Non autorizzato";
//       break;
//     case 400:
//       message = "Dati inseriti non corretti";
//       break;
//     default:
//       message = "Operazione fallita: Server non raggiungibile.";
//       break;
//   }
//   return message;
// };

// export default handlerError;

const handleErrorResponse = (error) => {
  let response = {
    message: "Operazione fallita: Server non raggiungibile.",
    status: "error",
  };

  if (error.response) {
    const responseData = error.response.data;
    if (responseData?.esito === 2) {
      response.message = responseData.message;
      response.status = "warning";
    } else if (responseData?.message) {
      response.message = responseData.message;
    } else {
      response.message = handleErrorStatus(error.response.status);
    }
  } else if (error.length > 0) {
    response.message = `${error}`;
  }

  return response;
};

const handleErrorStatus = (status) => {
  switch (status) {
    case 401:
    case 403:
      return "Non autorizzato";
    case 400:
      return "Dati inseriti non corretti";
    default:
      return "Operazione fallita: Server non raggiungibile.";
  }
};

export default handleErrorResponse;


import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { resetInitialState } from '../../../redux/reducers/RiepilogoDialogAfterPrenotazione-reducer';
import { useTranslation } from 'react-i18next';

const CustomDialog = ({ title, body, open = false, handleClose = ()=> {} }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(); 

useEffect(() => {

  return () => {
    dispatch(resetInitialState());
  }
},[])

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
          {body}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("close")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;

//add
export const dialogAddUser = { DialogTitle: "add_new_user" };
export const dialogAddZone = { DialogTitle: "add_new_zone" };
export const dialogAddBuilding = { DialogTitle: "add_new_building" };
export const dialogAddStanze = { DialogTitle: "add_new_room" };
export const dialogAddPostazioni = { DialogTitle: "add_workstation", DialogText: "add_workstation_confirmation" };

// Edit
export const dialogEditUser = { DialogTitle: "edit_user" };
export const dialogEditZone = { DialogTitle: "edit_zone" };
export const dialogEditBuilding = { DialogTitle: "edit_building" };
export const dialogEditPostazione = { DialogTitle: "edit_workstation" };
export const dialogEditUtenti = { DialogTitle: "user_association" };
export const dialogEditStanze = { DialogTitle: "edit_room" };
export const dialogEditSalaRiunione = { DialogTitle: "edit_meeting_room" }

// User Registration
export const dialogSignInUser = { DialogTitle: "request_access_credentials" };
export const dialogPasswordChangeAdvice = { DialogTitle: "password_change_advice" };

//Sincronizzazione
export const dialogSincronizzazioneUsers = {DialogTitle:"dialogSyncUsers"};
  
//Function tag
export const changeStatusUserTitleDialog = (userState, t) => {
    const textUserState = userState === null ? t("disable_Action") : t("enable");
    return textUserState + t("user_v2");
  }
  
  export const changeStatusUserTextDialog = (userState, t) => {
    const textUserState = userState === null ? t("disable_v2") : t("enable_v2");
    return t("change_status_text_dialog_1") + textUserState;
  }
  
  export const changeStatusBuildingTitleDialog = (userState, t) => {
    const textUserState = userState === null ? t("enable") : t("delete");
    return textUserState + t("building_v2");
  }
  
  export const changeStatusPostazioneTitleDialog = (userState, t) => {
    const textUserState = userState === null ? t("enable") : t("delete");
    return textUserState + t("postazione_v2");
  }
  
  export const changeStatusBuildingTextDialog = (userState, t) => {
    const textUserState = userState === null ? t("enable_v2") : t("delete_v2");
    return t("change_status_text_dialog_1") + " " + textUserState + " "+ t("change_status_building_text_dialog_2");
  }
  
  export const changeStatusStanzaTitleDialog = (userState, t) => {
    const textUserState = userState === null ? t("delete") : t("enable");
    return textUserState + t("stanza_v2");
  }
  
  export const changeStatusStanzaTextDialog = (userState, t) => {
    const textUserState = userState === null ? t("delete_v2") : t("enable_v2");
    return t("change_status_text_dialog_1") + textUserState + t("change_status_room_text_dialog_2");
  }
  
  export const changeStatusZonaTitleDialog = (userState, t) => {
    const textUserState = userState === null ? t("delete") : t("enable");
    return textUserState + t("zona_v2");
  }
  
  export const changeStatusZonaTextDialog = (userState, t) => {
    const textUserState = userState === null ? t("delete_v2") : t("enable_v2");
    return t("change_status_text_dialog_1") + textUserState + t("change_status_zone_text_dialog_2");
  }

  export const changeStatusPostazioneTextDialog = (userState, t) => {
    const textUserState = userState === null ? t("enable_v2") : t("delete_v2");
    return <>{t("change_status_text_dialog_1")} {textUserState} {t("the")} <b>{t("change_status_station_text_dialog_2")}</b> </>
}
  
//Generic
export const dialogButtonGeneral = { Confirm: "dialogButtonGeneral_Confirm", Cancel: "dialogButtonGeneral_Cancel" };

export const dialogButtonConfirm = { Confirm: "dialogButtonConfirm_Confirm", Cancel: "dialogButtonConfirm_Cancel" };

export const dialogButtonRiepilogo = {
  Confirm: "dialogButtonRiepilogo_Confirm",
  Chiudi: "dialogButtonRiepilogo_Chiudi",
  Elimina: "dialogButtonRiepilogo_Elimina",
  Disabilita: "dialogButtonRiepilogo_Disabilita",
  Indietro: "dialogButtonRiepilogo_Indietro",
  HoCapito: "dialogButtonRiepilogo_HoCapito"
};

export const dialogButtonQrCode = {
  Prenota: "dialogButtonQrCode_Prenota",
  Annulla: "dialogButtonQrCode_Annulla",
  Elimina: "dialogButtonQrCode_Elimina",
  Disabilita: "dialogButtonQrCode_Disabilita",
  Indietro: "dialogButtonQrCode_Indietro",
  Conferma: "dialogButtonQrCode_Conferma"
};

export const switchAlertPrenotazioniEsaurite = {
  Label: "switchAlertPrenotazioniEsaurite_Label"
};

export const convalidaOtpRichiestaCredenzialiButtonText = {
  Indietro: "convalidaOtpRichiestaCredenzialiButtonText_Indietro"
};

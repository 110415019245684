export const initialState = {
  utente: null,
  DataInizio: null,
  DataFine: null,
  Postazione: null,
  Stanza: null,
  Zona: null,
  Edificio: null,
  RadioButtonUser: true,
  MostraDisabilitazioni: false,
  PrenotazioniPassate: false,
  OrderByDateAsc: true,
  timeStamp: 0,
  TipologieStanza: []
};

const filterHistoryDatatableReducer = (state, action) => {
  switch (action.type) {
    case "ADD_UTENTE":
      state = { ...state, utente: action.payload };
      break;
    case "ADD_POSTAZIONE":
      state = { ...state, Postazione: action.payload };
      break;
    case "SET_RADIOBUTTON_USER":
      state = { ...state, RadioButtonUser: action.payload };
      break;
    case "ADD_STANZA":
      state = { ...state, Stanza: action.payload, Postazione: null };
      break;
    case "ADD_ZONA":
      state = { ...state, Zona: action.payload, Stanza: null, Postazione: null };
      break;
    case "ADD_EDIFICIO":
      state = { ...state, Edificio: action.payload, Zona: null, Stanza:null, Postazione:null };
      break;
    case "ADD_DATAINIZIO":
      state = { ...state, DataInizio: action.payload };
      break;
    case "ADD_DATAFINE":
      state = { ...state, DataFine: action.payload };
      break;
    case "ADD_PRENOTAZIONESTATUS":
      state = { ...state, MostraDisabilitazioni: action.payload };
      break;
    case "ADD_PRENOTAZIONIPASSATE":
      state = { ...state, PrenotazioniPassate: action.payload };
      break;
    case "ORDER_DATE_ASC":
      state = { ...state, OrderByDateAsc: action.payload };
      break;
      case "FILTERTIPOLOGIASTANZA":
        state = { ...state, TipologieStanza: action.payload };
        break;
    case "RESET":
      state = initialState;
      break;
  }
  return state;
};

export default filterHistoryDatatableReducer;

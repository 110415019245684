import { createSlice } from '@reduxjs/toolkit';

const initialState = {isOpen:false};

const dialogChangePasswordAdviceSlice = createSlice({
    name:'dialogChangePasswordAdvice',
    initialState,
    reducers: {
         openDialogChangePasswordAdvice:(state,payload) => {
             return {...state, isOpen:true}
         },
         closeDialogChangePasswordAdvice: (state) => {
            return {...state, isOpen: false};
         }
    }
});

export const { openDialogChangePasswordAdvice, closeDialogChangePasswordAdvice } = dialogChangePasswordAdviceSlice.actions;

const { reducer } = dialogChangePasswordAdviceSlice;

export default reducer;
import { createSlice } from '@reduxjs/toolkit';
import { actionOnStepper } from '../../const/Stepper/actionOnStepper';

const initialState = {idEdificio:0, idZona:0, idStanza:0, Edificio: {}, Zona: {}, Stanza: {}, Postazione: {}, triggerEffect: false, lastActionOnStepper: actionOnStepper.avanti};

const infoPrenotazione = createSlice({
    name:'infoPrenotazione',
    initialState,
    reducers: {
         saveEdificioInPrenotazioneStepper: (state,payload) => {
            return {...state, idEdificio: payload.payload};
         },
         resetEdificioInPrenotazioneStepper: (state,payload) => {
            return {...state, idEdificio: payload.payload};
         },
        saveZonaInPrenotazioneStepper: (state,payload) => {
            return {...state, idZona: payload.payload};
         },
         resetZonaInPrenotazioneStepper: (state) => {
            return {...state, idZona: 0};
         },
         saveStanzaInPrenotazioneStepper: (state,payload) => {
            return {...state, idStanza: payload.payload};
         },
         resetStanzaInPrenotazioneStepper: (state) => {
            return {...state, idStanza: 0};
         },
         saveEdificioCompletoInPrenotazioneStepper: (state,payload) => {
            return {...state, Edificio: payload.payload};
         },
         saveZonaCompletoInPrenotazioneStepper: (state,payload) => {
            return {...state, Zona: payload.payload, triggerEffect: !state.triggerEffect};
         },
         saveStanzaCompletoInPrenotazioneStepper: (state,payload) => {
            return {...state, Stanza: payload.payload, triggerEffect: !state.triggerEffect};
         },
         savePostazioneCompletoInPrenotazioneStepper: (state,payload) => {
            return {...state, Postazione: payload.payload};
         },
         lastActionOnStepperNext: (state) => {
            return {...state, lastActionOnStepper: actionOnStepper.avanti}
         },
         lastActionOnStepperBack: (state) => {
            return {...state, lastActionOnStepper: actionOnStepper.indietro}
         },
         resetStatePrenotazioneStepper: (state,payload) => {
            return initialState;
         },
         resetPostazioneSelected: (state) => {
            return {...state, Postazione:{}}
         },
    }
});

export const { resetPostazioneSelected,saveZonaInPrenotazioneStepper,lastActionOnStepperNext, lastActionOnStepperBack, saveEdificioInPrenotazioneStepper , savePostazioneCompletoInPrenotazioneStepper, saveEdificioCompletoInPrenotazioneStepper, saveZonaCompletoInPrenotazioneStepper, saveStanzaCompletoInPrenotazioneStepper ,resetEdificioInPrenotazioneStepper, saveStanzaInPrenotazioneStepper, resetZonaInPrenotazioneStepper, resetStanzaInPrenotazioneStepper,  resetStatePrenotazioneStepper} = infoPrenotazione.actions;

const { reducer } = infoPrenotazione;

export default reducer;
import { Grid, Typography } from "@mui/material";
import React from "react";
import "../../PlanimetriaIcons.css";
import "../../../../index.css";
import CheckIfShowDayOnSubMenu from "../../../../functions/MostraMappa.js/InfoSubMenu/CheckIfShowDayOnSubMenu";
import AvatarImage from "../../../Avatar/AvatarImage";
import { t } from "i18next";
import { truncateString } from "../../../../functions/Utils/truncateString";
import { RISERVATA } from "../../../../const/Planimetria/StatoPostazione";

export default function ShowSubMenuInfo({ info, stato, dataSelected }) {

  const { prenotazioni, posto } = info;
  //ORDINAMENTO PRENOTAZIONI
  prenotazioni?.sort(
    (a, b) =>
      Date.parse(a.dataInizioPrenotazione) -
      Date.parse(b.dataInizioPrenotazione)
  );

  let prenotazioniOrdinatoPerUtenteEData = prenotazioni.reduce(function (
    r,
    prenotazioni
  ) {
    r[prenotazioni.utentePrenotato] = r[prenotazioni.utentePrenotato] || [];
    r[prenotazioni.utentePrenotato].push(prenotazioni);
    return r;
  },
    new Array());

  let listaUtenti = Object.keys(prenotazioniOrdinatoPerUtenteEData);

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "#3e6bb4",
          color: "#F9F9FA",
          paddingLeft: "5px",
        }}
        className="flex-center-left"
      >
        {posto && (
          <Typography className="header-submenu-planimetriaInfo">
            <b>{t("workstation") + " " + posto}</b>
          </Typography>
        )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        className="flex-center-center containerSubmenu"
      >
        {listaUtenti.length > 0 ? (
          listaUtenti.map((key, index) => {
            let arrayInformazioni = prenotazioniOrdinatoPerUtenteEData[key];
            return arrayInformazioni.map((x, i) => {
              const {
                utentePrenotatoFullname,
                dataInizioPrenotazione,
                dataFinePrenotazione,
                isMaxValue,
                immagineAvatar,
                ospite,
                nominativoOspite,
              } = x;

              const isShowDay = CheckIfShowDayOnSubMenu(
                dataSelected,
                dataInizioPrenotazione,
                dataFinePrenotazione
              );
              // return i == 0 ? (
              return (
                <React.Fragment key={i}>
                  <Grid container className="submenu-separatore">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginX: "8px",
                        paddingLeft: "3px",
                        paddingRight: "3px",
                      }}
                    >
                      {ospite ? (
                        <AvatarImage
                          name={nominativoOspite}
                          isInputDisabled={true}
                          isCentered={false}
                          isUserLoggedImage={false}
                        ></AvatarImage>
                      ) : (
                        <AvatarImage
                          name={utentePrenotatoFullname}
                          isInputDisabled={true}
                          ImmagineAvatar={immagineAvatar}
                          isCentered={false}
                          isUserLoggedImage={false}
                        ></AvatarImage>
                      )}
                    </div>
                    <Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                          display: "Flex",
                          alignItems: "center",
                          justifyContent: "left",
                          paddingLeft: "5px",
                        }}
                      >
                        <Typography
                          sx={{ paddingTop: "5px", paddingBottom: "0px" }}
                        >
                          <b>
                            {ospite
                              // ? <>{nominativoOspite}<Typography sx={{display:"inline", color:"grey"}}> {" (" + utentePrenotatoFullname + ")"} </Typography></>
                              ? <>{nominativoOspite + " (" + utentePrenotatoFullname + ")"}</>
                              : utentePrenotatoFullname
                                ? utentePrenotatoFullname
                                : t("Not_available")}
                          </b>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                          display: "Flex",
                          alignItems: "center",
                          justifyContent: "left",
                          paddingBottom: info.isSalaRiunione ? "0px" : "5px",
                          paddingLeft: "5px",
                        }}
                      >
                        <span>{isShowDay}</span>
                      </Grid>
                      {info.isSalaRiunione && (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "Flex",
                            alignItems: "center",
                            justifyContent: "left",
                            paddingBottom: "5px",
                            paddingLeft: "5px",
                            fontSize: 14,
                            fontWeight: 100,
                          }}
                        >                         
                          {info.prenotazioni[0]?.titoloRiunione ? truncateString(info.prenotazioni[0].titoloRiunione, 35) : ""} 
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            });
          })
        ) :
          (prenotazioni.length !== 0 ?
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "Flex",
                alignItems: "center",
                justifyContent: "left",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingLeft: "5px",
              }}
            >
              <Typography className="header-submenu-planimetriaInfo">
                {" "}
                <i>{t("reserved")}</i>
              </Typography>
            </Grid>
            :
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "Flex",
                alignItems: "center",
                justifyContent: "left",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingLeft: "5px",
              }}
            >
              <Typography className="header-submenu-planimetriaInfo">
                {" "}
                <i>{stato === RISERVATA ? t("reserved") :  t("Available")} </i>
              </Typography>
            </Grid>
          )}
      </Grid>
    </>
  );
}

import {
  Button,
  Collapse,
  Divider,
  Fab,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  CDCard,
  CDCardContent,
  CDCardHeader,
} from "../../../const/CDCardStyles";
import { useSelector, useDispatch } from "react-redux";
import DialogStepper from "../Stepper/stepperComponent/DialogStepper";
import React from "react";
import useMobileDetection from "../../../Hooks/useMobileDetection";
import DialogQrCode from "../QrCode/DialogQrCode";
import { DialogRiepilogo } from "../../Dialog/DialogRiepilogo/DialogRiepilogo";
//new dashboard imports
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import Notification from "../../UI/Notification/Notification";
import ListaPrenotazioni from "./ComponentPrenotazioni/ListaPrenotazioni";
import PrenotazioniCalendar from "../Prenotazioni/ComponentPrenotazioni/PrenotazioniCalendar";
import { GridAddIcon } from "@mui/x-data-grid";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { MenuCollapse } from "../../MenuCollapse/MenuCollapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { openDialog } from "../../../redux/reducers/DialogGeneral";
import "./Prenotazioni.styles.css";
import handlerConfiguration from "../../../functions/general/handlerConfiguration";
import {
  hideLoader,
  showLoader,
} from "../../../redux/reducers/appLoader-reducer";
import { enumViewModePrenotazioni } from "../../../const/prenotazioni/enumViewMode";
import axios from "axios";
import { getPrenotazioniPaginated } from "../../../const/urlAction";
import createBodyRequestPrenotazioni, {
  SortDirection,
} from "../../../functions/prenotazioni/createBodyRequestPrenotazioni";
import resetSelectedDate from "../../../functions/prenotazioni/resetSelectedEndDate";
import RiepilogoDialogAfterPrenotazione from "../../RiepilogoDialog.js/RiepilogoDialog";
import { useTranslation } from "react-i18next";
import { setdataGiornoFromPrenotazioniComponent } from "../../../redux/reducers/prenotazioneStepper-reducer";

const sortOrder = 1;
const urlBase = process.env.REACT_APP_URL;
const initialState = enumViewModePrenotazioni.Month;
export default function PrenotazioniComponent() {
  const { t } = useTranslation();
  const configurations = useSelector((state) => state.configurations);
  const { enableQrCodeScanner } = handlerConfiguration(configurations);
  const [qrCodeScanningMode, setQrCodeScanningMode] = useState(false);
  const reloadingListaPrenotazioniAfterPrenotazione = useSelector(
    (state) => state.dataTablePrenotazioni
  );
  // si occupa di salvare il mese per capire se devo rimpostare il giorno una volta che da mese torno a giorno.
  const [lastMonthSelectedInDaily, setlastMonthSelectedInDaily] = useState(0);
  const [lastViewSelected, setlastViewSelected] = useState(
    enumViewModePrenotazioni.daily
  );
  const [
    lastDaySelectedInlastMonthSelectedDaily,
    setlastDaySelectedInlastMonthSelectedDaily,
  ] = useState(new Date());
  const dispatch = useDispatch();

  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(null);
  const [saveViewCalendar, setSaveViewCalendar] = useState(initialState);
  const [prenotazioniView, setPrenotazioniView] = useState(initialState);
  const [
    handlerResettingInAdvancedFilter,
    setHandlerResettingInAdvancedFilter,
  ] = useState(0); //impedisce resetting indesiderati della maschera di filtri, ogni volta che il componente viene distrutto e ricreato.

  const [totalFiltered, setTotalFiltered] = useState(0);
  const [pageSize, setPageSize] = useState(
    configurations?.pageSizePrenotazioni || 20
  );
  const isMobile = useMobileDetection();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [endSelectedDate, setEndSelectedDate] = useState(
    new Date(selectedDate)
  );

  const [bodyRequest, setbodyRequest] = useState(null);
  //il metodo associato al tasto di invio della richiesta filtrata
  const [filtriFromAdvancedFilter, setFiltriFromAdvancedFilter] =
    useState(null);
  const [prossimePrenotazioni, setProssimePrenotazioni] = useState([]);
  const dialogChangePasswordAdviceState = useSelector((state)=>state.dialogPasswordChangeAdvice.isOpen);
  const { isOpen, lastOpen } = useSelector((state) => state.dialogGeneral);
  const { rowInfo, dialogType, isOpenRiepilogo, id } = useSelector(
    (stateC) => stateC.dialogRiepilogo
  );
  const tipologieStanzaFiltrate = useSelector((state) => state.roomType);
  /* serve a raggruppare ciò che passo nel componente figlio */
  function setDateAndViewMode(date, CalendarViewMode) {
    setSelectedDate(date);
    setPrenotazioniView(CalendarViewMode);
  }

  /*gestisce la view del componente */
  useEffect(() => {
    setProssimePrenotazioni([]);

    let isCalendarView =
      prenotazioniView < enumViewModePrenotazioni.AdvancedFilter;
    let pageIndex = 0;

    if (isCalendarView) {
      setSaveViewCalendar(prenotazioniView);
      setFiltriFromAdvancedFilter(null);

      setlastDaySelectedInlastMonthSelectedDaily(selectedDate);
      const calendarDateRange = resetSelectedDate(
        selectedDate,
        prenotazioniView,
        lastMonthSelectedInDaily,
        lastDaySelectedInlastMonthSelectedDaily,
        lastViewSelected,
        setlastViewSelected,
        setlastMonthSelectedInDaily,
        setlastDaySelectedInlastMonthSelectedDaily
      );

      let calendarSelectedStartDate = calendarDateRange.selectedDate;
      let calendarSelectedEndDate = calendarDateRange.endSelectedDate;

      let bodyRequest = createBodyRequestPrenotazioni(
        calendarSelectedStartDate,
        calendarSelectedEndDate,
        filtriFromAdvancedFilter,
        prenotazioniView,
        { pageIndex, pageSize, sortOrder }
      );
      setbodyRequest(bodyRequest);
    } else {
      let initialFilters = {
        OrderByDateAsc: true,
        RadioButtonUser: true,
        MostraDisabilitazioni: false,
        PrenotazioniPassate: false,
        DataInizio: null,
        DataFine: null,
        TipologieStanza: tipologieStanzaFiltrate
      };
      setHandlerResettingInAdvancedFilter(0);
      setFiltriFromAdvancedFilter(initialFilters);
    }
  }, [prenotazioniView]);

  //gestisce l'utilizzo della data selezionata nel DataPicker con la data selezionata di base nella StepperCustom
  useEffect(() => {
    if (bodyRequest?.DataInizio)
      dispatch(setdataGiornoFromPrenotazioniComponent(bodyRequest?.DataInizio.toString()));
  }, [bodyRequest, isOpen]);

  // GESTISCE CAMBIO DI REQUEST PER AGGIORNAMENTO ELENCO PRENOTAZIONI
  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (bodyRequest) {
        const { action, method } = getPrenotazioniPaginated;
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data: bodyRequest,
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.data.ok && isMounted) {
            if (bodyRequest.pageIndex === 0)
              setProssimePrenotazioni(response.data.result.result);
            else {
              let prenotazioni = prossimePrenotazioni.concat(
                response.data.result.result
              );
              setProssimePrenotazioni(prenotazioni);
            }

            setTotalFiltered(response.data.result.totalFiltered);
          }
        } catch (error) {
          console.error(error);
        }
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [bodyRequest, reloadingListaPrenotazioniAfterPrenotazione]);

  // GESTISCE CAMBIO DI DATE PER MODALITA' CALENDARIO
  useEffect(() => {
    let isCalendarView =
      prenotazioniView < enumViewModePrenotazioni.AdvancedFilter;
    let pageIndex = 0;
    if (isCalendarView) {
      setProssimePrenotazioni([]);

      // Aggiorna le date in base alla modalità di visualizzazione del calendario.
      const calendarDateRange = resetSelectedDate(
        selectedDate,
        prenotazioniView,
        lastMonthSelectedInDaily,
        lastDaySelectedInlastMonthSelectedDaily,
        lastViewSelected,
        setlastViewSelected,
        setlastMonthSelectedInDaily,
        setlastDaySelectedInlastMonthSelectedDaily
      );

      let calendarSelectedStartDate = calendarDateRange.selectedDate;
      let calendarSelectedEndDate = calendarDateRange.endSelectedDate;

      let bodyRequest = createBodyRequestPrenotazioni(
        calendarSelectedStartDate,
        calendarSelectedEndDate,
        filtriFromAdvancedFilter,
        prenotazioniView,
        { pageIndex, pageSize, sortOrder }
      );

      // Aggiorna il payload per aggiornamento Elenco Prenotazioni
      setbodyRequest(bodyRequest);
    }
  }, [selectedDate, endSelectedDate]);

  // GESTISCE CAMBIO DEI FILTRI PER MODALITA' RICERCA AVANZATA
  useEffect(() => {
    let pageIndex = 0;
    if (prenotazioniView === enumViewModePrenotazioni.AdvancedFilter) {
      let bodyRequest = createBodyRequestPrenotazioni(
        null,
        null,
        filtriFromAdvancedFilter,
        prenotazioniView,
        { pageIndex, pageSize, sortOrder }
      );
      if (bodyRequest != null) setbodyRequest(bodyRequest);
    }
  }, [filtriFromAdvancedFilter]);

  return (
    <>
      <Grid container>
        <Grid item id="adaptiveCard" component={CDCard} xs={12} sm={12}>
          <CDCardHeader
            className="CDCardHeader-Padding forceCdCardHeader-height "
            title={
              <>
                <span>
                  {prenotazioniView ===
                    enumViewModePrenotazioni.AdvancedFilter ? (
                    <>{t("search_reservations")}</>
                  ) : (
                    <>{t("my_reservations")}</>
                  )}
                </span>
                {isMobile &&
                  prenotazioniView ===
                  enumViewModePrenotazioni.AdvancedFilter && (
                    <CalendarMonthIcon
                      sx={{ float: "right" }}
                      onClick={() => {
                        setPrenotazioniView(saveViewCalendar);
                        setOpenAdvancedSearch(false);
                      }}
                    />
                  )}
                {isMobile && (
                  <>
                    {openAdvancedSearch ? (
                      <ExpandLessIcon
                        sx={{ float: "right", marginRight: "15px" }}
                        onClick={() => {
                          setOpenAdvancedSearch(false);
                        }}
                      />
                    ) : (
                      <MenuOpenIcon
                        sx={{
                          float: "right",
                          marginRight: true ? "15px" : " ",
                        }}
                        onClick={() => {
                          setOpenAdvancedSearch(true);
                          setPrenotazioniView(
                            enumViewModePrenotazioni.AdvancedFilter
                          );
                        }}
                      />
                    )}
                  </>
                )}
              </>
            }
          />
          <Divider />
          <CDCardContent
            className={isMobile ? "no-padding-top-MuiCardContent-root" : "no-padding-MuiCardContent-root"}
            style={{ width: "100%" }}
          >
            <Grid container direction="row" justifyContent="center">
              <Grid
                container //contenitore sezione sx calendar e ricerca avanzata
                className={
                  !isMobile ? "with-right-divider leftContainer" : ""
                } /*divider solo in desktop view*/
                direction="row"
                item
                xs={12}
                sm={12}
                lessmd={12}
                md={12}
                lg={4}
                xl={4}
              >
                <Grid
                  container
                  item
                  justifyContent="center"
                  md={12} /*contenitore calendario*/
                  sx={ isMobile ? { boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.05)', zIndex: '1'} : null}
                >
                  {/* inizio calendar */}
                  <Grid
                    item
                    sx={{
                      width: "fit-content"
                    }} /*fit content per adeguare larghezza pulsanti*/
                  >
                    {/*chiudi calendario solo se in vers.desktop*/}
                    <Collapse in={!openAdvancedSearch}>
                      {prenotazioniView <
                        enumViewModePrenotazioni.AdvancedFilter && (
                          <PrenotazioniCalendar
                            selectedDate={selectedDate}
                            setDateAndViewMode={setDateAndViewMode}
                            saveViewMode={saveViewCalendar}
                          />
                        )}
                    </Collapse>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {!isMobile && (
                        <Button
                          sx={{ width: "2rem!important" }}
                          variant="outlined"
                          className="btn-w-right-icon"
                          onClick={() => {
                            setOpenAdvancedSearch(!openAdvancedSearch);
                            if (
                              prenotazioniView ===
                              enumViewModePrenotazioni.AdvancedFilter
                            ) {
                              setPrenotazioniView(saveViewCalendar);
                            } else {
                              setPrenotazioniView(
                                enumViewModePrenotazioni.AdvancedFilter
                              );
                            }
                          }}
                        >
                          {openAdvancedSearch ? (
                            <>
                              <ExpandLessIcon />
                              {t("close_advanced_search")}{" "}
                            </>
                          ) : (
                            <>
                              <MenuOpenIcon />
                              {t("advanced_search")}{" "}
                            </>
                          )}
                        </Button>
                      )}

                      {!isMobile && (
                        <Button
                          sx={{ width: "2rem!important" }}
                          variant="contained"
                          className="btn-w-right-icon"
                          onClick={() => {
                            dispatch(
                              openDialog({
                                dialogType: "Homepage",
                                rowInfo: -1,
                              })
                            );
                          }}
                        >
                          <GridAddIcon /> {t("book_a_reservation")}
                        </Button>
                      )}
                      <MenuCollapse
                        sx={{ position: "relative", top: "0px" }} //menu ricerca avanzata (rimpiazza calendar per desktop)
                        expanded={openAdvancedSearch} //variabile di controllo apertura finestra
                        prenotazioniView={prenotazioniView}
                        handleFilterData={setFiltriFromAdvancedFilter}
                        returnExpandedStateAfterConfirm={setOpenAdvancedSearch}
                        prenotazioniview={prenotazioniView}
                      />
                    </div>
                  </Grid>
                  {/* fine calendar */}
                </Grid>
              </Grid>
              {prossimePrenotazioni?.length > 0 && (
                <Grid
                  container
                  item
                  flexDirection={"column"}
                  xs={12}
                  sm={12}
                  lessmd={12}
                  md={12}
                  lg={8}
                  xl={8}
                  className={isMobile ? "CDCardContent-Mobile" : ''}
                  sx={
                    isMobile
                      ? {
                        height: "100%",
                        minHeight: !openAdvancedSearch ? '80vh' : '45vh',
                        padding: "0 12px",
                        paddingBottom: "6rem",
                      }
                      : {
                        height: "fit-content",
                        padding: "0 12px",
                        marginTop: "12px",
                      }
                  }
                >
                  {prenotazioniView ===
                    enumViewModePrenotazioni.AdvancedFilter &&
                    openAdvancedSearch &&
                    isMobile ? (
                    <></>
                  ) : (
                    <ListaPrenotazioni
                      isOpenAdvancedSearch={openAdvancedSearch}
                      viewOnlyMine={
                        prenotazioniView <
                          enumViewModePrenotazioni.AdvancedFilter
                          ? true
                          : filtriFromAdvancedFilter?.RadioButtonUser
                      }
                      prenotazioni={prossimePrenotazioni}
                      viewMode={prenotazioniView}
                    />
                  )}

                  {prossimePrenotazioni &&
                    prossimePrenotazioni.length > 0 &&
                    totalFiltered &&
                    totalFiltered > (bodyRequest.pageIndex + 1) * pageSize &&
                    (!isMobile || !openAdvancedSearch) && (
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="start"
                        sx={{ width: "100%" }}
                        height="50px"
                      >
                        <Button
                          variant="outline"
                          className={
                            isMobile
                              ? "btn-w-right-icon show-more-mob"
                              : "btn-w-right-icon show-more"
                          }
                          onClick={() => {
                            setbodyRequest((old) => {
                              return { ...old, pageIndex: old.pageIndex + 1 };
                            });
                          }}
                        >
                          <>
                            <KeyboardDoubleArrowDownIcon /> {t("show_more")}
                          </>
                        </Button>
                      </Grid>
                    )}
                </Grid>
              )}

              {!prossimePrenotazioni.length &&
                (!isMobile || !openAdvancedSearch) && (
                  <Grid
                    container
                    item
                    xs={9}
                    sm={9}
                    md={9}
                    lg={8}
                    xl={8}
                    className="no-result-card"
                    mt={3}
                  >
                    <Grid item xs={12}>
                      <EventBusyRoundedIcon className="no-result-icon" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className="no-result-text">
                        {t("no_reservation")}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </CDCardContent>
        </Grid>
        {isMobile && !openAdvancedSearch && (
          <Grid
            container
            className="flex-center-center bc"
            sx={{ padding: "15px", boxShadow:'0 1px 4px rgba(0, 0, 0, 0.25)' }}
            columnSpacing={1}
            position="fixed"
            bottom={0}
          >
            {enableQrCodeScanner && (
              <Grid item xs={6} className="flex-center-center">
                <Button
                  // sx={{ position: "absolute", bottom: "100px", right: "25px" }}
                  sx={{
                    width: "100%",
                    height: "60px",
                    marginLeft: "0px",
                  }}
                  variant="outlined"
                  aria-label="add"
                  onClick={() => {
                    setQrCodeScanningMode(!qrCodeScanningMode);
                  }}
                >
                  <QrCodeScannerIcon
                    color="primary"
                    sx={{ fontSize: "32px" }}
                  />
                  <Typography sx={{ paddingLeft: "5px" }} fontSize={13}>
                    {t("scan")}{" "}
                  </Typography>
                </Button>
              </Grid>
            )}

            <Grid item xs={6} className="flex-center-center">
              <Button
                sx={{ width: "100%", height: "60px" }}
                variant="contained"
                color="primary"
                aria-label="add"
                onClick={() => {
                  dispatch(openDialog("Homepage"));
                }}
              >
                <AddIcon sx={{ fontSize: "32px" }} />
                <Typography sx={{ marginRight: "5px" }} fontSize={13}>
                  {t("reserve")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        )}
        <Notification />
      </Grid>
      <DialogStepper isOpen={isOpen} />
      <DialogRiepilogo
        isOpenRiepilogo={isOpenRiepilogo}
        dialogType={dialogType}
        id={id}
      />
      <RiepilogoDialogAfterPrenotazione />
      {enableQrCodeScanner && qrCodeScanningMode && (
        <DialogQrCode
          isOpenQrCode={qrCodeScanningMode}
          returnStateFromComponent={setQrCodeScanningMode}
        />
      )}

    </>
  );
}

import { itIT, enUS } from '@mui/material/locale';

export function getLocaleMuiTheme(locale) {
    switch (locale) {
      case 'it':
        return itIT;
      case 'en':
        return enUS;
      default:
        return itIT;
    }
  }
  
import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function MenuText({ el, returnElClicked = () => null }) {
  const { t } = useTranslation();
  
  function getDataString() {
    const date = new Date(el?.data);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  
  function getPostazioneOreEMinuti() {
    let text = "";
    if (el.orePostazione?.ore) {
      if (el.orePostazione?.ore === 1) {
        text += el.orePostazione?.ore + t("planner_hour");
      } else {
        text += el.orePostazione?.ore + t("planner_hours");
      }
    }

    if (el.orePostazione?.minuti) {
      if (text != "") text += t("planner_and");

      if (el.orePostazione?.minuti === 1) {
        text += el.orePostazione?.minuti + t("planner_minute");
      } else {
        text += el.orePostazione?.minuti + t("planner_minutes");
      }
    }
    return text === "" ? undefined :t("planner_workstation") + text;
  }

  function getRiunioneOreEMinuti() {
    let text = "";


    if (el.oreRiunione?.ore) {
      if (el.oreRiunione?.ore === 1) {
        text += el.oreRiunione?.ore + t("planner_hour");
      } else {
        text += el.oreRiunione?.ore + t("planner_hours");
      }
    }

    if (el.oreRiunione?.minuti) {
      if (text != "") text += t("planner_and");

      if (el.oreRiunione?.minuti === 1) {
        text += el.oreRiunione?.minuti + t("planner_minute");
      } else {
        text += el.oreRiunione?.minuti + t("planner_minutes");
      }
    }

    return text === "" ? undefined :t("planner_meeting") + text;
  }

  function myFunction() {
    returnElClicked(null)
  }

  useEffect(() => {
    //il Menu di Mui genera una modale che impedisce l'intercettare degli eventi.
    //Viene aggiunto un evento alla modale che chiude al primo movimento del mouse
    var elements = document.getElementsByClassName("MuiModal-root");
      elements[0].addEventListener("mousemove", myFunction);
  }, []);

  return (
    <Grid container sx={{ margin: "0px 10px" }}>
      <Grid item xs={12}>
        <Typography className="titleMenu-planner">{getDataString()}</Typography>
        {el?.orePostazione && (
          <Grid item xs={12}>
            <Typography className="textMenu-planner">
            {getRiunioneOreEMinuti()}
            </Typography>
          </Grid>
        )}
        {el?.oreRiunione && (
            <Grid item xs={12}>
            <Typography className="textMenu-planner">
              {getPostazioneOreEMinuti()}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

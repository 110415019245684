import { Slider } from "@mui/material";
import { useEffect, useState } from "react";
import { marks } from "../../../const/Planner/marks";
import { useTranslation } from "react-i18next";
import useMarksArrayI18n from "../../../Hooks/I18n/useMarksArrayI18n";

const startDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1,
    0,
    0,
    0
  );
  let firstRender = true;
  let retard = 500; 
  let time;
export function SliderPlanner({returnSliderValue = () => {}}) {
    const [month, setMonth] = useState(startDate.getMonth());
    const { t } = useTranslation(); 
    const handleMonth = (event) => {
      setMonth(event.target.value);
    };

    const marks = useMarksArrayI18n()
  
    useEffect(() => {
      if (firstRender) {
        return (firstRender = false);
      }
      if (time) clearTimeout(time);
      time = setTimeout(function () {
        returnSliderValue(month)
      }, retard);
    }, [month]);
  
    return (
        <Slider
          sx={{
            width: "30rem",
            position: "relative",
            left: "4rem",
            top: "0.3rem",
          }}
          valueLabelDisplay="off"
          step={1}
          marks={marks}
          min={0}
          max={11}
          track={false}
          value={month}
          onChange={handleMonth}
        />
    );
  }
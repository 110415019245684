import { Grid, Typography } from "@mui/material";
import style from "../riepilogoDialog.module.css";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import convertTipoStanzaToKeysI18n from "../../../functions/DataTable/convertTipoStanzaToNameDataTable";
import { useTranslation } from "react-i18next";

export default function InfoPostazione({
  infoPostazione,
  tipologiaStanza,
  denominazioneEdificio,
  denominazioneStanza,
  denominazioneZona,
  numerazionePostazione
}) {
  const { titleDataTable, prefixRowDataTable } = convertTipoStanzaToKeysI18n(
    tipologiaStanza.id
  );
  const { t } = useTranslation();
  
  return (
    <Grid container className={`${style["flex-center"]} ${style["line-spacing"]}`}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" color="textSecondary">
          {denominazioneEdificio}, {denominazioneZona}{" "}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" color="textSecondary">
          {denominazioneStanza} {"(" + t(titleDataTable)+ ")"} - {t(prefixRowDataTable)} {numerazionePostazione}
        </Typography>
      </Grid>
    </Grid>
  );
}

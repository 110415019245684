import { createSlice } from '@reduxjs/toolkit';
import { getLocalizedString } from '../../functions/I18n/getLocalizedString';
import { SupportedLanguage } from '../../const/I18n/SupportedLanguage';

const localStorageNameProperty = 'currentCulture';



const initialState = {
  currentCulture: SupportedLanguage.enUS.lang,
};

const caltureSlice = createSlice({
  name: 'caltureSlice',
  initialState,
  reducers: {
    setCurrentCulture: (state, action) => {
      state.currentCulture = action.payload;
    },
    setItalianCulture: (state) => {
      let lang = getLocalizedString(SupportedLanguage.it);
      localStorage.setItem(localStorageNameProperty, lang )
      state.currentCulture = lang;
    },
    setEnglishCulture: (state) => {
      let lang = getLocalizedString(SupportedLanguage.enUS);
      localStorage.setItem(localStorageNameProperty, lang )
      state.currentCulture = lang;
    },
  },
});

export const { setCurrentCulture, setItalianCulture, setEnglishCulture } = caltureSlice.actions;

export const setCultureFromLocalStorage = () => (dispatch, getState) => {
  const cultureFromLocalStorage = localStorage.getItem(localStorageNameProperty);
  if (cultureFromLocalStorage) {
    dispatch(setCurrentCulture(cultureFromLocalStorage));
  } else {
    if(getState().configurations?.configurazioniGlobali?.culture) {
      const defaultCulture = getLocalizedString(getState().configurations?.configurazioniGlobali?.culture);
      localStorage.setItem('currentCulture', defaultCulture)
      dispatch(setCurrentCulture(defaultCulture));
    }
  }
};

export default caltureSlice.reducer;

import { Autocomplete, Button, Checkbox, Typography } from "@mui/material";
import { dialogPageTypes } from "../../const/dialogPageTypes";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import {
  addPostazione,
  deletePostazione,
  getPostazioneById,
  getUsersFiltered,
  getUsersAssociati,
  editPostazione,
  getDotazioniFilteredByTipologiaStanza
} from "../../const/urlAction";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  dialogEditPostazione,
  dialogEditUtenti,
  dialogButtonGeneral,
  dialogButtonConfirm,
  dialogAddPostazioni,
  dialogEditSalaRiunione,
  changeStatusPostazioneTitleDialog,
  changeStatusPostazioneTextDialog,
} from "../../const/DialogData";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import { reloadingDataTableBuildings } from "../../redux/reducers/dataTableBuildings-reducer";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useParams } from "react-router-dom";
import { loadPostazioniState } from "../../redux/reducers/postazioniSlice-reducer";
import handlerError from "../../functions/handlerError";
import { reloadingDataTableUsers } from "../../redux/reducers/dataTableUsers-reducer";
import { BoxDialogDisabilita } from "./DisableDialog/DialogDisabilita";
import { closeDialog } from "../../redux/reducers/DialogGeneral";
import DisabilitaUnaPostazione from "./PostazioneSingola/DisabilitaUnaPostazione";
import RipristinaUnaPostazione from "./PostazioneSingola/RipristinaUnaPostazione";
import { loadUsersState } from "../../redux/reducers/usersSlice-reducer";
import convertPositionToInt from "../../functions/convertPositionToInt";
import { hideLoader, showLoader } from "../../redux/reducers/appLoader-reducer";
import PostazioneDialogContent from "./PostazioneDialog/PostazioneComponent/PostazioneComponent";
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../Hooks/useMobileDetection";
import BoxAutoCompletePills from "../UI/BoxAutoCompletePills/BoxAutoCompletePills";

const urlBase = process.env.REACT_APP_URL;

const PostazioniDialog = () => {
  const backButtonState = useSelector((state) => state.dialogBackButton);
  const [users, setUsers] = useState();
  const [isRenderingDialog, setIsRenderingDialog] = useState(false);
  const [changedRow, setchangedRow] = useState();
  const dialogState = useSelector((state) => state.dialogGeneral);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { t } = useTranslation();
  const isMobile = useMobileDetection(950);
  const qrcodeDinamico = useSelector(
    (state) => state.configurations.configurazioniGlobali.qrCodeDinamico
  );
  const maxDotazioni = useSelector((state) => state.configurations.configurazioniPostazione.maxDotazioni);
  const [inputUserSelect, setInputUserSelect] = useState("");
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [inputDeviceSelect, setInputDeviceSelect] = useState("");
  const [inputDotazioneSelect, setInputDotazioneSelect] = useState("");
  const dispatch = useDispatch();
  const { rowInfo, dialogType, dialogPageType, isOpen } = dialogState;
  const params = useParams();
  const { idStanza } = params;
  const [isSalaRiunione, setIsSalaRiunione] = useState(false);
  const [dotazioni, setDotazioni] = useState(); 
  const [selectedDotazioni, setSelectedDotazioni] = useState([]);

  let validationSchema;

  const appLoadingState = useSelector((state) => state.appLoader.loading);

  if (dialogType === "edit") {
    validationSchema = yup.object({
      Posto: yup
        .number()
        .integer("Il Posto dev'essere un numero")
        .required("Campo Obbligatorio."),
    });
  } else if (dialogType === "add") {
    validationSchema = yup.object({
      Posto: yup
        .number()
        .integer("Il Posto dev'essere un numero")
        .required("Campo Obbligatorio."),
    });
  }

  let initialValues = {};

  if (dialogType === "edit") {
    initialValues = {
      id: rowInfo?.id,
      Posto: rowInfo?.posto,
      UtentiPostazione: rowInfo?.UtentiPostazione,
      Dotazioni: rowInfo?.dotazioni
    };
  } else if (dialogType === "add") {
    initialValues = {
      IdStanza: 0,
      Posto: 0,
    };
  } else if (dialogType === "delete") {
    initialValues = {
      //id: id,
      Posto: rowInfo?.posto,
    };
  }

  if (dialogType === "editUtenti") {
    initialValues = {
      id: rowInfo?.id,
      Posto: rowInfo?.posto,
      UtentiPostazione: rowInfo?.UtentiPostazione,
      Dotazioni: rowInfo?.dotazioni
    };
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (dialogType === "edit") {
        setchangedRow({
          id: values.id,
          Posto: values.Posto,
          UtentiPostazione: selectedUsers,
          Dotazioni: selectedDotazioni
        });
      } else if (dialogType === "add") {
        setchangedRow({
          IdStanza: 0,
          Posto: values.Posto,
          PosX: convertPositionToInt(dialogState?.body?.posX) || 0,
          PosY: convertPositionToInt(dialogState?.body?.posY) || 0,
          UtentiPostazione: selectedUsers,
          NominativoDevice: values.nominativoDevice,
          Dotazioni: selectedDotazioni
        });
      }
      if (dialogType === "editUtenti") {
        setchangedRow({
          id: values.id,
          UtentiPostazione: selectedUsers,
          NominativoDevice: values.nominativoDevice,
          Dotazioni: selectedDotazioni
        });
      }

      if (dialogType === "delete") {
        setchangedRow({
          id: values.id,
        });
      }
    },
  });

  const handleChangeUserSelect = (event, value) => {
    setSelectedUsers(value);
  };

  const handleInputChangeUserSelect = (event, value) => {
    setInputUserSelect(value);
  };

  const handleChangeDeviceSelect = (event, value) => {
    setSelectedDevices(value);
  };

  const handleInputChangeDeviceSelect = (event, value) => {
    setInputDeviceSelect(value);
  };

  const handleChangeDotazioneSelect = (value) => {
    if(value.length <= maxDotazioni) setSelectedDotazioni(value);
  };

  useEffect(() => {
    if (isOpen && dialogType === "editUtenti") {
      (async () => {
        const { action, method } = getUsersAssociati;
        let url = urlBase + action;
        const requestBody = {};
        try {
          const response = await axios({
            method: method,
            url,
            data: requestBody,
          });

          if (response.data.ok) {
            dispatch(loadUsersState(response.data.result));
          }
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
        }
      })();
    }
  }, [isOpen]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const { action, method } = getUsersFiltered;
      let url = urlBase + action;

      try {
        const response = await axios({
          method: method,
          url,
        });
        if (isMounted) {
          setUsers(response.data.result);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const { action, method } = getDotazioniFilteredByTipologiaStanza;
      let url = urlBase + action +"?idStanza="+ idStanza;

      try {
        const response = await axios({
          method: method,
          url
        });
        if (isMounted) {
          setDotazioni(response.data.result);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  //Gestisce il cambio di Datatable con la Dialog Aperta, la chiude e il dialogPageType impedisce di far partire la chiamata
  useEffect(() => {
    dispatch(closeDialog());
  }, [window.onhashchange]);

  useEffect(() => {
    let isMounted = true;
    if (
      isOpen &&
      dialogType === "editUtenti" &&
      dialogPageType === dialogPageTypes.postazioni
    ) {
      (async () => {
        setSelectedUsers([]);
        setSelectedDevices([]);
        setSelectedDotazioni([]);
        setIsRenderingDialog(true);

        const { id } = rowInfo;
        const { action, method } = getPostazioneById;
        let url = urlBase + action + id;

        try {
          const response = await axios({
            method: method,
            url,
          });
          if (isMounted) {
            if (response.data.ok) {
              dispatch(reloadingDataTableBuildings());
              const listnomeUtente = response.data.result[
                "utentiPostazione"
              ].map((x) => {
                return {
                  idUtente: x.idUtente,
                  idPostazione: x.idPostazione,
                  nominativo: x.nomeUtente
                };
              });

              const nominativoDevice = response.data.result["nominativoDevice"];
              formik.setFieldValue(
                `utentiPostazione`,
                setSelectedUsers(listnomeUtente),
                true
              );
              formik.setFieldValue(`nominativoDevice`, nominativoDevice, true);

              const dotazioni = response.data.result["dotazioni"];
              formik.setFieldValue(`dotazioni`, setSelectedDotazioni(dotazioni), true);

              const isSalaRiunione = response.data.result["isSalaRiunione"];
              setIsSalaRiunione(isSalaRiunione);

              setIsRenderingDialog(false);
            } else {
              dispatch(
                notificationOpen({
                  message: response.data.message,
                  status: "success",
                })
              );
            }
          }
        } catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(
              notificationOpen({
                message: `${error.response.data.message}`,
                status: "error",
              })
            );
          } else {
            dispatch(
              notificationOpen({
                message: "Operazione fallita: Server non raggiungibile.",
                status: "error",
              })
            );
          }
        }
        setIsRenderingDialog(false);
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [isOpen]);

  const closeForm = () => {
    formik.resetForm(initialValues);
    dispatch(closeDialog());
  };

  useEffect(() => {
    let isMounted = true;
    if (changedRow != null) {
      (async () => {
        let formData, action, method, url; /*userData */

        if (dialogType === "add") {
          const { PosX, PosY } = changedRow;
          formData = {
            ...changedRow,
            PosX: convertPositionToInt(PosX),
            PosY: convertPositionToInt(PosY),
            IdStanza: idStanza,
          };
          action = addPostazione.action;
          method = addPostazione.method;
        } else if (dialogType === "editUtenti") {
          var utentiPostazioni = selectedUsers.map((item) => item.idUtente);
          formData = {
            ...changedRow,
            UtentiPostazioneId: utentiPostazioni,
            idPostazione: rowInfo?.id,
          };
          action = editPostazione.action;
          method = editPostazione.method;
        }

        url = urlBase + action;
        dispatch(showLoader());
        try {
          const response = await axios({
            method: method,
            url,
            data: formData,
          });
          if (isMounted) {
            if (response.data.ok) {
              formik.resetForm(initialValues);
              dispatch(reloadingDataTableUsers());
              dispatch(loadPostazioniState());
              dispatch(closeDialog());
            }

            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          }
        } catch (error) {
          console.error(error);

          if (error?.response?.data?.errors != undefined) {
            let keys = Object.keys(error?.response?.data?.errors);
            keys.map((key) => {
              formik.setFieldError(key, error?.response?.data?.errors[key]);
            });
          }
          if(error.response.data.message) {
            dispatch(notificationOpen({ message: error.response.data.message, status: "error" }));
          }
          if (error.response.data.message == "") {
            dispatch(notificationOpen({ message: "", status: "error" }));
          }
          if (error.response.data.message == undefined) {
            dispatch(closeDialog());
            dispatch(
              notificationOpen({
                message: "Operazione fallita.",
                status: "error",
              })
            );
          }
        }
        dispatch(hideLoader());
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [changedRow]);

  const deleteRestore = (rowInfo) => {
    let isMounted = true;
    if (dialogType === "delete") {
      (async () => {
        setIsRenderingDialog(true);

        const { id } = rowInfo;
        const { action, method } = deletePostazione;
        let url = urlBase + action;
        dispatch(showLoader());
        try {
          const response = await axios({
            method: method,
            url,
            data: id,
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (isMounted) {
            if (response.data.ok) {
              dispatch(reloadingDataTableUsers());
              dispatch(loadPostazioniState());
              setIsRenderingDialog(false);
            }
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          }
        } catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(
              notificationOpen({
                message: `${error.response.data.message}`,
                status: "error",
              })
            );
          } else {
            dispatch(
              notificationOpen({
                message: "Operazione fallita: Server non raggiungibile.",
                status: "error",
              })
            );
          }
          setIsRenderingDialog(false);
        }
        dispatch(hideLoader());
      })();

      dispatch(closeDialog());

      return () => {
        isMounted = false;
      };
    }
  };

  useEffect(() => {
    setSelectedUsers([]);
    setSelectedDevices([]);
    setSelectedDotazioni([]);
  }, [isOpen]);

  useEffect(() => {
    dispatch(closeDialog());
  }, [backButtonState]);

  return (
    <>
      {dialogType === "delete" && (
        <>
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"sm"}
          >
            <DialogTitle>
              {changeStatusPostazioneTitleDialog(rowInfo?.deleted, t)}
            </DialogTitle>
            <DialogContent className="border-Content-dialog">
              <DialogContentText id="alert-dialog-description">
                {changeStatusPostazioneTextDialog(rowInfo?.deleted, t)} <b>{rowInfo?.posto}</b>?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => dispatch(closeDialog(1))}>
                {t(dialogButtonConfirm.Cancel)}
              </Button>
              <Button
                variant="contained"
                onClick={() => deleteRestore(rowInfo)}
              >
                {t(dialogButtonConfirm.Confirm)}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {/*Dialog di tipo editaUtente apposta per la modifica degli utenti quindi crea uno useEffect a parte */}
      {dialogType === "add" && !isRenderingDialog && (
        <>
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            fullScreen={isMobile}
            maxWidth={"sm"}
          >
            <DialogTitle>{t(dialogAddPostazioni.DialogTitle)}</DialogTitle>
            <DialogContent className="border-Content-dialog">
              <DialogContentText id="alert-dialog-description">
                <PostazioneDialogContent
                  formik={formik}
                  users={users}
                  selectedUsers={selectedUsers}
                  handleChangeUserSelect={handleChangeUserSelect}
                  handleInputChangeUserSelect={handleInputChangeUserSelect}
                  dotazioni={dotazioni}
                  selectedDotazioni={selectedDotazioni}
                  handleChangeDotazioneSelect={handleChangeDotazioneSelect}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeForm()}>
                {t(dialogButtonGeneral.Cancel)}
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={() => formik.handleSubmit()}
              >
                {t(dialogButtonGeneral.Confirm)}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {dialogType === "edit" && !isRenderingDialog && (
        <>
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            fullScreen={isMobile}
            maxWidth={"sm"}
          >
            <DialogTitle>
              {dialogType === "edit"
                ? t(dialogEditPostazione.DialogTitle)
                : t(dialogAddPostazioni.DialogTitle)}
            </DialogTitle>
            <DialogContent className="border-Content-dialog">
              <FormControl fullWidth>
                {dialogType === "edit" && (
                  <TextField
                    margin="dense"
                    id="id"
                    label={t("Workstation_ID")}
                    type="text"
                    fullWidth
                    variant="standard"
                    value={formik.values.id}
                    sx={{ display: "none" }}
                  />
                )}
                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      margin="dense"
                      id="Posto"
                      label={t("Number_of_workstations")}
                      type="number"
                      fullWidth
                      variant="standard"
                      value={formik.values.Posto}
                      onChange={formik.handleChange("Posto")}
                      error={
                        formik.touched.Posto && Boolean(formik.errors.Posto)
                      }
                      helperText={formik.touched.Posto && formik.errors.Posto}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </DialogContent>
            <DialogActions sx={{ mt: 3 }}>
              <Button onClick={() => closeForm()}>
                {t(dialogButtonGeneral.Cancel)}
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={() => formik.handleSubmit}
              >
                {t(dialogButtonGeneral.Confirm)}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {dialogType === "editUtenti" && !isRenderingDialog && (
        <>
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            fullScreen={isMobile}
            maxWidth={"sm"}
          >
            <DialogTitle>
              {dialogType === "editUtenti"
                ? isSalaRiunione ? t(dialogEditSalaRiunione.DialogTitle): t(dialogEditPostazione.DialogTitle)
                : t(dialogAddPostazioni.DialogTitle)}
            </DialogTitle>
            <DialogContent className="border-Content-dialog">
              <FormControl fullWidth>
                {dialogType === "editUtenti" && (
                  <TextField
                    margin="dense"
                    id="id"
                    label={t("Workstation_ID")}
                    type="text"
                    fullWidth
                    variant="standard"
                    value={formik.values.id}
                    sx={{ display: "none" }}
                  />
                )}
                <Grid item xs={12} sm={6}></Grid>
                <Grid container rowSpacing={1} columnSpacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      margin="dense"
                      id="Posto"
                      label={t("workstation")}
                      type="text"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="standard"
                      value={t("workstation") + " " + formik.values.Posto}
                      onChange={formik.handleChange("Posto")}
                      error={
                        formik.touched.Posto && Boolean(formik.errors.Posto)
                      }
                      helperText={formik.touched.Posto && formik.errors.Posto}
                      sx={{ display: dialogType === "editUtenti" && isSalaRiunione ? "none" : "" }}
                    />
                  </Grid>
                  {qrcodeDinamico && <Grid item xs={12} sm={12}>
                    <TextField
                      margin="dense"
                      id="nominativoDevice"
                      label={t("deviceName")}
                      type="text"
                      fullWidth
                      InputProps={{}}
                      variant="standard"
                      value={formik.values.nominativoDevice}
                      onChange={formik.handleChange("nominativoDevice")}
                      error={
                        formik.touched.PonominativoDevicesto &&
                        Boolean(formik.errors.nominativoDevice)
                      }
                      helperText={
                        formik.touched.nominativoDevice &&
                        formik.errors.nominativoDevice
                      }
                    />
                  </Grid>}
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      multiple
                      id="utentiPostazione"
                      size="small"
                      options={users} //tutti ma sotto forma di json selectedUsers idUtente Nominativo
                      value={selectedUsers}
                      disableCloseOnSelect
                      limitTags={1}
                      isOptionEqualToValue={(option, value) =>
                        option.idUtente === value.idUtente
                      }
                      getOptionLabel={(option) => option.nominativo || ""}
                      onChange={handleChangeUserSelect}
                      onInputChange={handleInputChangeUserSelect}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li key={option?.idUtente} {...props}>
                            {option.nominativo}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Workstation_reserved_for")}
                          variant="standard"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <BoxAutoCompletePills items={dotazioni} activeItems={selectedDotazioni} onActiveItemsChange={setSelectedDotazioni}/>
                  </Grid>
                  <br></br>
                  <br></br>
                </Grid>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeForm()}>
                {t(dialogButtonGeneral.Cancel)}
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={appLoadingState}
                onClick={() => formik.handleSubmit()}
              >
                {t(dialogButtonGeneral.Confirm)}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {(dialogState.dialogType === "disableRangeData" ||
        dialogState.dialogType === "ripristinaAll") && (
        <BoxDialogDisabilita
          isOpen={isOpen}
          dialogType={dialogType}
          rowInfo={rowInfo}
          closeForm={closeForm}
        />
      )}
      {dialogType === "disableOne" && (
        <DisabilitaUnaPostazione
          isOpen={isOpen}
          dialogType={dialogType}
          rowInfo={rowInfo}
          closeForm={closeForm}
        />
      )}
      {dialogType === "restoreOne" && (
        <RipristinaUnaPostazione
          isOpen={isOpen}
          dialogType={dialogType}
          rowInfo={rowInfo}
          closeForm={closeForm}
        />
      )}
    </>
  );
};

export default PostazioniDialog;

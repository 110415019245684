import React, { useEffect, useReducer, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Button,
  CardContent,
  IconButton,
  Collapse,
  Stack,
  Chip,
} from "@mui/material";
import {
  FormLabel,
} from "@mui/material";
import { AutocompleteBoxHistoryFilterPostazione } from "./MenuCollapseComponent/AutocompleteBoxHistoryFilterPostazione";
import { AutocompleteBoxHistoryFilterUtente } from "./MenuCollapseComponent//AutocompleteBoxHistoryFilterUtente";
import { DatapickerBoxFilterHistory } from "./MenuCollapseComponent/DatapickerBoxFilterHistory";
import { Box } from "@mui/system";
import reducer, { initialState } from "./reducerMenuCollapse/filterHistoryDatatable-reducer";
import { useDispatch, useSelector } from "react-redux";
import RadioButtonCustom from "../UI/RadiobuttonCustom.js/RadioButtonCustom";
import {
  accountListUserRadioButtonInHistoryDatatable,
  accountListViewRadioButtonInHistoryDatatable,
  dateSortOptionsAdvancedSearch,
} from "../../const/RadioButton/RadioButtonListRadio";
import { policies } from "../../const/policies";
import { ButtonExport } from "./MenuCollapseComponent/ButtonExport";
import CustomCheckBox from "../UI/TextField/customCheckBox";
import useMobileDetection from "../../Hooks/useMobileDetection";
import { enumViewModePrenotazioni } from "../../const/prenotazioni/enumViewMode";
import { active } from "../../redux/reducers/prenotazioniAdvancedMenuFilter-reducer";
import { useTranslation } from "react-i18next";
import { GetTipologiaStanzaAbilitate } from "../../const/urlAction";
import axios from "axios";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import handlerError from "../../functions/handlerError";
import { saveRoomTypes } from "../../redux/reducers/roomType-reducer";

const urlBase = process.env.REACT_APP_URL;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ruoliPermesso = ["Amministratore"]; // lista di ruoli che possono usare la ddl utente/altro utente all'interno della maschera

const now = new Date(Date.now());

export function MenuCollapse({
  expanded,
  handleFilterData,
  returnExpandedStateAfterConfirm,
  prenotazioniview,
}) {
  const [radioButtonUser, setRadioButtonUser] = useState("true");
  const [radioButtonView, setRadioButtonView] = useState("false");
  const [valueButtonPrenotazioniPassate, setvalueButtonPrenotazioniPassate] =
    useState(false);
  const dispatchHook = useDispatch();
  const [resetAll, setResetAll] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [ThereAreErrors, setThereAreErrors] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const { utente } = state;
  const { t } = useTranslation();
  const policiesUtente = useSelector((state) => state.userDate.policies);
  const { responsabileEdificio, utenteResponsabileUtenti, roles } = useSelector(
    (state) => state.userDate
  );
  const isMobile = useMobileDetection();
  const [sortByDateAsc, setSortByDateAsc] = useState(initialState.OrderByDateAsc);
  const [allTypes, setAllTypes] = useState([1, 2, 3, 4]);
  const [chipList, setChipList] = useState(allTypes);
  function handleSortChange(value) {
    setSortByDateAsc((value === "true") ? true : false);
    dispatch({ type: "ORDER_DATE_ASC", payload: (value === "true") ? true : false });
  }
  const _dispatch = useDispatch();
  const [excludedChipList, setExcludedChipList] = useState([]);
  const handleUserChange = (event) => {
    if (event?.target?.value == undefined || event?.target?.value == null) {
      setRadioButtonUser(event);
      dispatch({
        type: "SET_RADIOBUTTON_USER",
        payload:
          event?.target?.value === "false" || event === "false" ? false : true,
      });
    } else {
      setRadioButtonUser(event.target.value);
      dispatch({
        type: "SET_RADIOBUTTON_USER",
        payload:
          event?.target?.value === "false" || event === "false" ? false : true,
      });
    }
  };

  useEffect(() => {
    (async () => {
      const { action, method } = GetTipologiaStanzaAbilitate;
      let url = urlBase + action;

      try {
        const response = await axios({
          method: method,
          url,
        });

        setAllTypes(response.data.result);
        /*   const ids = response.data.result.map(item => item.id);
          handleFilterTipologieStanza(ids); */

      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        _dispatch(notificationOpen(notificationError));
      }
    })();
  }, []);

  const CheckThereAreErrors = (value) => {
    setThereAreErrors(value);
  };

  const handleButtonStatus = (status) => {
    setIsDisabledButton(status);
  };

  const handlePrenotazioniAbilitateChange = (event) => {
    event?.target?.value == undefined || event?.target?.value == null
      ? setRadioButtonView(event)
      : setRadioButtonView(event.target.value);

    if (event?.target?.value == "true" || event == "true")
      setRadioButtonUser("true");
    dispatch({
      type: "ADD_PRENOTAZIONESTATUS",
      payload:
        event?.target?.value === "false" || event === "false" ? false : true,
    });
  };

  const sendRequest = () => {
    let force = { ...state, timeStamp: new Date().getDate() } //serve a forzare a fare una nuova chiamata a ogni click del tasto
    handleFilterData(force);
    if (isMobile) {
      returnExpandedStateAfterConfirm(false);
    } else {
      returnExpandedStateAfterConfirm(true);
    }
  };

  //sendRequest esclusivo della Reset
  const sendRequestReseted = (initialState) => {
    handleFilterData(initialState);
    returnExpandedStateAfterConfirm(true);
  };

  //al cambio del check da true a false di "prenotazioniPassate" svuota le date.
  useEffect(() => {
    if (!valueButtonPrenotazioniPassate) setResetAll(!resetAll);
  }, [valueButtonPrenotazioniPassate])

  const resetAllFilter = () => {
    dispatch({
      type: "RESET",
    });
    setvalueButtonPrenotazioniPassate(false);
    setRadioButtonView("false");
    setRadioButtonUser("true");
    setSortByDateAsc(initialState.OrderByDateAsc);
    setResetAll(!resetAll);
    sendRequestReseted(initialState);
  };

  function returnvalueFromCheckBoxPrenotazioniPassate(value) {
    setvalueButtonPrenotazioniPassate(value);
    dispatch({
      type: "ADD_PRENOTAZIONIPASSATE",
      payload: value,
    });
  }


  useEffect(() => {
    setvalueButtonPrenotazioniPassate(false);
    setRadioButtonView("false");
    setRadioButtonUser("true");
    setSortByDateAsc(initialState.OrderByDateAsc);
    setResetAll(!resetAll);
    // sendRequestReseted(initialState);
    if (prenotazioniview < enumViewModePrenotazioni.AdvancedFilter) {
      dispatchHook(active())
    } else {
      resetAllFilter();
    }
  }, [prenotazioniview])

  const handleClick = (value) => {
    if (chipList.includes(value)) {
      // Rimuovi l'elemento da chipList
      const updatedChipList = chipList.filter(item => item !== value);
      setChipList(updatedChipList);
  
      // Aggiungi l'elemento a excludedChipList
      setExcludedChipList(prevExcludedChipList => [...prevExcludedChipList, value]);
    } else {
      setChipList([...chipList, value]);
  
      // Rimuovi l'elemento da excludedChipList se è presente
      if (excludedChipList.includes(value)) {
        const updatedExcludedChipList = excludedChipList.filter(item => item !== value);
        setExcludedChipList(updatedExcludedChipList);
      }
    }
  }

  useEffect(() => {
    if (chipList.length !== allTypes.length) {
      _dispatch(saveRoomTypes(excludedChipList));
      dispatch({
        type: "FILTERTIPOLOGIASTANZA",
        payload: excludedChipList,
      });
    } else {
      _dispatch(saveRoomTypes([]));
      dispatch({
        type: "FILTERTIPOLOGIASTANZA",
        payload: [],
      });
    }
  }, [chipList])

  return (
    <>
      <Collapse id="collapse" in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container columnSpacing={3} rowSpacing={3}>
            <Grid container item sx={isMobile ? { paddingTop: "0 !important" } : {}}>
              <Grid container item xs={12} sm={12}>
                {(responsabileEdificio.length > 0 ||
                  policiesUtente.indexOf(policies.VisualizzazioneCompleta) !== -1) && (
                    <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                      <RadioButtonCustom
                        GroupName={t("typology")}
                        startValueReset={radioButtonView}
                        labelList={accountListViewRadioButtonInHistoryDatatable}
                        handleChange={handlePrenotazioniAbilitateChange}
                        tabNumber={accountListViewRadioButtonInHistoryDatatable}
                      />
                    </Grid>
                  )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormLabel component="legend" className="labelAutoCompletePills">
                    {"Tipologia Stanze"}
                  </FormLabel>
                  {allTypes.map((x, index) => {
                    return <>
                      <Chip key={index} sx={{
                        marginRight: '0.2rem', marginBottom: '0.2rem',
                        '& .MuiChip-label': {
                          fontSize: '16px', // Imposta la dimensione del carattere a 16px
                        }, backgroundColor: chipList.includes(index + 1) ? '#3e6bb4' : '',
                        color: chipList.includes(index + 1) ? 'white' : ''
                      }}
                        label={x.descrizione} onClick={() => handleClick(x.id)} />
                    </>
                  })}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", alignItems: "end" }}
                  mt={2}
                >
                  <CustomCheckBox
                    returnValue={returnvalueFromCheckBoxPrenotazioniPassate}
                    oldValue={valueButtonPrenotazioniPassate}
                    label={
                      <FormLabel component="legend">
                        <b>{t("include_past_reservations")}</b>
                      </FormLabel>
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                {radioButtonView === "false" &&
                  (responsabileEdificio.length > 0 ||
                    utenteResponsabileUtenti.length > 0 ||
                    roles.some((r) => ruoliPermesso.includes(r))) && (
                    <RadioButtonCustom
                      GroupName={t("bookings")}
                      startValueReset={radioButtonUser}
                      labelList={accountListUserRadioButtonInHistoryDatatable}
                      handleChange={handleUserChange}
                      tabNumber={accountListUserRadioButtonInHistoryDatatable}
                    />
                  )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box className={`${radioButtonUser === "true" ? "nonActive" : ""}`}>
                  <AutocompleteBoxHistoryFilterUtente
                    dispatchUtente={dispatch}
                    startValue={state?.utente}
                    isActiveRadioButtonView={radioButtonUser}
                    expanded={expanded}
                    CheckThereAreErrors={CheckThereAreErrors}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormLabel component="legend">
                <b>{t("reference_period")}</b>
              </FormLabel>
              <DatapickerBoxFilterHistory
                dispatchDateOrari={dispatch}
                expanded={expanded}
                startValue={state}
                isResetting={resetAll}
                minDate={!valueButtonPrenotazioniPassate ? now : null}
                disableButton={handleButtonStatus}
              />
              <>
                <br />
                <RadioButtonCustom
                  GroupName={t("sorting")}
                  startValueReset={sortByDateAsc}
                  labelList={dateSortOptionsAdvancedSearch}
                  handleChange={handleSortChange}
                  tabNumber={dateSortOptionsAdvancedSearch}
                />
              </>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormLabel component="legend">
                <b>{t("location")}</b>
              </FormLabel>
              <AutocompleteBoxHistoryFilterPostazione
                utenteSelected={utente}
                personale={radioButtonUser}
                viewSelected={radioButtonView}
                expanded={expanded}
                isResetting={resetAll}
                startValue={state}
                dispatchPostazione={dispatch}
                utenteSelezionato={utente}
              />
            </Grid>
            <Grid container item className="pt-0">
              <Grid item xs={12}>
                <Button
                  className="buttonMenuCollapse"
                  variant="contained"
                  disabled={!isDisabledButton || ThereAreErrors}
                  onClick={() => {
                    sendRequest();
                  }}
                >
                  {t("apply")}
                </Button>
                <ButtonExport
                  isDisabled={isDisabledButton}
                  errors={ThereAreErrors}
                  date={state}
                />
                <Grid item>
                  <Button
                    style={{ float: "left" }}
                    variant="outlined"
                    onClick={() => {
                      resetAllFilter();
                    }}
                  >
                    {t("reset")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </>
  );
}

import {
  Divider,
  FormLabel,
  Grid,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import "./panoramica.css";
import React from "react";
import { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CDCard, CDCardContent, CDCardHeader } from "../../const/CDCardStyles";
import { TitleDatable } from "../TitleDatable/TitleDataTable";
import Notification from "../UI/Notification/Notification";
import SpinnerCustom from "../UI/spinner/spinner";
import { DialogRiepilogo } from "../Dialog/DialogRiepilogo/DialogRiepilogo";
import CardViewDisponibilita from "./componentsViewDisponibilita/CardViewDisponibilita";
import { useEffect } from "react";
import SliderDisponibiltaGiorno from "./componentsViewDisponibilita/sliderDisponibilita/SliderDisponibilitaGiorno";
import DialogMostraMappaDisponibilita from "./componentsViewDisponibilita/DialogMostraMappaDisponibilita/DialogMostraMappaDisponibilita";
import useMobileDetection from "../../Hooks/useMobileDetection";
import useViewportAdaptedHeight from "../../Hooks/Adaptive/useViewportAdaptedHeight";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { getLocaleValue } from "../../functions/I18n/getLocaleValue";
import { useSelector } from "react-redux";
import { DateCalendar } from "@mui/x-date-pickers";
import moment from "moment";

const now = new Date(Date.now());
export default function Disponibilita({ changeViewComponent }) {
  const [date, setDate] = useState(now);
  const [isSideEffectCompleted, setIsSideEffectCompleted] = useState(false);
  const refCard = useRef(null);
  const [returnRangeTime, setReturnRangeTime] = useState([]);
  const [monthChanged, setMonthChanged] = useState(0);
  const [
    elementToDialogMostraMappaDisponibilita,
    setElementToDialogMostraMappaDisponibilita,
  ] = useState([]);
  const [activeBackStepPath, setActiveBackStepPath] = useState(false);
  const [
    handlerStateDialogMappaDisponibilita,
    setHandlerStateDialogMappaDisponibilita,
  ] = useState(null);
  const currentCulture = useSelector((state) => state?.culture?.currentCulture);
  const { t } = useTranslation();
  const configurations = useSelector(
    (state) => state.configurations.configurazioniPrenotazione
  );
  const minDateCalendar = moment().add(-2, "years");
  const maxDateCalendar = moment().add(
    configurations.maxGiorniRipetizione || 90,
    "days"
  );

  function setDateClicked(info) {
    setDate(info);
  }

  const isMobile = useMobileDetection();

  useEffect(() => {
    let elements = document.querySelector(".MuiPickersDay-today");
    if(elements) elements.classList.add("selectedElement");
  }, []);

  //gestisce la persistenza dell'highlights nel datepicker
  useEffect(() => {
    if (date !== now && monthChanged === 0) {
      let elements = document.querySelector(".MuiPickersDay-today");
      if(elements) elements.classList.remove("selectedElement");
    }
    let el = document.querySelector(".MuiPickersDay-root:focus");
    if (el) el.classList.add("selectedElement");
    return () => {
      if (el) el.classList.remove("selectedElement");
    };
  }, [date, monthChanged]);
  
  let adaptHeight = useViewportAdaptedHeight(
    ".adaptHeight",
    -8,
    refCard,
    isSideEffectCompleted
  );

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        columnSpacing={1}
        rowSpacing={1}
      >
        <Grid item xs={12}>
          <>
            <Grid container variant="No-Padding">
              <Grid item component={CDCard} xs={12} sm={12}>
                <CDCardHeader
                  className="CDCardHeader-Padding adaptHeight"
                  title={
                    <>
                      <TitleDatable
                        nameDataTable={t("availability")}
                        changeView={changeViewComponent}
                      />
                    </>
                  }
                />
                <Divider />
                <CDCardContent
                  className="no-padding-MuiCardContent-root"
                  id="mainElement"
                  ref={refCard}
                  sx={{ height: adaptHeight }}
                >
                  <>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lessmd={6}
                        md={6}
                        lesslg={5}
                        lg={4}
                        lessxl={3}
                        xl={3}
                      >
                          {" "}
                          <DateCalendar
                            style={{ minHeight: "fit-content" }}
                            minDate={new Date(minDateCalendar)}
                            maxDate={new Date(maxDateCalendar)}
                            views={['year', 'day']}
                            onMonthChange={() =>
                              setMonthChanged(monthChanged + 1)
                            }
                            onChange={(info) => setDateClicked(info)}
                        />
                        <label>
                          {isMobile ? (
                            <Typography sx={{ paddingLeft: "16px" }}>
                              <b>{t("time_range")}</b>
                            </Typography>
                          ) : (
                            <Typography>
                              <b>{t("time_range")}</b>
                            </Typography>
                          )}
                        </label>
                        <SliderDisponibiltaGiorno
                          returnRangeTime={setReturnRangeTime}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lessmd={6}
                        md={6}
                        lesslg={7}
                        lg={8}
                        lessxl={9}
                        xl={9}
                      >
                        <Grid container sx={{ paddingTop: "10px" }}>
                          <CardViewDisponibilita
                            isSideEffectCompleted={setIsSideEffectCompleted}
                            dateSelected={date}
                            timeSelected={returnRangeTime}
                            returnElementToDialogMostraMappaDisponibilita={
                              setElementToDialogMostraMappaDisponibilita
                            }
                            activeBackStepPath={activeBackStepPath}
                            handlerStateDialogMappaDisponibilita={
                              handlerStateDialogMappaDisponibilita
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                </CDCardContent>
              </Grid>
            </Grid>
            <Notification />
          </>
        </Grid>
      </Grid>
      <DialogMostraMappaDisponibilita
        rows={elementToDialogMostraMappaDisponibilita}
        dataSelected={date}
        rangeTime={returnRangeTime}
        activeBackStepPath={setActiveBackStepPath}
        returnHandlerStateDialogMappaDisponibilita={
          setHandlerStateDialogMappaDisponibilita
        }
      />
    </>
  );
}

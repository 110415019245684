import { Grid, Typography } from "@mui/material";

export default function ResponseQrCode({ text1 = null, text2 = null, color = "black", fontSize = "20px", className }) {


  return (
    <>
      <Grid container className={className}>
        {(text1 || text2) && <Grid item xs={12} sm={12} className={className}>
          <Typography sx={{ color: color, fontSize: fontSize }}>
            {text1} {text2}
          </Typography>
        </Grid>}
        {/* {text2 && <Grid item xs={12} sm={12} className={className}>
          <Typography sx={{ color:color, fontSize: fontSize }}>
            {text2}
          </Typography>
        </Grid>} */}
      </Grid>
    </>
  )
}
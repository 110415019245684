import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
  useMediaQuery,
  Box,
  Chip,
  Stack,
  Divider,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { saveUser } from "../../../redux/reducers/user-reducer";
import {
  UpdateAlertPrenotazioniEsaurite,
  UpdateSingleBookingConfirmationEmailEnabled,
  getInfoAccount,
} from "../../../const/urlAction";
import axios from "axios";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { styled } from "@mui/material/styles";
import AvatarImage from "../../Avatar/AvatarImage";
import { useTheme } from "@emotion/react";
import "../Dialog.css";
import DayReminderBox from "./DayReminderBox";
import AvvisoFormMessage from "../../UI/Form/AvvisoFormMessage";
import handlerError from '../../../functions/handlerError';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const urlBase = process.env.REACT_APP_URL;

export default function DettaglioUtenteDialog({
  dialogState,
  returnCloseDialog,
  t
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const infoUser = useSelector((state) => state.userDate);
  const backButtonState = useSelector((state) => state.dialogBackButton);
  const refBoxPills = useRef();
  const dispatch = useDispatch();

  const [checkedPrenotazioniEsaurite, setCheckedPrenotazioniEsaurite] =
    useState(infoUser.alertPrenotazioniEsaurite);
  const [
    checkedSingleBookingConfirmationEmailEnabled,
    setCheckedSingleBookingConfirmationEmailEnabled,
  ] = useState(infoUser.singleBookingConfirmationEmailEnabled);
  const [giorniSelezionati, setGiorniSelezionati] = useState([]);
  const [backupGiorniSelezionati, setBackupGiorniSelezionati] = useState([]);

  function closeDialogState() {
    returnCloseDialog(false);
  }

  useEffect(() => {
    if (dialogState) {
      fetchInfoAccount();
    }
  }, [dialogState])

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 25 20" ><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M12 19c0-3.87 3.13-7 7-7 1.08 0 2.09.25 3 .68V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h8.08c-.05-.33-.08-.66-.08-1zM4 6l8 5 8-5v2l-8 5-8-5V6zm13.34 16-3.54-3.54 1.41-1.41 2.12 2.12 4.24-4.24L23 16.34 17.34 22z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#3e6bb4" : "#3e6bb4",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#3e6bb4" : "#3e6bb4",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 25 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M18.5 13c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zm2 4h-4v-1h4v1zm-6.95 0c-.02-.17-.05-.33-.05-.5 0-2.76 2.24-5 5-5 .92 0 1.76.26 2.5.69V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h8.55zM12 10.5 5 7V5l7 3.5L19 5v2l-7 3.5z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  const changeAlertPrenotazioniEsaurite = async (event) => {
    const { action, method } = UpdateAlertPrenotazioniEsaurite;
    let url = urlBase + action;

    try {
      const response = await axios({
        method: method,
        url,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setCheckedPrenotazioniEsaurite(!event.target.checked);
      dispatch(saveUser(infoUser));
    } catch (error) {
      console.error(error);
      setCheckedPrenotazioniEsaurite(!event.target.checked);
      if (error.response) {
        dispatch(
          notificationOpen({
            message: `${error.response.data.message}`,
            status: "error",
          })
        );
      } else {
        dispatch(
          notificationOpen({
            message: "Operazione fallita: Server non raggiungibile.",
            status: "error",
          })
        );
      }
    }
  };

  const changeSingleBookingConfirmationEmailEnabled = async (event) => {
    const { action, method } = UpdateSingleBookingConfirmationEmailEnabled;
    let url = urlBase + action;

    try {
      const response = await axios({
        method: method,
        url,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setCheckedSingleBookingConfirmationEmailEnabled(!event.target.checked);
      dispatch(saveUser(infoUser));
    } catch (error) {
      console.error(error);
      setCheckedSingleBookingConfirmationEmailEnabled(!event.target.checked);
      if (error.response) {
        dispatch(
          notificationOpen({
            message: `${error.response.data.message}`,
            status: "error",
          })
        );
      } else {
        dispatch(
          notificationOpen({
            message: "Operazione fallita: Server non raggiungibile.",
            status: "error",
          })
        );
      }
    }
  };

  //si occupa di gestire la chiusura della dialog attraverso il backButton
  useEffect(() => {
    closeDialogState("V");
  }, [backButtonState]);

  const fetchInfoAccount = () => {
    (async () => {
      const { action, method } = getInfoAccount;
      let url = urlBase + action;
      try {
        const response = await axios({
          method: method,
          url
        });

        let result = response.data.result;

        let updatedUser = {
          ...infoUser,
          email: result.email,
          fullName: result.fullName,
          phoneNumber: result.phoneNumber,
          roles: result.roles,
          responsabileEdificio: result.responsabileEdificio,
          utenteResponsabileUtenti: result.utenteResponsabileUtenti
        };

        dispatch(saveUser(updatedUser));

        const daysFromBackend = response.data.result.reminderGiornalieri.map(reminder => reminder.giornoSettimana);

        setGiorniSelezionati(daysFromBackend);
        setBackupGiorniSelezionati(daysFromBackend);

      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
  }

  return (
    <>
      <Dialog
        open={dialogState}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>{t("account_personal")}</DialogTitle>
        <DialogContent className="border-Content-dialog">
          <Grid container rowSpacing={1} columnSpacing={2}>
            <AvatarImage
              size={100}
              name={infoUser.firstName + " " + infoUser.lastName}
              isInputDisabled={false}
              isCentered={true}
              isUserLoggedImage={true}
            ></AvatarImage>

            <Grid item xs={12} sm={12}>
              <Typography textAlign={"center"} fontSize={"26px"}>{infoUser.fullName}</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography textAlign={"center"}>
                <FontAwesomeIcon icon={faEnvelope} color="#bdbdbd" style={{ marginRight: '0.5rem', height: '1.2em', position: 'relative', top: '0.15rem' }} />
                {infoUser.email}
                <Divider orientation="vertical" sx={{ height: '1.3rem', display: 'inline-flex', marginLeft: '0.8rem', marginRight: '0.8rem', position: 'relative', top: '0.3rem' }} ></Divider>
                <FontAwesomeIcon icon={faPhone} color="#bdbdbd" style={{ marginRight: '0.5rem' }} />
                {infoUser.phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} justifyContent={"center"}>
              {infoUser.roles.map((role) => {
                return (
                  <Stack direction="row" justifyContent={"center"} spacing={1}>
                    <Chip label={role} variant="outlined" />
                  </Stack>)
              })}
            </Grid>
            {infoUser.responsabileEdificio.length > 0 && <Grid item xs={12} sm={12}>
              <label>
                <Typography className="label-SuggerimentoPrenotazione labelAutoCompletePills">
                  {t("Associated_buildings")}
                </Typography>
              </label>
              <Box
                className={`boxElementi-AccountPersonale boxElementi-AccountPersonale-overflow ${refBoxPills?.current?.scrollWidth <=
                  refBoxPills?.current?.clientWidth && "flex-center-center"
                  }`}
                ref={refBoxPills}
                sx={{ width: "100%" }}
              >
                {infoUser.responsabileEdificio.map((x, index) => {
                  return (
                    <Chip
                      size="small"
                      id={"idElementChipEdificio"}
                      key={index}
                      label={x.nominativoEdificio}
                      sx={{ margin: '1px' }}
                    />
                  );
                })}
              </Box>
            </Grid>}
            {infoUser.utenteResponsabileUtenti.length > 0 && <Grid item xs={12} sm={12}>
              <label>
                <Typography className="label-SuggerimentoPrenotazione labelAutoCompletePills">
                  {t("Associated_users")}
                </Typography>
              </label>
              <Box
                className={`boxElementi-AccountPersonale boxElementi-AccountPersonale-overflow  ${refBoxPills?.current?.scrollWidth <=
                  refBoxPills?.current?.clientWidth && "flex-center-center"
                  }`}
                ref={refBoxPills}
                sx={{ width: "100%" }}
              >
                {infoUser.utenteResponsabileUtenti.map((x, index) => {
                  return (
                    <Chip
                      size="small"
                      id={"idElementChipUtenti"}
                      key={index}
                      label={x.nominativo}
                      sx={{ margin: '1px' }}
                    />
                  );
                })}
              </Box>
            </Grid>}
          </Grid>
          <DialogTitle style={{ paddingLeft: "0px" }}>
            {t("Notifications")}
          </DialogTitle>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12}>
              <Typography mb={1}>
                {t("My_reminders")}
              </Typography>
              <DayReminderBox fetchInfoAccount={fetchInfoAccount} backupGiorniSelezionati={backupGiorniSelezionati} giorniSelezionati={giorniSelezionati} setBackupGiorniSelezionati={setBackupGiorniSelezionati} setGiorniSelezionati={setGiorniSelezionati} />
              <Box><AvvisoFormMessage msg={t("My_reminders_description")} /></Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    checked={checkedPrenotazioniEsaurite}
                    onChange={(event) => changeAlertPrenotazioniEsaurite(event)}
                  />
                }
                label={
                  <Typography>{t("notify_running_out_reservations")}</Typography>
                }
              ></FormControlLabel>
              <AvvisoFormMessage msg={t("notify_running_out_reservations_description")} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    checked={checkedSingleBookingConfirmationEmailEnabled}
                    onChange={(event) =>
                      changeSingleBookingConfirmationEmailEnabled(event)
                    }
                  />
                }
                label={
                  <Typography>
                    {t("enable_single_booking_email_notification")}
                  </Typography>
                }
              ></FormControlLabel>
              <AvvisoFormMessage msg={t("enable_single_booking_email_notification_description")} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialogState()}>{t("close")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export function DayReminderButton({ buttonValue = {view:"A",value:100}, isActivated=false , method, state }) {
  const [statusButton, setStatusButton] = React.useState(false);
  const {view, value, status} = buttonValue;

const reminderStatus = state;

  const changeStatus = () => {
    setStatusButton(!statusButton);
    method({statusButton:!statusButton,value:value});
  };

useEffect(() => {
    reminderStatus ? setStatusButton(isActivated) : setStatusButton(false);
},[reminderStatus])


  return (
    <React.Fragment>
      <IconButton
        aria-label="delete"
        sx={{ width: "35px", height:"35px" }}
        onClick={changeStatus}
        disabled={!reminderStatus}
      >
        {statusButton ? (
          <div style={{ color: "#3e6bb4", fontWeight:"bold" }}> {view} </div>
        ) : (
          <div> {view}</div>
        )}
      </IconButton>
    </React.Fragment>
  );
}

import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export function DayButton({ buttonValue = {view:"A",value:100}, isActived=false , method }) {
  const [statusButton, setStatusButton] = React.useState(false);
  const state = useSelector((state) => state.prenotazioneStepper);
  const {view, value, status} = buttonValue;

const {ripetizioneStatus} = state;

  const changeStatus = () => {
    setStatusButton(!statusButton);
    method({statusButton:!statusButton,value:value});
  };

useEffect(() => {
    ripetizioneStatus ? setStatusButton(isActived) : setStatusButton(false);
},[ripetizioneStatus])


  return (
    <React.Fragment>
      <IconButton
        aria-label="delete"
        sx={{ width: "35px", height:"35px" , marginTop: "5px" }}
        onClick={changeStatus}
        disabled={!ripetizioneStatus}
      >
        {statusButton ? (
          <div style={{ color: "#3e6bb4", fontWeight:"bold" }}> {view} </div>
        ) : (
          <div> {view}</div>
        )}
      </IconButton>
    </React.Fragment>
  );
}

import {
  Button,
  DialogActions,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  dialogButtonGeneral,
} from "../../../../../../const/DialogData";
import { validationSchemaUserSignInToAuthDatabase } from "../../../../../../const/formik/User/validationSchemaUser";
import { closeDialogUser } from "../../../../../../redux/reducers/dialogUser-reducer";
import {
  registrationRequestNewUser,
} from "../../../../../../const/urlAction";
import axios from "axios";
import handlerError from "../../../../../../functions/handlerError";
import { notificationOpen } from "../../../../../../redux/reducers/notification-reducer";
import { initialValuesUserSignIn } from "../../../../../../const/formik/User/initialValuesUser";
import { AutenticationType } from "../../../../../../const/SignInUser/AutenticationType";
import { forceApp } from "../../../../../../redux/reducers/forceAppToReload";
import { hideLoader, showLoader } from "../../../../../../redux/reducers/appLoader-reducer";
import { useTranslation } from "react-i18next";


const urlBase = process.env.REACT_APP_URL;

export default function DialogViewSignInUserLocalDatabase({ isOpen = false, isCommitted, setIsCommitted }) {
  const [changedRow, setchangedRow] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let initialValues = initialValuesUserSignIn();
  let validationSchema = validationSchemaUserSignInToAuthDatabase;

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true, // rinizializza il valore che gli viene passato con rowInfo
    onSubmit: (values) => {
      setchangedRow({
        FirstName: values.firstName,
        LastName: values.lastName,
        Email: values.email,
        Username: values.email,
        PhoneNumber: values.phoneNumber,
        AutenticationType: AutenticationType.Database,
      });
    },
  });

  useEffect(() => {
    if (isCommitted) formik.submitForm();
    setIsCommitted(false);
  }, [isCommitted])

  useEffect(() => {
    if (changedRow != null) {
      (async () => {
        let body, action, method, url;
        body = {
          ...changedRow,
        };

        action = registrationRequestNewUser.action;
        method = registrationRequestNewUser.method;

        url = urlBase + action;
        dispatch(showLoader());
        try {
          const response = await axios({
            method: method,
            url,
            data: body,
          });

          if (response.data.ok) {
            formik.resetForm(initialValues);
            dispatch(forceApp());
            dispatch(closeDialogUser());
          }
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: response.data.ok ? "success" : "error",
            })
          );
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
          if (error?.response?.data?.errors != undefined) {
            let keys = Object.keys(error?.response?.data?.errors);
            keys.map((key) => {
              formik.setFieldError(key, error?.response?.data?.errors[key]);
            });
          }
        }
        dispatch(hideLoader());
      })();
    }
  }, [changedRow]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth>
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                margin="dense"
                id="email"
                label={t("email")}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.email}
                onChange={formik.handleChange("email")}
                error={
                  formik.touched.email && Boolean(formik.errors.email)
                }
                helperText={formik.touched.email && formik.errors.email}
                inputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="firstName"
                label={t("firstName")}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.firstName}
                onChange={formik.handleChange("firstName")}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="lastName"
                label={t("lastName")}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.lastName}
                onChange={formik.handleChange("lastName")}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                margin="dense"
                id="phoneNumber"
                label={t("Phone")}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange("phoneNumber")}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />
            </Grid>
          </Grid>
        </FormControl>
        {/*           <DialogActions sx={{ mt: 3 }}>
            <Button onClick={() => closeDialog()}>
              {t(dialogButtonGeneral.Cancel)}
            </Button>
            <Button type="submit" variant="contained">
              {t(dialogButtonGeneral.Confirm)}
            </Button>
          </DialogActions> */}
      </form>
    </>
  )
}
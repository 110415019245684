import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Provider, useSelector } from "react-redux";
import store from "./store/index.js";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import "./i18n"; //  esegue il codice all'interno del modulo
import { getLocaleMuiTheme } from "./functions/I18n/getLocaleMuiTheme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getLocaleValue } from "./functions/I18n/getLocaleValue";

function Main() {
  const currentCulture = useSelector((state) => state?.culture?.currentCulture);

  const mdTheme = React.useMemo(
    () =>
      createTheme(
        {
          palette: {
            primary: {
              main: "#3e6bb4",
            },
            customWarning: {
              main: "#ffc107",
            },
            warning: {
              main: "#ffc107",
            },
            customSuccess: {
              main: "#28a745",
            },
            success: {
              main: "#28a745",
            },
            customError: {
              main: "#dc3545",
            },
            error: {
              main: "#dc3545",
            },
            EmptyColor: {
              main: "#f5f5f5",
            },
          },
          typography: {
            fontFamily: "Segoe UI",
          },
          breakpoints: {
            values: {
              xs: 0,
              lesssm: 400,
              sm: 600,
              lessmd: 750,
              medium: 910,
              md: 990,
              lesslg: 1000,
              lg: 1200,
              lessxl: 1350,
              xl: 1536,
            },
          },
        },
        getLocaleMuiTheme(currentCulture)
      ),
    [currentCulture]
  );

  return (
    <ScopedCssBaseline>
        <ThemeProvider theme={mdTheme}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={getLocaleValue(currentCulture)}
          locale={getLocaleValue(currentCulture)}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LocalizationProvider>
        </ThemeProvider>
    </ScopedCssBaseline>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <Main />
  </Provider>,
  document.getElementById("root")
);
reportWebVitals();

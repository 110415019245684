import axios from 'axios';
import moment from 'moment';
import { refreshToken, logoutUser } from "../redux/reducers/user-reducer";
import store from "../store/index";
import { useNavigate } from 'react-router-dom';
import { checkifValueOfArrayIncludesInArray } from '../functions/Utils/checkifArrayIncludesInArray';
import { getLocalizedStringToBe } from '../functions/I18n/getLocalizedString';

const allowAnonymousPath = ["Account/Login","RichiestaCredenziali/ValidationRegistrationRequestOtp","Configuration/GetConfigurations"]

const Interceptor = (dispatch) => {
const navigate = useNavigate();

    axios.interceptors.response.use(
        (response) => {

            // Fix importante!
            let urlRequest = response.request.responseURL;
             axios.interceptors.response.clear();
                if(urlRequest.includes("Configuration/GetConfigurations")){
                return response;
            } 
            else if(urlRequest.includes("Account/Login")){
                let loggedUser = response.data.result;
                localStorage.setItem('deskbooking', JSON.stringify(loggedUser));
                dispatch(refreshToken(loggedUser, navigate));
            }
            else {
                const newToken = response.headers["x-token"];
                const expireDate = response.headers["x-scadenzatoken"]?.replaceAll("\"", "");
                const state = store.getState();
                const { userDate } = state;
                
                let updatedUser = {...userDate, token: newToken, scadenzaToken: expireDate};
                if( JSON.stringify(userDate) !== JSON.stringify(updatedUser) ) {
                    dispatch(refreshToken(updatedUser, navigate));
                    localStorage.setItem('deskbooking', JSON.stringify(updatedUser));
                }
            }

            return response;
        },
        (error) => { return Promise.reject(error); }        
    );

    axios.interceptors.request.use(
        (request) => {

            request = {
                ...request,
                transformRequest: [(data) => {
                    for(let key in data){
                        if(data[key] instanceof Date){
                            data[key] = moment(data[key]).format();
                        }
                    }
                    
                    return data;
                }, ...axios.defaults.transformRequest]
            };

            const urlRequest = request.url;  
            const state = store.getState();
            const { token, scadenzaToken } = state.userDate;         
            if(checkifValueOfArrayIncludesInArray(allowAnonymousPath,urlRequest)) return request;

            if(checkToken(scadenzaToken)){
                request.headers["Authorization"] = `Bearer ${token}`;
                request.headers["Accept-Language"] = getLocalizedStringToBe(state?.culture?.currentCulture);
            }
            else{
                dispatch(logoutUser());
                navigate("/")
            }       

            return request;
            
        },
        (error) => {

            return Promise.reject(error);
        }
    );
}

const checkToken = (scadenzaToken) => {
    let now = Date.now();
    let expireDate = new Date(scadenzaToken);
    if(now < expireDate){
        return true;
    }

    return false;
}

export default Interceptor;
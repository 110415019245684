import handlerViewStructures from "../operations/handlerViewStructures";

export default function MobileViewRow({ typeStructure = 0, BoxButton = null, row = {}, handleDialogToEdit = () => null }) {

  const PlaceHolder = handlerViewStructures(typeStructure)
  
  return (
    <>
      <PlaceHolder row={row} BoxButton={BoxButton} handleDialogToEdit={handleDialogToEdit}/>
    </>
  );
}

import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import sfondoDefault from "../../../assests/sfondoPlanimetriaDefault.png";
import { DISPONIBILE } from "../../../const/Planimetria/StatoPostazione";
import Notification from "../../UI/Notification/Notification";
import { creaElementoMappaRiepilogoPrenotazione } from "../../../functions/creaElementoMappa";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import PannelloRiepilogativo from "../PlanimetriaMostraRiepilogoPrenotazione/pannelloRiepilogativo/PannelloRiepilogativo";
import useNoPassiveEventListener from "../../../Hooks/useNoPassiveEventListener";
import useScrollGrabOnContainer from "../../../Hooks/useScrollGrabToContainer/useScrollGrabOnContainer";
import {
  initialValueScaleZoomDesktop,
  initialValueScaleZoomMobile,
} from "../../../const/Planimetria/valueScaleZoomPlanimetria";
import { addDoubleClickEvent } from "../../../functions/Utils/addDoubleClickEvent";
import {
  calcolaStato,
  handlerMobileZoom,
  handlerScale,
} from "../../../functions/handlerZoom";
import useMobileDetection from "../../../Hooks/useMobileDetection";
import {
  calcolaPercentualeDiMarginDaScaleImage,
  moltiplicaPercentualeScalling,
} from "../../../functions/calcolaScaleImageDelloZoom";
import { containerClass } from "../../../const/Planimetria/costantiPlanimetria";
import { useSelector } from "react-redux";
import handlerConfiguration from "../../../functions/general/handlerConfiguration";
import AvatarImage from "../../Avatar/AvatarImage";
import calcoloGrandezzaContainer from "../../../functions/planimetria/calcoloGrandezzaContainer";
import checkScroll from "../../../functions/planimetria/checkScroll";
import responsiveImagePlanimetria from "../../../functions/planimetria/ResponsiveImagePlanimetria";
import { ApplyScalePlanimetria, IncrementaDimensioneImageAlloZoom, IncrementaDimensioneValoreAlloZoom } from "../../../functions/planimetria/applyScalePlanimetria";

const grandezzaIcona = 24;
const parent = "";
export const idDialogPadre = "dialog-riepilogo";
export const idMainComponent = "main-component-riepilogo"

export default function MostraMappa({
  dettaglioSfondoPlanimetria = {},
  dataSelected,
  infoPannelloRiepilogativo = {},
  imageBackground,
  IconsToCreate = { general: <EmojiPeopleIcon /> },
  elementiDalDb = [],
  actionOnClickButton,
  typePage,
  SubMenu,
}) {
  const configurations = useSelector((state) => state.configurations);
  const { larghezzaPlanimetriaDefault: PlanimetriaLarghezza, altezzaPlanimetriaDefault: PlanimetriaAltezza } = handlerConfiguration(configurations)
  const isMobile = useMobileDetection();
  const refContainerDropzone = useRef(null);
  const [statusScrollPlanimetria, setStatusScrollPlanimetria] = useState({});
  const [isOpenSubmenu, setIsOpenSubmenu] = useState(false);
  const refImage = useRef(null);
  const isSecondRender = useRef(false);
  const [selected, setSelected] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [initDimensioneWidth, setInitDimensioneWidth] = useState(0);
  const [initDimensioneHeight, setInitDimensioneHeight] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [forzaRerenderPerAggiornamentoDom, setForzaRerenderPerAggiornamentoDom] = useState(false);
  const [scaleZoom, setScaleZoom] = useState(
    // isMobile ? initialValueScaleZoomMobile : initialValueScaleZoomDesktop
    initialValueScaleZoomDesktop
  );
  const [infoToSubmenu, setInfoToSubmenu] = useState({
    id: 0,
    x: 0,
    y: 0,
    status: 0,
    descrizioneStatus: "Disponibile",
  });
  const [info, setInfo] = useState([]);
  const [elementiRecuperati, setElementiRecuperati] = useState([]);

  const {
    altezzaPlanimetria,
    larghezzaPlanimetria,
    idSfondoPlanimetria,
  } = dettaglioSfondoPlanimetria;

  let deltaScale =
    handlerScale -
    (isMobile ? initialValueScaleZoomMobile : initialValueScaleZoomDesktop);

  useEffect(() => {
    setIsOpenSubmenu(false);
    setElementiRecuperati(
      elementiDalDb.map((el, index) => ({
        ...el,
        element: creaElementoMappaRiepilogoPrenotazione(
          IconsToCreate,
          el,
          parent,
          infoPannelloRiepilogativo
        ),
      }))
    );
    setLoading(!loading);
  }, [elementiDalDb]);

  function onClickIcon(event, index, el) {
    const { id, status, statusDescrizione, postazioniDisponibili } = el;

    let selectedElement = actionOnClickButton(
      event,
      id,
      index,
      status,
      postazioniDisponibili
    );
    if (status == DISPONIBILE) {
      setSelected({ index: selectedElement, status });
    }
    openSubmenu(event, id, statusDescrizione, status, selectedElement);
  }

  function closeMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    setIsOpenSubmenu(false);
  }

  function onTouchMobile(event, index, el) {
    event.preventDefault();
    event.stopPropagation();
    const { id, status, statusDescrizione, postazioniDisponibili } = el;
    let selectedElement = actionOnClickButton(
      event,
      id,
      index,
      status,
      postazioniDisponibili
    );
    if (status == DISPONIBILE) {
      setSelected({ index: selectedElement, status });
    }
    openSubmenu(event, id, statusDescrizione, status, selectedElement, index);
  }

  function stopEvent(event) {
    event.stopPropagation();
  }

  function openSubmenu(
    event,
    id,
    descrizioneStatus,
    status,
    selectedElement,
    index
  ) {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    if (selectedElement !== undefined) {
      // setInfo(elementiRecuperati[selectedElement]?.prenotazioni)
      setInfo(elementiRecuperati[selectedElement]);
      setIsOpenSubmenu(!isOpenSubmenu);
      // prev.id == id ? setIsOpenSubmenu(!isOpenSubmenu) : setIsOpenSubmenu(true)
    }
  }
  const [larghezzaContainer, altezzaContainer] = calcoloGrandezzaContainer(idDialogPadre, isMobile, idMainComponent);

  const { height, width, marginLeft, marginTop } = 
  calcolaPercentualeDiMarginDaScaleImage(
    isMobile ? initialValueScaleZoomMobile : initialValueScaleZoomDesktop,
    handlerScale,
    {
      altezzaCustomImg: altezzaPlanimetria || PlanimetriaAltezza,
      larghezzaCustomImg: larghezzaPlanimetria || PlanimetriaLarghezza,
    },
    {
      altezzaContainer,
      larghezzaContainer,
    },
    refContainerDropzone,
    scaleZoom
  );

  useEffect(() => {
    if (isSecondRender.current) {
      let statusScroll = checkScroll(refContainerDropzone);
      setStatusScrollPlanimetria(statusScroll)
      isSecondRender.current = false; 
    } else {
      setForzaRerenderPerAggiornamentoDom(prev => !prev);
      isSecondRender.current = true;
    }
  }, [scaleZoom, forzaRerenderPerAggiornamentoDom, marginTop,marginLeft, width, height ]);

  useNoPassiveEventListener(refImage, "wheel", setScaleZoom, statusScrollPlanimetria);

    //gestisce la grandezza dell'immagine
    useEffect(() => { 
      setInitDimensioneWidth(width + marginLeft);
      setInitDimensioneHeight(height + marginTop);
    },[marginLeft,marginTop, width, height])

  useScrollGrabOnContainer(refContainerDropzone, refImage);
  
  const deltaWidth = ApplyScalePlanimetria(
    isMobile ? initialValueScaleZoomMobile : initialValueScaleZoomDesktop,
    scaleZoom,
    initDimensioneWidth
    )

  const deltaHeight = ApplyScalePlanimetria(
    isMobile ? initialValueScaleZoomMobile : initialValueScaleZoomDesktop,
    scaleZoom,
    initDimensioneHeight
    )

  const altezzaContainerZoom = IncrementaDimensioneImageAlloZoom(
    Math.abs(marginTop),
    deltaHeight 
    )

  const larghezzaContainerZoom = IncrementaDimensioneImageAlloZoom(
    Math.abs(marginLeft),
    deltaWidth
    )

      const marginWidth = IncrementaDimensioneValoreAlloZoom(
      marginLeft,
      deltaWidth,
      scaleZoom
    )

    const marginHeight = IncrementaDimensioneValoreAlloZoom(
      marginTop,
      deltaHeight,
      scaleZoom
      )

  let responsiveWidth = responsiveImagePlanimetria(initDimensioneWidth, larghezzaContainerZoom, larghezzaContainer, Math.abs(marginLeft))
  let responsiveHeight =  responsiveImagePlanimetria(initDimensioneHeight, altezzaContainerZoom, altezzaContainer, Math.abs(marginTop))

  return (
    <React.Fragment>
      <Grid
        container
        className={true ? "" : "dontshow"}
        sx={{ overflow: "hidden" }}
        >

        <PannelloRiepilogativo infoPannelloRiepilogativo={infoPannelloRiepilogativo} />
        <Grid item xs={12} sm={12} id={idMainComponent}>
          <div
            className="container-map"
            style={{
              overflow: "auto",
              maxWidth: "100%",
              maxHeight:altezzaContainer,
              height:altezzaContainer + Math.abs(marginTop),
              width:larghezzaContainer + Math.abs(marginLeft)
            }}
            ref={refContainerDropzone}
          >
            {Object.keys(dettaglioSfondoPlanimetria).length !== 0 && (
              <div
                className={containerClass}
                ref={refImage}
                style={{
                  position: "relative",
                  marginLeft: `${marginWidth}px`,
                  marginTop: `${marginHeight}px`,
                  transform: `scale(${scaleZoom})`,
                  backgroundImage: idSfondoPlanimetria
                    ? `url(${imageBackground})`
                    : `url(${sfondoDefault})`,
                  height: `${responsiveHeight}px`,
                  width: `${responsiveWidth}px`,
                  backgroundRepeat: "no-repeat",
                }}
                onClick={(e) =>
                  addDoubleClickEvent(
                    e,
                    { function: closeMenu },
                    {
                      function: handlerMobileZoom,
                      params: {
                        saveStateFunction: setScaleZoom,
                        refContainer: refContainerDropzone,
                      },
                    },
                    { ableToDesktop: false, isMobile, timer: 200 }
                  )
                }
              >
                {elementiRecuperati.map((el, index) => (
                  <Draggable
                    onStart={() => false}
                    position={{ x: el.posX, y: el.posY }}
                    key={index}
                  >
                    <div
                      className={el.element.style}
                      style={{
                        position: "absolute",
                        width: `${grandezzaIcona}px`,
                        height: `${grandezzaIcona}px`,
                        displaY: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={(event) => onClickIcon(event, index, el)}
                      onTouchStart={(event) => onTouchMobile(event, index, el)}
                      onTouchStartCapture={(event) => stopEvent(event)}
                      key={index}
                    >
                      {el.prenotazioni.length > 1 && el.prenotazioni?.[0]?.utentePrenotato != null && el.prenotazioni.some(x => x.utentePrenotato !== el.prenotazioni?.[0].utentePrenotato)
                        ?
                        el.element.icon
                        : el.prenotazioni?.[0]?.utentePrenotato != null & el.prenotazioni.every(x => x.utentePrenotato === el.prenotazioni?.[0].utentePrenotato)
                          ?
                          <AvatarImage size={34} bgColor={'#ad3a3a'} ImmagineAvatar={el.prenotazioni.length >= 1 ? el.prenotazioni?.[0].immagineAvatar : null}
                            isUserLoggedImage={false} isCentered={false} isInputDisabled={true} isOspite={el.prenotazioni?.[0].ospite} name={el.prenotazioni?.[0].ospite ? el.prenotazioni?.[0].nominativoOspite : el.prenotazioni?.[0].utentePrenotatoFullname}></AvatarImage>
                          : el.element.icon
                      }
                    </div>
                  </Draggable>
                ))}
                <SubMenu
                  open={isOpenSubmenu}
                  mostraTasti={true}
                  infoPositionSubMenuAndId={infoToSubmenu}
                  info={info}
                  typeComponent={typePage}
                  anchorEl={anchorEl}
                  dataSelected={dataSelected}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <Notification />
    </React.Fragment>
  );
}

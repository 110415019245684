import { radioButtonPrenotazioneStepperMeAndGuests, radioButtonPrenotazioneStepperMeAndOther, radioButtonPrenotazioneStepperMeAndOtherAndGuests, radioButtonPrenotazioneStepperOnlyMe } from "../../../const/RadioButton/RadioButtonListRadio";
import { policies as policiesPermission } from "../../../const/policies";

export default  function handlerLabelPrenotazione (userDate, usersRows, guests) {
    const result = { labelArr: [], showLabel: true, showOspiti: true };
    const { responsabileEdificio, utenteResponsabileUtenti, policies } = userDate;

    const {guestEnabled, guestForUserEnabled} = guests;

    const isActiveFeatureOspitiForAll = guestEnabled && guestForUserEnabled

    if (
      (responsabileEdificio?.length > 0 && usersRows?.length > 1 
        || policies?.includes(policiesPermission.prenotazionePostazionePerAltri)) && usersRows?.length > 1
    ) {
      result.labelArr = guestEnabled ? radioButtonPrenotazioneStepperMeAndOtherAndGuests : radioButtonPrenotazioneStepperMeAndOther;
    }
    else if (utenteResponsabileUtenti?.length > 0 && usersRows?.length > 1) {
      result.labelArr = isActiveFeatureOspitiForAll ? radioButtonPrenotazioneStepperMeAndOtherAndGuests: radioButtonPrenotazioneStepperMeAndOther;
      result.showOspiti = isActiveFeatureOspitiForAll ? true : false;
    }
    else if (isActiveFeatureOspitiForAll) {
      result.labelArr = radioButtonPrenotazioneStepperMeAndGuests;
    }
    else {
      result.labelArr = radioButtonPrenotazioneStepperOnlyMe;
      result.showLabel = false;
      result.showOspiti = false; 
    }

    return result;
  };
  
  
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Layout from "./layout/Layout";
import Settings from "./pages/Settings";
import ErrorPage from "./pages/ErrorPage";
import Login from "./pages/Login/Login";
import { useSelector, useDispatch } from "react-redux";
import { policies } from "./const/policies"
import Interceptor from "./helpers/Interceptor";
import { refreshToken, saveUser } from "./redux/reducers/user-reducer";
import UsersPanel from "./pages/UserPanels/UsersPanel";
import ZonePanel from "./pages/ZonePanel/ZonePanel";
import Building from "./pages/BuildingPanel/Building";
import PostazioniPanel from "./pages/PostazioniPanel/PostazioniPanel";
import StanzePanel from "./pages/StanzePanels/StanzePanel";
import useGetConfiguration from "./Hooks/Configuration/useGetConfigurationAuth";
import RichiesteAccesso from "./pages/RichiesteAccesso/RichiesteAccesso"
import PlannerComponent from "./components/Planner/PlannerComponent";
import Prenotazioni from "./pages/Prenotazioni/Prenotazioni";
import DisponibilitaPage from "./pages/Disponibilita/Disponibilita";
import TrovaCollega from "./pages/TrovaCollega/TrovaCollega";
import { useState } from "react";
import { useEffect } from "react";
import ConvalidaRichiestaCredenziali from "./pages/ConvalidaRichiestaCredenziali/ConvalidaRichiestaCredenziali";
import { setCultureFromLocalStorage } from "./redux/reducers/culture-reducer";
import FAQ from "./pages/FAQ/Faq";
import useMobileDetection from "./Hooks/useMobileDetection";
import DialogChangePasswordAdvice from "./components/Dialog/DialogChangePasswordAdvice/dialogChangePasswordAdvice";
import { openDialogChangePasswordAdvice } from "./redux/reducers/dialogPasswordChangeAdvice-reducer";
import { getInfoAccount } from "./const/urlAction";
import axios from "axios";
import { notificationOpen } from "./redux/reducers/notification-reducer";
import handlerError from "./functions/handlerError";
let permessi = [];

export const pathConfermaCredenzialiRichiesta = "confermaregistrazione";
const urlBase = process.env.REACT_APP_URL;

function App() {
const reloadingApp = useSelector((state) => state.forceAppToReload)
const location = useLocation();
const [uniqueKey,setUniqueKey] = useState(Math.random()) //serve a renderizzare l'intera applicazione 
const [lessState,setLessState] = useState(false) //serve a renderizzare l'intera applicazione 
const isMobile = useMobileDetection(950);
const infoUser = useSelector((state) => state.userDate);

const userDate = localStorage.getItem('deskbooking');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.userDate);
  const currentCulture = useSelector((state) => state?.configurations?.configurazioniGlobali?.culture);
  const dialogChangePasswordAdviceState = useSelector((state)=>state.dialogPasswordChangeAdvice.isOpen);
  //recupera dal localstorage e popola il context(redux) con i dati degli utenti.
  if (JSON.stringify(state) === '{}' && location.pathname !== '/') {
    const objUserDate = JSON.parse(userDate)
    dispatch(refreshToken(objUserDate, navigate));
  }

  state.policies !== undefined ? permessi = state.policies : permessi = [];

  Interceptor(dispatch);

  const isConfigured = useGetConfiguration()

  const fetchInfoAccount = () => {
    (async () => {
      const { action, method } = getInfoAccount;
      let url = urlBase + action;
      try {
        const response = await axios({
          method: method,
          url
        });

        let result = response.data.result;

        dispatch(saveUser(result));

      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
  }

//serve a renderizzare l'intera applicazione 
  useEffect(() => {
    setLessState(!lessState)
    setUniqueKey(Math.random())
    let deskbooking = JSON.parse(localStorage.getItem('deskbooking'));
    if (deskbooking) fetchInfoAccount()
  },[reloadingApp, isMobile])

  useEffect(()=>{
    if (state.shouldChangePassword === true) {
      dispatch(openDialogChangePasswordAdvice());
    }
  },[state.shouldChangePassword])

  //setta la lingua dell'applicazione
  useEffect(() => {
    dispatch(setCultureFromLocalStorage())
  },[currentCulture])

  return (
    <>{
      isConfigured 
      ?
      <Layout key={uniqueKey}>
          <Routes>
            {/* #### aggiungi i path nella lista di costanti 'pagesAvaibale' in 'pages/pagesAvailable #### */}
            {state.token !== undefined && <Route path="/settings" element={<Settings />} />}
            {permessi.indexOf(policies.utentiVisualizzazione) != -1 && <Route path="/utenti" element={<UsersPanel />} />}
            {permessi.indexOf(policies.edificiVisualizzazione) != -1 && <Route path="/edifici" element={<Building />} />}
            {permessi.indexOf(policies.zoneVisualizzazione) != -1 && <Route path="/edifici/:idEdificio" element={<ZonePanel />} />}
            {permessi.indexOf(policies.stanzeVisualizzazione) != -1 && <Route path="/edifici/:idEdificio/:idZona" element={<StanzePanel />} />}
            {permessi.indexOf(policies.postazioniVisualizzazione) != -1 && <Route path="/edifici/:idEdificio/:idZona/:idStanza" element={<PostazioniPanel />} />}
            {permessi.indexOf(policies.gestioneRichiestaCredenziali) != -1 &&  <Route path="/richiesteAccesso" element={<RichiesteAccesso />} />} 
            {state.token !== undefined && <Route path="/disponibilita" element={<DisponibilitaPage />} />}
            {state.token !== undefined && <Route path="/planner" element={<PlannerComponent />} />}
            {state.token !== undefined && <Route path="/prenotazioni" element={<Prenotazioni />} />}
            {state.token !== undefined && <Route path="/trovaCollega" element={<TrovaCollega />} />}
            {state.token !== undefined && <Route path="/faq" element={<FAQ />} />}
            <Route path={`${pathConfermaCredenzialiRichiesta}/*`} element={<ConvalidaRichiestaCredenziali />} />
            <Route path="/" element={<Login />} />
            <Route path="*" element={ <ErrorPage />} />
          </Routes>
        </Layout>
      : 
        <Layout></Layout>
    }
         <DialogChangePasswordAdvice isOpen={dialogChangePasswordAdviceState}></DialogChangePasswordAdvice>
    </>
  );
}

export default App;



import React from "react";
import {
    iconPostazioneUfficio,
  iconStanzaMensa,
  iconStanzaParcheggio,
  iconStanzaRiunione,
} from "../../const/Planimetria/IconPlanimetria";
export default function handlerTimelineIcon(idTipologiaStanza) {
  let icon;
  let styleIcon;
  switch (idTipologiaStanza) {
    case 1:
      icon = iconPostazioneUfficio;
      styleIcon = "tl-icon-ufficio"
      break;
    case 2:
      icon = iconStanzaRiunione;
      styleIcon = "tl-icon-riunione"
      break;
    case 3:
      icon = iconStanzaParcheggio;
      styleIcon = "tl-icon-parcheggio"
      break;
    case 4:
      icon = iconStanzaMensa;
      styleIcon = "tl-icon-mensa"
      break;
    default:
  }

  const element = React.cloneElement(icon,{
    className: styleIcon
  })

  return element;
}

import { useTranslation } from "react-i18next";

const useWeekDayArrayI18nEuropeanSunday = () => {
    const { t } = useTranslation();
  
    return [
      { view: t("day_mon_First"), value: 1 },
      { view: t("day_tue_First"), value: 2 },
      { view: t("day_wed_First"), value: 3 },
      { view: t("day_thu_First"), value: 4 },
      { view: t("day_fri_First"), value: 5 },
      { view: t("day_sat_First"), value: 6 },
      { view: t("day_sun_First"), value: 7 },
    ];
  };
  
  export default useWeekDayArrayI18nEuropeanSunday;
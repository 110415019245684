import { Typography } from "@mui/material";
import moment from "moment";
import { maxValueText } from "../../../const/DialogRiepilogoText";

export default function CheckIfShowDayOnSubMenu(
  dataSelected,
  dataInizio,
  DataFine
) {
  const now = new Date(Date.now());

  const selected = dataSelected
    ? moment(dataSelected).format("DD/MM/YYYY")
    : null;
  const inizio = moment(dataInizio).format("DD/MM/YYYY");
  const fine = moment(DataFine).format("DD/MM/YYYY");

  let type;
  switch (true) {
    case (!selected || selected === inizio) && inizio === fine:

      type = (
        <Typography>
          {moment(dataInizio).format("HH:mm")} -{" "}
          {moment(DataFine).format("HH:mm")}
        </Typography>
      );
      break;
    case (!selected || selected === inizio) &&
      new Date(fine).getFullYear() == 9999:

      type = (
        <Typography>
          {moment(dataInizio).format("HH:mm")} - {maxValueText}
        </Typography>
      );
      break;
    case (!selected || selected === inizio) && inizio !== fine:

      type = (
        <Typography>
          {moment(dataInizio).format("DD/MM/YYYY HH:mm")} -{" "}
          {moment(DataFine).format("DD/MM/YYYY HH:mm")} 
          
          {/* {moment(dataInizio).format("HH:mm")} -{" "} */}
          {/* {"n.d."} */}
        </Typography>
      );
      break;
    case (!selected || selected !== inizio) && selected === fine:

      type = (
        <Typography>
          {moment(dataInizio).format("DD/MM/YYYY HH:mm")} -{" "}
          {moment(DataFine).format("DD/MM/YYYY HH:mm")}
        </Typography>
      );
      break;
    case (!selected || selected !== inizio) && selected !== fine:

      type = (
        <Typography>
          {moment(dataInizio).format("DD/MM/YYYY HH:mm")} -{" "}
          {moment(DataFine).format("DD/MM/YYYY HH:mm")}
        </Typography>
      );
      break;
    case (!selected || selected !== inizio) &&
      new Date(fine).getFullYear() == 9999:

      type = (
        <Typography>
          {moment(dataInizio).format("DD/MM/YYYY HH:mm")} - {maxValueText}
        </Typography>
      );
      break;
    default:

      type =
        new Date(fine).getFullYear() == 9999 ? (
          <Typography>
            {moment(dataInizio).format("HH:mm")} - {maxValueText}
          </Typography>
        ) : (
          <Typography>
            {moment(dataInizio).format("DD/MM/YYYY HH:mm")} -{" "}
            {moment(DataFine).format("DD/MM/YYYY HH:mm")}
          </Typography>
        );
      break;
  }
  return type;
}

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dialogButtonConfirm, dialogButtonGeneral } from "../../../const/DialogData";
import { addPrenotazioni } from "../../../const/urlAction";
import handlerError from "../../../functions/handlerError";
import { closeDialog } from "../../../redux/reducers/DialogGeneral";
import { resetStatePrenotazioneStepper } from "../../../redux/reducers/infoPrenotazione-reducer";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { resetPrenotazione, resetPrenotazioneWithDefaultConfiguration, ResetPrenotazioneWithDefaultConfiguration } from "../../../redux/reducers/prenotazioneStepper-reducer";
import { loadprenotazioniTableState } from "../../../redux/reducers/prenotazioneTable-reducer";
import { showLoader, hideLoader } from "../../../redux/reducers/appLoader-reducer";
import { saveDataForRiepilogo } from "../../../redux/reducers/RiepilogoDialogAfterPrenotazione-reducer";
import { useTranslation } from "react-i18next";

const urlBase = process.env.REACT_APP_URL;
export default function DialogConfermaPRenotazioneSuggerimento({ elementsSelected, infoPrenotazione = {} }) {
  const configuration = useSelector((state) => state.configurations.configurazioniPrenotazione);
  const [sendPrenotazione, setSendPrenotazione] = useState(0);
  const [elements, setElements] = useState(elementsSelected);
  const [isOpen, setIsOpen] = useState(false);
  const userDate = useSelector((state) => state.userDate);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function closeForm() {
    setIsOpen(false);
    setElements(undefined)
    elementsSelected = null;
  }

  useEffect(() => {
    setElements(elementsSelected.elementoDaMostrare)
    if (elementsSelected) setIsOpen(true)
  }, [elementsSelected.e.timeStamp])


  const {
    dataGiorno,
    dataFine,
    dataInizio,
    ripetizioneDataFine,
    ripetizioneGiorni,
    ripetizioneStatus,
    utentePrenotazione,
    dataGiornoFromPrenotazioniComponent,
  } = infoPrenotazione;
  const { idPostazione, isSalaRiunione } = elementsSelected.elementoDaMostrare;
  const { username } = userDate;

  //aggiunge la prenotazione suggerita
  useEffect(() => {
    let isMounted = true;
    if (sendPrenotazione) {
      dispatch(showLoader());
      (async () => {
        const requestBody = {
          prenotazioni: [{ utente: utentePrenotazione.length === 0 ? null : utentePrenotazione[0].utente, IdPosto: idPostazione }],
          disabilitazione: false,
          dataPrenotazione: moment(dataGiorno ?? dataGiornoFromPrenotazioniComponent).format(),
          oraInizioPrenotazione: dataInizio,
          oraFinePrenotazione: dataFine,
          dataFineRipetizioni: moment(ripetizioneDataFine).format(),
          giorniRipetizione: ripetizioneGiorni,
        };

        const { action, method } = addPrenotazioni;
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data: requestBody,
          });
          if (response.data.ok && isMounted) {
            handleReset();
            dispatch(closeDialog());
            dispatch(hideLoader());
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
            dispatch(saveDataForRiepilogo(response.data.result))
          } else {
            dispatch(hideLoader());
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "error",
              })
            );
          }
        } catch (error) {
          console.error(error);
          dispatch(hideLoader());
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
          if (notificationError.status === "warning") {
            dispatch(closeDialog());
            handleReset();
          }
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [sendPrenotazione]);

  const handleReset = () => {
    dispatch(resetPrenotazioneWithDefaultConfiguration(configuration, dispatch));
    dispatch(resetStatePrenotazioneStepper());
    dispatch(loadprenotazioniTableState());
  };


  return (
    <>
      {elements &&
        <Dialog
          open={isOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>{t("Confirm_booking")}</DialogTitle>
          <DialogContent className="border-Content-dialog">
            <Grid item sx={{ marginBottom: "5px" }}>
              <Typography>{t("msg_2")} <b>{elements?.edificioDenominazione + " - " + elements?.zonaDenominazione + " - " + elements?.stanzaDenominazione + (!isSalaRiunione ? " - " + elements?.posto : "")}</b>?</Typography>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeForm()}>
              {t(dialogButtonGeneral.Cancel)}
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={() => setSendPrenotazione((old) => old + 1)}
            >
              {t(dialogButtonConfirm.Confirm)}
            </Button>
          </DialogActions>
        </Dialog>}
    </>
  );
}

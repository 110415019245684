import { Autocomplete, Box, Button, Checkbox, Grid, Switch } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import { closeDialog } from "../../redux/reducers/dialogCambioPassword-reducer";
import { showLoader, hideLoader } from "../../redux/reducers/appLoader-reducer";
import { useDispatch } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import { changeDevices, getDispositiviById } from "../../const/urlAction";
import axios from "axios";
//import { reloadingDataTable } from "../../redux/reducers/dataTableDispositivi-reducer";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  dialogCambioPassword,
  dialogButtonGeneral,
} from "../../const/DialogData";
import { notificationOpen } from "../../redux/reducers/notification-reducer";
import { cambioPsw } from "../../const/urlAction";
import handlerError from "../../functions/handlerError";
import MessagePasswordRequired from "../Dialog/GeneralDialogComponent/MessagePasswordRequired/MessagePasswordRequired.js";
import { useTranslation } from "react-i18next";

const urlBase = process.env.REACT_APP_URL;

// const validationSchema = yup.object({
//   PasswordAttuale: yup.string().required("Campo Obbligatorio"),
//   NuovaPassword: yup.string().required("Campo Obbligatorio"),
//   ConfermaPassword: yup.string().required("Campo Obbligatorio"),
// });

const DialogCambioPassword = ({t}) => {
  const backButtonState = useSelector((state) => state.dialogBackButton);
  const dialogState = useSelector((state) => state.dialogCambioPassword);
  const dispatch = useDispatch();
  const [changedRow, setchangedRow] = useState(null);
  const { rowInfo, dialogType } = dialogState;

  const initialValues = {
    PasswordAttuale: "",
    NuovaPassword: "",
    ConfermaPassword: "",
  };

  const formik = useFormik({
    initialValues,
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      setchangedRow(values);
    },
  });

  useEffect(() => {
    closeForm();
  }, [backButtonState]);

  useEffect(() => {
    let isMounted = true;
    if (changedRow != null) {
      (async () => {
        let body = changedRow;
        dispatch(showLoader());
        const { action, method } = cambioPsw;
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
            data: body,
          });
          if (response.data.ok && isMounted) {
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
            formik.resetForm(initialValues);
            dispatch(closeDialog());
          }
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
          // if (error?.response?.data.message != undefined) {
          //   let keys = Object.keys(error?.response?.data.message);
          //   keys.map((key) => {
          //     formik.setFieldError(key, error?.response?.data?.errors[key]);
          //   });
          // }
        }
        dispatch(hideLoader());
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [changedRow]);

  const change = () => {};

  const closeForm = () => {
    formik.resetForm(initialValues);
    dispatch(closeDialog());
  };

  const handlerBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      closeForm();
    }
  };

  return (
    <>
      <Dialog
        open={dialogState.isOpen}
        // onClose={() => dispatch(closeDialog())}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        //onBlur={(e) =>handlerBlur(e)}
      >
        <DialogTitle>{t("change_password")}</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent className="border-Content-dialog">
            <FormControl fullWidth>
              <TextField
                margin="dense"
                id="PasswordAttuale"
                label={t("Current_Password")}
                type="password"
                fullWidth
                variant="standard"
                value={formik.values.PasswordAttuale}
                onChange={formik.handleChange}
                error={
                  formik.touched.PasswordAttuale &&
                  Boolean(formik.errors.PasswordAttuale)
                }
                helperText={
                  formik.touched.PasswordAttuale &&
                  formik.errors.PasswordAttuale
                }
                inputProps={{
                  autoComplete: "new-Password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              <TextField
                margin="dense"
                id="NuovaPassword"
                label={t("New_Password")}
                type="password"
                fullWidth
                variant="standard"
                value={formik.values.NuovaPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.NuovaPassword &&
                  Boolean(formik.errors.NuovaPassword)
                }
                helperText={
                  formik.touched.NuovaPassword && formik.errors.NuovaPassword
                }
                inputProps={{
                  autoComplete: "new-Password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            <Grid container>
              <MessagePasswordRequired />
            </Grid>
              <TextField
                margin="dense"
                id="ConfermaPassword"
                label={t("Confirm_Password")}
                type="password"
                fullWidth
                variant="standard"
                value={formik.values.ConfermaPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.ConfermaPassword &&
                  Boolean(formik.errors.ConfermaPassword)
                }
                helperText={
                  formik.touched.ConfermaPassword &&
                  formik.errors.ConfermaPassword
                }
                inputProps={{
                  autoComplete: "new-Password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeForm()}>
            {t(dialogButtonGeneral.Cancel)}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => change(rowInfo)}
            >
              {t(dialogButtonGeneral.Confirm)}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DialogCambioPassword;

import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useTranslation } from "react-i18next"; 
import { useSelector } from 'react-redux';

export default function BoxAutoCompletePills ({ items, activeItems, onActiveItemsChange = () => null }) {
  const [localActiveItems, setLocalActiveItems] = useState(activeItems);
  const maxDotazioni = useSelector((state) => state?.configurations.configurazioniPostazione?.maxDotazioni)

  const { t } = useTranslation(); 

  const handleItemClick = (item) => {
    if (localActiveItems.some(activeItem => activeItem.idDotazione === item.idDotazione)) {
      let arr = localActiveItems.filter(localItem => localItem.idDotazione !== item.idDotazione)
      onActiveItemsChange(arr);
      setLocalActiveItems(old => arr)
    } else {
      let arr = [...localActiveItems, item];
      if(arr.length > maxDotazioni) return
      onActiveItemsChange(arr);
      setLocalActiveItems(old => arr)
    }
  };

  return (
  <>
  <div className="containerAutoCompletePills">
    <Typography className="labelAutoCompletePills">
    {t("equipment")}
    </Typography>
    <Typography className="info-text" gutterBottom>
    {t("msg_equipment_dialog")}
    </Typography>
    <Box className="boxAutoCompletePills" >
{items.length > 0 ?  items.map((item, index) => {
        let isActive = localActiveItems.some(activeItem => activeItem.idDotazione === item.idDotazione);
        return (
          <Chip
            size="small"
            key={index}
            label={item.descrizione}
            onClick={() => handleItemClick(item)}
            className={isActive ? "pillsAutoCompletePills-selected": ""}
          />
        );
      }): <Typography className="labelAutoCompletePills" sx={{transformOrigin: "center"}}> {t("no_equipment_available")} </Typography>}
    </Box>
    </div>
  </>
  );
};

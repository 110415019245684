import { GridActionsCellItem } from "@mui/x-data-grid";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { dialogListSignIn } from "../../../../functions/dialog/dialogSignIn/confirm/createDialogConfirmActionInDialogSignIn";
import createDialogConfirmActionInDialogSignIn from "../../../../functions/dialog/dialogSignIn/confirm/createDialogConfirmActionInDialogSignIn";
import DialogConferma from "../../../UI/Dialog/DialogConferma";
import { reloadingDataTableUsers } from "../../../../redux/reducers/dataTableUsers-reducer";
import { faCheck, faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ButtonBoxRichiesteCredenziali({ activeRow, rowInfo }) {
  const { t } = useTranslation();
  const [isOpenDialogConfirm, setIsOpenDialogConfirm] = useState(false);
  const [dialog, setDialog] = useState("");
  const [noteValue, setNoteValue] = useState("");
        
  let objectToDialogConfirm = createDialogConfirmActionInDialogSignIn(
    dialog,
    { IdRichiesta: activeRow, Note: noteValue },
    rowInfo,
    t
  );


  function returnTextNote(value) {
    setNoteValue(value);
  }

  return (
    <>
      <GridActionsCellItem
        icon={
          <Tooltip title={t("approve")}>
            <FontAwesomeIcon icon={faCheck} style={{ fontSize: 25 }} className="pathIcon" />
          </Tooltip>
        }
        label={t("approve")}
        onClick={() => {
          setIsOpenDialogConfirm(!isOpenDialogConfirm);
          setDialog(t(dialogListSignIn.approved));
        }}
      />
      <GridActionsCellItem
        icon={
          <Tooltip title={t("reject")}>
            <FontAwesomeIcon icon={faXmark} style={{ fontSize: 25 }} className="pathIcon" />
          </Tooltip>
        }
        label={t("reject")}
        onClick={() => {
          setIsOpenDialogConfirm(!isOpenDialogConfirm);
          setDialog(t(dialogListSignIn.refuse));
        }}
      />
      <DialogConferma
        isOpenDialog={isOpenDialogConfirm}
        object={objectToDialogConfirm}
        returnTextNote={returnTextNote}
        handleState={setIsOpenDialogConfirm}
        reloadAfterAction={reloadingDataTableUsers()}
      />
    </>
  );
}

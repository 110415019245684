import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Typography } from "@mui/material"
import { testoRiabilita, testoRiabilitaOne } from "../../../const/Dialog/Disabilita/dialogDisabilitaText"
import { dialogButtonGeneral } from "../../../const/DialogData"
import ButtonRipristinaUnaPostazione from "./ButtonRipristinaUnaPostazione"
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../../Hooks/useMobileDetection";

export default function RipristinaUnaPostazione({isOpen= false, handlerBlur, rowInfo = false, change, closeForm, dialogType }){
  const { t } = useTranslation();
  const isMobile = useMobileDetection(950);
    return (
        <>
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={'sm'}
          >
            <DialogTitle >{t("restore_v2")}</DialogTitle>
            <DialogContent className="border-Content-dialog">
                <FormControl fullWidth>
                <DialogContentText id="alert-dialog-description">
                {testoRiabilitaOne(t,rowInfo.posto)}
                </DialogContentText>
                </FormControl>
                </DialogContent>
                <DialogActions 
                  >
                  <Button onClick={() => closeForm()} >
                    {t(dialogButtonGeneral.Cancel)}
                  </Button>
                   {/* <ButtonBoxRiattivaTuttePostazioni buttonText={t(dialogButtonGeneral.Confirm)}/>  */}
                   <ButtonRipristinaUnaPostazione rowInfo={rowInfo} />
                </DialogActions>
          </Dialog>
          </>
    )
}
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GetConfigurations } from "../../const/urlAction";
import createQueryStringFromArray from "../../functions/Utils/createQueryStringFromArray";
import { hideLoader, showLoader } from "../../redux/reducers/appLoader-reducer";
import { saveConfiguration } from "../../redux/reducers/configuration";
import { returnDateFine, returnDateInizio } from "../../redux/reducers/prenotazioneStepper-reducer";

const urlBase = process.env.REACT_APP_URL;

export default function useGetConfiguration() {
  const [isLoadedConfiguration, setIsLoadedConfiguration] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const { action, method } = GetConfigurations;
      let queryString = createQueryStringFromArray([0, 1, 2, 3, 4], "configurationTypes")
      let url = urlBase + action + queryString;
      try {
        dispatch(showLoader());
        const response = await axios({
          method: method,
          url,
        });

        dispatch(saveConfiguration(response.data.result))
        const { orarioInizioDefault, orarioFineDefault } = response.data.result.configurazioniPrenotazione
        dispatch(returnDateInizio(orarioInizioDefault))
        dispatch(returnDateFine(orarioFineDefault))
        setIsLoadedConfiguration(response.data.ok)
      } catch (error) {}
      dispatch(hideLoader());
    })();
  }, []);

  return isLoadedConfiguration
}
import { Button } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExportPrenotazioni } from "../../../const/urlAction";
import CreateRequestBodyTuttePrenotazioni from "../../../functions/createRequestBodyTuttePrenotazioni";
import { formatDate, formatDateExport } from "../../../functions/formatDate";
import { hideLoader, showLoader } from "../../../redux/reducers/appLoader-reducer";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import { useTranslation } from "react-i18next";

let today = new Date();
const urlBase = process.env.REACT_APP_URL;
const sortField = "DataOraInizioPrenotazione"; //la proprietà deve essere nell'entità prenotazione sul be.
const sortOrder = 1;
export function ButtonExport({ isDisabled = false, errors = false, date = {} }) {
  const user = useSelector((state) => state.userDate);
  const dispatch = useDispatch();
  const [esporta, setEsporta] = useState(0);
  const { t } = useTranslation();

  const {
    utente,
    DataInizio,
    DataFine,
    Postazione,
    Stanza,
    Zona,
    Edificio,
    MostraDisabilitazioni,
    PrenotazioniPassate,
  } = date;


  useEffect(() => {
    (async () => {
      if (esporta > 0) {
        dispatch(showLoader());

        const { action, method } = ExportPrenotazioni;
        let url = urlBase + action;

        const requestBody = CreateRequestBodyTuttePrenotazioni(date, sortField, sortOrder)

        try {
          const response = await axios({
            method: method,
            url,
            data: requestBody,
            responseType: 'blob'
          });
          const urlDownload = window.URL.createObjectURL(response.data);

          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = urlDownload;
          const name = MostraDisabilitazioni ? "Disabilitazioni" : utente?.nominativo !== (undefined && null) ? utente.nominativo : user.fullName
          a.download = "ExportPrenotazioni" +"_"+ formatDateExport(today);
          a.click();
          window.URL.revokeObjectURL(url);
          dispatch(hideLoader());

        } catch (error) {
          console.error(error);
          const msg = await error.response.data.text()
          const { message } = JSON.parse(msg)
          dispatch(notificationOpen({ message: message, status: 'error' }))
          dispatch(hideLoader());
        }
      }
    })();
  }, [esporta]);

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="success"
        className="buttonMenuCollapse buttonMenuCollapseExport"
        disabled={!isDisabled || errors}
        onClick={() => {
          setEsporta(old => old + 1)
        }}
      >
        {t("export")}
      </Button>
    </React.Fragment>
  );
}

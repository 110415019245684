import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dialogButtonGeneral,
} from "../../../../../../const/DialogData";
import {
  initialValuesUserAdd,
  initialValuesUserEdit,
} from "../../../../../../const/formik/User/initialValuesUser";
import {
  validationSchemaUserEditToAuthDatabase,
} from "../../../../../../const/formik/User/validationSchemaUser";
import { closeDialogUser } from "../../../../../../redux/reducers/dialogUser-reducer";
import {
  addAccount,
  editAccount,
  getUserById,
  getUsersFiltered,
} from "../../../../../../const/urlAction";
import axios from "axios";
import handlerError from "../../../../../../functions/handlerError";
import { notificationOpen } from "../../../../../../redux/reducers/notification-reducer";
import { reloadingDataTableUsers } from "../../../../../../redux/reducers/dataTableUsers-reducer";
import AvvisoFormMessage, { roleWarning } from "../../../../../UI/Form/AvvisoFormMessage";
import { hideLoader, showLoader } from "../../../../../../redux/reducers/appLoader-reducer";
import { useTranslation } from "react-i18next";

const urlBase = process.env.REACT_APP_URL;

export default function DialogViewEditUserLocalDatabase({
  infoUser,
  returnValue,
  isCommitted,
  setIsCommitted
}) {
  const stateUserRoles = useSelector((state) => state.userRoles);
  const [changedRow, setchangedRow] = useState();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [stateUsers, setStateUsers] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appLoadingState = useSelector((state) => state.appLoader.loading);
  const dialogState = useSelector((state) => state.dialogUser);
  let validationSchema = validationSchemaUserEditToAuthDatabase;
  let initialValues = initialValuesUserEdit(infoUser);

  // assegna i valori dentro la form partendo da infoUser
  useEffect(() => {
    Object.keys(infoUser).map((key) => {
      if (key === "ruoliUtente" && infoUser[key].length > 0) {
        formik.setFieldValue(`${[key]}`, setSelectedRoles(infoUser[key]), true);
      } else if (key === "utenteResponsabileUtenti") {
        formik.setFieldValue(`${[key]}`, setSelectedUsers(infoUser[key]), true);
      } else if (key === "username") {
        let value = infoUser[key].indexOf("@") === -1 ? infoUser["email"] : infoUser[key];
        formik.setFieldValue(`${[key]}`, value, true);
      } else {
        formik.setFieldValue(`${[key]}`, `${infoUser[key]}`, true);
      }
    });
  }, []);

  //recupera gli utenti per il campo
  useEffect(() => {
    let isMounted = true;
    if (dialogState.isOpen) {
      (async () => {
        const { action, method } = getUsersFiltered;
        let url = urlBase + action;
        try {
          const response = await axios({
            method: method,
            url,
          });
          if (isMounted) {
            setStateUsers(response.data.result);
          }
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [dialogState.isOpen]);

  const handleChangeRoleSelect = (event, value) => {
    setSelectedRoles(value);
  };

  const handleChangeUserSelect = (event, value) => {
    setSelectedUsers(value);
  };

  const closeDialog = () => {
    formik.resetForm(initialValues);
    dispatch(closeDialogUser());
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true, // rinizializza il valore che gli viene passato con rowInfo
    onSubmit: (values) => {
      setchangedRow({
        id: values.id,
        FirstName: values.firstName,
        LastName: values.lastName,
        Username: values.username,
        Email: values.username,
        PhoneNumber: values.phoneNumber,
        roles: selectedRoles,
        users: selectedUsers,
      });
    },
  });

  useEffect(()=>{
    if(isCommitted) formik.submitForm();
    setIsCommitted(false);
  },[isCommitted])

  useEffect(() => {
    if (changedRow != null) {
      (async () => {
        let body, action, method, url;

        body = {
          ...changedRow,
          roles: selectedRoles,
          users: selectedUsers,
        };
        action = editAccount.action;
        method = editAccount.method;
        url = urlBase + action;
        dispatch(showLoader())
        try {
          const response = await axios({
            method: method,
            url,
            data: body,
          });

          if (response.data.ok) {
            formik.resetForm(initialValues);
            dispatch(reloadingDataTableUsers());
            dispatch(closeDialogUser());
          }
          dispatch(
            notificationOpen({
              message: response.data.message,
              status: response.data.ok ? "success" : "error",
            })
          );
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
          if (error?.response?.data?.errors != undefined) {
            let keys = Object.keys(error?.response?.data?.errors);
            keys.map((key) => {
              formik.setFieldError(key, error?.response?.data?.errors[key]);
            });
          }
        }
        dispatch(hideLoader())
      })();
    }
  }, [changedRow]);

  // sincronizza i cambiamenti fatti nella form in tutte le schede della dialog.
  useEffect(() => {
    let keys = Object.keys(formik.initialValues);
    keys.forEach((key) => {
      infoUser[key] = formik.values[key];
    });
    infoUser["ruoliUtente"] = selectedRoles
    infoUser["utenteResponsabileUtenti"] = selectedUsers
    returnValue(infoUser);
  }, [formik.values, selectedRoles, selectedUsers]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth>
          <TextField
            margin="dense"
            id="id"
            label={t("User_ID")}
            type="text"
            fullWidth
            variant="standard"
            value={formik.values.id}
            sx={{ display: "none" }}
          />
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                margin="dense"
                id="username"
                label={t("email")}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.username}
                onChange={formik.handleChange("username")}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="firstName"
                label={t("firstName")}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.firstName}
                onChange={formik.handleChange("firstName")}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                id="lastName"
                label={t("lastName")}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.lastName}
                onChange={formik.handleChange("lastName")}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                margin="dense"
                id="phoneNumber"
                label={t("Phone")}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange("phoneNumber")}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                id="roles"
                size="small"
                options={stateUserRoles}
                value={selectedRoles}
                disableCloseOnSelect
                limitTags={1}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.description}
                onChange={handleChangeRoleSelect}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.description}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Assigned_Roles")}
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>{params.InputProps.endAdornment}</Fragment>
                      ),
                    }}
                  />
                )}
              />
              <AvvisoFormMessage msg={roleWarning} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                id="users"
                size="small"
                options={stateUsers}
                value={selectedUsers}
                disableCloseOnSelect
                limitTags={1}
                isOptionEqualToValue={(option, value) =>
                  option.idUtente === value.idUtente
                }
                getOptionLabel={(option) => option.nominativo}
                onChange={handleChangeUserSelect}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.nominativo}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("associated_users")}
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>{params.InputProps.endAdornment}</Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormControl>
{/*         <DialogActions sx={{ mt: 3 }}>
          <Button onClick={() => closeDialog()}>
            {t(dialogButtonGeneral.Cancel)}
          </Button>
          <Button variant="contained" type="submit" disabled={appLoadingState}>
            {t(dialogButtonGeneral.Confirm)}
          </Button>
        </DialogActions> */}
      </form>
    </>
  );
}

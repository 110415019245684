import React from "react";
import {
  stanzaMensaIcon,
  postazioneIconStanzaMensa,
  stanzaRiunioneIcon,
  iconStanzaDefault,
  stanzaParcheggioIcon,
  postazioneIconStanzaParcheggio,
  iconPostazioneDefault,
  iconStanzaParcheggio,
  iconPostoStanzaParcheggio,
  iconStanzaRiunione,
  iconPostazioneRiunione,
  iconStanzaMensa,
  iconPostoStanzaMensa,
  iconStanzaUfficio,
  iconPostazioneUfficio,
  stanzaUfficioIcon,
  postazioneIconStanzaUfficio,
  postazioneInSalaRiunione
} from "../const/Planimetria/IconPlanimetria";

//metodo di assegnazione icona di default per la creazione degli elementi nella toolbar
export function assegnaIconeInPlanimetriaGestioneToolbar(
  idStanza,
  idTipologiaStanza
) {
  let IconsToToolBar = []

  let IconsToCreate

  switch(idTipologiaStanza) {
    case 1:
      IconsToCreate = idStanza === undefined
      ? iconStanzaUfficio
      : iconPostazioneUfficio
    break;
    case 2:
      IconsToCreate = idStanza === undefined
      ? iconStanzaRiunione
      : iconPostazioneRiunione
    break;
    case 3:
      IconsToCreate = idStanza === undefined
      ? iconStanzaParcheggio
      : iconPostoStanzaParcheggio
    break;
    case 4:
      IconsToCreate = idStanza === undefined
      ? iconStanzaMensa
      : iconPostoStanzaMensa
    break;
    default:
      IconsToCreate = idStanza === undefined
      ? iconStanzaDefault
      : iconPostazioneDefault
  }
  let elementWithStyle 

  //aggiunge lo style alla pedina
   elementWithStyle = React.cloneElement(IconsToCreate, {
    style: {
      ...IconsToCreate.props.style,
      color: "gray"
    }
  })

  IconsToToolBar.push(elementWithStyle)

  return  IconsToToolBar;
}

//metodo di assegnazione icona di default per la creazione degli elementi nella planimetria
export function assegnaIconeInPlanimetriaGestione(
  idStanza,
  idTipologiaStanza,
) {

  let IconsToCreate = 
  idStanza === undefined
  ? iconStanzaDefault
  : iconPostazioneDefault

  switch(idTipologiaStanza) {
    case 1:
      IconsToCreate = idStanza === undefined
      ? stanzaUfficioIcon
      : postazioneIconStanzaUfficio
    break;
    case 2:
      IconsToCreate = idStanza === undefined
      ? stanzaRiunioneIcon
      : postazioneInSalaRiunione
    break;
    case 3:
      IconsToCreate = idStanza === undefined
      ? stanzaParcheggioIcon
      : postazioneIconStanzaParcheggio
    break;
    case 4:
      IconsToCreate = idStanza === undefined
      ? stanzaMensaIcon
      : postazioneIconStanzaMensa
    break;
  }

  return IconsToCreate ;
}

import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import CustomDialog from '../UI/Dialog/CustomDialog';
import { useDispatch, useSelector } from 'react-redux';
import {BodyRiepilogoDialog} from './BodyRiepilogoDialog/BodyRiepilogoDialog';
import { useEffect } from 'react';
import { resetInitialState } from '../../redux/reducers/RiepilogoDialogAfterPrenotazione-reducer';
import { useTranslation } from 'react-i18next';

const RiepilogoDialogAfterPrenotazione = ({ title, message, open, handleClose }) => {
    const riepilogoDialog= useSelector((state) => state.RiepilogoDialogAfterPrenotazione)
const [isOpen, setIsOpen] = useState(false);
const dispatch = useDispatch();
const { t } = useTranslation(); 


useEffect(() => {
    setIsOpen(riepilogoDialog.length > 0)
},[riepilogoDialog])

function handleClose() {
  setIsOpen(false);
  dispatch(resetInitialState());
 }

  return (
    <CustomDialog title={t("Booking_summary")} body={<BodyRiepilogoDialog date={riepilogoDialog}/>} handleClose={handleClose} open={isOpen}/>
  );
}

export default RiepilogoDialogAfterPrenotazione;




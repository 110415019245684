export function calcolaPercentualeDiMarginDaScaleImage(initialScale, scale, imagePlanimetria, container, dimensioniContenitore) {

  if(!dimensioniContenitore.current) return {height:0,width:0,marginLeft:0,marginTop:0}

  let altezzaContenitore = parseInt(container.altezzaContainer);
  let larghezzaContenitore = parseInt(container.larghezzaContainer);
  
  let height = imagePlanimetria.altezzaCustomImg;
  let width = imagePlanimetria.larghezzaCustomImg; 

  const nuovoMargineSuperiore = (altezzaContenitore - height) /2;
  const nuovoMargineSinistro = (larghezzaContenitore - width) /2;
  
  return {
    height: height,
    width: width,
    marginLeft: nuovoMargineSinistro,
    marginTop: nuovoMargineSuperiore / 2
  };
}

export function definisciDenominatoreCalcoloPercentualePlanimetria(num){
  return calcolaScallingPercentualePlanimetria(num)
}

export function moltiplicaPercentualeScalling(num) {
  return calcolaScallingPercentualePlanimetria(num)
}


function calcolaScallingPercentualePlanimetria(
  num
) {
  num = Math.abs(num)
  let str = "" + num;
  let arrayStr = str.split("");

  const arrayReversed = arrayStr.reverse();

  let arrayFiltered = arrayReversed.filter((x) => x != ".");

  arrayFiltered[0] = 1;
  let number = arrayFiltered.join("");
  
  return parseInt(number);
}



import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { BuildingHeader } from "../../../../const/DatatableData";
import {
  GetEdificiPerPrenotazione
} from "../../../../const/urlAction";
import axios from "axios";
import { useDispatch } from "react-redux";
import { notificationOpen } from "../../../../redux/reducers/notification-reducer";
import handlerError from "../../../../functions/handlerError";
import { useSelector } from "react-redux";
import {
  resetEdificioInPrenotazioneStepper,
  resetZonaInPrenotazioneStepper,
  saveEdificioCompletoInPrenotazioneStepper,
  saveEdificioInPrenotazioneStepper,
  saveZonaCompletoInPrenotazioneStepper,
  saveZonaInPrenotazioneStepper,
} from "../../../../redux/reducers/infoPrenotazione-reducer";
import {
  cambiaStatoTastoPrenotazione,
  resetPostazioneSelectedAndInfoPostazione,
  handlerOspitiPrenotazione,
} from "../../../../redux/reducers/prenotazioneStepper-reducer";
import {
  hideLoader,
  showLoader,
} from "../../../../redux/reducers/appLoader-reducer";
import CardEdificioStepper from "../../../CardEdificioStepper/CardEdificioStepper";
import useViewportAdaptedHeight from "../../../../Hooks/Adaptive/useViewportAdaptedHeight";
import useMobileDetection from "../../../../Hooks/useMobileDetection";
const urlBase = process.env.REACT_APP_URL;

export function AutocompleteBoxHome({ setStepNoAutoSkipped, framesIndex }) {
  const [edificio, setEdificio] = useState({});
  const [edificioRows, setEdificioRows] = useState([]);
  const [zona, setZona] = useState({});
  const refCard = useRef(null);
  const [isDisabledZona, setIsDisabledZona] = useState(true);
  const [stanza, setStanza] = useState({});
  const [stanzaRows, setStanzaRows] = useState([]);
  const [isDisabledStanza, setIsDisabledStanza] = useState(true);
  const [postazione, setPostazione] = useState({});
  const [postazioneRows, setPostazioneRows] = useState([]);
  const [isDisabledPostazione, setIsDisabledPostazione] = useState(true);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.prenotazioneStepper);
  const infoPrenotazioneStepper = useSelector(
    (state) => state.infoPrenotazione
  );

  //i dati recuperati dal contesto(redux)
  const {
    utentePrenotazione,
    ospitiPrenotazioneInfo
  } = state;

  const isMobile = useMobileDetection(950);

  //svuota Autocomplete a cascata
  useEffect(() => {
    if (isDisabledZona && edificio == null) {
      svuotoDaEdificio();
    } else if (isDisabledStanza && zona === null) {
      svuotoDaZona();
    }
  }, [isDisabledZona, isDisabledStanza, isDisabledPostazione]);

  function svuotoDaEdificio() {
    setZona({});
    setIsDisabledZona(true);
    setStanza({});
    setStanzaRows([]);
    setIsDisabledStanza(true);
    setPostazione({});
    setPostazioneRows([]);
    setIsDisabledPostazione(true);
  }

  function svuotoDaZona() {
    setStanza({});
    setStanzaRows([]);
    setIsDisabledStanza(true);
    setPostazione({});
    setPostazioneRows([]);
    setIsDisabledPostazione(true);
  }

  //gestisce lo stato del tasto della prenotazione nella stepper
  useEffect(() => {
    if (zona === null) setZona({});
    else {
      Object.keys(zona).length === 0
        ? dispatch(cambiaStatoTastoPrenotazione(true))
        : dispatch(cambiaStatoTastoPrenotazione(false));
    }
  }, [zona]);

  //Chiamata di Edificio
  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      //gestisce quali edifici vede chi sta effettuando la prenotazione nel momento in cui non sta prenotando per se stesso
      let IdUtentePrenotazione =
        utentePrenotazione.map((x) => x.utente) ?? [];
        let IdUtentePrenotazioneOspite =
           ospitiPrenotazioneInfo.map((x) => x.utente?.idUtente) ?? [];
        let idUtentePrenotazioni = IdUtentePrenotazione.concat(IdUtentePrenotazioneOspite)
      const { action, method } = GetEdificiPerPrenotazione;
      let url = urlBase + action;

      try {
        const response = await axios({
          method: method,
          url,
          data: idUtentePrenotazioni,
          headers: {
            "Content-Type": "application/json",
          },
        });
        setEdificioRows(response.data.result);
        // if (response.data.result.length === 1) {
        //   dispatch(hideLoader());
        //   setEdificio(response.data.result[0]); //se c'è un solo risultato inserisce quello automaticamente
        //   setZona(response.data.result[0].zone[0])
        // } else {
          dispatch(hideLoader());
          setStepNoAutoSkipped((old) => {
            //se c'è più di un record inserisce lo step in un array per la gestione del tasto indietro della stepper.
            if (old.indexOf(framesIndex) === -1) return [...old, framesIndex];
            return old;
          });
        }
      // } 
        catch (error) {
        console.error(error);
        dispatch(hideLoader());
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
      }
    })();
  }, []);

  const handleValueEdificio = (selectedValue) => {

    if (Object.keys(selectedValue).length === 0) return null;
    svuotoDaEdificio();
    setEdificio(selectedValue);
    dispatch(saveEdificioInPrenotazioneStepper(selectedValue?.id));
    dispatch(saveEdificioCompletoInPrenotazioneStepper(selectedValue));
  };

  const handleValueZona = (selectedValue) => {
    if (Object.keys(selectedValue).length === 0) return null;
    svuotoDaZona();
    setZona(selectedValue);

    if (
      infoPrenotazioneStepper.idZona !== selectedValue.id //questo impedisce di prenotare in ZONE differenti
    ) {
      dispatch(resetPostazioneSelectedAndInfoPostazione()); // se cambio edificio o zona, tutte le prenotazioni effettuate vengono annullate
    }

    dispatch(resetZonaInPrenotazioneStepper());
    dispatch(resetEdificioInPrenotazioneStepper());

    dispatch(saveZonaInPrenotazioneStepper(selectedValue?.id));
    dispatch(saveZonaCompletoInPrenotazioneStepper(selectedValue));
  };

  // si occupa di recuperare e inserire gli ospiti come prenotazioni nel context
  useEffect(() => {
    ospitiPrenotazioneInfo.map(x => {
      let ospitePrenotazione = {
        utente: x.utente.idUtente,
        nominativo: x.nominativo,
        idStanza: null,
        posto: null,
        immagineAvatar: null,
        ospite: true
      };
      dispatch(handlerOspitiPrenotazione(ospitePrenotazione));
    })
  }, [])

  
  let adaptHeight = useViewportAdaptedHeight(
    ".adaptHeightStepperPrenotazione",
    isMobile ? 30 : -65, 
    refCard,
    zona,
    );


  return (
    <React.Fragment>
      <>
        <Grid
          container
          sx={{ marginTop: "0px", backgroundColor:'#ececec' }}
          className="adaptHeightStepperPrenotazione"
        >
          <Grid item xs={12} 
          ref={refCard}
          container sx={{height: adaptHeight, overflow: 'auto' }}>
            {edificioRows.map((edificio, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <CardEdificioStepper edificio={edificio}
                    setIsDisabledZona={setIsDisabledZona}
                    key={index}
                    handleValueZona={handleValueZona}
                    svuotoDaEdificio={svuotoDaEdificio}
                    handleValueEdificio={handleValueEdificio}
                    setStepNoAutoSkipped={setStepNoAutoSkipped}
                    framesIndex={framesIndex}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </>
    </React.Fragment>
  );
}

import DialogViewBuildingAdd from "../../../components/Dialog/BuildingDialog/componentBuildingDialog/viewBuildingDialog/DialogViewBuildingAdd";
import DialogViewBuildingDelete from "../../../components/Dialog/BuildingDialog/componentBuildingDialog/viewBuildingDialog/DialogViewBuildingDelete";
import DialogViewBuildingEdit from "../../../components/Dialog/BuildingDialog/componentBuildingDialog/viewBuildingDialog/DialogViewBuildingEdit";
import DialogViewBuildingEnable from "../../../components/Dialog/BuildingDialog/componentBuildingDialog/viewBuildingDialog/DialogViewBuildingEnable";
import DialogViewBuildingDisable from "../../../components/Dialog/BuildingDialog/componentBuildingDialog/viewBuildingDialog/DialogViewBuildingDisable";


export const listOfDialogBuildViewComponent = 
[
    {component: DialogViewBuildingDelete, keyToIdentifyComponentToShow: "delete"},
    {component: DialogViewBuildingEdit, keyToIdentifyComponentToShow: "edit"},
    {component: DialogViewBuildingAdd ,keyToIdentifyComponentToShow: "add"},
    {component: DialogViewBuildingDisable, keyToIdentifyComponentToShow: "disableRangeData"},
    {component: DialogViewBuildingEnable, keyToIdentifyComponentToShow: "ripristinaAll"}
]

export default function BalanceCustomMainTabs(tabSelected) {
let value;
switch (tabSelected) {
    case 0:
        value = -42
        break;
    case 1:
        value = -27
        break;
}
return value
}
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import useMobileDetection from "../../../../Hooks/useMobileDetection";

export default function PannelloRiepilogativo({ infoPannelloRiepilogativo }) {
  const { t } = useTranslation();
const isMobile = useMobileDetection(950);
  const { dataFinePrenotazione, dataInizioPrenotazione } = infoPannelloRiepilogativo;
  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} className="adaptHeightDialog">
        <Box className="boxElementi-Planimetria flex-center-center" sx={{ width: "100%" }}>
          <Typography textAlign={isMobile ? "center": ""}>
            <i>{t("reference_period")} <b>{moment(dataInizioPrenotazione).format("HH:mm")}</b>{" - "} <b>{moment(dataFinePrenotazione).format("HH:mm")}</b> {t("on_the")} <b> {moment(dataInizioPrenotazione).format("DD/MM/YYYY")}{" "}</b></i>
          </Typography>
        </Box>
      </Grid>
    </React.Fragment>
  );
  
}

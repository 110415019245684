import { Grid, Typography } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export default function TextWarningQrCodeScanned() {

  return (
    <>
      <Grid container>
        <Typography className="qrcode-text-warning" >
          <ReportProblemIcon sx={{ position: "relative!important", top: "0.35rem!important" }} className="qrCode-icon-warning" />
          <b>Attenzione: </b>sono state rilevate alcune prenotazioni
          valide per la giornata corrente. In caso di sovrapposizione
          delle fasce orarie con l'orario selezionato, tali
          prenotazioni verranno chiuse o eliminate e verrà registrata
          la nuova prenotazione.
        </Typography>
      </Grid>
    </>
  )
}
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
} from "@mui/material";
import CustomStepper from "../CustomStepper";
import { useTheme } from "@emotion/react";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import useMobileDetection from "../../../../Hooks/useMobileDetection";
import { Stanza } from "../../../../const/Stepper/constStepper";
export default function DialogStepper({ isOpen = false }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState();
  const [maxWidth, setMaxWidth] = useState("md");
  const isMobile = useMobileDetection();

  const handleStepPadding = (step) => {
    setActiveStep(step);
  }

  useEffect(() => {
    if(!fullScreen && activeStep >= Stanza) {
      setMaxWidth("xl")
    } else {
      setMaxWidth("md")
    }
  },[activeStep])
 
  return (
    <>
      <Dialog
        open={isOpen}
        fullScreen={fullScreen}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={maxWidth}
      >
        <DialogTitle className="adaptHeightStepperPrenotazione">{t("Booking")}</DialogTitle>
        <DialogContent id="dialog-stepper" sx={{ paddingBottom: !isMobile && activeStep !== 1 ? "0px" : "10px", padding: activeStep === 1 ? '0px!important' : '' }} >
          <CustomStepper handleStepPadding={handleStepPadding}  />
        </DialogContent>
      </Dialog>
    </>
  );
}

import { Typography as Typo } from "@mui/material";

export const typoStyle= {fontSize:"12px", color:"#3e6bb4"}
                                           
export const testoDisabilitaOne = (t, posto) =>{
    const htmlString = t('text_9', {posto: posto });
     return <Typo sx={typoStyle}  dangerouslySetInnerHTML={{ __html: htmlString }}/>
}
    
export const testoRiabilitaOne = (t, posto) => {
    const htmlString = t('text_10', {posto: posto });
    return <Typo dangerouslySetInnerHTML={{ __html: htmlString }}/>
} 

export const textDisableWorkstations = (t, position) =>{
    const htmlString = t('text_14', {innerText: position });
     return <Typo sx={typoStyle}  dangerouslySetInnerHTML={{ __html: htmlString }}/>
}
    
export const textEnableWorkstations = (t, position) => {
    const htmlString = t('text_15', {innerText: position });
    return <Typo dangerouslySetInnerHTML={{ __html: htmlString }}/>
} 


export const exportUsers = {action: "", method:"post"}

/*### EDIFICIO ###*/
export const getBuildingPaginated = {action : "Edificio/GetEdificioDataTable", method:"post"}
export const deleteBuilding = {action: "Edificio/DisableAndRestore", method:"post"}
export const addBuilding = {action : "Edificio/AddEdificio", method : "post"}
export const getBuilding ={action : "Edificio/GetEdificio?id=", method: "get"}
export const editBuilding = {action : "Edificio/EditEdificio", method : "post"}
export const disablePostazioniDaEdificio = {action:"Edificio/DisablePostazioniDaEdificio", method:"post"}
export const restorePostazioniDaEdificio = {action:"Edificio/RestorePostazioniDaEdificio", method:"post"}
export const checkDisabilitazionePostazioniToEdificio = {action:"Edificio/CheckDisabilitazionePostazioniToEdificio?idEdificio=", method:"get"}
export const edificioAndDetail = {action:"Edificio/GetEdificioDetail", method:"post"}
export const getAdressFromGooglePlacesApi = {action:"Edificio/GetAdressFromGooglePlacesApi", method:"get"}
export const exportBuildings = {action: "", method:"post"}
export const getDetailsPlaceFromGooglePlaces = {action:"Edificio/GetDetailsPlaceFromGooglePlaces", method:"get"}

/*### Postazioni ###*/
export const getUsersAssociati = {action : "Postazioni/getUsersAssociati", method : "get"}
export const addPostazione = {action : "Postazioni/AddPostazione", method : "post"}
export const DisablePostazione ={action : "Postazioni/DisablePostazione" , method : "post"}
export const editUtentiAssociati = {action : "Postazioni/EditUtentiAssociati ", method : "post"}
export const GetPostazioniWithStatusByIdStanza = { action:"Postazioni/GetPostazioniWithStatusByIdStanza", method:"post"}
export const restorePostazione = { action:"Postazioni/RestorePostazione", method:"post"}
export const checkDisabilitazionePostazioni = { action:"Postazioni/CheckDisabilitazionePostazioni?idPostazione=", method:"get"}
export const getPostazioneById = {action:"Postazioni/GetPostazioneById?id=", method:"get"}
export const editPostazionePosizione = {action: "Postazioni/EditPostazionePosizione", method:"post"}
export const deletePostazione = {action: "Postazioni/DeletePostazione", method:"post"}
export const getBackgroundPlanimetriaZonaByIdZona = {action:"Zona/GetBackgroundPlanimetriaZonaByIdZona?idZona=", method:"get"}
export const getPostazioniByIdStanza = { action:"Postazioni/GetPostazioniByIdStanza", method:"post"}
export const getPostazioniDataTableByIdStanza = {action : "Postazioni/GetAllPostazioniByIdStanza", method: "post"}
export const checkPostazioneStato = {action:"Postazioni/CheckPostazioneStato", method:"post"}
export const GenerateQrCodePostazione = {action:"Postazioni/GenerateQrCodePostazione", method:"post"}
export const editPostazione = {action:"Postazioni/EditPostazione", method:"post"}
export const getAllDotazioni = {action: "Postazioni/GetAllDotazioni", method: "get"}

/*### Roles ### */
export const getUserRoles = {action: "Ruolo/GetRoles", method:"get"}

/*### Stanza ###*/
export const getStanzeDataTableByIdZona = {action: "Stanza/GetStanzeDataTableByIdZona", method:"post"}
export const checkDisabilitazionePostazioniToStanza = {action:"Stanza/CheckDisabilitazionePostazioniToStanza?idStanza=", method:"get"}
export const getStanzaById = { action:"Stanza/getStanzaById?Id=", method:"get"}
export const getStanzeByIdZona = { action:"Stanza/GetStanzeByIdZona", method:"post"}
export const addStanza = {action: "Stanza/AddStanza", method:"post"}
export const editStanza = {action: "Stanza/EditStanza", method:"post"}
export const editStanzaPosizione = {action: "Stanza/EditStanzaPosizione", method:"post"}
export const deleteStanza = {action: "Stanza/DisableAndRestoreStanze", method:"post"}
export const stanzaAndDetail = {action: "Stanza/GetStanzaAndDetail", method:"post"}
export const disablePostazioniDaStanza = {action:"Stanza/DisablePostazioniDaStanza", method:"post"}
export const RestorePostazioniDaStanza = {action:"Stanza/RestorePostazioniDaStanza", method:"post"}
export const getBackgroundPlanimetriaStanzaByIdStanza = {action:"Stanza/GetBackgroundPlanimetriaStanzaByIdStanza?idStanza=", method:"post"}
export const GenerateQrCodeStanza = {action:"Stanza/GenerateQrCodeStanza", method:"post"}

/*### Prenotazione ###*/
export const deletePrenotazione ={action : "Prenotazione/DeletePrenotazione" , method : "post"}
export const getDettaglioPrenotazioneById = { action:"Prenotazione/GetDettaglioPrenotazioneById?id=", method:"get"}
export const addPrenotazioni = {action: "Prenotazione/AddPrenotazioni", method:"post"}
export const suggerimentoPrenotazione = {action: "Prenotazione/GetSuggerimentoPrenotazione", method:"post"}
export const postazionePreferita = {action: "Prenotazione/GetPostazionePreferita", method:"get"}
export const getPrenotazioniPaginated = {action: "Prenotazione/GetPrenotazioniDataTable", method:"post"}
export const getUsersFiltered = {action: "Prenotazione/GetUtentiFiltered", method:"get"}
export const getUtentiFilteredWithoutUser = {action: "Prenotazione/GetUtentiFilteredWithoutUser", method:"get"}
export const getEdificiFiltered = {action: "Prenotazione/GetEdificiFiltered", method:"post"}
export const GetEdificiPerPrenotazione = {action: "Prenotazione/GetEdificiPerPrenotazione", method:"post"}
export const getZonePrenotazione = {action: "Prenotazione/GetZonePerPrenotazione", method:"post"}
export const getStanzaPrenotazione = {action: "Prenotazione/GetStanzePerPrenotazione", method:"post"}
export const getPostazionePrenotazione = {action: "Prenotazione/GetPostazionePerPrenotazione", method:"post"}
// export const validazionePrenotazionePreInserimento = { action:"Prenotazione/validazionePrenotazionePreInserimento", method:"post"} 
export const getPlannerData = { action:"Prenotazione/GetPlannerDataByUserAndDate", method:"post"}
export const GetPartecipantiRiunioneByPrenotazioneId = {action: "Prenotazione/GetPartecipantiRiunioneByPrenotazioneId?idPrenotazione=", method:"get"}
export const DeletePartecipanteRiunione = {action: "Prenotazione/DeletePartecipanteRiunione?idPartecipante=", method: "post"}
export const SincronizzaNotificaRiunione = {action: "Prenotazione/SincronizzaNotificaRiunione?idPrenotazione=", method:"post"}
export const AddPartecipanteRiunione = {action: "Prenotazione/AddPartecipanteRiunione", method:"post"}
export const getPrenotazioneDettaglioUtentePlanimetriaByIdPrenotazione = {action : "Prenotazione/GetPrenotazioneDettaglioUtentePlanimetriaById", method: "post"}
export const ExportPrenotazioni = {action:"Prenotazione/ExportPrenotazioni", method:"post"}
export const checkOutPrenotazione = {action:"Prenotazione/CheckOutPrenotazione", method:"post"}
export const checkInPrenotazione = {action:"Prenotazione/CheckInPrenotazione", method:"post"}
export const GetAllElelementsWithPrenotazioniCount = {action:"Prenotazione/GetAllElelementsWithPrenotazioniCount", method:"post"}
export const GetSchedulerPrenotazioni = {action:"Prenotazione/GetSchedulerPrenotazioni", method:"post"}
export const GetPrenotazioniByUtenteAndDataPrenotazione = {action:"Prenotazione/GetPrenotazioniByUtenteAndDataPrenotazione", method:"post"}

/*### Configuration ###*/
export const GetConfigurations = {action:"Configuration/GetConfigurations", method:"get"}
export const getFaq = {action:"Configuration/GetFAQ?Culture=", method:"get"}

/*### Zona ###*/
export const addZona = {action: "Zona/AddZona", method:"post"}
export const editZona = {action: "Zona/EditZona", method:"post"}
export const deleteZona = {action: "Zona/DisableAndRestoreZona", method:"post"}
export const zonaAndDetail = {action: "Zona/GetZonaAndDetail", method:"post"}
export const disablePostazioniDaZona = {action:"Zona/DisablePostazioniDaZona", method:"post"}
export const restorePostazioniDaZona = {action:"Zona/RestorePostazioniDaZona", method:"post"}
export const getZonaById = { action:"Zona/getZonaById?Id=", method:"get"}
export const getZonePaginated = {action: "Zona/GetZoneDataTable", method:"post"}
export const checkDisabilitazionePostazioniToZona = {action:"Zona/CheckDisabilitazionePostazioniToZona?idZona=", method:"get"}

/*### TASK ###*/
export const GetTaskSincronizzazioneUtenti = {action:"Task/GetTaskSincronizzazioneUtenti", method:"get"}

/*### ACCOUNT ###*/
export const getUserById = { action:"Account/getUserById?id=", method:"get"}
export const EnableDisableDispositivo = {action: "Account/DisableAccount", method:"post"}
export const sincronizzaUtenti = {action:"Account/SincronizzaUtenti", method:"post"}
export const loginUtente = {action:"Account/Login", method:"post"}
export const cambioPsw = {action: "Account/CambioPassword", method:"post"}
export const resetPassword = {action: "Account/ResetPassword", method:"post"}
export const GetUserFromActiveDirectory = {action: "Account/GetUserFromActiveDirectory", method:"get"}
export const getUsersPaginated = {action: "Account/GetAccountDataTable", method:"post"}
export const getUsers = {action: "Account/GetUsers", method:"get"}
export const addAccount = {action: "Account/AddUser", method:"post"}
export const getUserByUsername = { action:"Account/GetUserByUsername?username=", method:"get"}
export const editAccount = {action: "Account/EditAccount", method:"post"}
export const deleteAccount = {action: "Account/DisableAndRestore", method:"post"}
export const UpdateAlertPrenotazioniEsaurite = {action:"Account/UpdateAlertPrenotazioniEsaurite", method:"get"}
export const UpdateSingleBookingConfirmationEmailEnabled = {action:"Account/UpdateSingleBookingConfirmationEmailEnabled", method:"get"}
export const enableDisableDailyReminder = {action:"Account/EnableDisableDailyReminder?day=", method:"post"}
export const getInfoAccount = {action:"Account/GetInfoAccount", method:"get"}
export const setChangePasswordMessageRead = {action: "Account/SetChangePasswordMessageRead", method:"post"}

/*### RichiestaCredenzialiController ###*/
export const registrationRequestNewUser = {action:"RichiestaCredenziali/RegistrationRequestNewUser", method:"post"}
export const validationRegistrationRequestOtp = {action:"RichiestaCredenziali/ValidationRegistrationRequestOtp", method:"get"}
export const GetAllRichiestaCredenziali = { action:"RichiestaCredenziali/GetAllRichiestaCredenziali", method:"post"}
export const AcceptRichiestaCredenziali = { action:"RichiestaCredenziali/AcceptRichiestaCredenziali", method:"post"}
export const RefuseRichiestaCredenziali = { action:"RichiestaCredenziali/RefuseRichiestaCredenziali", method:"post"}

/*### TipologiaStanzaController ###*/
export const GetTipologiaStanzaAbilitate = {action:"TipologiaStanza/GetTipologiaStanzaAbilitate", method:"get"}

/*### TipologiaStanzaDotazioneController ###*/
export const getDotazioniFilteredByTipologiaStanza = {action:"TipologiaStanzaDotazione/GetDotazioniFilteredByTipologiaStanza", method:"get"}
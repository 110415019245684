import React, { useEffect } from "react"
import { DayButton } from "./DayButton"
import {  returnRipetizioneGiorni } from "../../../../../redux/reducers/prenotazioneStepper-reducer"
import { useSelector, useDispatch } from "react-redux";
import useWeekDayArrayI18n from "../../../../../Hooks/I18n/useWeekDayArrayI18n";
import { useTranslation } from "react-i18next";

export default function DayRipetizioniBox() {
    const state = useSelector((state) => state.prenotazioneStepper);
    const dispatch = useDispatch();
    const { ripetizioneGiorni } = state;
    const { t } = useTranslation();
    const buttonValue = useWeekDayArrayI18n(t);
    const [giorniSelezionati, setGiorniSelezionati] = React.useState(ripetizioneGiorni)

const returnValueFromButton = (newValue) => {
    const {statusButton, value} = newValue;
    statusButton ? setGiorniSelezionati((old) => [...old, value ]) 
                          : setGiorniSelezionati(giorniSelezionati.filter(giorni =>  giorni != value))
}

useEffect(() => {
    dispatch(returnRipetizioneGiorni(giorniSelezionati))
}, [giorniSelezionati])

    return (
        <React.Fragment>
            {buttonValue.map((value ,index) => {
             let actived = ripetizioneGiorni.includes(value.value)
                return <DayButton key={index} buttonValue={value} isActived={actived} method={returnValueFromButton}/>
            })}

        </React.Fragment>
    )
}
import { 
  Button,
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  FormControl 
} from "@mui/material";
import { dialogButtonConfirm } from "../../../../../const/DialogData";
import { useTranslation } from "react-i18next";
import ButtonBoxRiattivaTuttePostazioni from "../../../DisableDialog/ButtonBoxRiattivaTuttePostazioni";
import { textEnableWorkstations } from "../../../../../const/Dialog/Disabilita/dialogDisabilitaText";

export default function DialogViewBuildingEnable({rowInfo, returnIsOpen, isOpen}) {

  const { t } = useTranslation(); 

  const closeDialog = () => {
    returnIsOpen(false);
  };
  
  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>{t("detail_enabling")}</DialogTitle>
        <DialogContent className="border-Content-dialog">
          <DialogContentText>
            <FormControl fullWidth>{textEnableWorkstations(t, rowInfo.denominazione)}</FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()}>
            {t(dialogButtonConfirm.Cancel)}
          </Button>
          <ButtonBoxRiattivaTuttePostazioni buttonText={t("confirm")} />
        </DialogActions>
      </Dialog>
    </>
  );
}

import { Dialog, DialogTitle, Grid, DialogActions, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import handlerError from "../../../functions/handlerError";
import { notificationOpen } from "../../../redux/reducers/notification-reducer";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { getDettaglioPrenotazioneById } from "../../../const/urlAction";
import { closeDialogRiepilogo } from "../../../redux/reducers/DialogRiepilogo-reducer";
import DialogRemovePrenotazione from "../DialogRemove/DialogRemovePrenotazione";
import useMobileDetection from "../../../Hooks/useMobileDetection";
import { Box } from "@mui/system";
import createDialogConfirmActionInDialogRiepilogo, { dialogList } from "../../../functions/createDialogConfirmActionInDialogRiepilogo";
import "../../../index";
import CustomMainTabsComponent from "../../UI/tabs/CustomMainTabsComponent";
import { useTranslation } from "react-i18next";
import ListTabsDialogoRiepilogo from "../../../const/Tabs/Dialog/DialogRiepilogo/ListTabsDialogoRiepilogo";
import { idDialogPadre } from "../../Planimetria/mostraMappa/MostraMappa";

const urlBase = process.env.REACT_APP_URL;
const tabsWithWidthLarge = [1];


export function DialogRiepilogo({ isOpenRiepilogo = false, dialogType, id }) {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [dialog, setDialog] = useState("");
  const [noteValue, setNoteValue] = useState("");
  const [lastTabsSelected, setLastTabsSelected] = useState(0);
  const [isOpenRemove, setIsOpenRemove] = useState(false);
  const [isSalaRiunione, setIsSalaRiunione] = useState(false);
  const [sizePlanimetria, setSizePlanimetria] = useState({});
  const backButtonState = useSelector((state) => state.dialogBackButton);

  const isMobile = useMobileDetection();
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpenRiepilogo) {
      (async () => {

        const { action, method } = getDettaglioPrenotazioneById;
        let url = urlBase + action + id;

        try {
          const response = await axios({
            method: method,
            url,
          });

          if (response.data.ok) {
            setRows(response.data.result);
            setIsSalaRiunione(response.data.res)
          } else {
            dispatch(
              notificationOpen({
                message: response.data.message,
                status: "success",
              })
            );
          }
        } catch (error) {
          console.error(error);
          const notificationError = handlerError(error);
          dispatch(notificationOpen(notificationError));
        }
      })();
    }
    return () => {
      isOpenRiepilogo = false;
      setRows([]);
    };
  }, [isOpenRiepilogo]);

  function returnSizePlanimetria(size) {
    if (size != null || size != undefined) setSizePlanimetria(size);
  }

  let objectToDialogConfirm = createDialogConfirmActionInDialogRiepilogo(
    dialog,
    { id, NotaCancellazione: noteValue },
    t
  );

  function returnTextNote(value) {
    setNoteValue(value);
  }

  useEffect(() => {
    dispatch(closeDialogRiepilogo());
  }, [backButtonState])

  return (
    <>
      {rows.length === 0
        ? (<></>)
        : (
          <Dialog
            fullScreen={isMobile}
            open={isOpenRiepilogo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"xl"}
            sx={
              tabsWithWidthLarge.indexOf(lastTabsSelected) != -1
                ? {
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                    },
                    "& .MuiTabs-flexContainer": {
                      justifyContent: "center",
                    },
                  },
                }
                : {
                  "& .MuiTabs-flexContainer": {
                    justifyContent: "center",
                  },
                }
            }
          >
            <>
              <Grid container className="no-overflow"
                id={idDialogPadre}
              >
                <Grid item sm={12} md={12} xs={12}  className="dialog-riepilogo" 
              >
                  {rows.disabilitato ? (
                    <DialogTitle className="adaptHeightDialog">{t("detail_disabling")}</DialogTitle>
                  ) : (
                    <DialogTitle className="adaptHeightDialog">{t("detail_reservation")}</DialogTitle>
                  )}
                  <CustomMainTabsComponent
                    tabPanel={ListTabsDialogoRiepilogo(
                      id,
                      returnSizePlanimetria,
                      rows
                    ).filter(n => n)}
                    lastTabsSelected={lastTabsSelected}
                    setLastTabsSelected={setLastTabsSelected}
                  />
                  <DialogActions className={isMobile ? "border-Action-dialog riepilogoPrenotazioneButtonsContainer" : "border-Action-dialog"}>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                      <Grid item lg={2} md={2} sm={3} xs={3}>
                        <Button onClick={() => dispatch(closeDialogRiepilogo())}>
                          {t("back")}                          
                        </Button>
                      </Grid>
                      <Grid
                        container
                        item
                        lg={10}
                        md={10}
                        sm={9}
                        xs={9}
                        sx={{ display: "flex", justifyContent: "right" }}
                      >
                        {(!rows.disabilitato && rows.checkOutIsAvailable) && (
                          <Grid sx={{ textAlign: "right" }}>
                            <Box>
                              <Button
                                className="button-paddingLeft"
                                variant="contained"
                                onClick={() => {
                                  setIsOpenRemove(!isOpenRemove);
                                  setDialog(dialogList.checkOutPrenotazione);
                                }}
                              >
                                {t("checkout")}
                              </Button>
                            </Box>
                          </Grid>
                        )}
                        {(!rows.disabilitato && rows.checkInIsAvailable) && (
                          <Grid sx={{ textAlign: "right" }}>
                            <Box>
                              <Button
                                className="button-paddingLeft"
                                variant="contained"
                                onClick={() => {
                                  setIsOpenRemove(!isOpenRemove);
                                  setDialog(dialogList.checkInPrenotazione);
                                }}
                              >
                                {t("checkin")}
                              </Button>
                            </Box>
                          </Grid>
                        )}
                        {rows.isDeletable && (
                          <Grid sx={{ textAlign: "right" }}>
                            <Box>
                              <Button
                                className="button-paddingLeft"
                                variant="contained"
                                onClick={() => {
                                  setIsOpenRemove(!isOpenRemove);
                                  setDialog(dialogList.deletePrenotazione);
                                }}
                              >
                                {t("delete")}
                              </Button>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </DialogActions>
                </Grid>
              </Grid>
            </>
          </Dialog>
        )}
      <DialogRemovePrenotazione
        isOpenRemove={isOpenRemove}
        dialogType={dialogType}
        id={id}
        object={objectToDialogConfirm}
        returnTextNote={returnTextNote}
        handleState={setIsOpenRemove}
      />
    </>
  );
}

import moment from "moment";
import { radioButtonPrenotazioneStepper } from "../const/RadioButton/RadioButtonListRadio";

export function CheckStepperPrenotazioneGiorni(stateDati, configuration) {
  const {
    dataGiorno,
    dataInizio,
    dataFine,
    ripetizioneGiorni,
    ripetizioneDataFine,
    ripetizioneStatus,
    dataGiornoFromPrenotazioniComponent,
    prenotazione,
    utentePrenotazione,
  } = stateDati;
  let isDisabled = false;
  const minDate = "1970-01-01T";

  const now = new Date(Date.now());

  let dataFineRipetizione = new Date(ripetizioneDataFine);
  let dataGiornoConvert = new Date(dataGiorno ?? dataGiornoFromPrenotazioniComponent);
  let dataInizioConvert = new Date(minDate + dataInizio);
  let dataFineConvert = new Date(minDate + dataFine);


  let orarioInizio = moment(dataInizioConvert).format("HH:mm");
  let orarioFine = moment(dataFineConvert).format("HH:mm");
  let orarioNow = moment(now).format("HH:mm");

  let today = new Date();
  today.setHours(0, 0, 0, 0);

  if(dataGiornoConvert < today) {    
    return true;
  }

  let isValidDate = !isNaN(
    new Date(moment(dataGiorno ?? dataGiornoFromPrenotazioniComponent).format("YYYY-MM-DDTHH:mm:ss")).getTime()
  );

  if (configuration.maxGiorniRipetizione) {
    let maxGiornoPrenotabile = new Date(Date.now());
    let days = configuration.maxGiorniRipetizione;
    maxGiornoPrenotabile.setDate(new Date(Date.now()).getDate() + days);

    if (dataGiornoConvert > maxGiornoPrenotabile) {
      isDisabled = true;
    }
  }

  if (
    dataFine === "null" ||
    dataInizio === "null" ||
    (dataGiorno ?? dataGiornoFromPrenotazioniComponent) === "null" ||
    (ripetizioneStatus === true && ripetizioneDataFine === "null")
  ) {
    isDisabled = true;
  } else if (
    dataFineConvert <= dataInizioConvert ||
    (ripetizioneStatus === true && dataFineRipetizione < dataGiornoConvert) ||
    (dataGiornoConvert < now &&
      orarioInizio < orarioNow &&
      orarioFine < orarioNow) ||
    (now.getDate() === dataGiornoConvert.getDate() &&
      now.getMonth() === dataGiornoConvert.getMonth() &&
      orarioInizio < orarioNow) ||
    (now.getDate() === dataGiornoConvert.getDate() &&
      now.getMonth() === dataGiornoConvert.getMonth() &&
      orarioFine < orarioNow)
  ) {
    isDisabled = true;
  } else if (ripetizioneStatus === true && ripetizioneGiorni.length <= 0) {
    isDisabled = true;
  } else if (!isValidDate) {
    isDisabled = true;
  }

  return isDisabled;
}

export function CheckStepperIsPrenotazionePerAltri(stateDati) {
  const { radioButtonPrenotazionePerAltri, utentePrenotazione, ospitiPrenotazioneInfo } = stateDati;
  return radioButtonPrenotazionePerAltri === radioButtonPrenotazioneStepper[0].value ||
    (radioButtonPrenotazionePerAltri === radioButtonPrenotazioneStepper[1].value &&
      utentePrenotazione.length > 0 &&
      utentePrenotazione &&
      utentePrenotazione !== "") ||
      (radioButtonPrenotazionePerAltri === radioButtonPrenotazioneStepper[2].value && ospitiPrenotazioneInfo.length > 0)
    ? false
    : true;
}

export function CheckStepperPrenotazioneEdificioZona(stateDati) {
  return stateDati.idZona === 0 || stateDati.idZona === undefined
    ? true
    : false;
}




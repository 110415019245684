import { orariAperturaEdificioDefault } from "../../prenotazioni/orariAperturaEdificioDefault"

export function initialValuesBuildingEdit(row) {
  let rowInfo = row
  return {
    id: rowInfo?.id,
    Denominazione: rowInfo?.denominazione,
    Sigla: rowInfo?.sigla,
    UtentiResponsabiliPrenotazioni: rowInfo?.utentiResponsabiliPrenotazioni,
    Indirizzo: rowInfo?.indirizzo,
    Coordinate: rowInfo?.coordinate,
    IdFotoEdificio: rowInfo?.idFotoEdificio,
    OrariAperturaEdificio: rowInfo?.orariAperturaEdificio ?? []
  }
};

export function initialValuesBuildingAdd() {
  return {
    id: "",
    Denominazione: "",
    Sigla: "",
    UtentiResponsabiliPrenotazioni: [],
    Indirizzo: "",
    Coordinate: "",
    OrariAperturaEdificio: orariAperturaEdificioDefault
  }
}


export function initialValuesBuildingDelete(rowInfo) {
  return {
    id: rowInfo?.id,
    Denominazione: rowInfo?.denominazione,
    Sigla: rowInfo?.sigla,
  }
}
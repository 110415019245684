import { useTranslation } from "react-i18next";
import useMobileDetection from "../../../Hooks/useMobileDetection";
import { Button, Grid } from "@mui/material";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

export default function ShowMore({setPage}) {
    const isMobile = useMobileDetection();
    const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%" }}
        height="50px"
      >
        <Button
          variant="outline"
          className={
            isMobile
              ? "btn-w-right-icon show-more-mob"
              : "btn-w-right-icon show-more"
          }
          onClick={() => {
            setPage((old) => {
              return old + 1
            });
          }}
        >
          <>
            <KeyboardDoubleArrowDownIcon /> {t("show_more")}
          </>
        </Button>
      </Grid>
    </>
  );
}

import { AcceptRichiestaCredenziali, RefuseRichiestaCredenziali } from "../../../../const/urlAction";

export const dialogListSignIn = {
  approved: "Approved",
  refuse: "Refuse",
};

export default function createDialogConfirmActionInDialogSignIn(dialog, body, info = {}, t) {
  const { approved, refuse } = dialogListSignIn;
  let object = {};

  switch (true) {
    case dialog === approved:
      object = { noteField: false, viewComponent: { textPrincipal: t("approve_request_credentials") + info?.lastName +" "+ info?.firstName +" (" + info?.username + ") " + "?", textTitle: t("accept_credentials_request") }, actionApi: AcceptRichiestaCredenziali, body: body };
      break;
    case dialog === refuse:
      object = { noteField: true, actionApi: RefuseRichiestaCredenziali, viewComponent: { textPrincipal: t("reject_request_credentials") +  info?.lastName +" "+  info?.firstName +" ("+ info?.username + ") " + "?", textTitle: t("refuse_credentials_request") }, body: body };
      break;
  }
  return object;
}

import { useTranslation } from "react-i18next";

const useMarksArrayI18n = () => {
  const { t } = useTranslation();

  const marks = [
    { value: 0, label: t("month_jan") },
    { value: 1, label: t("month_feb") },
    { value: 2, label: t("month_mar") },
    { value: 3, label: t("month_apr") },
    { value: 4, label: t("month_may") },
    { value: 5, label: t("month_jun") },
    { value: 6, label: t("month_jul") },
    { value: 7, label: t("month_aug") },
    { value: 8, label: t("month_sep") },
    { value: 9, label: t("month_oct") },
    { value: 10, label: t("month_nov") },
    { value: 11, label: t("month_dec") },
  ];

  return marks;
};

export default useMarksArrayI18n;
